import React, { memo, useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, FormGroup, FormText, FormFeedback, Row, Col, Label, Input } from 'reactstrap';
import { isEmpty, has } from 'lodash';
import ReactSelect from 'react-select';
import * as API from '../../../services/api';
import DateTime from 'react-datetime';
import moment from 'moment';
import FileUpload from '../../../components/forms/FileUpload';

const MIN_DATE = moment().subtract(18, 'years').format('YYYY-MM-DD');

const schemaInvestorInformation = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  middle_name: yup.string(),
  suffix_name: yup.string(),
  investor_type: yup.string().required('Investor type is required'),
  gender: yup.string(),
  birth_date: yup
    .date()
    .required('Birth Date is required')
    .max(MIN_DATE, 'Must be 18 years old and above.'),
  nationality: yup.string(),
  tin: yup.string().required('TIN is required'),
  address_line_1: yup.string(),
  country: yup.number(),
  province: yup.string(),
  city: yup.string(),
  zipcode: yup.string(),
  email: yup.string().required('Email is required'),
  landline_code: yup.string(),
  landline: yup.string(),
  mobile_code: yup.string().required('Mobile code is required'),
  mobile_number: yup
    .string()
    .required('Mobile number is required')
    .min(8, 'Mobile number is too short - Must be minimum 8 characters')
    .matches(/^\d+$/, 'Mobile number is invalid'),
  proof_identity_type: yup.number().required('Document type is required'),
  proof_identity_file: yup
    .mixed()
    .required('Proof of Identity document is required')
    .test('fileSize', 'The file is too large', (value) => {
      return value && value[0].size <= 2000000;
    })
    .test('type', 'Only the following formats are accepted:.png .jpeg, .jpg,', (value) => {
      return (
        value &&
        (value[0].type === 'image/jpeg' ||
          value[0].type === 'image/jpg' ||
          value[0].type === 'image/png')
      );
    }),
  proof_address_type: yup.number().required('Document type is required'),
  proof_address_file: yup
    .mixed()
    .required('Proof of Address document is required')
    .test('fileSize', 'The file is too large', (value) => {
      return value && value[0].size <= 2000000;
    })
    .test('type', 'Only the following formats are accepted:.png .jpeg, .jpg,', (value) => {
      return (
        value &&
        (value[0].type === 'image/jpeg' ||
          value[0].type === 'image/jpg' ||
          value[0].type === 'image/png')
      );
    })
});

const FormInformation = (props) => {
  if (props.currentStep !== 1) {
    return null;
  }

  const {
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    defaultValues: {
      landline_code: '+63',
      mobile_code: '+63',
      suffix_name: '',
      middle_name: ''
    },
    resolver: yupResolver(schemaInvestorInformation)
  });

  const [optionCountry, setOptionCountry] = useState([]);
  const [optionCountryCodes, setOptionCountryCodes] = useState([]);
  const [optionGender, setOptionGender] = useState([]);
  const [optionInvestorType, setOptionInvestorType] = useState([]);
  const [optionPersonalDocument, setOptionPersonalDocument] = useState([]);

  const [optionNationalities, setOptionNationalities] = useState([]);
  const [optionAddressDocument, setOptionAddressDocument] = useState([]);

  useEffect(() => {
    API.dataSets([
      'countries_id',
      'country_codes',
      'gender',
      'proof_personal_identity',
      'proof_address',
      'investor_type',
      'withdrawal_type',
      'nationalities'
    ]).then((res) => {
      setOptionCountry(res.data.countries_id);
      setOptionCountryCodes(res.data.country_codes);
      setOptionGender(res.data.gender);
      setOptionPersonalDocument(res.data.proof_personal_identity);
      setOptionInvestorType(res.data.investor_type);
      setOptionNationalities(res.data.nationalities);
      setOptionAddressDocument(res.data.proof_address);
    });
  }, []);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const investmentForm = useRef(null);

  const onStepOneSubmit = async (data) => {
    setLoadingSubmit(true);

    const params = new FormData();
    for (let [key, value] of Object.entries({ ...data, is_qualified: 0 })) {
      if (typeof value != 'object') {
        params.append(key, value);
      }
    }

    // if (isFile(data.proof_identity_file[0])) {
    //   let res = await uploadFiles([data.proof_identity_file[0]]);
    //   params.append('proof_of_identity_attachment', res[0].id);
    // } else {
    //   params.append('proof_of_identity_attachment', data.proof_identity_file[0].id);
    // }

    // if (isFile(data.proof_address_file[0])) {
    //   let res = await uploadFiles([data.proof_address_file[0]]);
    //   params.append('proof_of_address_attachment', res[0].id);
    // } else {
    //   params.append('proof_of_address_attachment', data.proof_address_file[0].id);
    // }

    const investor = await API.investorCreate(params)
      .then(async (res) => {
        return res.data.data;
      })
      .catch((err) => {
        setLoadingSubmit(false);
      });

    // const paramsFile = {
    //   user_id: investor.id,
    //   document_type: data.document_type,
    //   document_file: data.proof_personal_identiy
    // };

    const filePersonal = new FormData();
    filePersonal.append('user_id', investor.id);
    filePersonal.append('document_type', data.proof_identity_type);
    filePersonal.append('document_file', data.proof_identity_file[0]);
    filePersonal.append('status', 1);

    API.userCreateDocument(filePersonal)
      .then((res) => {
        console.log('userCreateDocumentRes', res);
      })
      .catch((err) => {
        setLoadingSubmit(false);
        console.log('userCreateDocumentErr', err.response);
      });

    const fileAddress = new FormData();
    fileAddress.append('user_id', investor.id);
    fileAddress.append('document_type', data.proof_address_type);
    fileAddress.append('document_file', data.proof_address_file[0]);
    fileAddress.append('status', 1);

    API.userCreateDocument(fileAddress)
      .then((res) => {
        setLoadingSubmit(false);
        console.log('userCreateDocumentRes', res);
      })
      .catch((err) => {
        setLoadingSubmit(false);
        console.log('userCreateDocumentErr', err.response);
      });

    setLoadingSubmit(false);

    props.onSubmit(investor);
  };

  const uploadFiles = async (files) => {
    const promises = await files.map(async (file, index) => {
      if (!has(file, 'status')) {
        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('dir', 'Documents');
        return await API.fileUpload(formData)
          .then((res) => {
            files[index] = { label: file.label, ...res.data.data };
            return { label: file.label, status: 1, ...res.data.data };
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        return file;
      }
    });

    const responses = await Promise.all(promises);
    return responses;
  };

  return (
    <Form onSubmit={handleSubmit(onStepOneSubmit)} ref={investmentForm}>
      <FormGroup tag="fieldset">
        <h3>Investor Information</h3>

        <FormGroup row>
          <Label md="3">Investor Type</Label>
          <Col md="9">
            <Controller
              name="investor_type"
              control={control}
              defaultValue={1}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  value={optionInvestorType.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={optionInvestorType}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">First Name</Label>
          <Col md="9">
            <Controller
              control={control}
              name="first_name"
              defaultValue=""
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.first_name)} />
              )}
            />
            {errors.first_name && <FormFeedback>{errors.first_name?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Middle Name</Label>
          <Col md="9">
            <Controller
              control={control}
              name="middle_name"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  invalid={!isEmpty(errors.middle_name)}
                  placeholder="(Optional)"
                />
              )}
            />
            {errors.middle_name && <FormFeedback>{errors.middle_name?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Last Name</Label>
          <Col md="9">
            <Controller
              control={control}
              name="last_name"
              defaultValue=""
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.last_name)} />
              )}
            />
            {errors.last_name && <FormFeedback>{errors.last_name?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Suffix Name</Label>
          <Col md="9">
            <Controller
              control={control}
              name="suffix_name"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  invalid={!isEmpty(errors.suffix_name)}
                  placeholder="(If any)"
                />
              )}
            />
            {errors.suffix_name && <FormFeedback>{errors.suffix_name?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Birth Date</Label>
          <Col md="9">
            <Controller
              name="birth_date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DateTime
                  initialViewDate={MIN_DATE}
                  inputProps={{ placeholder: 'Select Date' }}
                  closeOnSelect={true}
                  timeFormat={false}
                  value={value}
                  dateFormat="YYYY-MM-DD"
                  onChange={(e) => {
                    onChange(moment(e).format('YYYY-MM-DD'));
                  }}
                  isValidDate={(currentDate) => currentDate < moment().subtract(18, 'years')}
                />
              )}
            />
            {errors.birth_date && <FormFeedback>{errors.birth_date?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Gender</Label>
          <Col md="9">
            <Controller
              name="gender"
              isClearable
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  value={optionGender.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={optionGender}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Nationality</Label>
          <Col md="9">
            <Controller
              name="nationality"
              isClearable
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  value={optionNationalities.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={optionNationalities}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
            {errors.nationality && <FormFeedback>{errors.nationality?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">TIN</Label>
          <Col md="9">
            <Controller
              name="tin"
              isClearable
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  className="number-only-field"
                  invalid={!isEmpty(errors.tin)}
                />
              )}
            />
            {errors.tin && <FormFeedback>{errors.tin?.message}</FormFeedback>}
          </Col>
        </FormGroup>
      </FormGroup>

      <hr />

      <FormGroup tag="fieldset">
        <h3>Contact Information</h3>

        <FormGroup row>
          <Label md="3">Address</Label>
          <Col md="9">
            <Controller
              control={control}
              name="address_line_1"
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.address_line_1)} />
              )}
            />
            {errors.address_line_1 && <FormFeedback>{errors.address_line_1?.message}</FormFeedback>}
            <FormText>(Apartment, unit, suite, or floor #, Street Name, Municipality)</FormText>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">City</Label>
          <Col md="9">
            <Controller
              name="city"
              isClearable
              control={control}
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.city)} />
              )}
              // render={({ field: { onChange, value, ref } }) => (
              //   <CreatableSelect
              //     inputRef={ref}
              //     value={optionProvince.find((c) => c.value === value)}
              //     onChange={(val) => onChange(val.value)}
              //     options={optionProvince}
              //     isOptionDisabled={(option) => option.disabled}
              //   />
              // )}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Province</Label>
          <Col md="9">
            <Controller
              name="province"
              isClearable
              control={control}
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.province)} />
              )}
              // render={({ field: { onChange, value, ref } }) => (
              //   <CreatableSelect
              //     inputRef={ref}
              //     value={optionProvince.find((c) => c.value === value)}
              //     onChange={(val) => onChange(val.value)}
              //     options={optionProvince}
              //     isOptionDisabled={(option) => option.disabled}
              //   />
              // )}
              //   render={({ field }) => (
              //     <CreatableSelect
              //       {...field}
              //       options={optionProvince}
              //       isOptionDisabled={(option) => option.disabled}
              //     />
              //   )}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Country</Label>
          <Col md="9">
            <Controller
              name="country"
              isClearable
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  value={optionCountry.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={optionCountry}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Postal/Zip Code</Label>
          <Col md="9">
            <Controller
              control={control}
              name="zipcode"
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  className="number-only-field"
                  invalid={!isEmpty(errors.zipcode)}
                />
              )}
            />
            {errors.zipcode && <FormFeedback>{errors.zipcode?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Landline</Label>
          <Col md="3">
            <Controller
              name="landline_code"
              isClearable
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  value={optionCountryCodes.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={optionCountryCodes}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
            {errors.landline_code && <FormFeedback>{errors.landline_code?.message}</FormFeedback>}
          </Col>
          <Col md="6">
            <Controller
              control={control}
              name="landline"
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  className="number-only-field"
                  invalid={!isEmpty(errors.landline)}
                />
              )}
            />
            {errors.landline && <FormFeedback>{errors.landline?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Mobile Number</Label>
          <Col md="3">
            <Controller
              name="mobile_code"
              isClearable
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  value={optionCountryCodes.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={optionCountryCodes}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
            {errors.mobile_code && <FormFeedback>{errors.mobile_code?.message}</FormFeedback>}
          </Col>
          <Col md="6">
            <Controller
              control={control}
              name="mobile_number"
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  className="number-only-field"
                  invalid={!isEmpty(errors.mobile_number)}
                />
              )}
            />
            {errors.mobile_number && <FormFeedback>{errors.mobile_number?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Email</Label>
          <Col md="9">
            <Controller
              control={control}
              type="email"
              name="email"
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.email)} />
              )}
            />
            {errors.email && <FormFeedback>{errors.email?.message}</FormFeedback>}
          </Col>
        </FormGroup>
      </FormGroup>

      <hr />

      <FormGroup tag="fieldset">
        <h3>Supporting Documents</h3>
        <FormGroup row>
          <Label md="3">Proof of Identity</Label>
          <Col md="9">
            <Controller
              name="proof_identity_type"
              isClearable
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  value={optionPersonalDocument.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={optionPersonalDocument}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
            {errors.proof_identity_type && (
              <FormFeedback style={{ display: 'block' }}>
                {errors.proof_identity_type?.message}
              </FormFeedback>
            )}
            <br />
            <Controller
              name="proof_identity_file"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <FileUpload
                    inputRef={ref}
                    onDrop={onChange}
                    onChange={onChange}
                    imagefile={value}
                    invalid={!isEmpty(errors.proof_identity_file)}
                  />
                );
              }}
            />

            {errors.proof_identity_file && (
              <FormFeedback style={{ display: 'block' }}>
                {errors.proof_identity_file?.message}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">Proof of Address</Label>
          <Col md="9">
            <Controller
              name="proof_address_type"
              isClearable
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  value={optionAddressDocument.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={optionAddressDocument}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
            {errors.proof_address_type && (
              <FormFeedback style={{ display: 'block' }}>
                {errors.proof_address_type?.message}
              </FormFeedback>
            )}
            <br />
            <Controller
              name="proof_address_file"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <FileUpload
                    inputRef={ref}
                    onDrop={onChange}
                    onChange={onChange}
                    imagefile={value}
                    invalid={!isEmpty(errors.proof_address_file)}
                  />
                );
              }}
            />

            {errors.proof_address_file && (
              <FormFeedback style={{ display: 'block' }}>
                {errors.proof_address_file?.message}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
      </FormGroup>

      <Row>
        <Col>
          {props.currentStep > 1 && (
            <button className="btn btn-primary float-left" type="button" onClick={props.prevStep}>
              Previous
            </button>
          )}
          <button
            type="submit"
            className={`btn ${loadingSubmit ? 'btn-gray' : 'btn-primary'} float-right`}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Next'}
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default withRouter(memo(FormInformation));
