import React, { Suspense, useEffect, useContext } from 'react';
import { Route, Redirect, Switch, withRouter, useLocation, useRouteMatch } from 'react-router-dom';
import routes from '../../config/page-route.js';
import PageContext from '../../config/page-settings.js';
import { useLocalStorage } from '../../utils';
import Login from '../../pages/login';
import Header from '../header/header.js';
import Sidebar from '../sidebar/sidebar.js';
import { useAppState } from '../../overmind/index.js';
import { useMemo } from 'react';
import { state } from '../../overmind/state.js';

const setTitle = (path, routeArray) => {
  var pageTitle;
  for (var i = 0; i < routeArray.length; i++) {
    if (routeArray[i].path === path) {
      pageTitle = 'Seedin Admin | ' + routeArray[i].title;
    }
  }
  document.title = pageTitle ? pageTitle : 'Seedin Admin';
};

const Content = (props) => {
  let location = useLocation();
  const match = useRouteMatch({
    path: '*',
    strict: true,
    sensitive: true
  });

  const pageContext = useContext(PageContext);

  useEffect(() => {
    setTitle(props.history.location.pathname, routes);
  }, [props]);

  useEffect(() => {
    // pageContext.handleSetPageSidebar(true);
    // pageContext.handleSetPageHeader(true);
    // pageContext.handleSetPageContentFullWidth(false);
  }, []);

  return (
    <div
      className={
        'content ' +
        (pageContext.pageContentFullWidth ? 'content-full-width ' : '') +
        (pageContext.pageContentInverseMode ? 'content-inverse-mode ' : '') +
        pageContext.pageContentClass
      }
    >
      {state.isAuthenticating ? (
        <div id="page-loader" className="fade show">
          <span className="spinner"></span>
        </div>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {routes.map((route, index) => (
              <AuthenticatedRoute
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
          </Switch>
        </Suspense>
      )}
    </div>
  );
};

export default withRouter(Content);

const AuthenticatedRoute = ({ path, component: Default, ...props }) => {
  const { isAuthenticated, isAuthenticating } = useAppState();

  let redirectUrl = '';

  if (isAuthenticated) {
    if (path === '/login') redirectUrl = '/';
  } else {
    if (path === '/login') return <Route {...props} component={Login} />;
    redirectUrl = '/login';
  }

  return (
    <Route
      {...props}
      render={() => (redirectUrl ? <Redirect to={redirectUrl} /> : <Default {...props} />)}
    />
  );
};
