import React, { useState, memo } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Form, FormGroup, Label, Col, Input } from 'reactstrap';
import moment from 'moment';
const MIN_DATE = moment().format('YYYY-MM-DD');

const ApproveFunding = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleModal = async () => {
    await setIsVisible(!isVisible);
  };

  const [startDate, setStartDate] = useState(MIN_DATE);

  const onChange = (e) => {
    setStartDate(e.target.value);
  };

  const onConfirm = () => {
    setIsVisible(false);
    toggleModal().then(() => {
      setTimeout(() => {
        props.onConfirm(startDate);
      }, 300);
    });
  };

  return (
    <>
      <button className="btn btn-xs btn-green m-l-5 text-white" onClick={toggleModal}>
        <i className="fa fa-thumbs-up mr-1"></i>Approve Funding
      </button>
      <SweetAlert
        title={
          <span className="text-black text-wrap">Are you sure you want to approve funding?</span>
        }
        success
        showCancel
        confirmBtnText="Yes, approve it!"
        confirmBtnBsStyle="green"
        focusCancelBtn
        onConfirm={onConfirm}
        onCancel={toggleModal}
        show={isVisible}
        style={{ width: '40em' }}
        dependencies={[startDate]}
      >
        <Form>
          <FormGroup row>
            <Label md="6" style={{ color: 'black' }}>
              Repayment Start Date
            </Label>
            <Col md="6">
              <Input
                type="date"
                placeholder="Enter Repayment Start Date"
                className="form-control"
                onChange={(e) => {
                  onChange(e);
                }}
              />
            </Col>
          </FormGroup>
        </Form>
      </SweetAlert>
    </>
  );
};

export default memo(ApproveFunding);
