import React, { memo, useState, useMemo, useEffect, useContext } from 'react';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, FormGroup, FormFeedback, Row, Col, Label, Input, Tooltip } from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';

import { isEmpty, isUndefined, has } from 'lodash';
import ReactSelect from 'react-select';
import PageContext from '../../config/page-settings.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as API from '../../services/api';
import moment from 'moment';
import FileUpload from '../../components/forms/FileUpload.js';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import PageHeader from '../../components/common/PageHeader.js';
import { Notification, PriceFormat } from '../../utils/index.js';

import DateTime from 'react-datetime';

import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      checked={props.checked}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
      disabled={props.disabled || false}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const TooltipItem = (props) => {
  const { placement, id, content } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <i className="fa fa-exclamation-circle text-info ml-1" id={'Tooltip-' + id} />
      <Tooltip placement={placement} isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
        {content}
      </Tooltip>
    </span>
  );
};

const schemaInvestorSelect = yup.object().shape({
  issuer: yup.string(),
  loan: yup.string(),
  publish_date: yup.date().required('Publish Date is required'),
  activate_invest_date: yup.date().when('publish_date', {
    is: (publishDate) => moment(publishDate) < 3,
    then: yup.string().required('First name is required')
  }),
  industry: yup.string().required('Industry type is required'),
  category_id: yup.string().required('Category type is required'),
  subscription_days: yup.number().required(),
  returns: yup.number().required('Returns is required'),
  op_service_fee: yup.number().required('Loan Fee is required'),
  title: yup.string().required('Title is required')
});

const ProjectUpdate = () => {
  const { project_id } = useParams();
  const history = useHistory();

  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [project, setProject] = useState({});
  const [loanScheduleList, setLoanScheduleList] = useState([]);
  const [loanScheduleTotal, setLoanScheduleTotal] = useState({});
  useEffect(() => {
    if (project_id) {
      API.getProject(project_id).then((res) => {
        setProject(res.data.data);
      });

      API.getProjectLoanSchedule(project_id).then((res) => {
        setLoanScheduleList(res.data.data.list);
        setLoanScheduleTotal(res.data.data.total);
      });
    }
  }, [project_id]);

  useEffect(() => {
    reset({
      issuer: project.company_name,
      loan_request_id: project.loan_request_id,
      funding_id: project.funding_id,
      has_guarantor: project.has_guarantor,
      has_pledge: project.has_pledge,
      has_visitation: project.has_visitation,
      subscription_days: project.subscription_days,
      op_service_fee: project.op_service_fee,
      category_id: project.category_id,
      industry: project.industry && project.industry.id,
      repayment_mode: project.repayment_mode && project.repayment_mode.id,
      publish_date: project.publish_date,
      loan_tenor: project.loan_tenor,
      title: project.title,
      banner: [{ ...project.banner, status: 1, name: `banner_${project.funding_id}.png` }],
      attachment: [
        { ...project.attachment, status: 1, name: `factsheet_${project.funding_id}.png` }
      ],
      description: project.description,
      loan_display_investors: 1,
      minimum_lend_amount: project.minimum_lend_amount,
      auto_invest_enabled: project.auto_invest_enabled == 1,
      allow_priority_investor: project.allow_priority_investor == 1,
      repayment_start_date: project.repayment_start_date,
      amount: project.amount,
      returns: project.returns
    });
  }, [project]);

  const {
    watch,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    control,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        issuer: project.company_name,
        loan: project.loan_request_id,
        funding_id: project.funding_id,
        has_guarantor: project.has_guarantor,
        has_pledge: project.has_pledge,
        has_visitation: project.has_visitation,
        subscription_days: project.subscription_days,
        repayment_mode: project.repayment_mode,
        title: project.title,
        publish_date: project.publish_date,
        description: project.description,
        loan_display_investors: 1,
        minimum_lend_amount: project.minimum_lend_amount,
        auto_invest_enabled: project.auto_invest_enabled == 1,
        allow_priority_investor: project.allow_priority_investor == 1,
        repayment_start_date: project.repayment_start_date,
        amount: project.amount,
        returns: project.returns
      };
    }, [project]),
    resolver: yupResolver(schemaInvestorSelect)
  });

  // const watchIssuer = watch('issuer', { id: null });
  const watchLoan = watch('loan', { id: null });
  const watchReturns = watch('returns');
  const watchSubDays = watch('subscription_days');

  const [optionIssuers, setOptionIssuers] = useState([]);

  const [optionIndustry, setOptionIndustry] = useState([]);

  const [optionCategories, setOptionCategories] = useState([]);

  useEffect(() => {
    API.dataSets(['industries', 'loan_categories']).then((res) => {
      setOptionCategories(res.data.loan_categories);
      setOptionIndustry(res.data.industries);
    });

    // API.getIssuers().then((res) => {
    //   var optionIssuers = res.data.issuers.map((issuer) => ({
    //     ...issuer,
    //     value: issuer.id,
    //     label: issuer.company_name
    //   }));
    //   setOptionIssuers(optionIssuers);
    // });
  }, []);

  // useEffect(() => {
  //   setValue(
  //     'issuer',
  //     optionIssuers.find((issuer) => issuer.user_id == user_id)
  //   );
  // }, [user_id, optionIssuers]);

  const watchPublishDate = watch('publish_date', project.publish_date);

  const watchRepaymentMode = watch(
    'repayment_mode',
    project.repayment_mode && project.repayment_mode.id
  );

  const [optionIssuerLoans, setOptionIssuerLoans] = useState([]);

  // useEffect(() => {
  //   if (!isEmpty(watchIssuer)) {
  //     API.getIssuerLoans(watchIssuer.id).then((res) => {
  //       var optionIssuerLoans = res.data.loan_requests.map((loan) => ({
  //         ...loan,
  //         value: loan.id,
  //         label: `LOAN ID: ${loan.id} - ${loan.purpose} (${loan.status_name})`,
  //         disabled: loan.status != 6
  //       }));
  //       setOptionIssuerLoans(optionIssuerLoans);
  //     });
  //   }
  // }, [watchIssuer]);

  // useEffect(() => {
  //   setValue('loan_tenor', watchLoan && watchLoan.loan_tenor);
  // }, [watchLoan]);

  useEffect(() => {
    setValue(
      'repayment_start_date',
      watchPublishDate &&
        moment(watchPublishDate, 'YYYY-MM-DD')
          .add(Number(getValues('subscription_days')) - 1, 'days')
          .format('YYYY-MM-DD')
    ) || moment().add(30, 'days').format('YYYY-MM-DD');
  }, [watchPublishDate]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const optionRepayment = [
    { value: 1, label: 'Balloon Payment' },
    { value: 3, label: 'Equal Monthly Repayment' }
  ];

  const optionInvestorType = [
    { value: 1, label: 'All Investors' },
    { value: 2, label: 'Retail Investors Only' },
    { value: 3, label: 'Qualified Investors Only' }
  ];

  const onSubmitForm = (data) => {
    setLoadingSubmit(true);
    const isFile = (input) => 'File' in window && input instanceof File;

    const params = new URLSearchParams();

    const filesArray = [data.banner[0], data.attachment[0]];

    uploadFiles(filesArray).then((res) => {
      params.append('banner', res[0].id);
      params.append('attachment', res[1].id);

      params.append('user_id', project.user_id);
      params.append('loan_request_id', project.loan_request_id);
      params.append('funding_id', Number(data.funding_id));
      params.append('industry', Number(data.industry));
      params.append('category_id', Number(data.category_id));
      params.append('amount', Number(project.amount));
      params.append('subscription_days', data.subscription_days);
      params.append('repayment_mode', data.repayment_mode);

      params.append('has_pledge', data.has_pledge ? 1 : 0);
      params.append('has_visitation', data.has_visitation ? 1 : 0);
      params.append('has_guarantor', data.has_guarantor ? 1 : 0);

      params.append('minimum_lend_amount', data.minimum_lend_amount);
      params.append('loan_tenor', project.loan_tenor);
      params.append('returns', project.returns);

      params.append('title', data.title);
      params.append('auto_invest_enabled', data.auto_invest_enabled ? 1 : 0);
      params.append('description', data.description);
      params.append('title', data.title);

      params.append('op_service_fee', data.op_service_fee);

      params.append('publish_date', moment(data.publish_date).format('YYYY-MM-DD HH:mm'));
      params.append(
        'activate_invest_date',
        moment(data.activate_invest_date).format('YYYY-MM-DD HH:mm')
      );
      params.append('auto_invest_enabled', data.auto_invest_enabled ? 1 : 0);
      params.append('restrict_non_investor', 0);
      params.append('repayment_start_date', data.repayment_start_date);

      API.putProject(project.id, params)
        .then((res) => {
          Notification.success('Project Create', 'Project successfully created!');
          history.push('/projects');
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status == 422) {
            if (err.response.data.errors.loan_request_id) {
              Notification.error(
                'Project Create',
                'Loan request is already linked to an existing project'
              );
            } else {
              Notification.error('Project Create', err.response.data.message);
            }
          } else {
            Notification.error('Project Create', 'Project failed to created!');
          }
        })
        .finally(() => setLoadingSubmit(false));
    });
  };

  const uploadFiles = async (files) => {
    const promises = await files.map(async (file, index) => {
      if (!has(file, 'status')) {
        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('dir', 'Banners');
        return await API.fileUpload(formData)
          .then((res) => {
            files[index] = { label: file.label, ...res.data.data };
            return { label: file.label, status: 1, ...res.data.data };
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        return file;
      }
    });

    const responses = await Promise.all(promises);
    return responses;
  };

  return (
    <>
      <PageHeader title="Project Create" />
      <Panel>
        <PanelHeader></PanelHeader>
        <PanelBody>
          <Row>
            <Col sm="12" md="12" lg={{ size: 10, offset: 1 }}>
              <Form onSubmit={handleSubmit(onSubmitForm)}>
                <FormGroup tag="fieldset">
                  <h3>Project Terms</h3>
                  <FormGroup row>
                    <Label md="3">Issuer</Label>
                    <Col md="9">
                      <Controller
                        name="issuer"
                        control={control}
                        render={({ field }) => <Input {...field} type="text" disabled />}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">Loan Request</Label>
                    <Col md="9">
                      <Controller
                        name="loan"
                        control={control}
                        render={({ field }) => <Input {...field} type="text" disabled />}
                        // render={({ field: { onChange, value, ref } }) => (
                        //   <ReactSelect
                        //     inputRef={ref}
                        //     value={optionIssuerLoans.find((c) => c.value === value)}
                        //     onChange={(val) => onChange(val)}
                        //     options={optionIssuerLoans}
                        //     isOptionDisabled={(option) => option.disabled}
                        //   />
                        // )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">
                      Investment ID
                      <TooltipItem placement="top" id="funding_id" content="Auto Generated" />
                    </Label>
                    <Col md="9">
                      <Controller
                        name="funding_id"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            disabled
                            invalid={!isEmpty(errors.funding_id)}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">
                      Target Offering Amount
                      <TooltipItem placement="top" id="amount" content="Funds Needed" />
                    </Label>
                    <Col md="9">
                      {/* <CurrencyInput
                        value={(watchLoan && watchLoan.amount) || '0.00'}
                        prefix="₱ "
                        disabled
                        placeholder="Loan Amount"
                        decimalsLimit={2}
                        className="form-control"
                      /> */}
                      <Controller
                        name="amount"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <CurrencyInput
                            value={value}
                            prefix="₱ "
                            disabled
                            decimalsLimit={2}
                            className="form-control"
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">
                      Offering Period
                      <TooltipItem
                        placement="top"
                        id="period"
                        content="Days allowed investor to subscribe"
                      />
                    </Label>
                    <Col md="9">
                      <Controller
                        name="subscription_days"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            className="number-only-field"
                            type="number"
                            invalid={!isEmpty(errors.subscription_days)}
                          />
                        )}
                      />
                      <FormFeedback
                        style={{ display: errors.subscription_days ? 'block' : 'none' }}
                      >
                        {errors.subscription_days?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">
                      Target Offering Deadline
                      <TooltipItem
                        placement="top"
                        id="deadline"
                        content="To be set based on the Subscription Period after the Publish Date"
                      />
                    </Label>
                    <Col md="9">
                      <Controller
                        name="repayment_start_date"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            disabled
                            invalid={!isEmpty(errors.repayment_start_date)}
                          />
                        )}
                      />
                      <FormFeedback
                        style={{ display: errors.repayment_start_date ? 'block' : 'none' }}
                      >
                        {errors.repayment_start_date?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">Repayment Mode</Label>
                    <Col md="9">
                      <Controller
                        name="repayment_mode"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            inputRef={ref}
                            isDisabled
                            value={optionRepayment.find((c) => c.value === value)}
                            onChange={(val) => onChange(val.value)}
                            options={optionRepayment}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.amount ? 'block' : 'none' }}>
                        {errors.repayment_mode?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">Has Pledge Collaterals</Label>
                    <Col md="9">
                      <Controller
                        name="has_pledge"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Switchery checked={value} onChange={onChange} for="has_pledge" />
                        )}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">Approved by Credit Risk Committee</Label>
                    <Col md="9">
                      <Controller
                        name="has_visitation"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Switchery checked={value} onChange={onChange} for="has_visitation" />
                        )}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">Has Personal Guarantor</Label>
                    <Col md="9">
                      <Controller
                        name="has_guarantor"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Switchery checked={value} onChange={onChange} for="has_guarantor" />
                        )}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">Minimum Investment</Label>
                    <Col md="9">
                      <Controller
                        name="minimum_lend_amount"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <CurrencyInput
                            value={value}
                            prefix="₱ "
                            decimalsLimit={2}
                            className="form-control"
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">
                      Tenor
                      <TooltipItem placement="top" id="loan_tenor" content="Loan Request Tenure" />
                    </Label>
                    <Col md="9">
                      <Controller
                        name="loan_tenor"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            disabled
                            invalid={!isEmpty(errors.loan_tenor)}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">Returns</Label>
                    <Col md="9">
                      <Controller
                        name="returns"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <CurrencyInput
                            value={value}
                            suffix="%"
                            disabled
                            decimalsLimit={2}
                            onValueChange={(value, name) => onChange(value)}
                            className="form-control"
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.returns ? 'block' : 'none' }}>
                        {errors.returns?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                </FormGroup>
                <hr />
                <FormGroup tag="fieldset">
                  <h3>Project Details</h3>
                  <FormGroup row>
                    <Label md="3">Title</Label>
                    <Col md="9">
                      <Controller
                        name="title"
                        control={control}
                        render={({ field }) => (
                          <Input {...field} type="text" invalid={!isEmpty(errors.title)} />
                        )}
                      />
                      <FormFeedback style={{ display: errors.title ? 'block' : 'none' }}>
                        {errors.title?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">Banner</Label>
                    <Col md="9">
                      <Controller
                        name="banner"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <FileUpload
                            onDrop={onChange}
                            onChange={onChange}
                            imagefile={value}
                            invalid={!isEmpty(errors.banner)}
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.banner ? 'block' : 'none' }}>
                        {errors.banner?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">Description</Label>
                    <Col md="9">
                      <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="textarea"
                            rows="5"
                            invalid={!isEmpty(errors.description)}
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.description ? 'block' : 'none' }}>
                        {errors.description?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">Factsheet</Label>
                    <Col md="9">
                      <Controller
                        name="attachment"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <FileUpload
                            onDrop={onChange}
                            onChange={onChange}
                            imagefile={value}
                            invalid={!isEmpty(errors.attachment)}
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.attachment ? 'block' : 'none' }}>
                        {errors.attachment?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Category</Label>
                    <Col md="9">
                      <Controller
                        name="category_id"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            inputRef={ref}
                            value={optionCategories.find((c) => c.value === value)}
                            onChange={(val) => onChange(val.value)}
                            options={optionCategories}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.category_id ? 'block' : 'none' }}>
                        {errors.category_id?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Industry</Label>
                    <Col md="9">
                      <Controller
                        name="industry"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            inputRef={ref}
                            isDisabled
                            value={optionIndustry.find((c) => c.value === value)}
                            onChange={(val) => onChange(val.value)}
                            options={optionIndustry}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.industry ? 'block' : 'none' }}>
                        {errors.industry?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Loan Fee</Label>
                    <Col md="9">
                      <Controller
                        name="op_service_fee"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <CurrencyInput
                            value={value}
                            suffix="%"
                            decimalsLimit={2}
                            onValueChange={(value, name) => onChange(value)}
                            className="form-control"
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.amount ? 'block' : 'none' }}>
                        {errors.op_service_fee?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Publish Date</Label>
                    <Col md="9">
                      <Controller
                        name="publish_date"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <DateTime
                              initialValue={watchPublishDate}
                              inputProps={{ placeholder: 'Select Date and Time' }}
                              closeOnSelect={true}
                              timeFormat="HH:mm"
                              value={value}
                              dateFormat="YYYY-MM-DD"
                              onChange={(e) => {
                                onChange(moment(e).format('YYYY-MM-DD HH:mm'));
                              }}
                              timeConstraints={{
                                minutes: { step: 5 }
                              }}
                              isValidDate={(currentDate) =>
                                currentDate > moment().subtract(1, 'days')
                              }
                            />
                          );
                        }}
                      />
                      <FormFeedback style={{ display: errors.publish_date ? 'block' : 'none' }}>
                        {errors.publish_date?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                </FormGroup>
                <hr />
                <FormGroup tag="fieldset">
                  <h3>Project Settings</h3>
                  <FormGroup row>
                    <Label md="3">Display to Investors</Label>
                    <Col md="9">
                      <Controller
                        name="loan_display_investors"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            inputRef={ref}
                            value={optionInvestorType.find((c) => c.value === value)}
                            onChange={(val) => onChange(val)}
                            options={optionInvestorType}
                            isDisabled
                            isOptionDisabled={(option) => option.disabled}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">Activate Manual Invest On</Label>
                    <Col md="9">
                      <Controller
                        name="activate_invest_date"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <DateTime
                            initialViewDate
                            inputProps={{ placeholder: 'Select Date and Time' }}
                            closeOnSelect={true}
                            timeFormat="HH:mm"
                            value={value}
                            dateFormat="YYYY-MM-DD"
                            timeConstraints={{
                              minutes: { step: 5 }
                            }}
                            onChange={(e) => {
                              onChange(moment(e).format('YYYY-MM-DD HH:mm'));
                            }}
                            isValidDate={(currentDate) =>
                              currentDate > moment().subtract(1, 'days')
                            }
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.amount ? 'block' : 'none' }}>
                        {errors.activate_invest_date?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="3">Priority VIP Investors</Label>
                    <Col md="9">
                      <Controller
                        name="allow_priority_investor"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Switchery
                            checked={value}
                            onChange={onChange}
                            for="allow_priority_investor"
                            disabled
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Enable Alfred Auto Invest</Label>
                    <Col md="9">
                      <Controller
                        name="auto_invest_enabled"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Switchery
                            checked={value}
                            onChange={onChange}
                            for="auto_invest_enabled"
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>
                </FormGroup>
                <hr />

                <FormGroup tag="fieldset">
                  <h3>Repayment Schedule</h3>
                  {loanScheduleList && loanScheduleList.length != 0 && (
                    <>
                      <fieldset>
                        <Panel>
                          <PanelHeader></PanelHeader>
                          <PanelBody>
                            <div className="table-responsive">
                              <table className="table table-striped mb-0 align-middle">
                                <thead>
                                  <tr>
                                    <th>No.</th>
                                    <th>Due Date</th>
                                    <th>Outstanding</th>
                                    <th>Payment</th>
                                    <th>Principal</th>
                                    <th>Interest</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {loanScheduleList &&
                                    loanScheduleList.map((schedule, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{schedule.period}</td>
                                          <td>{schedule.due_date || schedule.date_repayment}</td>
                                          <td>{PriceFormat(schedule.outstanding)}</td>
                                          <td>{PriceFormat(schedule.payment)}</td>
                                          <td>{PriceFormat(schedule.principal)}</td>
                                          <td>{PriceFormat(schedule.interest)}</td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan="2">
                                      <b>TOTAL</b>
                                    </td>
                                    <td></td>
                                    <td>
                                      <b>{PriceFormat(loanScheduleTotal.payment)}</b>
                                    </td>
                                    <td>
                                      <b>{PriceFormat(loanScheduleTotal.principal)}</b>
                                    </td>
                                    <td>
                                      <b>{PriceFormat(loanScheduleTotal.interest)}</b>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </PanelBody>
                        </Panel>
                      </fieldset>
                    </>
                  )}
                </FormGroup>
                <hr />
              </Form>
              <Row>
                <Col className="d-flex justify-content-start">
                  <ButtonGenerateLoanSchedule
                    params={{
                      amount: project.amount,
                      loan_tenor: project.loan_tenor,
                      returns: getValues('returns'),
                      subscription_days: getValues('subscription_days'),
                      repayment_mode: getValues('repayment_mode')
                    }}
                    isLoading={loadingSubmit}
                    onGenerate={(schedule) => {
                      setLoanScheduleList(schedule.list);
                      setLoanScheduleTotal(schedule.total);
                    }}
                  />
                </Col>
                <Col className="d-flex justify-content-end">
                  <ButtonApprove
                    onSubmit={handleSubmit(onSubmitForm)}
                    // disabled={isEmpty(loanScheduleList)}
                    project={getValues()}
                    loan={watchLoan}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
};

export default ProjectUpdate;

const ButtonGenerateLoanSchedule = ({ params, onGenerate, disabled = false }) => {
  const [modalReject, setModalReject] = useState(false);

  const [loadingGenerate, setLoadingGenerate] = useState(false);

  const toggleModalReject = async () => {
    setLoadingGenerate(!loadingGenerate);

    await setModalReject(!modalReject);
  };

  const onConfirm = () => {
    API.getLoanGenerateSchedule(params)
      .then((res) => {
        onGenerate(res.data.schedule);
        toggleModalReject();
      })
      .catch((err) => {
        Notification.error('Generate Loan Schedule', 'Unable to generate loan schedule. retry!');
        toggleModalReject();
      });
  };

  const optionRepayment = [
    { value: 1, label: 'Balloon Payment' },
    { value: 3, label: 'Equal Monthly Repayment' }
  ];

  return (
    <>
      <button
        className={`btn mr-2 text-white ${
          disabled || loadingGenerate ? 'btn-gray' : 'btn-primary'
        }`}
        type="button"
        disabled={disabled || loadingGenerate}
        onClick={toggleModalReject}
      >
        {loadingGenerate ? <i className="fas fa-spinner fa-spin"></i> : 'Generate Loan Schedule'}
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Generate Loan Schedule</span>}
        custom
        customIcon={<i className="fa fa-calendar fa-5x mb-3 text-primary"></i>}
        showCancel
        confirmBtnText="Generate"
        confirmBtnBsStyle="primary"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onConfirm}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <div>
          <table className="table table-borderless text-left">
            <tbody>
              <tr>
                <td>Amount</td>
                <td>{PriceFormat(params.amount)}</td>
              </tr>
              <tr>
                <td>Tenor</td>
                <td>{params.loan_tenor}</td>
              </tr>
              <tr>
                <td>Returns</td>
                <td>{params.returns}%</td>
              </tr>
              <tr>
                <td>Offering Period</td>
                <td>{params.subscription_days} days</td>
              </tr>
              <tr>
                <td>Repayment Mode</td>
                <td>
                  {params.repayment_mode &&
                    optionRepayment.find((mode) => mode.value == params.repayment_mode).label}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </SweetAlert>
    </>
  );
};

const ButtonApprove = ({ onSubmit, disabled, isLoading, loan, project }) => {
  const [modal, setModal] = useState(false);

  const toggleModal = async () => {
    await setModal(!modal);
  };

  const onConfirm = () => {
    toggleModal().then(() => {
      onSubmit();
    });
  };

  return (
    <>
      <button
        className={`btn mr-2 text-white ${disabled || isLoading ? 'btn-gray' : 'btn-success'}`}
        type="button"
        disabled={disabled}
        onClick={toggleModal}
      >
        {isLoading ? <i className="fas fa-spinner fa-spin"></i> : 'Update Project'}
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Update Project?</span>}
        success
        showCancel
        confirmBtnText="Start"
        confirmBtnBsStyle="success"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onConfirm}
        onCancel={toggleModal}
        show={modal}
      >
        <p>Are you sure you want to start this project?</p>
      </SweetAlert>
    </>
  );
};
