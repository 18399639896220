import React, {
  memo,
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
  lazy,
  Suspense
} from 'react';
import { withRouter, Link, useParams } from 'react-router-dom';
import PageContext from '../../config/page-settings.js';
import { Table } from './../../components/table';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';

import * as API from '../../services/api';
import moment from 'moment';
// import BanksList from '../issuers/components/Review/BanksList.js';
import { PriceFormat } from '../../utils/format';
// import FormInformationEdit from './components/FormInformationEdit.js';
import { Notification } from '../../utils/index.js';

import { Form, FormGroup, Row, Col, Input, FormFeedback, Label } from 'reactstrap';
import { Button } from '../../components/common';

import CurrencyInput from 'react-currency-input-field';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isEmpty, isNull } from 'lodash';
import ReactSelect from 'react-select';
import LoadingSpinner from '../../components/common/LoadingSpinner.js';
const BanksList = lazy(() => import('../issuers/components/Review/BanksList.js'));
const FormInformationEdit = lazy(() => import('./components/FormInformationEdit.js'));
const FormEditSourceOfIncome = lazy(() => import('./components/FormEditSourceOfIncome.js'));
const FormEditSupportingDocuments = lazy(() =>
  import('./components/FormEditSupportingDocuments.js')
);

const InvestmentList = lazy(() => import('./components/InvestmentList.js'));
const AutoInvestList = lazy(() => import('./components/AutoInvestList.js'));
const ActivityLogsList = lazy(() => import('../logs/activity/ActivityLogsList.js'));
const InvestorTransactionList = lazy(() =>
  import('../transactions/investors/InvestorTransactionList.js')
);

const ListSupportingDocuments = lazy(() => import('./components/ListSupportingDocuments.js'));
const ListPendingOnHold = lazy(() => import('./components/ListPendingOnHold.js'));

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      {...props}
      checked={props.checked}
      disabled={props.disabled || false}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const OPTION_OTP_DESINATION = [
  { value: 1, label: 'SMS' },
  { value: 2, label: 'Email' }
];

const schemaChangePassword = yup.object().shape({
  password: yup
    .string()
    .required('New password is required')
    .min(8, 'New password must be at least 8 characters'),
  password_confirmation: yup
    .string('Confirm password is required')
    .required()
    .min(8, 'New password must be at least 8 characters')
});

const schemaRemarks = yup.object().shape({
  remarks: yup.string().nullable()
});

const InvestorView = (props) => {
  const pageContext = useContext(PageContext);
  const { id } = useParams();

  const [investor, setInvestor] = useState({});
  const [banks, setBanks] = useState([]);

  const [wallet, setWallet] = useState({});

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullHeight(true);
    pageContext.handleSetPageContentFullWidth(true);
    // pageContext.handleSetPageTwoSidebar(true);

    API.investorView(id).then((res) => {
      setInvestor(res.data.data);
      setWallet(res.data.data.wallet);
    });

    // API.getInvestorBanks(id).then((res) => {
    //   setBanks(res.data.data);
    // });
  }, []);

  const [data, setData] = useState([]);

  const [tabs, setActiveTab] = useState({
    summary: true,
    profile: false,
    activities: false,
    transactions: false,
    investments: false,
    referrals: false,
    commissions: false,
    autoInvestmentRules: false
  });

  const toggleAutoInvestSettings = (value) => {
    setInvestor({ ...investor, auto_invest_enable: value.target.checked });
    const params = new URLSearchParams();
    params.append('auto_invest_enable', value.target.checked ? 1 : 0);
    API.investorSettingsAutoInvest(id, params)
      .then((res) => {
        Notification.success(
          'Auto Invest Settings',
          `Auto Invest Successfully ${value.target.checked ? 'enabled' : 'disabled'}`
        );
      })
      .catch((err) => {
        setInvestor({ ...investor, auto_invest_enable: !value.target.checked });
        Notification.error(err);
      });
  };

  const showTab = (e, tab) => {
    e.preventDefault();
    setActiveTab({
      summary: tab === 'summary' ? true : false,
      profile: tab === 'profile' ? true : false,
      activities: tab === 'activities' ? true : false,
      transactions: tab === 'transactions' ? true : false,
      investments: tab === 'investments' ? true : false,
      referrals: tab === 'referrals' ? true : false,
      funds: tab === 'funds' ? true : false,
      autoInvestmentRules: tab === 'auto' ? true : false,
      settings: tab === 'settings' ? true : false
    });
  };

  const profileStatus = useCallback(
    (id) => {
      switch (id) {
        case 3:
          return 'Pending';
        case 4:
          return 'Approved';
        case 5:
          return 'Rejected';
        case 6:
          return 'Deleted';
        default:
          return 'Incomplete';
      }
    },
    [investor, investor.status]
  );

  const onReject = ({ reason, type }) => {
    const params = {
      status: String(5),
      remarks: reason,
      reject_type: type,
      user_id: id
    };

    API.updateInvestorStatus(params).then(
      () => {
        API.investorView(id).then((res) => {
          setInvestor(res.data.data);
        });
      },
      (err) => {
        console.log(err.response);
      }
    );
  };

  const onApprove = (reason) => {
    const params = {
      status: String(4),
      remarks: '',
      user_id: id
    };
    API.updateInvestorStatus(params).then(() => {
      API.investorView(id).then((res) => {
        setInvestor(res.data.data);
      });
    });
  };

  const onRejectBank = (bankId, reason) => {
    const params = {
      status: String(3),
      remarks: reason
    };

    API.updateInvestorBankStatus(bankId, params).then(() => {
      API.getInvestorBanks(id).then((res) => {
        setBanks(res.data.data);
      });
    });
  };

  const onApproveBank = (bankId) => {
    const params = {
      status: String(2),
      remarks: ''
    };
    API.updateInvestorBankStatus(bankId, params).then(() => {
      API.getInvestorBanks(id).then((res) => {
        setBanks(res.data.data);
      });
    });
  };

  const onViewContract = async () => {
    var token = localStorage.getItem('access_token');

    var win = window.open('_blank');

    downloadFile(investor.contract_url, function (blob) {
      var url = URL.createObjectURL(blob);
      win.location = url;
    });

    function downloadFile(url, success) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (success) success(xhr.response);
        } else {
        }
      };
      xhr.send(null);
    }
  };

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm({
    resolver: yupResolver(schemaChangePassword)
  });

  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

  const toggleChangePasswordForm = () => {
    setShowChangePasswordForm(!showChangePasswordForm);
  };
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const onChangePassword = (data) => {
    setLoadingChangePassword(true);

    API.changeUserPassword(id, data)
      .then((res) => {
        setLoadingChangePassword(false);
        Notification.success('Change Password', 'Successfully change password');
      })
      .catch((err) => {
        setLoadingChangePassword(false);
        if (err.response.status === 422) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            setError(key, {
              type: 'manual',
              message: value
            });
          }
        } else {
          Notification.error(err);
        }
      });
  };

  const toggleOTPRequired = (value) => {
    setInvestor({ ...investor, is_required_otp: value.target.checked });

    const params = {
      is_required_otp: value.target.checked ? 1 : 0
    };

    API.changeSecurity(id, params)
      .then((res) => {
        Notification.success(
          'Security OTP',
          `Security OTP Successfully ${value.target.checked ? 'enabled' : 'disabled'}`
        );
      })
      .catch((err) => {
        setInvestor({ ...investor, auto_invest_enable: !value.target.checked });
        Notification.error(err);
      });
  };

  const [otpDestination, setOTPDestination] = useState({ value: 1, label: 'SMS' });

  useEffect(() => {
    investor &&
      setOTPDestination(OPTION_OTP_DESINATION.find((c) => c.value === investor?.otp_platform_id));
  }, [investor]);

  const onChangeOTPDestination = (value) => {
    setOTPDestination(value);
    setInvestor({ ...investor, otp_platform_id: value.value });

    const params = {
      otp_platform_id: value.value
    };
    API.changeSecurity(id, params)
      .then((res) => {
        Notification.success(
          'Security OTP Destination',
          `Security OTP Destination Successfully switch to ${value.label}`
        );
      })
      .catch((err) => {
        setInvestor({ ...investor, otp_platform_id: investor.otp_platform_id });
        Notification.error(err);
      });
  };

  const toggleBiometric = (value) => {
    setInvestor({ ...investor, bio_enable: value.target.checked });

    const params = {
      bio_enable: value.target.checked ? 1 : 0
    };
    API.changeSecurity(id, params)
      .then((res) => {
        Notification.success(
          'Security Biometric',
          `Security Biometric Successfully ${value.target.checked ? 'enabled' : 'disabled'}`
        );
      })
      .catch((err) => {
        setInvestor({ ...investor, bio_enable: !value.target.checked });
        Notification.error(err);
      });
  };

  const togglePriorityInvestor = (value) => {
    setInvestor({ ...investor, is_priority_investor: value.target.checked });

    const params = {
      is_priority_investor: value.target.checked ? 1 : 0
    };

    API.patchInvestor(id, params)
      .then((res) => {
        Notification.success(
          'Priority Investor (VIP)',
          `Priority Investor (VIP) Successfully ${value.target.checked ? 'enabled' : 'disabled'}`
        );
      })
      .catch((err) => {
        setInvestor({ ...investor, is_priority_investor: !value.target.checked });
        Notification.error(err);
      });
  };

  return (
    <div>
      <div className="profile">
        <div className="profile-header">
          <div className="profile-header-cover"></div>
          <div className="profile-header-content">
            <div className="profile-header-img">
              <img
                src={investor && investor.photo && investor.photo.view}
                alt=""
                style={{ objectFit: 'cover', width: '100%' }}
              />
            </div>
            <div className="profile-header-info">
              <h4 className="m-t-10 m-b-5">{investor.full_name}</h4>
              {/* <p className="m-b-10">UXUI + Frontend Developer</p> */}
              <div style={{ width: '300px', marginBottom: '10px' }}>
                <div className="progress h-10px rounded-pill mb-5px">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-orange fs-10px fw-bold"
                    style={{
                      width: `${investor && investor.account_progress?.total}%`
                    }}
                  >
                    Progress ({investor && investor.account_progress?.total}
                    %)
                  </div>
                </div>
              </div>
              {investor && investor.status && investor.status.id == 3 && (
                <>
                  <ButtonApprove onConfirm={onApprove} />
                  {/* <ButtonReject onConfirm={onReject} /> */}
                </>
              )}
              <a onClick={onViewContract} className="btn btn-xs btn-danger m-l-5">
                Preview Contract
              </a>
              <ButtonSendMessage investor={investor} />
            </div>
          </div>
          <ul className="profile-header-tab nav nav-tabs">
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'summary')}
                className={'nav-link ' + (tabs.summary ? 'active ' : '')}
              >
                SUMMARY
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'profile')}
                className={'nav-link ' + (tabs.profile ? 'active ' : '')}
              >
                PROFILE
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'funds')}
                className={'nav-link ' + (tabs.funds ? 'active ' : '')}
              >
                FUNDS
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'activities')}
                className={'nav-link ' + (tabs.activities ? 'active ' : '')}
              >
                ACTIVITIES
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'transactions')}
                className={'nav-link ' + (tabs.transactions ? 'active ' : '')}
              >
                TRANSACTIONS
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'investments')}
                className={'nav-link ' + (tabs.investments ? 'active ' : '')}
              >
                INVESTMENTS
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'auto')}
                className={'nav-link ' + (tabs.auto ? 'active ' : '')}
              >
                AUTO INVEST RULES
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'settings')}
                className={'nav-link ' + (tabs.settings ? 'active ' : '')}
              >
                SETTINGS
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="profile-content">
        <div className="tab-content p-0">
          <div className={'tab-pane fade ' + (tabs.summary ? 'show active ' : '')}>
            <div className="table-responsive form-inline">
              <table className="table table-profile">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <h4>
                        {investor && investor.full_name} <small>Retail Investor</small>
                      </h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="field">Mobile:</td>
                    <td>
                      <i className="fa fa-mobile fa-lg m-r-5"></i> {investor && investor.otp_number}
                      <Link to="/extra/profile" className="m-l-5">
                        <i className="fa fa-paper-plane fa-lg" />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Email:</td>
                    <td>
                      <Link to="/extra/profile" className="">
                        {investor && investor.email} <i className="fa fa-envelope fa-lg m-l-5" />
                      </Link>
                    </td>
                  </tr>
                  <tr className="">
                    <td colSpan="2">
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Referred By:</td>
                    <td>
                      {/* <Link to="/extra/profile"> */}
                      N/A
                      {/* <button className="btn btn-sm btn-icon btn-primary m-l-5">
                          <i className="fa fa-eye"></i>
                        </button>
                      </Link> */}
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Registered Date:</td>
                    <td>
                      {investor &&
                        moment(investor.created_at).utc(true).format('YYYY-MM-DD hh:mm A')}
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Last Update:</td>
                    <td>
                      {investor &&
                        moment(investor.updated_at).utc(true).format('YYYY-MM-DD hh:mm A')}
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Profile Status:</td>
                    <td>{investor && investor.status && investor.status.name}</td>
                  </tr>
                  {/* <tr>
                    <td className="field">Priority Investor:</td>
                    <td>No</td>
                  </tr> */}
                  <tr>
                    <td className="field">Priority Investor:</td>
                    <td className="float-left">
                      <Switchery
                        checked={investor.is_priority_investor == 1}
                        onChange={togglePriorityInvestor}
                        for="is_priority_investor"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Alfred Auto Invest:</td>
                    <td>{investor?.auto_invest_enable ? 'Yes' : 'No'}</td>
                  </tr>
                  <AddRemarks investor={investor} />
                </tbody>
              </table>
            </div>
          </div>
          <div className={'tab-pane fade ' + (tabs.profile ? 'show active ' : '')}>
            <div className="col-lg-10 offset-lg-1">
              <Suspense fallback={<LoadingSpinner />}>
                <FormInformationEdit investor={investor} />
                <FormEditSourceOfIncome investor={investor} />
              </Suspense>

              <Suspense fallback={<LoadingSpinner />}>
                <BanksList banks={banks} />
              </Suspense>

              <Suspense fallback={<LoadingSpinner />}>
                <ListSupportingDocuments investor={investor} />
              </Suspense>

              {/* <form onSubmit={handleSubmit(props.onSubmit)}> */}
              {/* <form>
                <fieldset disabled>
                  <h3>Investor Information</h3>
                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">First Name</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control form-control-md`}
                          value={investor.first_name}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Last Name</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control form-control-md`}
                          value={investor.last_name}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Gender</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <select className="form-control form-control">
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Etc">Etc</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Birth Date</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input
                          type="date"
                          className={`form-control form-control-md`}
                          value={
                            investor && investor.user_details && investor.user_details.birth_date
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Marital Status</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <select className="form-control form-control">
                          <option value="Single">Single</option>
                          <option value="Married">Married</option>
                          <option value="Widowed">Widowed</option>
                          <option value="Divorced">Divorced</option>
                          <option value="Separated">Separated</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">TIN Number</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input
                          type="number"
                          className={`form-control form-control-md`}
                          value={investor && investor.user_details && investor.user_details.tin}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">
                      Proof of you holding your Personal ID
                    </label>
                    <div className="col-md-9">
                      <button className="btn btn-primary float-left" type="submit">
                        View File
                      </button>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Proof of your Income</label>
                    <div className="col-md-9">
                      <button className="btn btn-primary float-left" type="submit">
                        View File
                      </button>
                    </div>
                  </div>
                </fieldset>
                <hr />
                <fieldset disabled>
                  <h3>Contact Information</h3>
                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Address</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control form-control-md`}
                          value={
                            (investor &&
                              investor.user_details &&
                              investor.user_details.address_line_1) ||
                            ''
                          }
                        />
                      </div>
                      <small id="emailHelp" className="form-text text-muted">
                        (Apartment, unit, suite, or floor #, Street Name, Municipality)
                      </small>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Country</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <select className="form-control form-control">
                          <option value="Philippines">Philippines</option>
                          <option value="Singapore">Singapore</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Province</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <select className="form-control form-control">
                          <option value="Admin">National Capital Region</option>
                          <option value="IT">Batanes</option>
                          <option value="etc">La Union</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">City</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <select className="form-control form-control">
                          <option value="Admin">Marikina</option>
                          <option value="IT">Pasig</option>
                          <option value="etc">Mandaluyong</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Postal/Zip Code</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input type="text" className={`form-control form-control-md`} />
                      </div>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Landline</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input type="text" className={`form-control form-control-md`} />
                      </div>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Mobile Number</label>
                    <div className="col-md-9">
                      <div className="row row-space-10">
                        <div className="col-md-5">
                          <div className="input-group">
                            <select className="form-control form-control">
                              <option value="+63">+63 Philippines</option>
                              <option value="+65">+65 Singapore</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="input-group">
                            <input
                              type="number"
                              className={`form-control form-control-md number-only-field`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Email</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input type="text" className={`form-control form-control-md`} />
                      </div>
                    </div>
                  </div>
                </fieldset>

                <hr />
                <fieldset disabled>
                  <h3>Source Of Income</h3>
                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Source of Income</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <select className="form-control form-control">
                          <option value="Employment" selected>
                            Employment
                          </option>
                          <option value="Business">Business</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Company Name</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input type="text" className={`form-control form-control-md`} />
                      </div>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Employee Position</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input type="text" className={`form-control form-control-md`} />
                      </div>
                    </div>
                  </div>

                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Gross Annual Income</label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <select className="form-control form-control">
                          <option value="1">Php360,001.00 to Php 540,000.00</option>
                          <option value="2">Php360,001.00 to Php540,000.00</option>
                          <option value="3">Php540,001.00 to Php720,000.00</option>
                          <option value="4">Php720,001.00 to Php900,000.00</option>
                          <option value="5">Php900,001.00 to Php1,080,000.00</option>
                          <option value="6">Php1,080,001.00 and above</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row form-group m-b-10">
                    <label className="col-md-3 col-form-label">Proof of your Income</label>
                    <div className="col-md-9">
                      <button className="btn btn-primary float-left" type="submit">
                        View File
                      </button>
                    </div>
                  </div>
                </fieldset>
              </form> */}
              <hr />
            </div>
            {/* <div className="col-lg-8 offset-lg-2">
              <BanksList banks={banks} onApprove={onApproveBank} onReject={onRejectBank} />
            </div> */}
          </div>
          <div className={'tab-pane fade ' + (tabs.funds ? 'show active ' : '')}>
            <div className="row">
              <div className="col-xl-3 col-md-6">
                <div className="widget widget-stats bg-green">
                  <div className="stats-icon">
                    <i className="ion ion-ios-wallet"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Available Funds</h4>
                    <p>{wallet && PriceFormat(wallet.available_funds)}</p>
                  </div>
                  <div className="stats-link">&nbsp;</div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="widget widget-stats bg-orange">
                  <div className="stats-icon">
                    <i className="ios ion-ios-hand"></i>
                  </div>
                  <div className="stats-info">
                    <h4>On Hold Funds</h4>
                    <p>{wallet && PriceFormat(wallet.on_hold)}</p>
                  </div>
                  <div className="stats-link">&nbsp;</div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="widget widget-stats bg-indigo">
                  <div className="stats-icon">
                    <i className="ion ion-ios-trending-up"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Project Payout </h4>
                    <p>{wallet && PriceFormat(wallet.investment_balance)}</p>
                  </div>
                  <div className="stats-link">&nbsp;</div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="widget widget-stats bg-info">
                  <div className="stats-icon">
                    <i className="ion ion-ios-stats"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Balance</h4>
                    <p>{wallet && PriceFormat(wallet.total_balance)}</p>
                  </div>
                  <div className="stats-link">&nbsp;</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3 col-md-6"></div>
              <div className="col-xl-3 col-md-6"></div>
              <div className="col-xl-3 col-md-6"></div>
              <div className="col-xl-3 col-md-6 d-flex justify-content-end">
                <ButtonAddFund />
                <ButtonDeductFund />
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-3">
                <ListPendingOnHold />
              </div>
            </div>
          </div>
          <div className={'tab-pane fade ' + (tabs.activities ? 'show active ' : '')}>
            <Suspense fallback={<LoadingSpinner />}>
              <ActivityLogsList />
            </Suspense>
          </div>
          <div className={'tab-pane fade ' + (tabs.transactions ? 'show active ' : '')}>
            <Suspense fallback={<LoadingSpinner />}>
              <InvestorTransactionList />
            </Suspense>
          </div>
          <div className={'tab-pane fade ' + (tabs.investments ? 'show active ' : '')}>
            <Suspense fallback={<LoadingSpinner />}>
              <InvestmentList />
            </Suspense>
          </div>
          <div className={'tab-pane fade ' + (tabs.autoInvestmentRules ? 'show active ' : '')}>
            <div
              style={{
                marginBottom: 10
              }}
            >
              <div className="with-btn text-nowrap d-flex justify-content-center align-items-center">
                <label className="form-check-label mr-2" htmlFor="checkbox1">
                  Alfred Auto Invest:
                </label>
                <div className="input-group">
                  <Switchery
                    checked={investor.auto_invest_enable}
                    onChange={toggleAutoInvestSettings}
                    for="auto_invest_enable"
                  />
                </div>
              </div>
            </div>
            <Suspense fallback={<LoadingSpinner />}>
              <AutoInvestList />
            </Suspense>
          </div>
          <div className={'tab-pane fade ' + (tabs.settings ? 'show active ' : '')}>
            <div className="col-lg-8 offset-lg-2">
              <h3>Security</h3>
              <div className="table-responsive form-inline">
                <table className="table">
                  <tbody>
                    {/* <tr>
                      <td className="field">Web OTP Enabled:</td>
                      <td>
                        <div className="switcher">
                          <input type="checkbox" name="wed" id="web" />
                          <label htmlFor="web"></label>
                        </div>
                      </td>
                    </tr> */}
                    <tr>
                      <td className="field">Mobile OTP Enabled:</td>
                      <td className="float-right">
                        <Switchery
                          checked={investor.is_required_otp}
                          onChange={toggleOTPRequired}
                          for="is_required_otp"
                        />
                      </td>
                    </tr>
                    {investor.is_required_otp && (
                      <tr>
                        <td className="field">OTP Destination:</td>
                        <td>
                          <ReactSelect
                            className="text-black"
                            value={otpDestination}
                            onChange={onChangeOTPDestination}
                            options={OPTION_OTP_DESINATION}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td className="field">Biometrics Enabled:</td>
                      <td className="float-right">
                        <Switchery
                          checked={investor.bio_enable == 1}
                          onChange={toggleBiometric}
                          for="bio_enabled"
                        />
                      </td>
                    </tr>
                    <tr className="highlight">
                      <td className="field">Password</td>
                      <td className="p-t-10 p-b-10">
                        {showChangePasswordForm ? (
                          <Form onSubmit={handleSubmit(onChangePassword)} autoComplete="off">
                            <FormGroup row className="mb-2">
                              <Label>New Password</Label>
                              <Col>
                                <Controller
                                  name="password"
                                  control={control}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      className="float-right"
                                      autoComplete="false"
                                      type="password"
                                      invalid={!isEmpty(errors.password)}
                                    />
                                  )}
                                />
                                {errors.password && (
                                  <FormFeedback>{errors.password?.message}</FormFeedback>
                                )}
                              </Col>
                            </FormGroup>
                            <FormGroup row className="mb-2">
                              <Label>Confirm Password</Label>
                              <Col>
                                <Controller
                                  name="password_confirmation"
                                  control={control}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      className="float-right"
                                      autoComplete="false"
                                      type="password"
                                      invalid={!isEmpty(errors.password)}
                                    />
                                  )}
                                />
                                {errors.password && (
                                  <FormFeedback>{errors.password?.message}</FormFeedback>
                                )}
                              </Col>
                            </FormGroup>
                            <Row>
                              <Col>
                                <Button
                                  type="submit"
                                  isloading={loadingChangePassword}
                                  className={`btn float-right ${
                                    loadingChangePassword ? 'btn-disabled btn-gray' : 'btn-primary'
                                  }`}
                                  disabled={loadingChangePassword}
                                  title="Submit"
                                />
                              </Col>
                            </Row>
                          </Form>
                        ) : (
                          <Button
                            className={`btn float-right btn-primary`}
                            onClick={toggleChangePasswordForm}
                            disabled={loadingChangePassword}
                            title="Reset Password"
                          />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr />
              <h3>Devices</h3>
              <Panel>
                <PanelHeader>Device List</PanelHeader>
                <PanelBody>
                  <div className="table-responsive">
                    <table className="table table-striped mb-0 align-middle">
                      <thead>
                        <tr>
                          <th>Last Login</th>
                          <th>Device</th>
                          <th>API version</th>
                          <th>API Key</th>
                          <th>IP</th>
                          <th width="10%">Action Items</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>02/02/2020</td>
                          <td>Android</td>
                          <td>2.0.0</td>
                          <th>cc7b94af056588d1ec716ba3e2029f6e_1626163611</th>
                          <td>65.33.232.232</td>
                          <td nowrap="true">
                            <a className="btn btn-sm btn-danger w-60px me-1" href="/table/basic">
                              Revoke
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>02/02/2020</td>
                          <td>Chrome</td>
                          <td>2.0.0</td>
                          <th>e88e1bbe17731a3cbb02b1524141b755_1626238356</th>
                          <td>192.434.232.232</td>
                          <td nowrap="true">
                            <a className="btn btn-sm btn-danger w-60px me-1" href="/table/basic">
                              Revoke
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(InvestorView);

const ButtonReject = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = (type) => () => {
    setModalReject(false);
    toggleModalReject().then(() => {
      props.onConfirm({ reason, type });
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-xs btn-red m-l-5" type="button" onClick={toggleModalReject}>
        Reject
      </button>

      <SweetAlert
        title={<span className="text-black text-wrap">Reject Investor Application</span>}
        danger
        showCancel
        // confirmBtnText="Reject"
        // confirmBtnBsStyle="danger"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        customButtons={
          <>
            <button className="btn btn-red m-l-5 m-b-5" type="button" onClick={onReject(2)}>
              Unsuitable Customer
            </button>
            <button className="btn btn-red m-l-5 m-b-5" type="button" onClick={onReject(3)}>
              Insufficient Profile Info
            </button>
            <button
              className="btn btn-outline-danger m-l-5 m-b-5"
              type="button"
              onClick={toggleModalReject}
            >
              Cancel
            </button>
          </>
        }
        // onCancel={toggleModalReject}
        show={modalReject}
        dependencies={[reason]}
      >
        <form>
          <textarea
            type="text"
            className="form-control"
            value={reason}
            onChange={onChangeText}
            placeholder="Please indicate the reason for rejecting investor application"
          />
        </form>
      </SweetAlert>
    </>
  );
};

const ButtonApprove = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    setModalReject(false);
    toggleModalReject().then(() => {
      props.onConfirm(reason);
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-xs btn-green m-l-5" type="button" onClick={toggleModalReject}>
        Approve
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Approve Investor Application</span>}
        success
        showCancel
        confirmBtnText="Approve"
        confirmBtnBsStyle="success"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <p style={{ color: 'black' }}>
          Are you sure you want to approve this investor application?
        </p>
      </SweetAlert>
    </>
  );
};

const schemaAdjustFunds = yup.object().shape({
  amount: yup.string(),
  description: yup.string().required()
});

const ButtonAddFund = (props) => {
  const { id } = useParams();

  const [modalReject, setModalReject] = useState(false);
  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onSubmit = (data) => {
    API.userAddFunds(id, data)
      .then((res) => {
        Notification.success('Add Funds', 'Funds successfully adjusted');
        toggleModalReject();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const {
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    resolver: yupResolver(schemaAdjustFunds),
    defaultValues: {
      amount: '',
      description: ''
    }
  });

  return (
    <>
      <button
        className="btn btn-md btn-primary m-l-5 no-border"
        type="button"
        onClick={toggleModalReject}
      >
        <i className="fa fa-plus mr-1"></i>&nbsp;Add Funds
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Fund Adjustment : Add</span>}
        customIcon={<i className="fa fa-plus fa-5x text-primary"></i>}
        custom
        showCancel
        confirmBtnText="Confirm Add Funds"
        confirmBtnBsStyle="primary"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <Form>
          <FormGroup row className="mt-2">
            <Col md="12">
              <Controller
                name="amount"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CurrencyInput
                    value={value}
                    prefix="₱"
                    placeholder="Amount"
                    decimalsLimit={2}
                    onValueChange={(value, name) => onChange(value)}
                    className="form-control"
                  />
                )}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="12">
              <Controller
                name="description"
                isClearable
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="textarea"
                    placeholder="Description"
                    invalid={!isEmpty(errors.description)}
                  />
                )}
              />
            </Col>
          </FormGroup>
        </Form>
      </SweetAlert>
    </>
  );
};

const ButtonDeductFund = (props) => {
  const { id } = useParams();

  const [modalReject, setModalReject] = useState(false);
  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onSubmit = (data) => {
    const params = new URLSearchParams();
    params.append('amount', data.amount);
    params.append('description', data.description);
    API.userDeductFunds(id, params)
      .then((res) => {
        Notification.success('Deduct Funds', 'Funds successfully adjusted');
        toggleModalReject();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const {
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    resolver: yupResolver(schemaAdjustFunds),
    defaultValues: {
      amount: '',
      description: ''
    }
  });

  return (
    <>
      <button
        className="btn btn-md btn-green m-l-5 no-border text-white"
        type="button"
        onClick={toggleModalReject}
      >
        <i className="fa fa-minus text-white mr-1"></i>&nbsp;Deduct Funds
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Fund Adjustment : Deduct</span>}
        customIcon={<i className="fa fa-minus fa-5x text-green"></i>}
        custom
        showCancel
        confirmBtnText="Confirm Deduct Funds"
        confirmBtnBsStyle="green"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <Form>
          <FormGroup row className="mt-2">
            <Col md="12">
              <Controller
                name="amount"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CurrencyInput
                    value={value}
                    prefix="₱"
                    placeholder="Amount"
                    decimalsLimit={2}
                    onValueChange={(value, name) => onChange(value)}
                    className="form-control"
                  />
                )}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="12">
              <Controller
                name="description"
                isClearable
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="textarea"
                    placeholder="Description"
                    invalid={!isEmpty(errors.description)}
                  />
                )}
              />
            </Col>
          </FormGroup>
        </Form>
      </SweetAlert>
    </>
  );
};

const schemaSendMessage = yup.object().shape({
  type: yup.string(),
  message: yup.string().required('Message is required'),
  subject: yup.string().when('type', {
    is: (optionType) => optionType == 2,
    then: yup.string().required('Subject is required')
  })
});

const ButtonSendMessage = (props) => {
  const { investor } = props;
  const [modalReject, setModalReject] = useState(false);
  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const optionType = [
    { value: 1, label: 'SMS' },
    { value: 2, label: 'Email' }
  ];

  const onSubmit = (data) => {
    const recipient =
      data.type == 2
        ? { email_address: [investor.email] }
        : { mobile_number: [investor.mobile_number] };

    const params = {
      ...data,
      ...recipient
    };
    console.log(params);
    API.sendMessage(params)
      .then((res) => {
        Notification.success(
          `Send ${data.type == 1 ? 'SMS' : 'Email'}`,
          `${data.type == 1 ? 'SMS' : 'Email'} successfully sent`
        );
        toggleModalReject();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch
  } = useForm({
    resolver: yupResolver(schemaSendMessage),
    defaultValues: {
      type: 1,
      subject: '',
      message: ''
    }
  });
  const selectedType = watch('type', 1);

  return (
    <>
      <button
        className="btn btn-xs btn-primary m-l-5 no-border text-white"
        type="button"
        onClick={toggleModalReject}
      >
        Send Message
      </button>
      <SweetAlert
        title={
          <span className="text-black text-wrap">
            Send Message to: <br /> {investor.full_name}
          </span>
        }
        customIcon={<i className="fa fa-paper-plane fa-4x text-primary"></i>}
        custom
        showCancel
        confirmBtnText="Send Message"
        confirmBtnBsStyle="primary"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <Form>
          <FormGroup row>
            <Col md="12">
              <Controller
                name="type"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <ReactSelect
                    inputRef={ref}
                    className="text-black"
                    value={optionType.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                    options={optionType}
                    isOptionDisabled={(option) => option.disabled}
                  />
                )}
              />
            </Col>
          </FormGroup>
          {selectedType == 2 && (
            <FormGroup row>
              <Col md="12">
                <Controller
                  name="subject"
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input {...field} placeholder="Subject" invalid={!isEmpty(errors.subject)} />
                  )}
                />
                {errors.subject && (
                  <FormFeedback style={{ display: 'block' }}>
                    {errors.subject?.message}
                  </FormFeedback>
                )}
              </Col>
            </FormGroup>
          )}

          <FormGroup row>
            <Col md="12">
              <Controller
                name="message"
                isClearable
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    rows="5"
                    type="textarea"
                    placeholder="Enter Message..."
                    invalid={!isEmpty(errors.message)}
                  />
                )}
              />
              {errors.message && (
                <FormFeedback style={{ display: 'block' }}>{errors.message?.message}</FormFeedback>
              )}
            </Col>
          </FormGroup>
        </Form>
      </SweetAlert>
    </>
  );
};

const AddRemarks = (props) => {
  const { investor } = props;

  const [showAddRemarksForm, setShowAddRemarksForm] = useState(false);

  const toggleAddRemarksForm = () => {
    setShowAddRemarksForm(!showAddRemarksForm);
  };
  const [loadingAddRemarks, setLoadingAddRemarks] = useState(false);

  const onSubmitRemarks = (data) => {
    setLoadingAddRemarks(true);

    API.patchInvestor(investor.id, data)
      .then((res) => {
        setLoadingAddRemarks(false);
        Notification.success('Update Remarks', 'Successfully update remarks');
      })
      .catch((err) => {
        setLoadingAddRemarks(false);
        if (err.response.status === 422) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            setError(key, {
              type: 'manual',
              message: value
            });
          }
        } else {
          Notification.error(err);
        }
      });
  };

  const onRemoveRemarks = () => {
    setLoadingAddRemarks(true);
    API.patchInvestor(investor.id, { remarks: null })
      .then((res) => {
        setLoadingAddRemarks(false);
        Notification.success('Update Remarks', 'Successfully remove remarks');
      })
      .catch((err) => {
        setLoadingAddRemarks(false);
        if (err.response.status === 422) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            setError(key, {
              type: 'manual',
              message: value
            });
          }
        } else {
          Notification.error(err);
        }
      });
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch
  } = useForm({
    resolver: yupResolver(schemaRemarks),
    defaultValues: {
      remarks: ''
    }
  });

  return (
    <tr>
      <td className="field">Remarks:</td>
      <td className="float-left">
        {showAddRemarksForm ? (
          <Form onSubmit={handleSubmit(onSubmitRemarks)} autoComplete="off">
            <FormGroup row className="mb-2">
              <Col>
                <Controller
                  name="remarks"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="float-right"
                      autoComplete="false"
                      type="textarea"
                      invalid={!isEmpty(errors.remarks)}
                    />
                  )}
                />
              </Col>
            </FormGroup>
            <Row>
              <Col>
                <Button
                  type="submit"
                  isloading={loadingAddRemarks}
                  className={`btn float-right ${
                    loadingAddRemarks ? 'btn-disabled btn-gray' : 'btn-primary'
                  }`}
                  disabled={loadingAddRemarks}
                  title="Submit"
                />
              </Col>
            </Row>
          </Form>
        ) : isNull(investor.remarks) ? (
          <Button
            className={`btn float-right ${
              loadingAddRemarks ? 'btn-disabled btn-gray' : 'btn-primary'
            }`}
            onClick={toggleAddRemarksForm}
            disabled={loadingAddRemarks}
            isloading={loadingAddRemarks}
            title="Add Remarks"
          />
        ) : (
          <>
            <p>{investor.remarks}</p>
            <Button
              className={`btn float-right ${
                loadingAddRemarks ? 'btn-disabled btn-gray' : 'btn-danger'
              }`}
              onClick={onRemoveRemarks}
              disabled={loadingAddRemarks}
              isloading={loadingAddRemarks}
              title="Remove Remarks"
            />
          </>
        )}
      </td>
    </tr>
  );
};
