import React, { memo, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Row, Col, Tooltip } from 'reactstrap';

import PageContext from '../../../config/page-settings.js';
import * as API from '../../../services/api';
import moment from 'moment';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { PriceFormat } from '../../../utils/index.js';

import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import ReactHtmlParser from 'react-html-parser';
import { Table, RowActionItems, RowItemDelete, RowItemView } from '../../../components/table';
import IssuerPayNow from './IssuerPayNow.js';

const LoanSchedule = () => {
  const { project_id } = useParams();

  const pageContext = useContext(PageContext);

  //   useEffect(() => {
  //     pageContext.handleSetPageSidebar(true);
  //     pageContext.handleSetPageHeader(true);
  //     pageContext.handleSetPageContentFullWidth(false);
  //   }, []);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [loanScheduleList, setLoanScheduleList] = useState([]);
  const [loanScheduleTotal, setLoanScheduleTotal] = useState({});

  useEffect(() => {
    API.getProjectLoanSchedule(project_id).then((res) => {
      setLoanScheduleList(res.data.data.list);
      setLoanScheduleTotal(res.data.data.total);
    });
  }, [project_id]);

  return (
    <>
      <h3>Repayment Schedule</h3>
      {loanScheduleList && loanScheduleList.length != 0 && (
        <>
          <fieldset>
            <Panel>
              <PanelHeader></PanelHeader>
              <PanelBody>
                <div className="table-responsive">
                  <table className="table table-striped mb-0 align-middle">
                    <thead>
                      <tr>
                        <th>Period</th>
                        <th>Due Date</th>
                        <th>Payment</th>
                        <th>Principal</th>
                        <th>Interest</th>
                        <th>Payment Made</th>
                        <th>Status</th>
                        <th>Action Items</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loanScheduleList &&
                        loanScheduleList.map((schedule, index) => {
                          return (
                            <tr key={index}>
                              <td>{schedule.period}</td>
                              <td>{moment(schedule.due_date).format('L')}</td>
                              <td>{PriceFormat(schedule.payment)}</td>
                              <td>{PriceFormat(schedule.principal)}</td>
                              <td>{PriceFormat(schedule.interest)}</td>
                              <td>{PriceFormat(schedule.totalPaid)}</td>
                              <td>{ReactHtmlParser(schedule.status_styled)}</td>
                              <td>
                                <RowActionItems>
                                  {/* {!schedule.paid && moment().isSame(schedule.due_date, 'day') && (
                                    <IssuerPayNow data={schedule} />
                                  )} */}
                                  {!schedule.paid && <IssuerPayNow data={schedule} />}
                                </RowActionItems>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="2">
                          <b>TOTAL</b>
                        </td>
                        <td>
                          <b>{PriceFormat(loanScheduleTotal.payment)}</b>
                        </td>
                        <td>
                          <b>{PriceFormat(loanScheduleTotal.principal)}</b>
                        </td>
                        <td>
                          <b>{PriceFormat(loanScheduleTotal.interest)}</b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </PanelBody>
            </Panel>
          </fieldset>
        </>
      )}
    </>
  );
};

export default LoanSchedule;
