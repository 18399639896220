import { store } from 'react-notifications-component';
import { has } from 'lodash';

const success = (
  title = 'Notification Title',
  message = 'Notification Message',
  duration = 3000
) => {
  store.addNotification({
    title: title,
    message: message,
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: duration,
      onScreen: true
    }
  });
};

const error = (error, duration = 3000) => {
  console.error(error.response ? error.response : error.message);
  var title = '';
  var message = '';

  if (error.response === undefined) {
    console.log(error.message);
    title = 'Client-Side Error';
    message = error.message;
  } else {
    console.log(error.response);
    title = `Error Code: ${error.response.status}`;
    message = JSON.stringify(
      error.response.data.message ? error.response.data.message : error.response.data
    );
  }

  store.addNotification({
    title: title,
    message: message,
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: duration,
      onScreen: true
    }
  });
};

const info = (title = 'Notification Title', message = 'Notification Message', duration = 3000) => {
  store.addNotification({
    title: title,
    message: message,
    type: 'info',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: duration,
      onScreen: true
    }
  });
};

export { success, error, info };
