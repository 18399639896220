import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PageContext from './../../config/page-settings.js';
import ProjectList from './ProjectList';
const Projects = (props) => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const addUser = () => {
    props.history.push('/projects/create');
  };

  return (
    <div>
      <h1 className="page-header">
        Projects{' '}
        <button onClick={addUser} className="btn btn-primary btn-icon btn-circle btn-md ml-2">
          <i className="fa fa-plus"></i>
        </button>
        <button className="btn btn-secondary btn-icon btn-circle btn-md ml-2">
          <i className="fa fa-download"></i>
        </button>
      </h1>
      <ProjectList />
    </div>
  );
};

export default withRouter(Projects);
