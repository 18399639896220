import React, { memo, useState, useMemo, useEffect, useContext } from 'react';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, FormGroup, FormFeedback, Row, Col, Label, Input, Tooltip, Button } from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';

import { isEmpty, isUndefined } from 'lodash';
import ReactSelect from 'react-select';
import PageContext from '../../../config/page-settings.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as API from '../../../services/api';
import moment from 'moment';
import FileUpload from '../../../components/forms/FileUpload.js';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import PageHeader from '../../../components/common/PageHeader.js';
import { Notification, PriceFormat } from '../../../utils/index.js';

import DateTime from 'react-datetime';

import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      checked={props.checked}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
      disabled={props.disabled || false}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const TooltipItem = (props) => {
  const { placement, id, content } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <i className="fa fa-exclamation-circle text-info ml-1" id={'Tooltip-' + id} />
      <Tooltip placement={placement} isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
        {content}
      </Tooltip>
    </span>
  );
};

const schemaInvestorSelect = yup.object().shape({
  issuer: yup.object(),
  loan: yup.object(),
  publish_date: yup
    .date()
    .required('Publish Date is required')
    .min(moment(), 'Date cannot be in the past'),
  activate_invest_date: yup.date().when('publish_date', {
    is: (publishDate) => moment(publishDate) < 3,
    then: yup.string().required('First name is required')
  }),
  industry: yup.string().required('Industry type is required'),
  category_id: yup.string().required('Category type is required'),
  subscription_days: yup.number().required(),
  returns: yup.number().required('Returns is required'),
  op_service_fee: yup.number().required('Loan Fee is required'),
  title: yup.string().required('Title is required'),
  banner: yup
    .mixed()
    .required('Banner is required')
    .test('fileSize', 'The file is too large', (value) => {
      return value && value[0].size <= 2000000;
    })
    .test('type', 'Only the following formats are accepted:.png .jpeg, .jpg,', (value) => {
      return (
        value &&
        (value[0].type === 'image/jpeg' ||
          value[0].type === 'image/jpg' ||
          value[0].type === 'image/png')
      );
    })
});

const ProjectDetails = ({ project }) => {
  const { user_id } = useParams();
  const history = useHistory();

  const pageContext = useContext(PageContext);

  //   useEffect(() => {
  //     pageContext.handleSetPageSidebar(true);
  //     pageContext.handleSetPageHeader(true);
  //     pageContext.handleSetPageContentFullWidth(false);
  //   }, []);

  const {
    watch,
    formState: { errors },
    getValues,
    setValue,
    setError,
    handleSubmit,
    control,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        funding_id: project.funding_id,
        has_guarantor: project.has_guarantor,
        has_pledge: project.has_pledge,
        has_visitation: project.has_visitation,
        subscription_days: project.subscription_days,
        repayment_mode: project.repayment_mode && project.repayment_mode.id,
        category_id: project.category && project.category.id,
        industry: project.industry && project.industry.id,
        publish_date: moment(project.publish_date).format('YYYY-MM-DD HH:mm'),
        title: project.title,
        description: project.description,
        loan_display_investors: 1,
        minimum_lend_amount: project.minimum_lend_amount,
        auto_invest_enabled: project.auto_invest_enabled == 1,
        allow_priority_investor: project.allow_priority_investor == 1,
        repayment_start_date: project.repayment_start_date,
        amount: project.amount,
        returns: project.returns,
        sec_filling_at: moment(project.sec_filling_at).format('YYYY-MM-DD')
      };
    }, [project]),
    resolver: yupResolver(schemaInvestorSelect)
  });

  const watchIssuer = watch('issuer', { id: project.user_id });
  const watchLoan = watch('loan', project);
  const watchReturns = watch('returns');
  const watchSubDays = watch('subscription_days');

  const [optionIssuers, setOptionIssuers] = useState([]);

  const [optionIndustry, setOptionIndustry] = useState([]);

  const [loanScheduleList, setLoanScheduleList] = useState([]);
  const [loanScheduleTotal, setLoanScheduleTotal] = useState({});

  const [optionCategories, setOptionCategories] = useState([]);

  const [loadingSECSubmit, setLoadingSECSubmit] = useState(false);
  const updateFillingSECDate = () => {
    setLoadingSECSubmit(true);
    const params = new URLSearchParams();
    params.append('sec_filling_at', getValues('sec_filling_at'));
    API.updateSECDate(project.id, params)
      .then(() => {
        setLoadingSECSubmit(false);
        Notification.success('Update SEC Filling Date', 'Filling date successfully updated!');
        history.push('/projects');
      })
      .catch((err) => {
        setLoadingSECSubmit(false);
        Notification.error('Update SEC Filling Date', 'Filling date failed to update!');
      });
  };

  const [loadingReservedAmount, setLoadingReservedAmount] = useState(false);

  const updateReservedAmount = () => {
    setLoadingReservedAmount(true);
    const params = new URLSearchParams();
    params.append('reserved_amount', getValues('reserved_amount'));
    params.append('project_id', project.id);
    API.patchProject(project.id, params)
      .then(() => {
        setLoadingReservedAmount(false);
        Notification.success('Add Reserve Amount', 'Reserve amount successfully updated!');
        history.go(0);
      })
      .catch((err) => {
        setLoadingReservedAmount(false);

        if (err.response && err.response.status === 422) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            setError(key, {
              type: 'manual',
              message: value[0]
            });
          }
        } else {
          Notification.error('Add Reserve Amount', 'Reserve amount failed to update!');
        }
      });
  };

  const removeReservedAmount = () => {
    setLoadingReservedAmount(true);
    const params = new URLSearchParams();
    params.append('reserved_amount', 0);
    params.append('project_id', project.id);
    API.patchProject(project.id, params)
      .then(() => {
        setLoadingReservedAmount(false);
        Notification.success('Add Reserve Amount', 'Reserve amount successfully updated!');
        history.go(0);
      })
      .catch((err) => {
        setLoadingReservedAmount(false);

        if (err.response && err.response.status === 422) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            setError(key, {
              type: 'manual',
              message: value[0]
            });
          }
        } else {
          Notification.error('Add Reserve Amount', 'Reserve amount failed to update!');
        }
      });
  };

  useEffect(() => {
    API.dataSets(['industries', 'loan_categories']).then((res) => {
      setOptionCategories(res.data.loan_categories);
      setOptionIndustry(res.data.industries);
    });
    reset({
      ...project,
      repayment_mode: project.repayment_mode && project.repayment_mode.id,
      category_id: project.category_id && project.category_id.id,
      industry: project.industry && project.industry.id,
      publish_date: moment(project.publish_date).format('YYYY-MM-DD HH:mm'),
      activate_invest_date: moment(project.activate_invest_date).format('YYYY-MM-DD HH:mm')
    });
  }, [project]);

  //   useEffect(() => {

  //     API.getIssuers().then((res) => {
  //       var optionIssuers = res.data.issuers.map((issuer) => ({
  //         ...issuer,
  //         value: issuer.user_id,
  //         label: issuer.company_name
  //       }));
  //       setOptionIssuers(optionIssuers);
  //     });
  //   }, []);

  //   useEffect(() => {
  //     setValue(
  //       'issuer',
  //       optionIssuers.find((issuer) => issuer.user_id == project.user_id)
  //     );
  //   }, [project, optionIssuers]);

  //   const watchPublishDate = watch('publish_date', moment().format('YYYY-MM-DD'));

  //   const watchRepaymentMode = watch('repayment_mode');

  const [optionIssuerLoans, setOptionIssuerLoans] = useState([]);

  //   useEffect(() => {
  //     if (!isEmpty(watchIssuer)) {
  //       API.getIssuerLoans(watchIssuer.user_id).then((res) => {
  //         var optionIssuerLoans = res.data.loan_requests.map((loan) => ({
  //           ...loan,
  //           value: loan.id,
  //           label: `LOAN ID: ${loan.id} - ${loan.purpose} (${loan.status_name})`,
  //           disabled: loan.status != 6
  //         }));
  //         setOptionIssuerLoans(optionIssuerLoans);
  //       });
  //     }
  //   }, [watchIssuer]);

  //   useEffect(() => {
  //     setValue('loan_tenor', watchLoan && watchLoan.loan_tenor);
  //   }, [watchLoan]);

  //   useEffect(() => {
  //     setValue(
  //       'repayment_start_date',
  //       watchPublishDate &&
  //         moment(watchPublishDate, 'YYYY-MM-DD')
  //           .add(Number(getValues('subscription_days')) - 1, 'days')
  //           .format('YYYY-MM-DD')
  //     ) || moment().add(30, 'days').format('YYYY-MM-DD');
  //   }, [watchPublishDate]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onPreviewFactsheet = async () => {
    var token = localStorage.getItem('access_token');

    var win = window.open('_blank');

    downloadFile(project && project.attachment?.view, function (blob) {
      var url = URL.createObjectURL(blob);
      win.location = url;
    });

    function downloadFile(url, success) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (success) success(xhr.response);
        } else {
        }
      };
      xhr.send(null);
    }
  };

  const optionRepayment = [
    { value: 1, label: 'Balloon Payment' },
    { value: 3, label: 'Equal Monthly Repayment' }
  ];

  const optionInvestorType = [
    { value: 1, label: 'All Investors' },
    { value: 2, label: 'Retail Investors Only' },
    { value: 3, label: 'Qualified Investors Only' }
  ];

  const onSubmitForm = (data) => {
    setLoadingSubmit(true);
    const isFile = (input) => 'File' in window && input instanceof File;

    const params = new FormData();

    params.append('user_id', data.issuer.user_id);
    params.append('loan_request_id', data.loan.id);
    params.append('funding_id', Number(data.funding_id));
    params.append('industry', Number(data.industry));
    params.append('category_id', Number(data.category_id));
    params.append('amount', Number(data.loan.amount));
    params.append('subscription_days', data.subscription_days);
    params.append('repayment_mode', data.repayment_mode);

    params.append('has_pledge', data.has_pledge ? 1 : 0);
    params.append('has_visitation', data.has_visitation ? 1 : 0);
    params.append('has_guarantor', data.has_guarantor ? 1 : 0);

    params.append('minimum_lend_amount', data.minimum_lend_amount);
    params.append('loan_tenor', data.loan_tenor);
    params.append('returns', data.returns);

    params.append('title', data.title);

    if (data.banner && isFile(data.banner[0])) {
      params.append('banner', data.banner[0]);
    }

    params.append('loan_request_id', data.loan.id);
    params.append('auto_invest_enabled', data.auto_invest_enabled ? 1 : 0);
    params.append('description', data.description);
    params.append('title', data.title);

    if (data.attachment && isFile(data.attachment[0])) {
      params.append('attachment', data.attachment[0]);
    }

    params.append('op_service_fee', data.op_service_fee);

    params.append('publish_date', moment(data.publish_date).format('YYYY-MM-DD HH:mm'));
    params.append(
      'activate_invest_date',
      moment(data.activate_invest_date).format('YYYY-MM-DD HH:mm')
    );
    params.append('auto_invest_enabled', data.auto_invest_enabled ? 1 : 0);
    params.append('restrict_non_investor', 0);
    params.append('repayment_start_date', data.repayment_start_date);

    API.postProject(params)
      .then((res) => {
        Notification.success('Project Create', 'Project successfully created!');
        history.push('/projects');
      })
      .catch((err) => {
        if (err.response.status == 422) {
          if (err.response.data.errors.loan_request_id) {
            Notification.error(
              'Project Create',
              'Loan request is already linked to an existing project'
            );
          } else {
            Notification.error('Project Create', err.response.data.message);
          }
        } else {
          Notification.error('Project Create', 'Project failed to created!');
        }
      })
      .finally(() => setLoadingSubmit(false));
  };

  const subscriptionPercentage = (Number(project.total_funds) / Number(project.amount)) * 100;

  return (
    <>
      <Row>
        <Col sm="12" md="12" lg={{ size: 10, offset: 1 }}>
          {project && project.status && project.status.id == 4 && (
            <>
              <FormGroup tag="fieldset">
                <h3>Repayment Information</h3>
                <FormGroup row>
                  <Col md="5">Maturity Date</Col>
                  <Col md="7">{project.repayment_maturity_date}</Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="5">Outstanding Balance</Col>
                  <Col md="7">{PriceFormat(project.repayment_outstanding_balance)}</Col>
                </FormGroup>
                {project.repayment_mode?.id == 1 && (
                  <FormGroup row>
                    <Col md="5">Outstanding Interest</Col>
                    <Col md="7">{PriceFormat(project.repayment_outstanding_interest)}</Col>
                  </FormGroup>
                )}
                <FormGroup row>
                  <Col md="5">Settlement Amount</Col>
                  <Col md="7">{PriceFormat(project.repayment_settlement_amount)}</Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="5">Previous Payment Date</Col>
                  <Col md="7">{project.repayment_previous_payment_date ?? '-No Payment Yet-'}</Col>
                </FormGroup>
              </FormGroup>
              <div style={{ border: '1px solid black', marginBottom: '12px' }}>
                <FormGroup row>
                  <Col md="5">-- Next Payout --</Col>
                  <Col md="7"></Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="5">Next Payment Date</Col>
                  <Col md="7">2022-08-11 ( 29 days days left )</Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="5">Next Interest To Pay</Col>
                  <Col md="7">{PriceFormat(22500)}</Col>
                </FormGroup>
              </div>
              <div style={{ border: '1px solid black' }}>
                <FormGroup row>
                  <Col md="5">
                    <b>Total Amount To Pay</b>
                  </Col>
                  <Col md="7">
                    <span style={{ borderBottom: '3px double black', marginBottom: '12px' }}>
                      {PriceFormat(22500)}
                    </span>
                  </Col>
                </FormGroup>
              </div>
            </>
          )}

          {project?.status?.id == 1 && (
            <>
              <div className="progress mb-2">
                <div
                  className="progress-bar fs-10px fw-bold"
                  style={{ width: `${subscriptionPercentage}%` }}
                >
                  {`${subscriptionPercentage}%`}
                </div>
              </div>
              <Row style={{ marginBottom: 18 }}>
                <Col>
                  <Row>
                    <Col>{PriceFormat(project.total_funds)}</Col>
                  </Row>
                  <Row>
                    <Col>Amount Raised</Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col style={{ textAlign: 'right' }}>
                      {PriceFormat(project.amount - project.total_funds)}
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ textAlign: 'right' }}>Remaining</Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}

          {/* { / {PriceFormat(project.amount)} */}
          <Form onSubmit={handleSubmit(onSubmitForm)}>
            <FormGroup tag="fieldset">
              <h3>Project Terms</h3>
              <FormGroup row>
                <Label md="3">Issuer</Label>
                <Col md="9">
                  <Controller
                    name="company_name"
                    control={control}
                    render={({ field }) => <Input {...field} type="text" disabled />}
                  />
                </Col>
              </FormGroup>
              {/* <FormGroup row>
                <Label md="3">Loan Request</Label>
                <Col md="9">
                  <Controller
                    name="loan"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <ReactSelect
                        inputRef={ref}
                        value={optionIssuerLoans.find((c) => c.value === value)}
                        onChange={(val) => onChange(val)}
                        options={optionIssuerLoans}
                        isOptionDisabled={(option) => option.disabled}
                      />
                    )}
                  />
                </Col>
              </FormGroup> */}

              <FormGroup row>
                <Label md="3">
                  Investment ID
                  <TooltipItem placement="top" id="funding_id" content="Auto Generated" />
                </Label>
                <Col md="9">
                  <Controller
                    name="funding_id"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        disabled
                        invalid={!isEmpty(errors.funding_id)}
                      />
                    )}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label md="3">
                  Target Offering Amount
                  <TooltipItem placement="top" id="amount" content="Funds Needed" />
                </Label>
                <Col md="9">
                  <CurrencyInput
                    value={project.amount}
                    prefix="₱ "
                    disabled
                    placeholder="Loan Amount"
                    decimalsLimit={2}
                    className="form-control"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">
                  Offering Period
                  <TooltipItem
                    placement="top"
                    id="period"
                    content="Days allowed investor to subscribe"
                  />
                </Label>
                <Col md="9">
                  <Controller
                    name="subscription_days"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        disabled
                        className="number-only-field"
                        type="number"
                        invalid={!isEmpty(errors.subscription_days)}
                      />
                    )}
                  />
                  <FormFeedback style={{ display: errors.subscription_days ? 'block' : 'none' }}>
                    {errors.subscription_days?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">
                  Target Offering Deadline
                  <TooltipItem
                    placement="top"
                    id="deadline"
                    content="To be set based on the Subscription Period after the Publish Date"
                  />
                </Label>
                <Col md="9">
                  <Controller
                    name="repayment_start_date"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        disabled
                        invalid={!isEmpty(errors.repayment_start_date)}
                      />
                    )}
                  />
                  <FormFeedback style={{ display: errors.repayment_start_date ? 'block' : 'none' }}>
                    {errors.repayment_start_date?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Repayment Mode</Label>
                <Col md="9">
                  <Controller
                    name="repayment_mode"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <ReactSelect
                        inputRef={ref}
                        value={optionRepayment.find((c) => c.value === value)}
                        onChange={(val) => onChange(val.value)}
                        options={optionRepayment}
                        isOptionDisabled={(option) => option.disabled}
                      />
                    )}
                  />
                  <FormFeedback style={{ display: errors.amount ? 'block' : 'none' }}>
                    {errors.repayment_mode?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Has Pledge Collaterals</Label>
                <Col md="9">
                  <Controller
                    name="has_pledge"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Switchery checked={value} onChange={onChange} for="has_pledge" />
                    )}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Approved by Credit Risk Committee</Label>
                <Col md="9">
                  <Controller
                    name="has_visitation"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Switchery checked={value} onChange={onChange} for="has_visitation" />
                    )}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Has Personal Guarantor</Label>
                <Col md="9">
                  <Controller
                    name="has_guarantor"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Switchery checked={value} onChange={onChange} for="has_guarantor" />
                    )}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Minimum Investment</Label>
                <Col md="9">
                  <Controller
                    name="minimum_lend_amount"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CurrencyInput
                        value={value}
                        prefix="₱ "
                        disabled
                        decimalsLimit={2}
                        className="form-control"
                      />
                    )}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">
                  Tenor
                  <TooltipItem placement="top" id="loan_tenor" content="Loan Request Tenure" />
                </Label>
                <Col md="9">
                  <Controller
                    name="loan_tenor"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="text"
                        disabled
                        invalid={!isEmpty(errors.loan_tenor)}
                      />
                    )}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Returns</Label>
                <Col md="9">
                  <Controller
                    name="returns"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <CurrencyInput
                        value={value}
                        suffix="%"
                        decimalsLimit={2}
                        onValueChange={(value, name) => onChange(value)}
                        className="form-control"
                      />
                    )}
                  />
                  <FormFeedback style={{ display: errors.returns ? 'block' : 'none' }}>
                    {errors.returns?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>
            </FormGroup>
            <hr />
            <FormGroup tag="fieldset">
              <h3>Project Details</h3>
              <FormGroup row>
                <Label md="3">Title</Label>
                <Col md="9">
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <Input {...field} type="text" invalid={!isEmpty(errors.title)} />
                    )}
                  />
                  <FormFeedback style={{ display: errors.title ? 'block' : 'none' }}>
                    {errors.title?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Banner</Label>
                <Col md="9">
                  <img alt="Banner" src={(project && project?.banner?.view) || null} width="100%" />
                  {/* <Controller
                    name="banner"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <FileUpload
                        onDrop={onChange}
                        onChange={onChange}
                        imagefile={value}
                        invalid={!isEmpty(errors.banner)}
                      />
                    )}
                  /> */}
                  <FormFeedback style={{ display: errors.banner ? 'block' : 'none' }}>
                    {errors.banner?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Description</Label>
                <Col md="9">
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="textarea"
                        rows="5"
                        invalid={!isEmpty(errors.description)}
                      />
                    )}
                  />
                  <FormFeedback style={{ display: errors.description ? 'block' : 'none' }}>
                    {errors.description?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Factsheet</Label>
                <Col md="9">
                  {/* <Controller
                    name="attachment"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <FileUpload
                        onDrop={onChange}
                        onChange={onChange}
                        imagefile={value}
                        invalid={!isEmpty(errors.attachment)}
                      />
                    )}
                  /> */}
                  <Button color="primary" onClick={onPreviewFactsheet}>
                    Preview
                  </Button>
                  <FormFeedback style={{ display: errors.attachment ? 'block' : 'none' }}>
                    {errors.attachment?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label md="3">Category</Label>
                <Col md="9">
                  <Controller
                    name="category_id"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <ReactSelect
                        inputRef={ref}
                        value={optionCategories.find((c) => c.value === value)}
                        onChange={(val) => onChange(val.value)}
                        options={optionCategories}
                        isOptionDisabled={(option) => option.disabled}
                      />
                    )}
                  />
                  <FormFeedback style={{ display: errors.category_id ? 'block' : 'none' }}>
                    {errors.category_id?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label md="3">Industry</Label>
                <Col md="9">
                  <Controller
                    name="industry"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <ReactSelect
                        inputRef={ref}
                        value={optionIndustry.find((c) => c.value === value)}
                        onChange={(val) => onChange(val.value)}
                        options={optionIndustry}
                        isOptionDisabled={(option) => option.disabled}
                      />
                    )}
                  />
                  <FormFeedback style={{ display: errors.industry ? 'block' : 'none' }}>
                    {errors.industry?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label md="3">Loan Fee</Label>
                <Col md="9">
                  <Controller
                    name="op_service_fee"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <CurrencyInput
                        value={value}
                        suffix="%"
                        decimalsLimit={2}
                        onValueChange={(value, name) => onChange(value)}
                        className="form-control"
                      />
                    )}
                  />
                  <FormFeedback style={{ display: errors.amount ? 'block' : 'none' }}>
                    {errors.op_service_fee?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label md="3">Publish Date</Label>
                <Col md="9">
                  <Controller
                    name="publish_date"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DateTime
                        inputProps={{ placeholder: 'Select Date and Time' }}
                        closeOnSelect={true}
                        timeFormat="HH:mm"
                        value={value}
                        dateFormat="YYYY-MM-DD"
                        onChange={(e) => {
                          onChange(moment(e).format('YYYY-MM-DD HH:mm'));
                        }}
                        timeConstraints={{
                          minutes: { step: 5 }
                        }}
                        isValidDate={(currentDate) => currentDate > moment().subtract(1, 'days')}
                      />
                    )}
                  />
                  <FormFeedback style={{ display: errors.publish_date ? 'block' : 'none' }}>
                    {errors.publish_date?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Date of Filling to SEC</Label>
                <Col md="6">
                  <Controller
                    name="sec_filling_at"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DateTime
                        inputProps={{ placeholder: 'Select Date' }}
                        closeOnSelect={true}
                        timeFormat={false}
                        value={value}
                        dateFormat="YYYY-MM-DD"
                        onChange={(e) => {
                          onChange(moment(e).format('YYYY-MM-DD'));
                        }}
                      />
                    )}
                  />
                  <FormFeedback style={{ display: errors.sec_filling_at ? 'block' : 'none' }}>
                    {errors.sec_filling_at?.message}
                  </FormFeedback>
                </Col>
                <Col md="3">
                  <button
                    className={`btn text-white ${loadingSECSubmit ? 'btn-gray' : 'btn-primary'}`}
                    type="button"
                    onClick={updateFillingSECDate}
                  >
                    {loadingSECSubmit ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      'Update Submitted Date'
                    )}
                  </button>
                </Col>
              </FormGroup>
            </FormGroup>
            <hr />
            <FormGroup tag="fieldset">
              <h3>Project Settings</h3>
              <FormGroup row>
                <Label md="3">Display to Investors</Label>
                <Col md="9">
                  <Controller
                    name="loan_display_investors"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <ReactSelect
                        inputRef={ref}
                        value={optionInvestorType.find((c) => c.value === value)}
                        onChange={(val) => onChange(val)}
                        options={optionInvestorType}
                        isDisabled
                        isOptionDisabled={(option) => option.disabled}
                      />
                    )}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Activate Manual Invest On</Label>
                <Col md="9">
                  <Controller
                    name="activate_invest_date"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DateTime
                        initialViewDate
                        inputProps={{ placeholder: 'Select Date and Time' }}
                        closeOnSelect={true}
                        timeFormat="HH:mm"
                        value={value}
                        dateFormat="YYYY-MM-DD"
                        timeConstraints={{
                          minutes: { step: 5 }
                        }}
                        onChange={(e) => {
                          onChange(moment(e).format('YYYY-MM-DD HH:mm'));
                        }}
                        isValidDate={(currentDate) => currentDate > moment().subtract(1, 'days')}
                      />
                    )}
                  />
                  <FormFeedback style={{ display: errors.amount ? 'block' : 'none' }}>
                    {errors.activate_invest_date?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Priority VIP Investors</Label>
                <Col md="9">
                  <Controller
                    name="allow_priority_investor"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Switchery
                        checked={value}
                        onChange={onChange}
                        for="allow_priority_investor"
                        disabled
                      />
                    )}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label md="3">Enable Alfred Auto Invest</Label>
                <Col md="9">
                  <Controller
                    name="auto_invest_enabled"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Switchery checked={value} onChange={onChange} for="auto_invest_enabled" />
                    )}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label md="3">Reserved Amount</Label>
                <Col md="6">
                  <Controller
                    name="reserved_amount"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <CurrencyInput
                        value={value}
                        prefix="₱ "
                        // decimalsLimit={2}
                        onValueChange={(value, name) => onChange(value)}
                        className="form-control"
                        disabled={project.reserved_amount != '0.00'}
                      />
                    )}
                  />
                  <FormFeedback style={{ display: errors.reserved_amount ? 'block' : 'none' }}>
                    {errors.reserved_amount?.message}
                  </FormFeedback>
                </Col>
                {project.reserved_amount == '0.00' ? (
                  <Col md="3">
                    <button
                      className={`btn text-white ${
                        loadingReservedAmount ? 'btn-gray' : 'btn-primary'
                      }`}
                      type="button"
                      onClick={updateReservedAmount}
                    >
                      {loadingReservedAmount ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        'Add Reserved Amount'
                      )}
                    </button>
                  </Col>
                ) : (
                  <Col md="3">
                    <button
                      className={`btn text-white ${
                        loadingReservedAmount ? 'btn-gray' : 'btn-warning'
                      }`}
                      type="button"
                      onClick={removeReservedAmount}
                    >
                      {loadingReservedAmount ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        'Remove Reserved Amount'
                      )}
                    </button>
                  </Col>
                )}
              </FormGroup>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ProjectDetails;
