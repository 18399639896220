import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import PageContext from '../../../config/page-settings.js';
import * as API from '../../../services/api';
import { Table } from '../../../components/table';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { useParams } from 'react-router-dom';
// const ACTIVITY_TYPES = [
//   1, 2, 3, 4, 81,

//   5,

//   6, 83,

//   8, 80,

//   9, 10, 82,

//   11, 12,

//   13, 14,

//   15,

//   16,

//   17,

//   18,

//   19,

//   20, 21, 22,

//   23, 24, 25, 26,

//   98, 99,

//   103, 104,

//   105, 106, 107,

//   108, 109,

//   110, 111, 112,

//   113,

//   114,

//   120, 121, 122, 123, 124, 125, 126,

//   127,

//   129, 130, 131,

//   132, 133, 134, 135, 136,

//   149, 150,

//   151, 152, 153, 154, 155, 156
// ];
const ACTIVITY_TYPES = [155];
const ActivityLogsList = () => {
  const { id } = useParams();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const onChangeKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const getRequestParams = (keyword, page, pageSize) => {
    let params = {};

    if (id != undefined) {
      params['filter[user_id]'] = id;
    } else {
      params['filter[type]'] = ACTIVITY_TYPES;
    }

    if (keyword) {
      params['filter[name]&filter[key]'] = keyword;
    }

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    return params;
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    const params = getRequestParams(keyword, page, pageSize);
    setIsLoading(true);
    API.getActivityLogs(params)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        setData([]);
        setMeta({});
        setIsLoading(false);
      });
  };

  useEffect(fetchData, [page, pageSize]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Date',
        accessorFn: (d) => {
          return moment(d.created_at).format('DD-MM-YYYY hh:mm A');
        },
        sortable: true
      },
      {
        header: 'Customer',
        accessorKey: 'user_name',
        sortable: true
      },
      {
        header: 'Admin',
        accessorFn: (d) => {
          return d.admin_name || '';
        },
        sortable: true
      },
      {
        header: 'Description',
        cell: ({ cell }) => {
          const { original } = cell.row;
          return <div>{ReactHtmlParser(original.content)}</div>;
        },
        sortable: true
      }
    ],
    []
  );

  const userColumns = useMemo(
    () => [
      {
        header: 'Date',
        accessorFn: (d) => {
          return moment(d.created_at).format('DD-MM-YYYY hh:mm A');
        },
        sortable: true
      },
      {
        header: 'Description',
        cell: ({ cell }) => {
          const { original } = cell.row;
          return <div>{ReactHtmlParser(original.content)}</div>;
        }
      }
    ],
    []
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Panel>
      <PanelHeader>Activity List</PanelHeader>
      <PanelBody>
        <Table
          columns={id ? userColumns : columns}
          data={data}
          meta={meta}
          isLoading={isLoading}
          onSetPageSize={handlePageSizeChange}
          updateRow={updateRow}
          onNextPage={handlePageChange('next')}
          onPrevPage={handlePageChange('prev')}
          skipPageReset={skipPageReset}
          // onChangeKeyword={onChangeKeyword}
          // keyword={keyword}
          // onSearchKeyword={handleSearch}
        />
      </PanelBody>
    </Panel>
  );
};

export default memo(ActivityLogsList);
