import React, { memo, useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import PageContext from '../../config/page-settings.js';
import { Table, RowActionItems, RowItemDelete, RowItemView } from '../../components/table';
import { Notification } from '../../utils/index.js';
import * as API from '../../services/api';
import moment from 'moment';
import { isNull } from 'lodash';
import { Tooltip } from 'reactstrap';
import axios from 'axios';

const SEARCH_ARRAY = [
  { label: 'Name', value: 'full_name' },
  { label: 'Email', value: 'email' },
  { label: 'Mobile', value: 'otp_number' }
];

const FILTER_ARRAY = [
  {
    name: 'status',
    value: 0
  }
];

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const InvestorList = (props) => {
  const history = useHistory();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [filters, setFilters] = useState(FILTER_ARRAY);


  const onChangeKeyword = (e) => {
    setKeyword(e.target.value);
    setIsTyping(true);
    debouncedSearch(e.target.value);
  };

  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      setIsTyping(false);
      setPage(1);
      // fetchData(getRequestParams(page, pageSize));
    }, 500),
    [page, pageSize]
  );

  useEffect(() => {
    const controller = fetchData(getRequestParams(page, pageSize, keyword, filters));
    // Cleanup function to cancel request on component unmount or params change
    return () => {
      if (controller) {
        controller.abort('Operation canceled by the user.');
      }
    };
  }, [page, pageSize, keyword, filters]);


  const [searchKeyword, setKeywordSearch] = useState(SEARCH_ARRAY[0]);

  const getRequestParams = (page, pageSize, keyword, filters) => {
    let params = {};

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    if (filters) {
      filters.forEach((f) => {
        params[`filter[${f.name}]`] = f.value.value;
      });
    }

    if (keyword) {
      params[`filter[search]`] = keyword;
    }

    return params;
  };


  const onChangeFilter = (i) => (e) => {
    const newState = filters.map((obj, index) => {
      if (index === i) {
        return { ...obj, value: e };
      }
      return obj;
    });

    setFilters(newState);
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = (params) => {
    const controller = new AbortController();
    setIsLoading(true);
  
    API.getInvestors(params, controller.signal)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          console.log('Request canceled:', e.message);
        } else {
          setIsLoading(false);
          setData([]);
          setMeta({});
        }
      })

    return controller;
  }


  // const fetchData = () => {
  //   const params = getRequestParams(keyword, page, pageSize, filters);
  //   console.log(params);

  //   setIsLoading(true);
  //   API.getInvestors(params)
  //     .then((response) => {
  //       const { data, meta } = response.data;
  //       setData(data);
  //       setMeta(meta);
  //       setIsLoading(false);
  //     })
  //     .catch((e) => {
  //       setData([]);
  //       setMeta({});
  //       setIsLoading(false);
  //     });
  // };

  // const fetchInvestors = () => {
  //   const params = getRequestParams(keyword, page, pageSize);
  //   API.getInvestors(params)
  //     .then((response) => {
  //       const { data, meta } = response.data;
  //       setData(data);
  //       setMeta(meta);
  //     })
  //     .catch((e) => {
  //       setData([]);
  //       setMeta({});
  //     });
  // };

  // useEffect(fetchData, [page, pageSize, filters]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Date Joined',
        accessorFn: (d) => {
          return moment(d.created_at).local().format('MMM DD, YYYY');
        }
      },
      {
        header: 'Name',
        cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <div>
              {original.first_name} {original.last_name}{' '}
              {!isNull(original.remarks) && (
                <TooltipItem placement="top" id={original.id} content={original.remarks} />
              )}
              {original.is_qualified === 1 && (
                <span className="badge bg-yellow text-black">Qualified</span>
              )}
            </div>
          );
        }
      },
      {
        header: 'Email',
        accessorKey: 'email'
      },
      {
        header: 'Mobile',
        accessorFn: (d) => {
          return d.otp_number ? d.otp_number : `N/A`;
        }
      },
      {
        header: 'Status',
        accessorFn: (d) => {
          return d.status.name;
        }
      },
      {
        header: 'Action Items',
        cell: ({ cell }) => {
          const { original } = cell.row;

          const onDelete = () => {
            API.deleteInvestor(original.id)
              .then((res) => {
                const dataCopy = [...data];
                dataCopy.splice(cell.row.index, 1);
                setData(dataCopy);
                Notification.success('Investor Deleted', 'Investor successfully deleted!');
              })
              .catch((err) => {
                Notification.error(err);
              });
          };

          const onView = () => {
            history.push(`/investors/${original.id}`, original);
          };

          return (
            <RowActionItems>
              <RowItemView onClick={onView} />
              <RowItemDelete onConfirm={onDelete} />
            </RowActionItems>
          );
        }
      }
    ],
    [data]
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Panel>
      <PanelHeader>Investors List</PanelHeader>
      <PanelBody>
        <Table
          columns={columns}
          data={data}
          meta={meta}
          isLoading={isLoading}
          onSetPageSize={handlePageSizeChange}
          updateRow={updateRow}
          onNextPage={handlePageChange('next')}
          onPrevPage={handlePageChange('prev')}
          skipPageReset={skipPageReset}
          onChangeKeyword={onChangeKeyword}
          keyword={keyword}
          onSearchKeyword={handleSearch}
          filterAPI={[
            {
              name: 'status',
              options: [
                { value: 2, label: 'Incomplete', is_active: true },
                { value: 3, label: 'Pending', is_active: true },
                { value: 4, label: 'Approved', is_active: true },
                { value: 5, label: 'Rejected', is_active: true },
                { value: 6, label: 'Deleted', is_active: true }
              ],
              onChange: onChangeFilter(0),
              value: filters[0]?.value
            }
          ]}
          // keyword={keyword}
          // onSearchKeyword={handleSearch}
          // searchByGroup={SEARCH_ARRAY}
          // searchValueBy={searchKeyword}
          // onSelectSearch={setKeywordSearch}
        />
      </PanelBody>
    </Panel>
  );
};

export default memo(InvestorList);

const TooltipItem = (props) => {
  const { placement, id, content } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <i
        className="fas fa-exclamation-triangle fa-fw"
        id={'Tooltip-' + id}
        style={{ color: 'orange' }}
      ></i>
      <Tooltip placement={placement} isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
        {content}
      </Tooltip>
    </span>
  );
};
