import React, { memo, useEffect, useState } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useLocation, useHistory, useParams } from 'react-router';
import { Row, Col, Label, Form, FormGroup, Input, Media, Button } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as API from '../../../services/api';
import { Notification } from '../../../utils/index.js';

const ReviewBank = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const { state } = props.location;

  const [bank, setBank] = useState(state);

  useEffect(() => {
    API.getUserBankDetail(id)
      .then((res) => {
        setBank(res.data.data);
      })
      .catch((err) => {
        setBank(state);
      });
  }, []);

  const onReject = (reason) => {
    const params = {
      status: String(3),
      remarks: reason,
      force: 1
    };

    API.updateInvestorBankStatus(state.id, params)
      .then(() => {
        history.goBack();
        Notification.success('Bank Request Reject', 'Bank request successfully rejected!');
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const onApprove = () => {
    const params = {
      status: String(2),
      remarks: '',
      force: 1
    };
    API.updateInvestorBankStatus(id, params)
      .then(() => {
        history.goBack();
        Notification.success('Bank Request Approve', 'Bank request successfully rejected!');
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  return (
    <div>
      <h1 className="page-header">Review Bank</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader>Bank Details</PanelHeader>
            <PanelBody>
              <FormGroup tag="fieldset">
                <FormGroup row>
                  <Label md="3">Bank</Label>
                  <Col md="9">
                    <Input value={bank.bank_name} disabled />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md="3">Account Name</Label>
                  <Col md="9">
                    <Input value={bank.account_name} disabled />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label md="3">Account Number</Label>
                  <Col md="9">
                    <Input value={bank.account_number} disabled />
                  </Col>
                </FormGroup>
                {bank && bank.withdrawal_type == 1 && (
                  <>
                    <FormGroup row>
                      <Label md="3">Branch</Label>
                      <Col md="9">
                        <Input value={bank.bank_address} disabled />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label md="3">Swift Code</Label>
                      <Col md="9">
                        <Input value={bank.swift_code} disabled />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label md="3">Swift Code</Label>
                      <Col md="9">
                        <Input value={bank.swift_code} disabled />
                      </Col>
                    </FormGroup>
                  </>
                )}
                <FormGroup row>
                  <Col md="3">Attachment</Col>
                  <Col md="9">
                    {bank?.attachment?.view != null ? (
                      <>
                        <Media>
                          <Media object src={bank.attachment?.view} alt="attachment" />
                        </Media>
                        <a
                          className="btn btn-primary"
                          href={bank.attachment.download}
                          target="_blank"
                        >
                          Download <i className="fa fa-download"></i>
                        </a>
                      </>
                    ) : (
                      'No Attachment Available'
                    )}
                  </Col>
                </FormGroup>
              </FormGroup>
              <Row>
                <Col className="d-flex justify-content-end">
                  <ButtonApprove onConfirm={onApprove} />
                  <ButtonReject onConfirm={onReject} />
                  {/* <ButtonReject />
                  <button className="btn btn-primary" type="button">
                    Approve
                  </button> */}
                </Col>
              </Row>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default memo(ReviewBank);

// const ButtonReject = (props) => {
//   const [modalReject, setModalReject] = useState(false);

//   const toggleModalReject = async () => {
//     await setModalReject(!modalReject);
//   };

//   const onConfirm = () => {
//     setModalReject(false);
//     // toggleModalReject().then(() => {
//     //   // setTimeout(() => {
//     //   //   props.onConfirm();
//     //   // }, 300);
//     // });
//   };

//   const [reason, setReason] = useState('');

//   const optionReasons = [
//     { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
//     { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
//     { value: 'Others', label: 'Others' }
//   ];

//   const onChangeText = (e) => {
//     setReason(e.target.value);
//   };

//   const onChangeSelect = (e) => {
//     setReason(e.value);
//   };

//   return (
//     <>
//       <button className="btn btn-danger mr-2" type="button" onClick={toggleModalReject}>
//         Reject
//       </button>
//       <SweetAlert
//         title={<span className="text-black text-wrap">Bank Details</span>}
//         danger
//         showCancel
//         confirmBtnText="Reject"
//         confirmBtnBsStyle="danger"
//         focusCancelBtn={false}
//         focusConfirmBtn={false}
//         onConfirm={onConfirm}
//         onCancel={toggleModalReject}
//         show={modalReject}
//         dependencies={[reason]}
//       >
//         <form>
//           <textarea
//             type="text"
//             className="form-control"
//             value={reason}
//             onChange={onChangeText}
//             placeholder="Please indicate the reason for rejecting bank details"
//           />
//         </form>
//       </SweetAlert>
//     </>
//   );
// };

const ButtonReject = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    setModalReject(false);
    toggleModalReject().then(() => {
      props.onConfirm(reason);
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-danger" type="button" onClick={toggleModalReject}>
        Reject
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Bank Details</span>}
        danger
        showCancel
        confirmBtnText="Reject"
        confirmBtnBsStyle="danger"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
        dependencies={[reason]}
      >
        <form>
          <textarea
            type="text"
            className="form-control"
            value={reason}
            onChange={onChangeText}
            placeholder="Please indicate the reason for rejecting bank details"
          />
        </form>
      </SweetAlert>
    </>
  );
};

const ButtonApprove = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    setModalReject(false);
    toggleModalReject().then(() => {
      props.onConfirm(reason);
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-green mr-2" type="button" onClick={toggleModalReject}>
        Approve
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Bank Details</span>}
        success
        showCancel
        confirmBtnText="Approve"
        confirmBtnBsStyle="success"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <p style={{ color: 'black' }}>Are you sure you want to approve this bank details?</p>
      </SweetAlert>
    </>
  );
};
