import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import PageContext from '../../../config/page-settings.js';
import * as API from '../../../services/api.js';
import {
  Table,
  RowActionItems,
  RowItemDelete,
  RowItemView
} from '../../../components/table/index.js';
import { Notification } from '../../../utils/index.js';

const CategoryList = (props) => {
  const history = useHistory();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  useEffect(() => {
    fetchIndustyList();
  }, [props]);

  const fetchIndustyList = () => {
    API.getIndustyList().then((res) => {
      setData([
        {
          id: 1,
          created_date: 'September 2021',
          name: 'Grab',
          countries: 'Philippines',
          repayments: '1',
          paid: '1'
        }
      ]);
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        sortable: true
      },
      {
        Header: 'Countries',
        accessor: 'countries',
        sortable: true
      },
      {
        Header: 'Categories',
        accessor: '',
        sortable: true
      },
      {
        Header: 'Action Items',
        cell: ({ cell }) => {
          const { original } = cell.row;
          const viewIndusry = () => {
            history.push(`/settings/industry/${original.id}`, original);
          };

          return (
            <RowActionItems>
              <RowItemView onClick={viewIndusry} />
            </RowActionItems>
          );
        }
      }
    ],
    []
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  const onCreate = () => {
    history.push('/merchants/categories/create');
  };

  return (
    <>
      <h1 className="page-header">
        Categories{' '}
        <button onClick={onCreate} className="btn btn-primary btn-icon btn-circle btn-md ml-2">
          <i className="fa fa-plus"></i>
        </button>
        <button className="btn btn-secondary btn-icon btn-circle btn-md ml-2">
          <i className="fa fa-download"></i>
        </button>
      </h1>
      <Panel>
        <PanelHeader>Category List</PanelHeader>
        <PanelBody>
          <Table
            columns={columns}
            data={data}
            updateRow={updateRow}
            skipPageReset={skipPageReset}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default memo(CategoryList);
