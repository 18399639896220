import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { store } from 'react-notifications-component';
import * as API from '../../../../services/api';
import { Form, FormGroup, FormFeedback, Col, Label, Input } from 'reactstrap';
import { isEmpty, has } from 'lodash';
import ReactSelect from 'react-select';

import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import FileUpload from '../../../../components/forms/FileUpload';
import moment from 'moment';
import { isValidUrl, OPTION_SORT_ORDER } from '../data/constant';

const schemaBannerCreate = yup.object().shape({
  banner: yup.mixed().required('Banner image is required'),
  // .test('type', 'Only the following formats are accepted:.png .jpeg, .jpg,', (value) => {
  //   return (
  //     value &&
  //     (value[0].type === 'image/jpeg' ||
  //       value[0].type === 'image/jpg' ||
  //       value[0].type === 'image/png')
  //   );
  // }),
  title: yup.string().required('Title is required'),
  caption: yup.string().required('Caption is required'),
  posted_at: yup.date().required('Posted Date is required'),
  url: yup.string().test('is-url-valid', 'URL Link is not valid', (value) => isValidUrl(value)),
  is_scheduled: yup.boolean(),
  with_expiry: yup.boolean()
});

const FormNews = (props) => {
  const { action, values } = props;
  const history = useHistory();
  const { id } = useParams();
  const {
    watch,
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    defaultValues: useMemo(() => {
      if (id) {
        return {
          ...values,
          banner: [{ ...values.banner_details, status: 1 }],
          sort_order: '',
          categories: values.categories.map((c) => ({
            value: c.ar_category_id,
            label: c.category_name
          }))
        };
      } else {
        return {
          is_banner: false,
          is_scheduled: false,
          with_expiry: false,
          sort_order: ''
        };
      }
    }, [id]),
    resolver: yupResolver(schemaBannerCreate)
  });

  const [optionNewsCategory, setOptionNewsCategory] = useState([]);
  useEffect(() => {
    API.dataSets(['news_categories']).then((res) => {
      setOptionNewsCategory(res.data.news_categories);
    });
  }, []);

  const withSchedule = watch('is_scheduled', false);
  const withExpiry = watch('with_expiry', false);
  const isBanner = watch('is_banner', false);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onSubmit = (data) => {
    if (action == 'create') {
      uploadFiles(data.banner).then((res) => {
        const params = new FormData();
        params.append('title', data.title);
        params.append('is_scheduled', data.is_scheduled ? 1 : 0);
        params.append('with_expiry', data.with_expiry ? 1 : 0);
        params.append('caption', data.caption);
        params.append('banner', res[0].id);

        params.append('posted_at', moment(data.posted_at).format('YYYY-MM-DD HH:mm'));
        params.append('is_banner', data.is_banner ? 1 : 0);
        params.append('sort_order', data.sort_order);
        params.append('url', data.url);
        params.append('publish_start', data.publish_start || '');
        params.append('publish_end', data.publish_end || '');

        if (data.categories.length > 0) {
          data.categories.forEach((ct, index) => {
            params.append(`categories[${index}]`, ct.value);
          });
        }

        API.createNews(params)
          .then((res) => {
            store.addNotification({
              title: 'News Submitted',
              message: `News successfully created!`,
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            history.goBack();
          })
          .catch((err) => {
            console.log(err.response);
          })
          .finally(() => {
            setLoadingSubmit(false);
          });
      });
    } else {
      uploadFiles(data.banner).then((res) => {
        const params = new URLSearchParams();
        params.append('title', data.title);
        params.append('is_scheduled', data.is_scheduled ? 1 : 0);
        params.append('with_expiry', data.with_expiry ? 1 : 0);
        params.append('caption', data.caption);
        params.append('banner', res[0].id);

        params.append('posted_at', moment(data.posted_at).format('YYYY-MM-DD HH:mm'));
        params.append('is_banner', data.is_banner ? 1 : 0);
        params.append('sort_order', data.sort_order || '');
        params.append('url', data.url);
        params.append('publish_start', data.publish_start || '');
        params.append('publish_end', data.publish_end || '');

        data.categories.forEach((ct, index) => {
          params.append(`categories[${ct.value}]`, ct.value);
        });

        API.updateNews(id, params)
          .then((res) => {
            store.addNotification({
              title: 'News Updated',
              message: `News successfully updated!`,
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            history.goBack();
          })
          .catch((err) => {
            console.log(err.response);
          })
          .finally(() => {
            setLoadingSubmit(false);
          });
      });
    }
  };

  const uploadFiles = async (files) => {
    const promises = await files.map(async (file, index) => {
      if (!has(file, 'status')) {
        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('dir', 'Articles');
        return await API.fileUpload(formData)
          .then((res) => {
            files[index] = { label: file.label, ...res.data.data };
            return { label: file.label, status: 1, ...res.data.data };
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        return file;
      }
    });

    const responses = await Promise.all(promises);
    return responses;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label md="3">Banner Image</Label>
        <Col md="9">
          <Controller
            name="banner"
            control={control}
            render={({ field: { onChange, value, ref } }) => {
              return (
                <FileUpload
                  inputRef={ref}
                  onDrop={onChange}
                  onChange={onChange}
                  imagefile={value}
                  invalid={!isEmpty(errors.banner)}
                />
              );
            }}
          />

          {errors.banner && (
            <FormFeedback style={{ display: 'block' }}>{errors.banner?.message}</FormFeedback>
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Title</Label>
        <Col md="9">
          <Controller
            control={control}
            name="title"
            defaultValue=""
            render={({ field }) => (
              <Input {...field} type="text" invalid={!isEmpty(errors.title)} />
            )}
          />
          {errors.title && <FormFeedback>{errors.title?.message}</FormFeedback>}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Caption</Label>
        <Col md="9">
          <Controller
            control={control}
            name="caption"
            defaultValue=""
            render={({ field }) => (
              <Input {...field} type="textarea" invalid={!isEmpty(errors.caption)} />
            )}
          />
          {errors.caption && <FormFeedback>{errors.caption?.message}</FormFeedback>}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Date Posted</Label>
        <Col md="9">
          <Controller
            control={control}
            name="posted_at"
            render={({ field: { onChange, value } }) => (
              <DateTime
                inputProps={{
                  placeholder: 'Select Date and Time',
                  className: errors.posted_at ? 'form-control is-invalid' : 'form-control'
                }}
                closeOnSelect={true}
                timeFormat="HH:mm"
                value={value}
                dateFormat="YYYY-MM-DD"
                onChange={(e) => {
                  onChange(moment(e).format('YYYY-MM-DD HH:mm'));
                }}
                timeConstraints={{
                  minutes: { step: 5 }
                }}
                // isValidDate={(currentDate) => currentDate > moment().subtract(1, 'days')}
              />
            )}
          />
          <FormFeedback style={{ display: errors.posted_at ? 'block' : 'none' }}>
            {errors.posted_at?.message}
          </FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">URL Link</Label>
        <Col md="9">
          <Controller
            control={control}
            name="url"
            defaultValue=""
            render={({ field }) => <Input {...field} type="text" invalid={!isEmpty(errors.url)} />}
          />
          {errors.url && <FormFeedback>{errors.url?.message}</FormFeedback>}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Category</Label>
        <Col md="9">
          <Controller
            name="categories"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value, ref } }) => (
              <ReactSelect
                inputRef={ref}
                isMulti
                value={value}
                onChange={onChange}
                options={optionNewsCategory}
                isOptionDisabled={(option) => option.disabled}
              />
            )}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Is Banner?</Label>
        <Col md="9">
          <Controller
            control={control}
            name="is_banner"
            render={({ field: { value, onChange } }) => (
              <div className="switcher">
                <input
                  checked={value}
                  onChange={onChange}
                  type="checkbox"
                  name="is_banner"
                  id="is_banner"
                />
                <label htmlFor="is_banner"></label>
              </div>
            )}
          />
        </Col>
      </FormGroup>
      {/* {isBanner && (
        <FormGroup row>
          <Label md="3">Sort Order</Label>
          <Col md="9">
            <Controller
              name="sort_order"
              control={control}
              defaultValue="0"
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  value={OPTION_SORT_ORDER.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={OPTION_SORT_ORDER}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
          </Col>
        </FormGroup>
      )} */}

      <FormGroup row>
        <Label md="3">Schedule Publish?</Label>
        <Col md="9">
          <Controller
            control={control}
            name="is_scheduled"
            render={({ field: { value, onChange } }) => (
              <div className="switcher">
                <input
                  checked={value}
                  onChange={onChange}
                  type="checkbox"
                  name="is_scheduled"
                  id="is_scheduled"
                />
                <label htmlFor="is_scheduled"></label>
              </div>
            )}
          />
        </Col>
      </FormGroup>
      {withSchedule && (
        <>
          <FormGroup row>
            <Label md="3">Publish Start</Label>
            <Col md="9">
              <Controller
                control={control}
                name="publish_start"
                render={({ field: { onChange, value } }) => (
                  <DateTime
                    inputProps={{ placeholder: 'Select Date and Time' }}
                    closeOnSelect={true}
                    timeFormat="HH:mm"
                    value={value}
                    dateFormat="YYYY-MM-DD"
                    onChange={(e) => {
                      onChange(moment(e).format('YYYY-MM-DD HH:mm'));
                    }}
                    timeConstraints={{
                      minutes: { step: 5 }
                    }}
                    isValidDate={(currentDate) => currentDate > moment().subtract(1, 'days')}
                  />
                )}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="3">With Expiry?</Label>
            <Col md="9">
              <Controller
                control={control}
                name="with_expiry"
                render={({ field: { value, onChange } }) => (
                  <div className="switcher">
                    <input
                      checked={value}
                      onChange={onChange}
                      type="checkbox"
                      name="with_expiry"
                      id="with_expiry"
                    />
                    <label htmlFor="with_expiry"></label>
                  </div>
                )}
              />
            </Col>
          </FormGroup>
          {withExpiry && (
            <FormGroup row>
              <Label md="3">Publish Until</Label>
              <Col md="9">
                <Controller
                  control={control}
                  name="publish_end"
                  render={({ field: { onChange, value } }) => (
                    <DateTime
                      inputProps={{ placeholder: 'Select Date and Time' }}
                      closeOnSelect={true}
                      timeFormat="HH:mm"
                      value={value}
                      dateFormat="YYYY-MM-DD"
                      onChange={(e) => {
                        onChange(moment(e).format('YYYY-MM-DD HH:mm'));
                      }}
                      timeConstraints={{
                        minutes: { step: 5 }
                      }}
                      isValidDate={(currentDate) => currentDate > moment().subtract(1, 'days')}
                    />
                  )}
                />
              </Col>
            </FormGroup>
          )}
        </>
      )}

      <FormGroup row>
        <Label md="3" />
        <Col md="9">
          <button
            type="submit"
            className={`btn ${loadingSubmit ? 'btn-secondary' : 'btn-primary'}`}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
          </button>
        </Col>
      </FormGroup>
    </Form>
  );
};

export default withRouter(FormNews);
