import React, { memo } from 'react';

const RowItemView = (props) => {
  return (
    <button className="btn btn-default btn-icon btn-primary m-r-2" onClick={props.onClick}>
      <i className="fa fa-eye"></i>
    </button>
  );
};

export default memo(RowItemView);
