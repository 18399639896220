import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { InputGroup, Input, InputGroupButtonDropdown, Button, Row, Col } from 'reactstrap';
import * as API from '../../services/api';
import moment from 'moment';
import { PriceFormat } from '../../utils';
import { LoadingSpinner } from '../../components/common';
import axios from 'axios';


const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const statusOptions = [
  { id: 0, label: 'Pending' },
  { id: 1, label: 'Approved' },
  { id: 2, label: 'Rejected' },
  { id: 3, label: 'Cancelled' },
  { id: 4, label: 'Repayment' },
  { id: 5, label: 'Completed' },
  { id: 6, label: 'Early Maturity Default' },
  { id: 7, label: 'Early Maturity Full Payment' },
  { id: 8, label: 'Cancelled Funding' },
  { id: 9, label: 'Abort Due to Restructured' },
];

const ProjectList = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [error, setError] = useState(null);
  const [meta, setMeta] = useState({});
  const [isDisbursed, setIsDisbursed] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [isDisbursedToggled, setIsDisbursedToggled] = useState(false);
  const [isDefaultToggled, setIsDefaultToggled] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState([0, 1, 2, 3, 5]);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const toggleDropDown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleSplit = () => {
    setSplitButtonOpen(!splitButtonOpen);
  };


  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    if (id === 'is_default') {
      setIsDefault(checked);
      setIsDefaultToggled(true);
    } else if (id === 'is_disbursed') {
      setIsDisbursed(checked);
      setIsDisbursedToggled(true);
    }
  };

  const handleStatusChange = (status) => {
    setSelectedStatuses((prevStatuses) =>
      prevStatuses.includes(status)
        ? prevStatuses.filter((s) => s !== status)
        : [...prevStatuses, status]
    );
  };

  const clearStatuses = () => {
    setSelectedStatuses([0, 1, 2, 3, 5]);
  };

  const getRequestParams = (page, pageSize) => {
    const params = {};
    if (keyword) params['filter[search]'] = keyword;
    if (page) params['page'] = page;
    if (pageSize) params['size'] = pageSize;
    if (isDefaultToggled) params['filter[is_default]'] = isDefault ? 1 : 0;
    if (isDisbursedToggled) params['filter[is_disbursed]'] = isDisbursed ? 0 : 1;
    if (selectedStatuses.length > 0) params['filter[status]'] = selectedStatuses.join(',');
    return params;
  };

  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      setIsTyping(false);
      setPage(1);
      // fetchData(getRequestParams(page, pageSize));
    }, 500),
    [page, pageSize]
  );

  useEffect(() => {
    const controller = fetchData(getRequestParams(page, pageSize));
    // Cleanup function to cancel request on component unmount or params change
    return () => {
      if (controller) {
        controller.abort('Operation canceled by the user.');
      }
    };
  }, [page, pageSize, isDisbursed, isDefault, selectedStatuses, keyword]);
  
  const fetchData = (params) => {
    const controller = new AbortController();
    setIsLoading(true);
    setError(null);
    console.log('isLoading', isLoading)
  
    API.getProjectsList(params, controller.signal)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          console.log('Request canceled:', e.message);
        } else {
          setIsLoading(false);
          setError(e);
          setData([]);
          setMeta({});
        }
      })

    return controller;
  }

  const handleSearch = () => {
    setPage(1);
    // fetchData(); // Removed as useEffect handles fetching data
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handlePageChange = (action) => (event, value) => {
    setPage(action === 'next' ? page + 1 : page - 1);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const onChangeKeyword = (e) => {
    setKeyword(e.target.value);
    setIsTyping(true);
    debouncedSearch(e.target.value);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="result-container">
            <InputGroup size="lg" className="m-b-20">
              <Input
                placeholder="Seach for Company Name or Funding ID"
                type="text"
                className="input-white"
                onChange={onChangeKeyword}
                onKeyDown={handleKeyDown}
              />
              <InputGroupButtonDropdown
                addonType="append"
                isOpen={splitButtonOpen}
                toggle={toggleSplit}
              >
                <Button color="primary" onClick={handleSearch}>
                  <i className="fa fa-search fa-fw"></i> Search
                </Button>
              </InputGroupButtonDropdown>
            </InputGroup>
            <div className="d-block d-md-flex align-items-center mb-3">
              <UncontrolledButtonDropdown className="mr-2">
                <DropdownToggle color="white" className="btn-white-without-border" caret>
                  Status
                </DropdownToggle>
                <DropdownMenu>
                  {statusOptions.map((status) => (
                    <DropdownItem key={status.id}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`status_${status.id}`}
                          checked={selectedStatuses.includes(status.id)}
                          onChange={() => handleStatusChange(status.id)}
                        />
                        <label className="form-check-label" htmlFor={`status_${status.id}`}>
                          {status.label}
                        </label>
                      </div>
                    </DropdownItem>
                  ))}
                  <DropdownItem divider />
                  <DropdownItem onClick={clearStatuses}>Reset to Default</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>

              <UncontrolledButtonDropdown className="mr-2">
                <DropdownToggle color="white" className="btn-white-without-border" caret>
                  Filters
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="is_default"
                        checked={isDefault}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="is_default">
                        Default
                      </label>
                    </div>
                  </DropdownItem>
                  <DropdownItem>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="is_disbursed"
                        checked={isDisbursed}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="is_disbursed">
                        Undisbursed
                      </label>
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            {(isLoading || isTyping) ? (
              <LoadingSpinner />
            ) : (
              <ul className="result-list">
                {data.map((project) => (
                  <ProjectListItem key={project.id} project={project} />
                ))}
              </ul>
            )}
             <Pagination meta={meta} setPage={setPage} />

            {/* <div className="clearfix m-t-20">
              <ul className="pagination pull-right">
                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                  <Link to="#" onClick={handlePageChange('prev')} className="page-link">
                    «
                  </Link>
                </li>
                {Array.from({ length: meta.last_page }, (_, i) => i + 1).map((v) => (
                  <li
                    key={v}
                    className={`page-item ${meta.current_page === v ? 'active' : ''}`}
                    onClick={() => setPage(v)}
                  >
                    <div className="page-link">{v}</div>
                  </li>
                ))}
                <li className={`page-item ${page === meta.last_page ? 'disabled' : ''}`}>
                  <Link to="#" onClick={handlePageChange('next')} className="page-link">
                    »
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectListItem = ({ project }) => {
  const start = moment().format('MMM DD, YYYY');
  const end = moment(project.repayment_start_date);
  const daysLeft = moment.duration(end.diff(start)).asDays();

  const subscriptionPercentage = (Number(project.total_funds) / Number(project.amount)) * 100;

  return (
    <li>
      <Link
        to={`projects/${project.id}`}
        className="result-image"
        style={{ backgroundImage: `url(${project.banner.view})` }}
      />
      <div className="result-info">
        <Row>
          <Col>
            <div className="d-flex flex-row align-items-center">
              <h4 className="title">
                <Link to={`projects/${project.id}`}>{project.funding_id}</Link>
              </h4>
              <span className="badge bg-primary ml-2 mt-0">
                {project?.status?.name}
              </span>

{project?.is_default == 1 && <span className="badge bg-danger ml-2 mt-0">
                Default
              </span>}

              {project?.is_disbursed == 0 && <span className="badge bg-info ml-2 mt-0">
                Undisbursed
              </span>}
              
               
            </div>
            <div>
              {project.user_company?.company_name ?? project.title}
            </div>
            <p className="location">
              {moment(project.created_at).local().format('MMMM DD, YYYY hh:mm A')}
            </p>
            <p className="text-description">{project.description}</p>
          </Col>
          <Col>
            <p className="location">
              Next Repayment : {moment(project.repayment_start_date).local().format('MMM DD, YYYY')} ({daysLeft} days left)
            </p>
            <p className="location">Published : {project.is_published === 1 ? 'Yes' : 'No'}</p>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="btn-row">
              <Link to={`projects/${project.id}`} data-toggle="tooltip" data-container="body" data-title="Analytics">
                <i className="fa fa-fw fa-chart-bar"></i>
              </Link>
              <Link to={`projects/${project.id}`} data-toggle="tooltip" data-container="body" data-title="Tasks">
                <i className="fa fa-fw fa-tasks"></i>
              </Link>
              <Link to={`projects/${project.id}`} data-toggle="tooltip" data-container="body" data-title="Configuration">
                <i className="fa fa-fw fa-cog"></i>
              </Link>
              <Link to={`projects/${project.id}`} data-toggle="tooltip" data-container="body" data-title="Performance">
                <i className="fa fa-fw fa-tachometer-alt"></i>
              </Link>
              <Link to={`projects/${project.id}`} data-toggle="tooltip" data-container="body" data-title="Users">
                <i className="fa fa-fw fa-user"></i>
              </Link>
            </div>
          </Col>
          <Col>
            <div className="progress mb-2">
              <div
                className="progress-bar fs-10px fw-bold"
                style={{ width: `${subscriptionPercentage}%` }}
              >
                {`${subscriptionPercentage}%`}
              </div>
            </div>
            {PriceFormat(project.total_funds)} / {PriceFormat(project.amount)}
          </Col>
        </Row>
      </div>
      <div className="result-price">
        {PriceFormat(project.amount)} <small>IN {project.loan_tenor} MONTH</small>
        <Link to={`projects/${project.id}`} className="btn btn-yellow btn-block">
          View Details
        </Link>
      </div>
    </li>
  );
};


const Pagination = ({ meta, setPage }) => {
  const { current_page, last_page } = meta;
  const totalPages = last_page || 1; // Ensure totalPages is at least 1
  const currentPage = current_page || 1; // Ensure currentPage defaults to 1

  // Number of pages to display adjacent to the current page
  const adjacentPages = 1;
  const pageNumbers = [];

  // Add the first page
  pageNumbers.push(1);

  // Add pages around the current page
  for (let i = Math.max(2, currentPage - adjacentPages); i <= Math.min(totalPages - 1, currentPage + adjacentPages); i++) {
    pageNumbers.push(i);
  }

  // Add the last page if it is not included
  if (totalPages > 1 && !pageNumbers.includes(totalPages)) {
    pageNumbers.push(totalPages);
  }

  // Add ellipses if needed
  const displayEllipsesBefore = currentPage - adjacentPages > 2;
  const displayEllipsesAfter = currentPage + adjacentPages < totalPages - 2;

  return (
    <div className="clearfix m-t-20">
      <ul className="pagination pull-right">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button onClick={() => setPage(currentPage - 1)} className="page-link">
            «
          </button>
        </li>
        {pageNumbers.map((page, index) => (
          <React.Fragment key={page}>
            {/* Add ellipsis before the current page range */}
            {index === 1 && displayEllipsesBefore && (
              <li className="page-item">
                <span className="page-link">...</span>
              </li>
            )}

            <li className={`page-item ${currentPage === page ? 'active' : ''}`}>
              <button onClick={() => setPage(page)} className="page-link">
                {page}
              </button>
            </li>

            {/* Add ellipsis after the current page range */}
            {index === pageNumbers.length - 2 && displayEllipsesAfter && (
              <li className="page-item">
                <span className="page-link">...</span>
              </li>
            )}
          </React.Fragment>
        ))}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button onClick={() => setPage(currentPage + 1)} className="page-link">
            »
          </button>
        </li>
      </ul>
    </div>
  );
};


export default ProjectList;
