import React, { memo, useEffect, useState } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import Chart from 'react-apexcharts';
import * as API from '../../../services/api';
import { PriceFormat } from '../../../utils';
const TotalFunds = () => {
  const convertNumberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const [wallet, setWallet] = useState({});
  useEffect(() => {
    API.totalFunds().then((res) => {
      setWallet(res.data.data);
    });
  }, []);

  const totalSalesChart = {
    options: {
      chart: {
        type: 'line',
        width: 200,
        height: 36,
        sparkline: {
          enabled: true
        },
        stacked: true
      },
      stroke: {
        curve: 'smooth',
        width: 3
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [
            {
              offset: 0,
              color: '#348fe2',
              opacity: 1
            },
            {
              offset: 100,
              color: '#8753de',
              opacity: 1
            }
          ]
        }
      },
      tooltip: {
        theme: 'dark',
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return '';
            }
          },
          formatter: (value) => {
            return '$' + convertNumberWithCommas(value);
          }
        },
        marker: {
          show: false
        }
      },
      responsive: [
        {
          breakpoint: 1500,
          options: {
            chart: {
              width: 130
            }
          }
        },
        {
          breakpoint: 1300,
          options: {
            chart: {
              width: 100
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 200
            }
          }
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              width: 180
            }
          }
        },
        {
          breakpoint: 400,
          options: {
            chart: {
              width: 120
            }
          }
        }
      ]
    },
    series: [
      {
        data: [9452.37, 11018.87, 7296.37, 6274.29, 7924.05, 6581.34, 12918.14]
      }
    ]
  };

  return (
    <div className="card border-0 text-white bg-blue-dark mb-3 overflow-hidden">
      <div className="card-body">
        <div className="row">
          <div className="col-xl-7 col-lg-8">
            <div className="mb-3 text-grey">
              <b>TOTAL AVAILABLE FUNDS</b>
              {/* <span className="ml-2">
                <i className="fa fa-info-circle" id="popover1"></i>
                <UncontrolledPopover trigger="hover" placement="top" target="popover1">
                  <PopoverHeader>Popover Title</PopoverHeader>
                  <PopoverBody>
                    Net sales (gross sales minus discounts and returns) plus taxes and shipping.
                    Includes orders from all sales channels.
                  </PopoverBody>
                </UncontrolledPopover>
              </span> */}
            </div>
            <div className="d-flex mb-1">
              <h2 className="mb-0">{PriceFormat(wallet.total_available_funds)}</h2>
              {/* <div className="ml-auto mt-n1 mb-n1">
                <Chart
                  type="line"
                  height={'36px'}
                  options={totalSalesChart.options}
                  series={totalSalesChart.series}
                />
              </div> */}
            </div>
            {/* <div className="mb-3 text-grey">
              <i className="fa fa-caret-up"></i> 33.21% compare to last week
            </div> */}
            <hr className="bg-white-transparent-2 my-3" />
            <div className="row text-truncate">
              <div className="col-6">
                <div className="f-s-12 text-grey">Total Withdrawal</div>
                <div className="f-s-18 m-b-5 f-w-600 p-b-1">
                  {PriceFormat(wallet.total_withdrawal_funds)}
                </div>
                {/* <div className="progress progress-xs rounded-lg m-b-5">
                  <div
                    className="progress-bar progress-bar-striped rounded-right bg-teal"
                    style={{ width: '55%' }}
                  ></div>
                </div> */}
              </div>
              <div className="col-6">
                <div className="f-s-12 text-grey">Total Top Up</div>
                <div className="f-s-18 m-b-5 f-w-600 p-b-1">
                  {PriceFormat(wallet.total_topup_funds)}
                </div>
                {/* <div className="progress progress-xs rounded-lg m-b-5">
                  <div
                    className="progress-bar progress-bar-striped rounded-right"
                    style={{ width: '55%' }}
                  ></div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-4 align-items-center d-flex justify-content-center">
            <div className="row text-truncate">
              <div className="col-12">
                <div className="f-s-12 text-grey">On Hold Funds</div>
                <div className="f-s-18 m-b-5 f-w-600 p-b-1">
                  {PriceFormat(wallet.total_onhold_funds)}
                </div>
                {/* <div className="progress progress-xs rounded-lg m-b-5">
                  <div
                    className="progress-bar progress-bar-striped rounded-right bg-teal"
                    style={{ width: '55%' }}
                  ></div>
                </div> */}
              </div>
              <div className="col-12">
                <div className="f-s-12 text-grey">Invested Funds</div>
                <div className="f-s-18 m-b-5 f-w-600 p-b-1">
                  {PriceFormat(wallet.total_invested_funds)}
                </div>
                {/* <div className="progress progress-xs rounded-lg m-b-5">
                  <div
                    className="progress-bar progress-bar-striped rounded-right"
                    style={{ width: '55%' }}
                  ></div>
                </div> */}
              </div>
              <div className="col-12">
                <div className="f-s-12 text-grey">Remaining Payouts</div>
                <div className="f-s-18 m-b-5 f-w-600 p-b-1">{wallet.total_remaining_payouts}</div>
                {/* <div className="progress progress-xs rounded-lg m-b-5">
                  <div
                    className="progress-bar progress-bar-striped rounded-right"
                    style={{ width: '55%' }}
                  ></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TotalFunds);
