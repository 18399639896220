import React, { memo, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import { store } from 'react-notifications-component';
import * as API from '../../../services/api';
import { Form, FormGroup, FormText, FormFeedback, Row, Col, Label, Input } from 'reactstrap';
import { isEmpty, has } from 'lodash';
import ReactSelect from 'react-select';
import FileUpload from '../../../components/forms/FileUpload';
import { Notification } from '../../../utils';

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      checked={props.checked}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const schemaIssuerInformation = yup.object().shape({
  bankruptcy_year: yup.string()
});

const FormSummary = (props) => {
  const { issuer } = props;
  if (props.currentStep !== 5) {
    return null;
  }

  const {
    watch,
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    defaultValues: { bankruptcy_history_label: false, bankruptcy_discharge: false },
    resolver: yupResolver(schemaIssuerInformation)
  });

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onSubmitForm = async (data) => {
    setLoadingSubmit(true);

    const params = new URLSearchParams();

    for (let [key, value] of Object.entries({
      bankruptcy_history_label: data.bankruptcy_history_label ? 1 : 0,
      bankruptcy_discharge: data.bankruptcy_discharge ? 1 : 0,
      bankruptcy_year: data.bankruptcy_year
      // ap_first_name: issuer.first_name,
      // ap_last_name: issuer.last_name,
      // business_size:
      //   issuer &&
      //   issuer.company_details &&
      //   issuer.company_details.business_size &&
      //   issuer.company_details.business_size.id,
      // company_name: issuer && issuer.company_details && issuer.company_details.company_name,
      // email: issuer.email,
      // mobile_code: issuer.mobile_code,
      // mobile_number: issuer.mobile_number
    })) {
      params.append(key, value);
    }

    const isFile = (input) => 'File' in window && input instanceof File;

    if (data.document_dti_certificate && isFile(data.document_dti_certificate[0])) {
      let res = await uploadFiles([data.document_dti_certificate[0]]);
      params.append('document_dti_certificate', res[0].id);
    } else {
      params.append('document_dti_certificate', '');
    }

    if (data.document_sec_certificate && isFile(data.document_sec_certificate[0])) {
      let res = await uploadFiles([data.document_sec_certificate[0]]);
      params.append('document_sec_certificate', res[0].id);
    } else {
      params.append('document_sec_certificate', '');
    }

    if (data.document_articles_incorporation && isFile(data.document_articles_incorporation[0])) {
      let res = await uploadFiles([data.document_articles_incorporation[0]]);
      params.append('document_articles_incorporation', res[0].id);
    } else {
      params.append('document_articles_incorporation', '');
    }

    if (data.document_latest_gis && isFile(data.document_latest_gis[0])) {
      let res = await uploadFiles([data.document_latest_gis[0]]);
      params.append('document_latest_gis', res[0].id);
    } else {
      params.append('document_latest_gis', '');
    }

    if (data.document_afs && isFile(data.document_afs[0])) {
      let res = await uploadFiles([data.document_afs[0]]);
      params.append('document_afs', res[0].id);
    } else {
      params.append('document_afs', '');
    }

    if (data.document_latest_gis && isFile(data.document_latest_gis[0])) {
      let res = await uploadFiles([data.document_latest_gis[0]]);
      params.append('document_latest_gis', res[0].id);
    } else {
      params.append('document_latest_gis', '');
    }

    if (data.document_valid_id && isFile(data.document_valid_id[0])) {
      let res = await uploadFiles([data.document_valid_id[0]]);
      params.append('document_valid_id', res[0].id);
    } else {
      params.append('document_valid_id', '');
    }

    if (data.document_business_plan && isFile(data.document_business_plan[0])) {
      let res = await uploadFiles([data.document_business_plan[0]]);
      params.append('document_business_plan', res[0].id);
    } else {
      params.append('document_business_plan', '');
    }

    if (
      data.document_business_contonuity_plan &&
      isFile(data.document_business_contonuity_plan[0])
    ) {
      let res = await uploadFiles([data.document_business_contonuity_plan[0]]);
      params.append('document_business_contonuity_plan', res[0].id);
    } else {
      params.append('document_business_contonuity_plan', '');
    }

    API.updateIssuer(issuer.id, params)
      .then((response) => {
        const params = {
          status: String(3),
          remarks: '',
          user_id: issuer.id
        };
        API.updateInvestorStatus(params)
          .then(() => {
            props.onSubmit();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        setLoadingSubmit(false);
        error && Notification.error(error);
      });
  };

  const uploadFiles = async (files) => {
    const promises = await files.map(async (file, index) => {
      if (!has(file, 'status') && !isEmpty(file)) {
        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('dir', 'Attachments');
        return await API.fileUpload(formData)
          .then((res) => {
            files[index] = { label: file.label, ...res.data.data };
            return { label: file.label, status: 1, ...res.data.data };
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        return file;
      }
    });

    const responses = await Promise.all(promises);
    return responses;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitForm)}>
      <FormGroup tag="fieldset">
        <h3>Document Requirements</h3>

        {issuer &&
        issuer.company_details &&
        issuer.company_details.entity_type &&
        issuer.company_details.entity_type.id != 1 ? (
          <>
            <FormGroup row>
              <Label md="3">SEC Certificate</Label>
              <Col md="9">
                <Controller
                  name="document_sec_certificate"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <FileUpload
                        inputRef={ref}
                        onDrop={onChange}
                        onChange={onChange}
                        imagefile={value}
                        invalid={!isEmpty(errors.document_sec_certificate)}
                      />
                    );
                  }}
                />
                {errors.document_sec_certificate && (
                  <FormFeedback style={{ display: 'block' }}>
                    {errors.document_sec_certificate?.message}
                  </FormFeedback>
                )}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Articles of Incorporation and By-laws with/without Amendments</Label>
              <Col md="9">
                <Controller
                  name="document_articles_incorporation"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <FileUpload
                        inputRef={ref}
                        onDrop={onChange}
                        onChange={onChange}
                        imagefile={value}
                        invalid={!isEmpty(errors.document_articles_incorporation)}
                      />
                    );
                  }}
                />
                {errors.document_articles_incorporation && (
                  <FormFeedback style={{ display: 'block' }}>
                    {errors.document_articles_incorporation?.message}
                  </FormFeedback>
                )}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Latest General Information Sheet (GIS)</Label>
              <Col md="9">
                <Controller
                  name="document_latest_gis"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <FileUpload
                        inputRef={ref}
                        onDrop={onChange}
                        onChange={onChange}
                        imagefile={value}
                        invalid={!isEmpty(errors.document_latest_gis)}
                      />
                    );
                  }}
                />
                {errors.document_latest_gis && (
                  <FormFeedback style={{ display: 'block' }}>
                    {errors.document_latest_gis?.message}
                  </FormFeedback>
                )}
              </Col>
            </FormGroup>
          </>
        ) : (
          <FormGroup row>
            <Label md="3">DTI Certificate</Label>
            <Col md="9">
              <Controller
                name="document_dti_certificate"
                control={control}
                render={({ field: { onChange, value, ref } }) => {
                  return (
                    <FileUpload
                      inputRef={ref}
                      onDrop={onChange}
                      onChange={onChange}
                      imagefile={value}
                      invalid={!isEmpty(errors.document_dti_certificate)}
                    />
                  );
                }}
              />
              {errors.document_dti_certificate && (
                <FormFeedback style={{ display: 'block' }}>
                  {errors.document_dti_certificate?.message}
                </FormFeedback>
              )}
            </Col>
          </FormGroup>
        )}
        <FormGroup row>
          <Label md="3">Audited Financial Statement (AFS) for the latest 1-3 years</Label>
          <Col md="9">
            <Controller
              name="document_afs"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <FileUpload
                    inputRef={ref}
                    onDrop={onChange}
                    onChange={onChange}
                    imagefile={value}
                    invalid={!isEmpty(errors.document_afs)}
                  />
                );
              }}
            />
            {errors.document_afs && (
              <FormFeedback style={{ display: 'block' }}>
                {errors.document_afs?.message}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Latest Interim Financial Statements and/or management report (balance sheet and income
            statement of the current year)
          </Label>
          <Col md="9">
            <Controller
              name="document_latest_gis"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <FileUpload
                    inputRef={ref}
                    onDrop={onChange}
                    onChange={onChange}
                    imagefile={value}
                    invalid={!isEmpty(errors.document_latest_gis)}
                  />
                );
              }}
            />
            {errors.document_latest_gis && (
              <FormFeedback style={{ display: 'block' }}>
                {errors.document_latest_gis?.message}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">Valid ID of company President w/ specimen signature/s</Label>
          <Col md="9">
            <Controller
              name="document_valid_id"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <FileUpload
                    inputRef={ref}
                    onDrop={onChange}
                    onChange={onChange}
                    imagefile={value}
                    invalid={!isEmpty(errors.document_valid_id)}
                  />
                );
              }}
            />
            {errors.document_valid_id && (
              <FormFeedback style={{ display: 'block' }}>
                {errors.document_valid_id?.message}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">Business Plan</Label>
          <Col md="9">
            <Controller
              name="document_business_plan"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <FileUpload
                    inputRef={ref}
                    onDrop={onChange}
                    onChange={onChange}
                    imagefile={value}
                    invalid={!isEmpty(errors.document_business_plan)}
                  />
                );
              }}
            />
            {errors.document_business_plan && (
              <FormFeedback style={{ display: 'block' }}>
                {errors.document_business_plan?.message}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">Business Continuity Plan (Optional)</Label>
          <Col md="9">
            <Controller
              name="document_business_contonuity_plan"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <FileUpload
                    inputRef={ref}
                    onDrop={onChange}
                    onChange={onChange}
                    imagefile={value}
                    invalid={!isEmpty(errors.document_business_contonuity_plan)}
                  />
                );
              }}
            />
            {errors.document_business_contonuity_plan && (
              <FormFeedback style={{ display: 'block' }}>
                {errors.document_business_contonuity_plan?.message}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
      </FormGroup>
      <hr />
      <FormGroup tag="fieldset">
        <h3>Litigations / Bankruptcy</h3>
        <FormGroup row>
          <Label md="3">Bankruptcy History Label</Label>
          <Col md="9">
            <Controller
              name="bankruptcy_history_label"
              defaultValue={false}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Switchery checked={value} onChange={onChange} for="bankruptcy_history_label" />
              )}
            />
            <FormFeedback style={{ display: errors.username ? 'block' : 'none' }}>
              {errors.username?.message}
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">Bankruptcy Discharge</Label>
          <Col md="9">
            <Controller
              name="bankruptcy_discharge"
              defaultValue={false}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Switchery checked={value} onChange={onChange} for="bankruptcy_discharge" />
              )}
            />
            <FormFeedback style={{ display: errors.username ? 'block' : 'none' }}>
              {errors.username?.message}
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">Bankruptcy year</Label>
          <Col md="9">
            <Controller
              name="bankruptcy_year"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.bankruptcy_year)} />
              )}
            />
            <FormFeedback style={{ display: errors.bankruptcy_year ? 'block' : 'none' }}>
              {errors.bankruptcy_year?.message}
            </FormFeedback>
          </Col>
        </FormGroup>
      </FormGroup>
      <hr />
      <Row>
        <Col>
          {props.currentStep > 5 && (
            <button className="btn btn-primary float-left" type="button" onClick={props.prevStep}>
              Previous
            </button>
          )}
          <button
            type="submit"
            className={`btn ${loadingSubmit ? 'btn-gray' : 'btn-primary'} float-right`}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default withRouter(FormSummary);
