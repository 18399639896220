import React, { useState, memo } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const RowItemDelete = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleModal = async () => {
    await setIsVisible(!isVisible);
  };

  const onConfirm = () => {
    setIsVisible(false);
    toggleModal().then(() => {
      setTimeout(() => {
        props.onConfirm();
      }, 300);
    });
  };

  return (
    <>
      <button className="btn btn-xs btn-green m-l-5 text-white" onClick={toggleModal}>
        <i className="fa fa-thumbs-up mr-1"></i>Publish
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Are you sure you want to publish it?</span>}
        success
        showCancel
        confirmBtnText="Yes, publish it!"
        confirmBtnBsStyle="green"
        focusCancelBtn
        onConfirm={onConfirm}
        onCancel={toggleModal}
        show={isVisible}
      />
    </>
  );
};

export default memo(RowItemDelete);
