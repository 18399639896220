import React from 'react';

const LoadingSpinner = (props) => (
  <div className="row justify-content-center align-items-center" style={{ height: '50vh' }}>
    <div className="col-12 text-center">
      <span className="spinner"></span>
    </div>
  </div>
);

export default LoadingSpinner;
