import React, { useState, useEffect, useContext, memo, useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Moment from 'moment';
import PageContext, { PageSettings } from './../../config/page-settings.js';
import ReactSelect from 'react-select';
import ENV from '../../config/env.config.js';
import { isNull } from 'lodash';
import { Notification, PriceFormat } from '../../utils/index.js';
import { Line } from 'react-chartjs-2';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import * as API from '../../services/api';
import LoadingSpinner from '../../components/common/LoadingSpinner.js';

const REPORTS = [
  {
    value: 'overall',
    label: 'Overall Sales Report',
    color: '#000'
  },
  {
    value: 'fresh_funds',
    label: 'Overall Fresh Funds',
    color: 'rgb(0, 128, 0)'
  },
  {
    value: 'interest',
    label: 'Overall Interest',
    color: 'rgb(99, 100, 102)'
  },
  {
    value: 'investments',
    label: 'Overall Investments',
    color: 'rgb(243, 111, 36)'
  },
  {
    value: 'withdrawal',
    label: 'Overall Withdrawn Amount',
    color: 'rgb(193, 32, 47)'
  }
  // {
  //   value: 'sunlife',
  //   label: 'Overall Insurance Fee Charge'
  // },
  // {
  //   value: 'sunlife',
  //   label: 'Overall Idling Funds'
  // },
  // {
  //   value: 'sunlife',
  //   label: 'Customer by Month'
  // },
  // {
  //   value: 'sunlife',
  //   label: 'Overall Investors'
  // }
];

const ReportChart = (props) => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [loadingChart, setLoadingChart] = useState(true);
  const [reports, setReports] = useState([]);

  const generateYearsArray = () => {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = currentYear; year >= 2017; year--) {
      years.push({ value: year.toString(), label: year.toString() });
    }

    return years;
  };

  const [yearOptions, setYearOpttions] = useState(generateYearsArray());

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [year, setYear] = useState({ value: currentYear, label: currentYear });

  useEffect(() => {
    getOverAllSalesReport(year);
  }, [year]);

  const handleChangeYear = (selectedYear) => {
    setYear(selectedYear);
    setSelectedReport({
      value: 'overall',
      label: 'Overall Sales Report'
    });
  };

  const [dataSets, setDataSets] = useState([]);
  const getOverAllSalesReport = async (year) => {
    const params = {
      year: year.value
    };

    setLoadingChart(true);

    await API.getOverallReports({ params }).then((res) => {
      const overallReports = res.data.data;
      const keys = Object.keys(overallReports);

      var dSets = [];

      for (const key of keys) {
        const value = overallReports[key];
        const foundReport = REPORTS.find((report) => report.value === key);
        dSets.push({
          label: foundReport.label,
          fill: false,
          lineTension: 0.1,
          backgroundColor: foundReport.color,
          borderColor: foundReport.color,
          borderWidth: 2,
          pointBorderColor: foundReport.color,
          pointBackgroundColor: '#fff',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: foundReport.color,
          pointHoverBorderWidth: 3,
          pointRadius: 5,
          pointHitRadius: 10,
          data: value.map((ff) => ff.total)
        });
      }

      setDataSets(dSets);
      setReports(res.data.data.fresh_funds.map((ff) => ff.total));
      setLoadingChart(false);
    });
  };

  const [selectedReport, setSelectedReport] = useState({
    value: 'overall',
    label: 'Overall Sales Report'
  });

  const [singleDataSet, setSingleDataSet] = useState([]);
  const handleChange = (selectedOption) => {
    setSelectedReport(selectedOption);

    var selectedSet = dataSets.find((dSet) => dSet.label === selectedOption.label);
    setSingleDataSet([selectedSet]);
  };

  const [downloading, setDownloading] = useState(false);
  const downloadCSV = () => {
    setDownloading(true);

    const params = new URLSearchParams();
    params.append('download_type', selectedReport?.value);
    params.append('date_start', Moment(startDate).format('YYYY-MM-DD'));
    params.append('date_end', Moment(endDate).format('YYYY-MM-DD'));

    fetch(`${ENV.API_URL}downloads?${params}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage['access_token']
      }
    })
      .then((response) => response.blob())
      .then((response) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${reports?.value}-report.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        Notification.error(err);
        setDownloading(false);
      });
  };

  const lineChartData = useMemo(() => {
    return {
      labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
      datasets: selectedReport.value == 'overall' ? dataSets : singleDataSet
    };
  }, [reports, dataSets, selectedReport]);

  const [lineChartOptions, setLineChartOptions] = useState({
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: 'black',
            callback: function (value, index, ticks) {
              return PriceFormat(value);
            }
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            fontColor: 'black'
          }
        }
      ]
    },
    legend: {
      labels: {
        fontColor: 'black'
      }
    },
    tooltips: {
      callbacks: {
        label: function (context) {
          let label = context.label || '';

          if (label) {
            label += ': ';
          }
          if (context.yLabel !== null) {
            label += PriceFormat(context.yLabel);
          }
          return label;
        }
      }
    }
  });
  const downloadPDF = () => {
    const chartContainer = document.getElementById('chart-container'); // Replace with the actual ID of your chart container
    const tableContainer = document.getElementById('table-container'); // Replace with the actual ID of your table container

    html2canvas(chartContainer).then((chartCanvas) => {
      const chartImage = chartCanvas.toDataURL('image/png');

      html2canvas(tableContainer).then((tableCanvas) => {
        const tableImage = tableCanvas.toDataURL('image/png');

        const pdf = new jsPDF();
        pdf.text(`Summary Report - ${year.value}`, 10, 10);
        pdf.addImage(chartImage, 'PNG', 10, 20, 180, 65);
        pdf.addImage(tableImage, 'PNG', 10, 90, 180, 110);

        pdf.save(`${selectedReport.value == 'overall' ? 'overall' : singleDataSet}.pdf`);
      });
    });
  };

  return (
    <div>
      <h1 className="page-header">Reports</h1>
      <Row className="d-flex justify-content-start">
        <Col className="col-3">
          <ReactSelect value={selectedReport} onChange={handleChange} options={REPORTS} />
        </Col>
        <Col className="col-3">
          <ReactSelect value={year} onChange={handleChangeYear} options={yearOptions} />
        </Col>
        <Col className="pull-right w-auto">
          <button
            onClick={downloadPDF}
            className="btn btn-secondary btn-md ml-2"
            disabled={isNull(reports)}
          >
            {downloading ? (
              <span>
                Downloading... <i className="fas fa-spinner fa-pulse"></i>
              </span>
            ) : (
              <span>
                Download <i className="fa fa-download"></i>
              </span>
            )}
          </button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Panel>
            <PanelHeader>Report Chart</PanelHeader>
            <PanelBody>
              {!loadingChart ? (
                <>
                  <div id="chart-container">
                    <Line data={reports && lineChartData} height={300} options={lineChartOptions} />
                  </div>
                </>
              ) : (
                <LoadingSpinner />
              )}
            </PanelBody>
          </Panel>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Panel>
            <PanelHeader>Report Table</PanelHeader>
            <PanelBody>
              {!loadingChart ? (
                <>
                  <div id="table-container">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Report</th>
                          {lineChartData.datasets.map((ds) => (
                            <th key={ds.label}>{ds.label}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {lineChartData.labels.map((lb, index) => (
                          <tr key={lb}>
                            <td>{lb}</td>
                            {lineChartData.datasets.map((dataset) => (
                              <td key={dataset.label}>{PriceFormat(dataset.data[index])}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <LoadingSpinner />
              )}
            </PanelBody>
          </Panel>
        </Col>
      </Row>
    </div>
  );
};

export default memo(ReportChart);
