import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import PageContext from '../../../config/page-settings.js';
import * as API from '../../../services/api';
import ENV from '../../../config/env.config.js';

import { Table } from '../../../components/table';

import { PriceFormat } from '../../../utils/index.js';
import { useParams, Link } from 'react-router-dom';
import { isNull } from 'lodash';
import moment from 'moment';
// TYPE_INVESTOR_INVEST              = 1004
// TYPE_INVESTOR_DIVIDENDS           = 1005
// TYPE_INVESTOR_RETURN_PRINCIPAL    = 1006
// TYPE_INVESTOR_EARNED_FROM_PENALTY = 1007
// TYPE_COMMISSION                   = 1009
// TYPE_INVESTMENT_CANCEL            = 1010
//
const InvestorTransactionList = () => {
  const { id } = useParams();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const onChangeKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const getRequestParams = (keyword, page, pageSize) => {
    let params = {};

    if (id != undefined) {
      params['filter[user_id]'] = id;
    }

    if (keyword) {
      params['filter[name]&filter[key]'] = keyword;
    }

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    params['filter[type]'] = '1004,1005,1006,1007,1009,1010,3001,3004,3008,5001,5002,5004,5005';

    return params;
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    const params = getRequestParams(keyword, page, pageSize);
    setIsLoading(true);
    API.getUserTransactions(params)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        setData([]);
        setMeta({});
        setIsLoading(false);
      });
  };

  useEffect(fetchData, [page, pageSize]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  function isNegative(num) {
    if (typeof num === 'number' && Math.sign(num) === -1) {
      return true;
    }
    return false;
  }

  const userColumns = useMemo(
    () => [
      {
        header: 'Date',
        accessorFn: (d) => {
          return moment(d.created_at).format('MM/DD/YYYY');
        },
        sortable: true
      },
      {
        header: 'Debit',
        accessorFn: (d) => {
          return !isNull(d.debit) ? PriceFormat(Number(d.debit)) : '';
        },
        sortable: true
      },
      {
        header: 'Credit',
        accessorFn: (d) => {
          return !isNull(d.credit) ? PriceFormat(Number(d.credit)) : '';
        },
        sortable: true
      },
      {
        header: 'Type',
        cell: ({ cell }) => {
          const { original } = cell.row;
          console.log(original);
          return original.type == 5002 ? (
            <Link
              to={`/request/withdrawals/${original.ref_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {original.description}
            </Link>
          ) : (
            <div>{original.description}</div>
          );
        }
      },
      {
        header: 'Balance',
        accessorFn: (d) => {
          return !isNull(d.balance) ? PriceFormat(Number(d.balance)) : '';
        },
        sortable: true
      }
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        header: 'User ID',
        accessorKey: 'user_id',
        sortable: true
      },
      // {
      //   header: 'Account Name',
      //   accessor: 'full_name',
      //   sortable: true
      // },
      {
        header: 'Date',
        accessorFn: (d) => {
          return moment(d.created_at).format('MM/DD/YYYY');
        },
        sortable: true
      },
      {
        header: 'Amount',
        accessorFn: (d) => {
          return PriceFormat(d.amount);
        },
        sortable: true
      },
      {
        header: 'Type',
        accessorKey: 'description',
        sortable: true
      },
      {
        header: 'Balance',
        accessorFn: (d) => {
          return !isNull(d.balance) && PriceFormat(Number(d.balance));
        },
        sortable: true
      }
    ],
    []
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  const [downloading, setDownloading] = useState(false);
  const onDownload = () => {
    setDownloading(true);
    fetch(`${ENV.API_URL}downloads?filter[user_id]=${id}&download_type=user_transaction`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage['access_token']
      }
    })
      .then((response) => response.blob())
      .then((response) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Transaction-${id}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  return (
    <Panel>
      <PanelHeader>Investor Transactions</PanelHeader>
      <PanelBody>
        <Table
          columns={id ? userColumns : columns}
          data={data}
          meta={meta}
          isLoading={isLoading}
          onSetPageSize={handlePageSizeChange}
          updateRow={updateRow}
          onNextPage={handlePageChange('next')}
          onPrevPage={handlePageChange('prev')}
          skipPageReset={skipPageReset}
          onChangeKeyword={onChangeKeyword}
          keyword={keyword}
          onSearchKeyword={handleSearch}
          allowDownload={true}
          downloading={downloading}
          onDownload={onDownload}
        />
      </PanelBody>
    </Panel>
  );
};

export default memo(InvestorTransactionList);
