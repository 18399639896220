import { startsWith } from 'lodash';

const PriceFormat = (price, config = {}) => {
  return new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
    currencyDisplay: 'symbol',
    ...config
  }).format(price || 0);
};

const isBase64 = (string) => {
  return string.startsWith('data:');
};

const isFile = (input) => 'File' in window && input instanceof File;

export { PriceFormat, isBase64, isFile };
