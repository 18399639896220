import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PageContext from '../../../config/page-settings.js';

import CountryList from './CountryList';

const CountryManagement = (props) => {
  const pageContext = useContext(PageContext);

  // useEffect(() => {
  //   pageContext.handleSetPageSidebar(true);
  //   pageContext.handleSetPageHeader(true);
  //   pageContext.handleSetPageContentFullWidth(false);
  // }, []);

  const createRole = () => {
    props.history.push('/settings/countries/create');
  };

  return (
    <div>
      <h1 className="page-header">
        Country List{' '}
        <button onClick={createRole} className="btn btn-primary btn-icon btn-circle btn-md ml-2">
          <i className="fa fa-plus"></i>
        </button>
      </h1>
      <CountryList />
    </div>
  );
};

export default withRouter(CountryManagement);
