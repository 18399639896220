import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from './../../../components/panel/panel.jsx';
import PageContext from './../../../config/page-settings.js';
import FormUser from './components/FormUser.js';
import { LoadingSpinner, PageHeader } from '../../../components/common';
import { Row, Col } from 'reactstrap';
import * as API from './../../../services/api';

const UserView = () => {
  const { id } = useParams();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    getUserInfo(id);
  }, [id]);

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState('');

  const getUserInfo = (id) => {
    setLoading(true);
    API.readAdmin(id).then((res) => {
      setUser(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  return (
    <>
      <PageHeader title="User View" />

      <Row>
        <Col>
          <Panel>
            <PanelHeader>Information</PanelHeader>
            <PanelBody>
              <Row>
                {!loading ? (
                  <Col lg="8">
                    <FormUser action="update" values={{ ...user }} />
                  </Col>
                ) : (
                  <Col>
                    <LoadingSpinner />
                  </Col>
                )}
              </Row>
            </PanelBody>
          </Panel>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(UserView);
