import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import DropdownNotification from './dropdown/notification.js';
import DropdownLanguage from './dropdown/language.js';
import DropdownProfile from './dropdown/profile.js';
import SearchForm from './search/form.js';
import DropdownMegaMenu from './dropdown/mega.js';

import PageContext from '../../config/page-settings.js';

const Header = () => {
  const pageContext = useContext(PageContext);

  const [collapseMegaMenu, setCollapseMegaMenu] = useState(false);

  const toggleMegaMenu = () => {
    setCollapseMegaMenu(!collapseMegaMenu);
  };

  const toggleMobileTopMenu = () => {};
  return (
    <div id="header" className="header navbar-default">
      <div className="navbar-header">
        {pageContext.pageTwoSidebar && (
          <button
            type="button"
            className="navbar-toggle pull-left"
            onClick={pageContext.toggleMobileRightSidebar}
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        )}
        <Link to="/" className="navbar-brand">
          <img src="/assets/img/logos/logo_seedin_full_blue.png" className="img-responsive" />
        </Link>

        {pageContext.pageHeaderMegaMenu && (
          <button type="button" className="navbar-toggle pt-0 pb-0 mr-0" onClick={toggleMegaMenu}>
            <span className="fa-stack fa-lg text-inverse">
              <i className="far fa-square fa-stack-2x"></i>
              <i className="fa fa-cog fa-stack-1x"></i>
            </span>
          </button>
        )}

        {pageContext.pageTopMenu && pageContext.pageSidebar && (
          <button
            type="button"
            className="navbar-toggle pt-0 pb-0 mr-0 collapsed"
            onClick={pageContext.toggleMobileTopMenu}
          >
            <span className="fa-stack fa-lg text-inverse">
              <i className="far fa-square fa-stack-2x"></i>
              <i className="fa fa-cog fa-stack-1x"></i>
            </span>
          </button>
        )}
        {!pageContext.pageSidebar && pageContext.pageTopMenu && (
          <button type="button" className="navbar-toggle" onClick={pageContext.toggleMobileTopMenu}>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        )}
        {pageContext.pageSidebar && (
          <button type="button" className="navbar-toggle" onClick={pageContext.toggleMobileSidebar}>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        )}
      </div>

      {pageContext.pageHeaderMegaMenu && <DropdownMegaMenu collapse={collapseMegaMenu} />}

      <ul className="navbar-nav navbar-right">
        {/* <SearchForm /> */}
        {/* <DropdownNotification /> */}

        {pageContext.pageHeaderLanguageBar && <DropdownLanguage />}

        <DropdownProfile />

        {pageContext.pageTwoSidebar && <li className="divider d-none d-md-block"></li>}

        {pageContext.pageTwoSidebar && (
          <li className="d-none d-md-block">
            <Link to="/" onClick={pageContext.toggleRightSidebar} className="f-s-14">
              <i className="fa fa-th"></i>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Header;
