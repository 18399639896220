import * as API from '../services/api';
import { setHeaderToken } from '../services';

export const api = (() => {
  return {
    async initialize() {
      try {
        const token = localStorage.getItem('access_token');
        await setHeaderToken(token);
        return token;
      } catch (error) {
        throw error;
      }
    },
    async getUserMe() {
      const response = await API.userMe();
      return response.data.data;
    },
    async getBadges() {
      const response = await API.badges();
      return response.data.badges.request;
    }
  };
})();

export const userLogin = async (payload) => {
  return await API.userLogin(payload);
};

export const userLogout = async () => {
  return await API.userLogout();
};
