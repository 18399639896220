import React, { useContext, useState, useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Dropzone from 'react-dropzone';
import * as API from '../../../services/api';
import { Row, Col, Form, FormGroup, Tooltip, Label } from 'reactstrap';
import ReactSelect from 'react-select';

const TooltipItem = (props) => {
  const { placement, id, content } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <i className="fa fa-exclamation-circle text-info" id={'Tooltip-' + id} />
      <Tooltip placement={placement} isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
        {content}
      </Tooltip>
    </span>
  );
};

const schemaDirectorsCreate = yup.object().shape({
  directors: yup.array().of(
    yup.object().shape({
      full_name: yup.string().required('Full name is required.'),
      position: yup.string().required('Current position is required.'),
      function: yup.string().required('Current function is required.'),
      mobile_number: yup.string().required('Contact Number is required.'),
      address: yup.string().required('Address is required.')
    })
  )
});

const FormStepTwo = (props) => {
  const { issuer, currentStep } = props;
  if (props.currentStep !== 2) {
    return null;
  }

  const defaultValues = {
    directors: [{}]
  };

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setValue
  } = useForm({
    defaultValues,
    resolver: yupResolver(schemaDirectorsCreate)
  });

  const [optionNationalities, setOptionNationalities] = useState([]);

  useEffect(() => {
    API.dataSets(['nationalities']).then((res) => {
      setOptionNationalities(res.data.nationalities);
    });
  }, []);

  // const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onSubmit = (data) => {
    const formData = new FormData();
    data.directors.forEach((director, index) => {
      for (let [key, value] of Object.entries({
        ...director,
        user_id: issuer.id
      })) {
        formData.append(
          `issuer_directors[${index}][${key}]`,
          key === 'is_beneficial_owner' ? (value ? 1 : 0) : value
        );
      }
    });
    API.createIssuerDirectors(formData)
      .then((response) => {
        props.onSubmit();
      })
      .catch((error) => {
        console.log('createIssuerDirectorsError', error.response);
      })
      .finally(() => {
        // setLoadingSubmit(false);
      });
  };

  const appendForm = () => {
    setValue('directors', [...(getValues().directors || []), {}]);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup tag="fieldset">
        <h3>Directors and Officers List</h3>
        <FieldArray
          {...{
            control,
            register,
            defaultValues,
            getValues,
            setValue,
            errors,
            optionNationalities
          }}
        />
        <Row>
          <Col>
            <button className="btn btn-primary float-right" type="button" onClick={appendForm}>
              <i className="fa fa-plus" style={{ marginRight: 10 }} />
              Add Another Directors/Officers
            </button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            {props.currentStep > 1 && (
              <button className="btn btn-primary float-left" type="button" onClick={props.prevStep}>
                Previous
              </button>
            )}
            {/* <button
              type="submit"
              className={`btn ${loadingSubmit ? 'btn-gray' : 'btn-primary'} float-right`}
              disabled={loadingSubmit}
            >
              {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Next'}
            </button> */}
            <button className="btn btn-primary float-right" type="submit">
              Next
            </button>
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
};

export default FormStepTwo;

const FieldArray = ({ control, register, errors, setValue, getValues, optionNationalities }) => {
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'directors'
  });

  return (
    <>
      {fields.map((item, index) => {
        return (
          <FormGroup tag="fieldset">
            {fields.length > 1 && index > 0 && <hr style={{ border: `1px dashed black` }} />}
            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label">Full Name</label>
              <div className={index > 0 ? 'col-md-8' : 'col-md-9'}>
                <div className="input-group">
                  <input
                    {...register(`directors.${index}.full_name`)}
                    type="text"
                    className={`form-control form-control-md ${
                      errors.directors &&
                      errors.directors[index] &&
                      errors.directors[index].full_name
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  {/* {index == 0 && (
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2">
                        Primary
                      </span>
                    </div>
                  )} */}
                  {errors.directors &&
                    errors.directors[index] &&
                    errors.directors[index].full_name && (
                      <div className="invalid-feedback">
                        {errors.directors[index].full_name?.message}
                      </div>
                    )}
                </div>
              </div>
              {index > 0 && (
                <div className="col">
                  <button
                    className="btn btn-danger btn-block float-right"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    <i className="fa fa-lg fa-times" />
                  </button>
                </div>
              )}
            </div>
            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label">Current Position</label>
              <div className="col-md-9">
                <div className="input-group">
                  <input
                    {...register(`directors.${index}.position`)}
                    type="text"
                    className={`form-control form-control-md ${
                      errors.directors &&
                      errors.directors[index] &&
                      errors.directors[index].position
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  {errors.directors &&
                    errors.directors[index] &&
                    errors.directors[index].position && (
                      <div className="invalid-feedback">
                        {errors.directors[index].position?.message}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label">Current Function</label>
              <div className="col-md-9">
                <div className="input-group">
                  <input
                    {...register(`directors.${index}.function`)}
                    type="text"
                    className={`form-control form-control-md ${
                      errors.directors &&
                      errors.directors[index] &&
                      errors.directors[index].function
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  {errors.directors &&
                    errors.directors[index] &&
                    errors.directors[index].function && (
                      <div className="invalid-feedback">
                        {errors.directors[index].function?.message}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label">Address</label>
              <div className="col-md-9">
                <div className="input-group">
                  <input
                    {...register(`directors.${index}.address`)}
                    type="text"
                    className={`form-control form-control-md ${
                      errors.directors && errors.directors[index] && errors.directors[index].address
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  {errors.directors &&
                    errors.directors[index] &&
                    errors.directors[index].address && (
                      <div className="invalid-feedback">
                        {errors.directors[index].address?.message}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <FormGroup row>
              <Label md="3">Nationality</Label>
              <Col md="9">
                <Controller
                  name={`directors.${index}.nationality`}
                  isClearable
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <ReactSelect
                      inputRef={ref}
                      value={optionNationalities.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.value)}
                      options={optionNationalities}
                      isOptionDisabled={(option) => option.disabled}
                    />
                  )}
                />
                {errors.nationality && <FormFeedback>{errors.nationality?.message}</FormFeedback>}
              </Col>
            </FormGroup>

            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label">Contact Number</label>
              <div className="col-md-9">
                <div className="input-group">
                  <input
                    {...register(`directors.${index}.mobile_number`)}
                    type="text"
                    className={`form-control form-control-md ${
                      errors.directors &&
                      errors.directors[index] &&
                      errors.directors[index].mobile_number
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  {errors.directors &&
                    errors.directors[index] &&
                    errors.directors[index].mobile_number && (
                      <div className="invalid-feedback">
                        {errors.directors[index].mobile_number?.message}
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label">Employment in the last three years</label>
              <div className="col-sm-2 col-md-1 d-flex align-items-center">Year 1</div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register(`directors.${index}.position_1`)}
                    type="text"
                    placeholder="Occupation/Position"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register(`directors.${index}.former_office_name_1`)}
                    type="text"
                    placeholder="Name of Former Office/Employer"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label"></label>
              <div className="col-sm-2 col-md-1 d-flex align-items-center">Year 2</div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register(`directors.${index}.position_2`)}
                    type="text"
                    placeholder="Occupation/Position"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    // {...register('last_name', { required: true })}
                    {...register(`directors.${index}.former_office_name_2`)}
                    type="text"
                    placeholder="Name of Former Office/Employer"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label"></label>
              <div className="col-sm-2 col-md-1 d-flex align-items-center">Year 3</div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register(`directors.${index}.position_3`)}
                    type="text"
                    placeholder="Occupation/Position"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register(`directors.${index}.former_office_name_3`)}
                    type="text"
                    placeholder="Name of Former Office/Employer"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="row form-group m-b-10 d-flex align-items-center">
              <label className="col-md-3 col-form-label">
                Is beneficial owner of more than 10%?
              </label>
              <div className="col-sm-2 col-md-1 d-flex align-items-center">
                <div className="input-group">
                  <div className="switcher">
                    <input
                      {...register(`directors.${index}.is_beneficial_owner`)}
                      type="checkbox"
                      name={`directors.${index}.is_beneficial_owner`}
                      id={`directors.${index}.is_beneficial_owner`}
                    />
                    <label htmlFor={`directors.${index}.is_beneficial_owner`}></label>
                  </div>
                </div>
              </div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register(`directors.${index}.voting_power`)}
                    type="text"
                    placeholder="Voting Power"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register(`directors.${index}.ownership`)}
                    type="text"
                    placeholder="Ownership"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
            </div>
          </FormGroup>
        );
      })}
    </>
  );
};
