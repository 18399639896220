import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import PageContext from '../../../config/page-settings.js';
import { Table, RowActionItems, RowItemView } from '../../../components/table';
import { useHistory, useParams } from 'react-router-dom';
import * as API from '../../../services/api';

const PaynamicsTransactionList = () => {
  const { id } = useParams();
  const history = useHistory();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const onChangeKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const getRequestParams = (keyword, page, pageSize) => {
    let params = {};

    if (keyword) {
      params['filter[search]'] = keyword;
    }

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    return params;
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    const params = getRequestParams(keyword, page, pageSize);
    setIsLoading(true);
    API.getPaynamicsTransactions(params)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data), setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        setData([]);
        setMeta({});
        setIsLoading(false);
      });
  };

  useEffect(fetchData, [page, pageSize]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Date',
        accessorKey: 'created_at',
        sortable: true
      },
      {
        header: 'Account Name',
        accessorKey: 'user_full_name',
        sortable: true
      },
      {
        header: 'Amount',
        accessorFn: (d) => {
          return `₱${d.amount}`;
        },
        sortable: true
      },
      {
        header: 'Type',
        accessorKey: 'type_name',
        sortable: true
      },
      {
        header: 'Request ID',
        accessorKey: 'request_id',
        sortable: true
      },
      {
        header: 'Response ID',
        accessorKey: 'response_id',
        sortable: true
      },
      {
        header: 'Status',
        accessorKey: 'status_name',
        sortable: true
      },
      {
        header: 'Action Items',
        cell: ({ cell }) => {
          const { original } = cell.row;
          const onView = () => {
            history.push(`/transactions/paynamics/${original.id}`, original);
          };

          return (
            <RowActionItems>
              <RowItemView onClick={onView} />
            </RowActionItems>
          );
        }
      }
    ],
    []
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Panel>
      <PanelHeader>Paynamics Transactions</PanelHeader>
      <PanelBody>
        <Table
          columns={columns}
          data={data}
          meta={meta}
          isLoading={isLoading}
          onSetPageSize={handlePageSizeChange}
          updateRow={updateRow}
          onNextPage={handlePageChange('next')}
          onPrevPage={handlePageChange('prev')}
          skipPageReset={skipPageReset}
          onChangeKeyword={onChangeKeyword}
          keyword={keyword}
          onSearchKeyword={handleSearch}
        />
      </PanelBody>
    </Panel>
  );
};

export default memo(PaynamicsTransactionList);
