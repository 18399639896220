import React, { memo } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import FormGlobalSettings from './components/FormGlobalSettings.js';

const GlobalSettingsCreate = (props) => {
  return (
    <div>
      <h1 className="page-header">Global Settings Create</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader>Information</PanelHeader>
            <PanelBody>
              <FormGlobalSettings action="create" />
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default memo(GlobalSettingsCreate);
