import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PageContext from '../../config/page-settings.js';
import SidebarProfile from './sidebar-profile.js';
import SidebarNav from './sidebar-nav.js';

const Sidebar = () => {
  const pageContext = useContext(PageContext);

  return (
    <React.Fragment>
      <div
        id="sidebar"
        className={'sidebar ' + (pageContext.pageSidebarTransparent ? 'sidebar-transparent' : '')}
        data-disable-slide-animation="true"
      >
        <PerfectScrollbar className="height-full" options={{ suppressScrollX: true }}>
          {/* {!pageContext.pageSidebarSearch && <SidebarProfile />} */}
          <SidebarNav />
          <a className="sidebar-minify-btn" onClick={pageContext.toggleSidebarMinify}>
            <i className="fa fa-angle-double-left"></i>
          </a>
        </PerfectScrollbar>
      </div>
      <div className="sidebar-bg"></div>
      <div className="sidebar-mobile-dismiss" onClick={pageContext.toggleMobileSidebar}></div>
    </React.Fragment>
  );
};

export default Sidebar;
