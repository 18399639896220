import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import PageContext from '../../../config/page-settings.js';
import * as API from '../../../services/api';
import { Table, RowActionItems, RowItemDelete, RowItemView } from '../../../components/table';
import { Notification } from '../../../utils/index.js';
import moment from 'moment';

const NewsList = (props) => {
  const pageContext = useContext(PageContext);
  const history = useHistory();

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const onChangeKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const getRequestParams = (keyword, page, pageSize) => {
    let params = {};

    params['include'] = 'categories';

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    if (keyword) {
      params['filter[title]'] = keyword;
    }

    return params;
  };

  const fetchData = () => {
    const params = getRequestParams(keyword, page, pageSize);
    API.getNews(params)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
      })
      .catch((e) => {
        setData([]);
        setMeta({});
      });
  };

  useEffect(fetchData, [page, pageSize]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Date Posted',
        accessorFn: (d) => {
          return moment(d.posted_at).local().format('MMM DD, YYYY');
        },
        sortable: true
      },
      {
        header: 'ID',
        accessorFn: (d) => {
          return d.id;
        },
        sortable: true
      },
      {
        header: 'Title',
        accessorKey: 'title',
        cell: ({ cell }) => {
          const { original } = cell.row;
          const [tooltipOpen, setTooltipOpen] = useState(false);
          const toggle = () => setTooltipOpen(!tooltipOpen);
          return (
            <div className="d-flex justify-content-between align-items-center">
              <span>{original.title}</span>
              {original.is_banner && (
                <span>
                  <i
                    className="fas fa-play-circle fa-lg text-secondary"
                    id={`banner_${original.id}`}
                  ></i>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target={`banner_${original.id}`}
                    toggle={toggle}
                  >
                    Banner
                  </Tooltip>
                </span>
              )}
            </div>
          );
        },
        sortable: true
      },
      {
        header: 'Category',
        accessorKey: 'categories',
        cell: ({ cell }) => {
          const { original } = cell.row;
          return original.categories.map((ct) => (
            <span className="badge bg-default mr-2">{ct.category_name}</span>
          ));
        },
        sortable: true
      },
      {
        header: 'Action Items',
        cell: ({ cell }) => {
          const { original } = cell.row;

          const deleteRow = () => {
            API.deleteNews(cell.value).then((res) => {
              const dataCopy = [...data];
              dataCopy.splice(cell.row.index, 1);
              setData(dataCopy);
              Notification.success('News Deleted', 'News successfully deleted!');
            });
          };

          const viewRow = () => {
            history.push(`/marketing/news/${cell.value}`, original);
          };

          return (
            <RowActionItems>
              <RowItemView onClick={viewRow} />
              <RowItemDelete onConfirm={deleteRow} />
            </RowActionItems>
          );
        }
      }
    ],
    []
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Panel>
      <PanelHeader>News List</PanelHeader>
      <PanelBody>
        <Table
          columns={columns}
          data={data}
          meta={meta}
          onSetPageSize={handlePageSizeChange}
          updateRow={updateRow}
          onNextPage={handlePageChange('next')}
          onPrevPage={handlePageChange('prev')}
          skipPageReset={skipPageReset}
          onChangeKeyword={onChangeKeyword}
          keyword={keyword}
          onSearchKeyword={handleSearch}
        />
      </PanelBody>
    </Panel>
  );
};

export default memo(NewsList);
