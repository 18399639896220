import React, { memo, useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  ColumnDef
} from '@tanstack/react-table';
import {
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown,
  ButtonDropdown,
  Input,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col
} from 'reactstrap';
import { LoadingSpinner } from '../common';
import { isNull } from 'lodash';
import ReactSelect from 'react-select';

const Table = (props) => {
  const {
    columns,
    data,
    meta,
    updateRow,
    skipPageReset,
    onSetPageSize,
    onNextPage,
    onPrevPage,
    onChangeKeyword,
    keyword,
    isLoading = false,
    onSearchKeyword,
    searchByGroup = [],
    searchValueBy = {},
    onSelectSearch = {},
    downloading = false,
    onDownload = null,
    allowDownload = false,
    filterAPI = []
  } = props;

  const pageSizes = [10, 20, 30, 40, 50];

  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   prepareRow,
  //   page,
  //   canPreviousPage,
  //   canNextPage,
  //   pageOptions,
  //   pageCount,
  //   gotoPage,
  //   nextPage,
  //   previousPage,
  //   setPageSize,
  //   state: { pageIndex, pageSize }
  // } = useTable(
  //   { columns, data, autoResetPage: !skipPageReset, initialState: { pageIndex: 0 }, updateRow },
  //   useSortBy,
  //   usePagination
  // );

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: Number(meta.per_page)
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);

  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

  const toggleSplit = () => setSplitButtonOpen(!splitButtonOpen);
  return (
    <>
      {/* <div className="d-flex"> */}
      {/* <div className="d-inline-flex align-items-center mb-3">
          Show
          <select
            className="form-control mx-2"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              onSetPageSize(Number(e.target.value));
            }}
          >
            {pageSizes.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          Entries
        </div> */}

      {/* <div
          className="d-flex d-inline-flex align-items-center justify-content-between"
          style={{ flex: 1 }}
        > */}
      <Row>
        {allowDownload && (
          <Col>
            <div className="form-group" style={{ borderWidth: 1, borderColor: 'black' }}>
              {/* <div className="col-sm-8">Download</div> */}
              <button
                onClick={onDownload}
                className="btn btn-secondary btn-icon btn-circle btn-md mr-2"
              >
                {downloading ? (
                  <i className="fas fa-spinner fa-pulse"></i>
                ) : (
                  <i className="fa fa-download"></i>
                )}
              </button>
            </div>
          </Col>
        )}
        {filterAPI &&
          filterAPI.map((fa) => {
            return (
              <Col>
                <div className="form-group input-group row">
                  <label htmlFor="selectStatus" className="col-2 col-form-label text-capitalize">
                    {fa.name}
                  </label>
                  <div className="col-10">
                    <ReactSelect
                      className="text-black"
                      value={fa.value}
                      onChange={fa.onChange}
                      options={fa.options}
                    />
                  </div>
                </div>
              </Col>
            );
          })}
        {onChangeKeyword && (
          <Col>
            <div className="form-group input-group row">
              <label htmlFor="inputSearch" className="col-2 col-form-label">
                Search
              </label>
              <div className="col-10">
                <InputGroup>
                  <Input placeholder="Enter keyword" onChange={onChangeKeyword} value={keyword} />
                  <InputGroupAddon addonType="append">
                    <Button color="primary" onClick={onSearchKeyword}>
                      <i className="fa fa-search"></i>
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </div>
          </Col>
        )}
      </Row>
      {/* </div> */}
      {/* </div> */}

      <div className="table-responsive">
        <table
          className="table table-striped table-bordered table-td-valign-middle"
          // {...getTableProps()}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {!isLoading ? (
              data.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                    No result found
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                  {/* <span className="spinner"></span> */}
                  <LoadingSpinner />
                </td>
              </tr>
            )}
          </tbody>
          {/* <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot> */}
          {/* <thead>
            {table.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  return (
                    <th
                      className={
                        column.Header === 'Action Items' || column.Header === 'Active'
                          ? `width-${column.width}`
                          : `w-auto`
                      }
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="d-flex justify-content-center" style={{ minWidth: '150px' }}>
                        <span className="text-center">{column.render('Header')}</span>
                        <span className={column.sortable ? 'ml-auto' : ''}>
                          {column.sortable ? (
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <i className="fa fa-sort-down fa-fw f-s-14 text-blue"></i>
                              ) : (
                                <i className="fa fa-sort-up fa-fw f-s-14 text-blue"></i>
                              )
                            ) : (
                              <i className="fa fa-sort fa-fw f-s-14 opacity-3"></i>
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead> */}
          {
            // <tbody {...getTableBodyProps()}>
            //   {!isLoading ? (
            //     page.length > 0 ? (
            //       page.map((row, i) => {
            //         prepareRow(row);
            //         return (
            //           <tr {...row.getRowProps()}>
            //             {row.cells.map((cell) => {
            //               return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
            //             })}
            //           </tr>
            //         );
            //       })
            //     ) : (
            //       <tr>
            //         <td colSpan={columns.length} style={{ textAlign: 'center' }}>
            //           No result found
            //         </td>
            //       </tr>
            //     )
            //   ) : (
            //     <tr>
            //       <td colSpan={columns.length} style={{ textAlign: 'center' }}>
            //         {/* <span className="spinner"></span> */}
            //         <LoadingSpinner />
            //       </td>
            //     </tr>
            //   )}
            // </tbody>
          }
        </table>
      </div>
      <hr className="mx-0 my-2" />
      {meta && (
        <div className="d-flex align-items-center justify-content-center">
          {/* <div className="me-1">Go to page:</div>
          <div className="w-50px mx-2 me-auto">
            <input
              className="form-control"
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
          </div> */}
          <ul className="pagination mb-0">
            {/* <li className="page-item">
              <button
                className="page-link"
                onClick={() => gotoPage(0)}
                disabled={meta.current_page == 1 || false}
              >
                <i className="fa fa-angle-double-left"></i>
              </button>
            </li> */}
            <li className="page-item">
              <button
                className="page-link"
                onClick={onPrevPage}
                disabled={meta.current_page == 1 || false}
              >
                <i className="fa fa-angle-left"></i>
              </button>
            </li>
            <li className="page-item d-flex align-items-center px-2">
              <div>
                Page{' '}
                <strong>
                  {meta.current_page} of {Number(meta.last_page || 0)}
                </strong>
              </div>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={onNextPage}
                disabled={meta.current_page == meta.last_page || false}
              >
                <i className="fa fa-angle-right"></i>
              </button>
            </li>
            {/* <li className="page-item">
              <button
                className="page-link"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={meta.current_page == meta.last_page || false}
              >
                <i className="fa fa-angle-double-right"></i>
              </button>
            </li> */}
            <li className="page-item">
              <div className="d-inline-flex align-items-center mr-3">
                Show
                <select
                  className="form-control mx-2"
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                    onSetPageSize(Number(e.target.value));
                  }}
                >
                  {pageSizes.map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
                Entries
              </div>
            </li>
          </ul>
        </div>
      )}

      {/* {meta && (
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-inline-flex align-items-center mr-3">
            Show
            <select
              className="form-control mx-2"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
                onSetPageSize(Number(e.target.value));
              }}
            >
              {pageSizes.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            Entries
          </div>
          <div className="d-flex align-items-center">
            <b>
              Showing {meta.from || 0} to {meta.to || 0} of {Number(meta.total || 0)} entries
            </b>
          </div>
          <div className="d-flex align-items-center">
            <ul className="pagination mb-0">
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <i className="fa fa-angle-double-left"></i>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={onPrevPage}
                  disabled={meta.current_page == 1 || false}
                >
                  <i className="fa fa-angle-left"></i>
                </button>
              </li>
              <li className="page-item d-flex align-items-center px-2">
                <div>
                  Page{' '}
                  <strong>
                    {meta.current_page || 0} of {meta.last_page || 0}
                  </strong>
                </div>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={onNextPage}
                  disabled={meta.current_page == meta.last_page || false}
                >
                  <i className="fa fa-angle-right"></i>
                </button>
              </li>

              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <i className="fa fa-angle-double-right"></i>
                </button>
              </li>
            </ul>
            <div className="ml-3 mr-1">Go to page:</div>
            <div className="width-50 mx-2">
              <input
                className="form-control"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default memo(Table);
