import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import PageContext from '../../../config/page-settings.js';
import * as API from '../../../services/api';
import { Table } from '../../../components/table';
import { PriceFormat } from '../../../utils/index.js';
import { useParams } from 'react-router-dom';
import { isNull } from 'lodash';
// TYPE_BORROWER_REPAYMENT_PRINCIPAL         = 2002
// TYPE_BORROWER_REPAYMENT_INTEREST          = 2003
// TYPE_PRODUCT_B_BORROWER_APPROVED_LOAN     = 2005
// TYPE_PRODUCT_B_BORROWER_DISBURSE          = 2006 // after charge

const IssuerTransactionList = () => {
  const { id } = useParams();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const onChangeKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const getRequestParams = (keyword, page, pageSize) => {
    let params = {};

    if (id != undefined) {
      params['filter[user_id]'] = id;
    }

    if (keyword) {
      params['filter[name]&filter[key]'] = keyword;
    }

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    params['filter[type]'] = '2002,2003,2005,2006,5004,5005';

    return params;
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    const params = getRequestParams(keyword, page, pageSize);
    setIsLoading(true);
    API.getUserTransactions(params)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        setData([]);
        setMeta({});
        setIsLoading(false);
      });
  };

  useEffect(fetchData, [page, pageSize]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  function isNegative(num) {
    if (typeof num === 'number' && Math.sign(num) === -1) {
      return true;
    }

    return false;
  }

  const userColumns = useMemo(
    () => [
      {
        header: 'Date',
        accessorKey: 'created_at',
        sortable: true
      },
      {
        header: 'Debit',
        accessorFn: (d) => {
          return !isNull(d.debit) ? PriceFormat(Number(d.debit)) : '';
        },
        sortable: true
      },
      {
        header: 'Credit',
        accessorFn: (d) => {
          return !isNull(d.credit) ? PriceFormat(Number(d.credit)) : '';
        },
        sortable: true
      },
      {
        header: 'Type',
        accessorKey: 'description',
        sortable: true
      }
    ],
    []
  );
  const columns = useMemo(
    () => [
      {
        header: 'User ID',
        accessorKey: 'user_id',
        sortable: true
      },
      // {
      //   header: 'Account Name',
      //   accessor: 'full_name',
      //   sortable: true
      // },
      {
        header: 'Date',
        accessorKey: 'created_at',
        sortable: true
      },
      {
        header: 'Amount',
        accessorFn: (d) => {
          return PriceFormat(d.amount);
        },
        sortable: true
      },
      {
        header: 'Type',
        accessorKey: 'description',
        sortable: true
      }
    ],
    []
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Panel>
      <PanelHeader>Issuer Transactions</PanelHeader>
      <PanelBody>
        <Table
          columns={id ? userColumns : columns}
          data={data}
          meta={meta}
          isLoading={isLoading}
          onSetPageSize={handlePageSizeChange}
          updateRow={updateRow}
          onNextPage={handlePageChange('next')}
          onPrevPage={handlePageChange('prev')}
          skipPageReset={skipPageReset}
          onChangeKeyword={onChangeKeyword}
          keyword={keyword}
          onSearchKeyword={handleSearch}
        />
      </PanelBody>
    </Panel>
  );
};

export default memo(IssuerTransactionList);
