import React, { memo, useEffect, useState } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import * as API from '../../../services/api';
import { PriceFormat } from '../../../utils';
import moment from 'moment';
const Payouts = (props) => {
  const [payouts, setPayouts] = useState([]);
  useEffect(() => {
    API.payouts().then((res) => {
      console.log('payouts', res.data.data);
      setPayouts(res.data.data);
    });
  }, []);
  return (
    <div className="card border-0 text-white bg-blue-dark mb-3">
      <div className="card-body">
        <div className="d-flex flex-row justify-content-between mb-3 text-grey">
          <div>
            <b>UPCOMING PAYOUTS</b>
            <span className="ml-2 mr-2">
              <i className="fa fa-info-circle" id="popover7"></i>
              <UncontrolledPopover trigger="hover" placement="top" target="popover7">
                <PopoverHeader>Upcoming Payouts</PopoverHeader>
                <PopoverBody>Payouts 7 days ahead</PopoverBody>
              </UncontrolledPopover>
            </span>
          </div>
        </div>
        <div className="overflow-auto" style={{ maxHeight: '320px' }}>
          {Object.keys(payouts).map((date) => (
            <>
              <b>
                {moment(date).format('LL')} ({moment(date).format('dddd')})
              </b>
              {payouts[date].map((repaymentData) => (
                <RepaymentComponent key={repaymentData.id} repaymentData={repaymentData} />
              ))}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(Payouts);

const RepaymentComponent = ({ repaymentData }) => {
  return (
    <div className="ml-2">
      <p className="mb-0">
        {repaymentData.title} ({repaymentData.funding_id}){' '}
      </p>
      <small>Principal: {PriceFormat(repaymentData.principal)}</small>
      <br />
      <p className={repaymentData.period == repaymentData.total_period && 'mb-0'}>
        <small>Interest: {PriceFormat(repaymentData.payment)}</small>
      </p>
      {repaymentData.period == repaymentData.total_period && (
        <span className="badge bg-danger rounded-pill mb-2">Last Payout</span>
      )}
    </div>
  );
};
