import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PageContext from '../../../config/page-settings.js';

import BanksList from './BanksList';

const BanksManagement = (props) => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const createRole = () => {
    props.history.push('/settings/role/create');
  };

  return (
    <div>
      <h1 className="page-header">Banks Management</h1>
      <BanksList />
    </div>
  );
};

export default withRouter(BanksManagement);
