import React, { memo, useEffect, useState } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useLocation, useHistory, useParams } from 'react-router';
import { Row, Col, Label, Form, FormGroup, Input, Media, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { PriceFormat } from '../../../utils/format.js';
// import * as API from '../../../services/api';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { Notification } from '../../../utils/index.js';
import * as API from '../../../services/api';
import moment from 'moment';
import LoadingSpinner from '../../../components/common/LoadingSpinner.js';
import { isNull } from 'lodash';
require('codemirror/mode/xml/xml.js');
require('codemirror/mode/javascript/javascript.js');
const XenditDetail = (props) => {
  const { id } = useParams();
  const location = useLocation();

  const { state } = props.location;

  const [xendit, setXendit] = useState({ query: null, ...state });

  const [loadingHistory, setLoadingHistory] = useState(false);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    API.getXenditTransaction(id)
      .then((res) => {
        setXendit(res.data.data);
        // setHistory(res.data.data.history);
        setLoadingHistory(false);
      })
      .catch((err) => {
        setXendit(state);
        setHistory([]);
        setLoadingHistory(false);
      });
  }, []);

  return (
    <div>
      <h1 className="page-header">Xendit Detail</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader>Details</PanelHeader>
            <PanelBody>
              <FormGroup tag="fieldset">
                <Row>
                  <Col>
                    <FormGroup row>
                      <Col md="3">Date</Col>
                      <Col md="9">{xendit.created_at}</Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md="3">Customer</Col>
                      <Col md="9">{xendit.user_full_name}</Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md="3">Amount</Col>
                      <Col md="9">₱{xendit.amount}</Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup row>
                      <Col md="3">Request ID</Col>
                      <Col md="9">{xendit.request_id}</Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md="3">Type</Col>
                      <Col md="9">{xendit.type_name}</Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md="3">Status</Col>
                      <Col md="9">{xendit.status_name}</Col>
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
              <Row>
                <Col className="d-flex justify-content-end">
                  {xendit.status_name != 'Success' && (
                    <ButtonForceCheck status={xendit.status_name} />
                  )}
                  {!isNull(xendit.query) && <ButtonQueryRequest query={xendit.query} />}

                  <ButtonLatestResponse response={xendit.response} />
                  <ButtonRequestData requestData={xendit.post_data} />
                </Col>
              </Row>
            </PanelBody>
          </Panel>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader>Response History</PanelHeader>
            <PanelBody>
              <div className="table-responsive">
                <table className="table table-striped mb-0 align-middle">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Action Item</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loadingHistory ? (
                      <tr>
                        <td colSpan={4} style={{ textAlign: 'center' }}>
                          <LoadingSpinner />
                        </td>
                      </tr>
                    ) : history.length ? (
                      history.map((history) => (
                        <tr key={history.id}>
                          <td>{moment(history.created_at).format('LLL')}</td>

                          <td nowrap="true">
                            <div>
                              <RowActionItemQueryRequest query={history.query} />
                              <RowActionItemResponse response={history.response} />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4} style={{ textAlign: 'center' }}>
                          No history(s) recorded
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div> */}
    </div>
  );
};

export default memo(XenditDetail);

const ButtonRequestData = ({ requestData = '{}' }) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };
  const codeMirrorOptions = {
    mode: 'application/json',
    theme: 'material',
    lineNumbers: true,
    indentWithTabs: true,
    tabSize: 2,
    autoScroll: false
  };

  const json = JSON.parse(requestData);

  return (
    <>
      <button className="btn btn-primary mr-2" type="button" onClick={toggleModalReject}>
        Post Data
      </button>
      <Modal isOpen={modalReject} toggle={toggleModalReject} size="lg" centered={true}>
        <ModalHeader toggle={toggleModalReject}>Request Data Message</ModalHeader>
        <ModalBody>
          <CodeMirror options={codeMirrorOptions} value={JSON.stringify(json, null, 2)} />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={toggleModalReject}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const ButtonLatestResponse = ({ response = '{}' }) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const codeMirrorOptions = {
    mode: 'application/json',
    theme: 'material',
    lineNumbers: true,
    indentWithTabs: true,
    tabSize: 2,
    autoScroll: false
  };

  const json = JSON.parse(response);

  return (
    <>
      <button className="btn btn-primary mr-2" type="button" onClick={toggleModalReject}>
        Latest Response
      </button>
      <Modal isOpen={modalReject} toggle={toggleModalReject} size="lg" centered={true}>
        <ModalHeader toggle={toggleModalReject}>Latest Response Message</ModalHeader>
        <ModalBody>
          <CodeMirror options={codeMirrorOptions} value={JSON.stringify(json, null, 2)} />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={toggleModalReject}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const ButtonQueryRequest = ({ query = '{}' }) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const codeMirrorOptions = {
    mode: 'application/json',
    theme: 'material',
    lineNumbers: true,
    indentWithTabs: true,
    tabSize: 2,
    autoScroll: false
  };

  const json = JSON.parse(query);
  return (
    <>
      <button className="btn btn-primary mr-2" type="button" onClick={toggleModalReject}>
        Query Request
      </button>
      <Modal isOpen={modalReject} toggle={toggleModalReject} size="lg" centered={true}>
        <ModalHeader toggle={toggleModalReject}>Query Request Message</ModalHeader>
        <ModalBody>
          <CodeMirror options={codeMirrorOptions} value={JSON.stringify(json, null, 2)} />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={toggleModalReject}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const ButtonForceCheck = ({ status = 'Pending' }) => {
  const { id } = useParams();

  const [modalReject, setModalReject] = useState(false);

  const [loading, setLoading] = useState(false);

  const onForceCheck = () => {
    setLoading(true);

    API.checkXenditTransactionStatus(id)
      .then((res) => {
        setLoading(false);
        Notification.success('Xendit Check Status', 'Transactions successfully updated');
      })
      .catch((err) => {
        Notification.error(err);
      });
  };
  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  return (
    <button
      type="button"
      onClick={onForceCheck}
      className={`btn ${loading ? 'btn-gray' : 'btn-warning'} mr-2`}
      disabled={loading}
    >
      {loading ? (
        <span>
          Checking Status <i className="fas fa-spinner fa-spin"></i>
        </span>
      ) : (
        `${status === 'Pending' ? '' : 'Force '}Check Status`
      )}
    </button>
  );
};

const RowActionItemQueryRequest = ({ query }) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const codeMirrorOptions = {
    mode: 'application/json',
    theme: 'material',
    lineNumbers: true,
    indentWithTabs: true,
    tabSize: 2,
    autoScroll: false
  };

  const json = JSON.parse(query);

  return (
    <>
      <button className="btn btn-sm btn-primary mr-2" type="button" onClick={toggleModalReject}>
        Query Request
      </button>
      <Modal isOpen={modalReject} toggle={toggleModalReject} size="lg" centered={true}>
        <ModalHeader toggle={toggleModalReject}>Query Request Message</ModalHeader>
        <ModalBody>
          <CodeMirror options={codeMirrorOptions} value={JSON.stringify(json, null, 2)} />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={toggleModalReject}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const RowActionItemResponse = ({ response }) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const codeMirrorOptions = {
    mode: 'application/json',
    theme: 'material',
    lineNumbers: true,
    indentWithTabs: true,
    tabSize: 2,
    autoScroll: false
  };

  const json = JSON.parse(response);

  return (
    <>
      <button className="btn btn-sm btn-primary mr-2" type="button" onClick={toggleModalReject}>
        Response
      </button>
      <Modal isOpen={modalReject} toggle={toggleModalReject} size="lg" centered={true}>
        <ModalHeader toggle={toggleModalReject}>Response Message</ModalHeader>
        <ModalBody>
          <CodeMirror options={codeMirrorOptions} value={JSON.stringify(json, null, 2)} />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={toggleModalReject}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};
