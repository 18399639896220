import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Moment from 'moment';
import PageContext, { PageSettings } from './../../config/page-settings.js';
import TotalFunds from './components/TotalFunds.js';
import TotalLogin from './components/TotalLogin.js';
import TotalRequest from './components/TotalRequest.js';
import NewUsers from './components/NewUsers.js';
import TopInvestors from './components/TopInvestors.js';
import Payouts from './components/Payouts.js';

const Dashboard = (props) => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [startDate, setStartDate] = useState(Moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(Moment());

  const [dateRange, setDateRange] = useState({
    currentWeek:
      Moment().subtract('days', 7).format('D MMMM YYYY') + ' - ' + Moment().format('D MMMM YYYY'),
    prevWeek:
      Moment().subtract('days', 15).format('D MMMM') +
      ' - ' +
      Moment().subtract('days', 8).format('D MMMM YYYY')
  });

  const handleDateApplyEvent = (event, picker) => {
    var startDate = picker.startDate;
    var endDate = picker.endDate;
    var gap = endDate.diff(startDate, 'days');

    var currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY');
    var prevWeek =
      Moment(startDate).subtract('days', gap).format('D MMMM') +
      ' - ' +
      Moment(startDate).subtract('days', 1).format('D MMMM YYYY');

    dateRange.currentWeek = currentWeek;
    dateRange.prevWeek = prevWeek;

    setDateRange((prevState) => ({
      ...prevState,
      currentWeek: currentWeek,
      prevWeek: prevWeek
    }));
  };

  return (
    <div>
      <h1 className="page-header">
        Dashboard{' '}
        <button className="btn btn-secondary btn-icon btn-circle btn-md ml-2">
          <i className="fa fa-download" style={{ color: 'white' }}></i>
        </button>
      </h1>
      <div className="d-sm-flex align-items-center mb-3">
        {/* <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onApply={handleDateApplyEvent}
          initialSettings={{
            maxDate: Moment()
          }}
        >
          <button className="btn btn-inverse mr-2 text-truncate">
            <i className="fa fa-calendar fa-fw text-white-transparent-5 ml-n1"></i>
            <span>{dateRange.currentWeek}</span>
            <b className="caret"></b>
          </button>
        </DateRangePicker> */}
        {/* <div className="text-muted f-w-600 mt-2 mt-sm-0">
          compared to <span>{dateRange.prevWeek}</span>
        </div> */}
      </div>
      <Row>
        <Col xl="6">
          <TotalFunds />
        </Col>
        <Col xl="3">
          <TotalLogin />
        </Col>
        <Col xl="3">
          <TotalRequest />
        </Col>
      </Row>
      <Row>
        <Col xl="8">
          <NewUsers />
        </Col>
        <Col xl="4">
          <Payouts />
        </Col>
      </Row>
      {/* <Row>
        <Col xl="4">
          <Demographics />
        </Col>
        <Col xl="4">
          <WorldwideInvestor />
        </Col>
      </Row> */}
    </div>
  );
};

export default Dashboard;
