import React, { useState, useEffect, memo } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Row, Col, Label, Form, FormGroup, Input } from 'reactstrap';
import { isEmpty } from 'lodash';
import * as API from '../../../services/api';
import CreatableSelect from 'react-select/creatable';
import FileUpload from '../../../components/forms/FileUpload';

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      checked={props.checked}
      disabled={props.disabled || false}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const schemaWithdrawalMethod = yup.object().shape({
  methods: yup.array().of(
    yup.object().shape({
      full_name: yup.string().required('Full name is required.'),
      position: yup.string().required('Current position is required.'),
      function: yup.string().required('Current function is required.'),
      address: yup.string().required('Address is required.')
    })
  )
});

const FormWithdrawalMethod = (props) => {
  const { issuer, currentStep } = props;

  if (currentStep !== 4) {
    return null;
  }

  const defaultValues = {
    banks: [
      {
        is_primary: true
      }
    ]
  };

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch
  } = useForm({
    defaultValues,
    resolver: yupResolver(schemaWithdrawalMethod)
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    data.banks.forEach((bank, index) => {
      for (let [key, value] of Object.entries({
        ...bank,
        user_id: issuer.id,
        is_primary: bank.is_primary ? 1 : 0,
        withdrawal_type: 1
      })) {
        formData.append(`${key}`, key == 'attachment' ? value[0] : value);
      }
    });

    API.userCreateBank(formData)
      .then((res) => {
        props.onSubmit();
        console.log('userCreateBankRes', res);
      })
      .catch((err) => {
        console.log('userCreateBankErr', err.response);
      });
  };

  const enableBanks = watch('enable_banks', false);
  const enableGcash = watch('enable_gcash', false);
  const enableCoinsPH = watch('enable_coinsph', false);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup tag="fieldset">
        <h3 className="my-0">Bank Details</h3>
        <FieldArray {...{ control, register, defaultValues, getValues, setValue, errors }} />
        <hr />
        <Row>
          <Col>
            {props.currentStep > 1 && (
              <button className="btn btn-primary float-left" type="button" onClick={props.prevStep}>
                Previous
              </button>
            )}
            <button className="btn btn-primary float-right" type="submit">
              Next
            </button>
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
};

export default FormWithdrawalMethod;

const FieldArray = ({ control, register, errors, setValue, getValues }) => {
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'banks'
  });

  const [optionBanks, setOptionBanks] = useState([]);
  useEffect(() => {
    API.dataSets(['banks']).then((res) => {
      setOptionBanks(res.data.banks);
    });
  }, []);

  const setAsPrimary = (indexToBePrimary) => {
    getValues().banks.forEach((_value, index) => {
      setValue(`banks.${index}.is_primary`, index === indexToBePrimary);
    });
  };

  return (
    <>
      {fields.map((item, index) => {
        return (
          <FormGroup tag="fieldset" key={index}>
            {fields.length > 1 && index > 0 && <hr style={{ border: `1px dashed black` }} />}
            <FormGroup row>
              <Label md="3">Bank</Label>
              <Col md="9">
                <Controller
                  name={`banks.${index}.bank_name`}
                  isClearable
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <CreatableSelect
                      inputRef={ref}
                      value={optionBanks.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.value)}
                      options={optionBanks}
                      isOptionDisabled={(option) => option.disabled}
                    />
                  )}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Account Name</Label>
              <Col md="9">
                <Controller
                  name={`banks.${index}.account_name`}
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                  )}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Account Number</Label>
              <Col md="9">
                <Controller
                  name={`banks.${index}.account_number`}
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      className="number-only-field"
                      invalid={!isEmpty(errors.position)}
                    />
                  )}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Branch</Label>
              <Col md="9">
                <Controller
                  name={`banks.${index}.bank_address`}
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                  )}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Swift Code</Label>
              <Col md="9">
                <Controller
                  name={`banks.${index}.swift_code`}
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                  )}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Attachment</Label>
              <Col md="9">
                <Controller
                  name={`banks.${index}.attachment`}
                  isClearable
                  control={control}
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <FileUpload
                        inputRef={ref}
                        onDrop={onChange}
                        onChange={onChange}
                        imagefile={value}
                        // invalid={!isEmpty(errors.proof_identity_file)}
                      />
                    );
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Set As Primary</Label>
              <Col md="9">
                <Controller
                  name={`banks.${index}.is_primary`}
                  isClearable
                  control={control}
                  render={({ field: { value = fields.length > 1 ? false : true, onChange } }) => (
                    <Switchery
                      checked={value}
                      onChange={(val) => {
                        setAsPrimary(index);
                      }}
                      for={`banks.${index}.is_primary`}
                    />
                  )}
                />
              </Col>
            </FormGroup>
          </FormGroup>
        );
      })}
      {/* {enableBanks && ( */}
      <>
        {/* <Row>
          <Col>
            <button
              className="btn btn-primary float-right"
              type="button"
              onClick={() => {
                append({});
              }}
            >
              <i className="fa fa-plus" style={{ marginRight: 10 }} />
              Add Another Bank
            </button>
          </Col>
        </Row> */}
      </>
      {/* )} */}
    </>
  );
};
