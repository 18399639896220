import React, { memo, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Row, Col, Tooltip } from 'reactstrap';

import PageContext from '../../../config/page-settings.js';
import * as API from '../../../services/api';
import moment from 'moment';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { PriceFormat } from '../../../utils/index.js';
import { Table, RowActionItems, RowItemDelete, RowItemView } from '../../../components/table';
import { Notification } from '../../../utils/index.js';

import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';

const CancelledInvestmentsList = ({ total = '0.00' }) => {
  const { project_id } = useParams();

  //   const [loadingSubmit, setLoadingSubmit] = useState(false);

  //   const [investors, setInvestors] = useState([]);

  //   useEffect(() => {
  //     API.getProjectInvestors(project_id).then((res) => {
  //       console.log(res.data.data);
  //     });
  //   }, [project_id]);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const onChangeKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const getRequestParams = (keyword, page, pageSize) => {
    let params = {};

    if (keyword) {
      params['filter[name]&filter[key]'] = keyword;
    }

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    return params;
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    const params = getRequestParams(keyword, page, pageSize);
    setIsLoading(true);
    API.getProjectCancelledInvestors(project_id, params)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        setData([]);
        setMeta({});
        setIsLoading(false);
      });
  };

  useEffect(fetchData, [page, pageSize, project_id]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Date Invested',
        cell: ({ cell }) => {
          const { original } = cell.row;
          return original.investment_details.map((detail) => (
            <div>
              {moment(detail.created_at).format('MMMM DD YYYY hh:mm A')}
              <br />
              <small>{PriceFormat(detail.amount)}</small>
              <br />
            </div>
          ));
        },
        sortable: true
      },
      {
        header: 'Investor',
        accessorKey: 'investor_name',
        sortable: true
      },
      {
        header: 'Amount',
        accessorFn: (d) => {
          return PriceFormat(d.amount);
        },
        sortable: true
      }
    ],
    [data]
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <h3>Cancelled Investments : {PriceFormat(total)}</h3>

      <Panel>
        <PanelHeader></PanelHeader>
        <PanelBody>
          <Table
            columns={columns}
            data={data}
            meta={meta}
            isLoading={isLoading}
            onSetPageSize={handlePageSizeChange}
            updateRow={updateRow}
            onNextPage={handlePageChange('next')}
            onPrevPage={handlePageChange('prev')}
            skipPageReset={skipPageReset}
            onChangeKeyword={onChangeKeyword}
            keyword={keyword}
            onSearchKeyword={handleSearch}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default CancelledInvestmentsList;
