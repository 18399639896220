import React, { useState, memo } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useParams } from 'react-router-dom';

const CancelFunding = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleModal = async () => {
    await setIsVisible(!isVisible);
  };

  const onConfirm = () => {
    setIsVisible(false);
    toggleModal().then(() => {
      setTimeout(() => {
        props.onConfirm();
      }, 300);
    });
  };

  return (
    <>
      <button className="btn btn-xs btn-warning m-l-5 text-white disabled" onClick={toggleModal}>
        <i className="fa fa-ban mr-1"></i>Cancel Funding
      </button>
      <SweetAlert
        title={
          <span className="text-black text-wrap">Are you sure you want to cancel funding?</span>
        }
        danger
        showCancel
        confirmBtnText="Yes, cancel it!"
        confirmBtnBsStyle="danger"
        focusCancelBtn
        onConfirm={onConfirm}
        onCancel={toggleModal}
        show={isVisible}
      />
    </>
  );
};

export default memo(CancelFunding);
