import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import { BrowserRouter } from 'react-router-dom';

// css
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-calendar/dist/Calendar.css';
import 'react-quill/dist/quill.snow.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';
import 'bootstrap-social/bootstrap-social.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-notifications-component/dist/theme.css';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
// ========================================

// import Axios from 'axios';

import ReactNotification from 'react-notifications-component';

// Axios.get('http://dev.seedin-api.com/sanctum/csrf-cookie', { withCredentials: true });

console.log('REACT_APP_ENV', process.env.REACT_APP_ENV);

import { createOvermind } from 'overmind';
import { Provider } from 'overmind-react';
import { config } from './overmind';

const overmind = createOvermind(config);

ReactDOM.render(
  <Provider value={overmind}>
    <BrowserRouter>
      <ReactNotification />
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
