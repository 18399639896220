import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { Table, RowActionItems, RowItemView } from '../../../components/table';
import PageContext from '../../../config/page-settings.js';
import ReactSelect from 'react-select';

import * as API from '../../../services/api';

const GlobalSettingsList = () => {
  const history = useHistory();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const onChangeKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const getRequestParams = (keyword, page, pageSize) => {
    let params = {};

    if (keyword) {
      params['filter[name]&filter[key]'] = keyword;
    }

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    return params;
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    const params = getRequestParams(keyword, page, pageSize);
    setIsLoading(true);
    API.getGlobalData(params)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        setData([]);
        setMeta({});
        setIsLoading(false);
      });
  };

  useEffect(fetchData, [page, pageSize]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Settings Name',
        width: 100,
        cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <div>
              {original.name}
              <br />
              <small>({original.key})</small>
            </div>
          );
        },
        sortable: true
      },
      {
        header: 'Value',
        cell: ({ cell }) => {
          const { original } = cell.row;
          if (original.type === 'select') {
            const arrayOptions = original.value.map((e) => ({
              ...e,
              value: e.id,
              label: e.name
            }));
            return (
              <ReactSelect
                defaultValue={arrayOptions[0]}
                options={arrayOptions}
                isOptionDisabled={(option) => !option.is_active}
              />
            );
          } else if (original.type === 'number') {
            return <p style={{ color: 'blue' }}>{original.value}</p>;
          } else {
            return original.value;
          }
        },
        sortable: true
      },
      {
        header: 'Action Items',
        cell: ({ cell }) => {
          const { original } = cell.row;

          const onView = () => {
            history.push(`/settings/global-settings/${original.id}`, original);
          };

          return (
            <RowActionItems>
              <RowItemView onClick={onView} />
            </RowActionItems>
          );
        }
      }
    ],
    [data]
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Panel>
      <PanelHeader>Global Settings List</PanelHeader>
      <PanelBody>
        <Table
          columns={columns}
          data={data}
          meta={meta}
          isLoading={isLoading}
          onSetPageSize={handlePageSizeChange}
          updateRow={updateRow}
          onNextPage={handlePageChange('next')}
          onPrevPage={handlePageChange('prev')}
          skipPageReset={skipPageReset}
        />
      </PanelBody>
    </Panel>
  );
};

export default memo(GlobalSettingsList);
