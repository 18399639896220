import React, { memo, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import * as API from '../../../../services/api';
import { Form, FormGroup, FormFeedback, Row, Col, Label, Input } from 'reactstrap';
import { Button } from '../../../../components/common';
import { isBase64, Notification } from '../../../../utils';
import { isEmpty } from 'lodash';
import ReactSelect from 'react-select';
import { UploadAvatar } from '../../../../components/upload';
import { useActions } from '../../../../overmind';

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      checked={props.checked}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const schemaUserCreate = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  username: yup.string().required('Username is required').min(8, 'Must be minimum 8 characters'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password is too short - Must be minimum 8 characters'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  is_required_otp: yup.boolean(),
  mobile_number: yup
    .string()
    .required('Mobile number is required')
    .min(8, 'Mobile number is too short - Must be minimum 10 characters')
    .matches(/^\d+$/, 'Mobile number is invalid')
});

const schemaUserUpdate = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  username: yup.string().required('Username is required').min(8, 'Must be minimum 8 characters'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  is_required_otp: yup.boolean(),
  mobile_number: yup
    .string()
    .required('Mobile number is required')
    .min(8, 'Mobile number is too short - Must be minimum 10 characters')
    .matches(/^\d+$/, 'Mobile number is invalid')
});

const FormUser = (props) => {
  const { action, values } = props;
  const {
    register,
    reset,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    setError
  } = useForm({
    defaultValues: {
      avatar: null,
      is_required_otp: false,
      mobile_code: '+63',
      avatar: action === 'update' ? values.photo && values.photo.view : null
    },
    resolver: yupResolver(action === 'update' ? schemaUserUpdate : schemaUserCreate)
  });

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [optionCountryCode, setCountryCodes] = useState([]);
  const [optionRoles, setRoles] = useState([]);

  useEffect(() => {
    API.dataSets(['roles', 'country_codes']).then((res) => {
      setRoles(res.data.roles);
      setCountryCodes(res.data.country_codes);
    });
  }, []);

  useEffect(() => {
    if (action === 'update') {
      reset({
        ...values,
        avatar: values.photo && values.photo.view,
        roles: values.role_name
      });
    }
  }, [action, values]);

  const onSubmit = async (data) => {
    setLoadingSubmit(true);

    const params = action == 'update' ? new URLSearchParams() : new FormData();

    params.append('first_name', data.first_name);
    params.append('last_name', data.last_name);
    params.append('mobile_code', data.mobile_code);
    params.append('roles', data.roles);

    params.append('email', data.email);
    params.append('mobile_number', data.mobile_number);
    params.append('username', data.username);
    params.append('is_required_otp', data.is_required_otp ? '1' : '0');
    params.append('otp_platform_id', '1');

    if (data.avatar && isBase64(data.avatar)) {
      let res = await uploadFiles(data.avatar);
      params.append('photo', res.id);
    } else {
      if (values.photo && values.photo.id) {
        params.append('photo', values.photo && values.photo.id);
      }
    }

    if (action != 'update') {
      params.append('password', data.password);
    }

    const api = async () => {
      if (action == 'update') {
        return await API.userAdminUpdate(values.id, params);
      } else {
        return await API.userAdminCreate(params);
      }
    };

    api()
      .then(() => {
        Notification.success(
          `User ${action == 'update' ? 'Updated' : 'Created'}`,
          `User successfully ${action == 'update' ? 'updated' : 'created'}!`
        );
        props.history.push('/settings/users');
        setLoadingSubmit(false);
      })
      .catch((err) => {
        setLoadingSubmit(false);
        if (err.response.status === 422) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            setError(key, {
              type: 'manual',
              message: value[0]
            });
          }
        }
      });
  };

  const onCrop = (e) => {
    setValue('avatar', e);
  };

  const uploadFiles = async (base64) => {
    const formData = new FormData();
    formData.append('attachment', base64);
    formData.append('dir', 'Avatars');
    return await API.base64Upload(formData)
      .then((res) => {
        return { status: 1, ...res.data.data };
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <FormGroup tag="fieldset">
        {/* <FormGroup row>
          <Label md="3">Avatar</Label>
          <Col md="9">
            <UploadAvatar {...register('avatar')} onChange={onCrop} />
            {errors.avatar && <FormFeedback>{errors.avatar?.message}</FormFeedback>}
          </Col>
        </FormGroup> */}

        <FormGroup row>
          <Label md="3">Avatar</Label>
          <Col md="9">
            <Controller
              name="avatar"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <UploadAvatar
                  onChange={onChange}
                  source={value}
                  invalid={!isEmpty(errors.avatar)}
                />
              )}
            />
            <FormFeedback style={{ display: errors.avatar ? 'block' : 'none' }}>
              {errors.avatar?.message}
            </FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">First Name</Label>
          <Col md="9">
            <Controller
              name="first_name"
              isClearable
              control={control}
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.first_name)} />
              )}
            />
            {errors.first_name && <FormFeedback>{errors.first_name?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Last Name</Label>
          <Col md="9">
            <Controller
              name="last_name"
              isClearable
              control={control}
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.last_name)} />
              )}
            />
            {errors.last_name && <FormFeedback>{errors.last_name?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Username</Label>
          <Col md="9">
            <Controller
              name="username"
              isClearable
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  autoComplete="off"
                  invalid={!isEmpty(errors.username)}
                />
              )}
            />
            {errors.username && <FormFeedback>{errors.username?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Password</Label>
          <Col md="9">
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <Input
                  {...field}
                  autoComplete="new-password"
                  type="password"
                  invalid={!isEmpty(errors.password)}
                />
              )}
            />
            {errors.password && <FormFeedback>{errors.password?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Email</Label>
          <Col md="9">
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <Input {...field} type="email" invalid={!isEmpty(errors.email)} />
              )}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Mobile Number</Label>
          <Col md="3" sm="4">
            <Controller
              control={control}
              name="mobile_code"
              isClearable
              defaultValue={optionCountryCode.map((c) => c.value)}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  value={optionCountryCode.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={optionCountryCode}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
            {errors.mobile_code && <FormFeedback>{errors.mobile_code?.message}</FormFeedback>}
          </Col>
          <Col md="6" sm="8">
            <Controller
              control={control}
              name="mobile_number"
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.mobile_number)} />
              )}
            />
            {errors.mobile_number && <FormFeedback>{errors.mobile_number?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Role</Label>
          <Col md="9">
            {/* <Controller
              name="roles"
              isClearable
              control={control}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  options={optionRoles}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            /> */}
            <Controller
              name="roles"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  value={optionRoles.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={optionRoles}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
            {errors.roles && <FormFeedback>{errors.roles?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Required OTP</Label>
          <Col md="9">
            <Controller
              control={control}
              name="is_required_otp"
              render={({ field: { value, onChange } }) => (
                <Switchery checked={value} onChange={onChange} for="is_required_otp" />
              )}
            />
          </Col>
        </FormGroup>
      </FormGroup>

      <Row>
        <Col>
          <Button
            type="submit"
            isloading={loadingSubmit}
            className={`btn float-right ${loadingSubmit ? 'btn-disabled btn-gray' : 'btn-primary'}`}
            disabled={loadingSubmit}
            title="Submit"
          />
          {/* <button
            type="submit"
            className={`btn float-right ${loadingSubmit ? 'btn-secondary' : 'btn-primary'}`}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
          </button> */}
        </Col>
      </Row>
    </Form>
  );
};

export default withRouter(FormUser);
