import React, {
  memo,
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
  lazy,
  Suspense
} from 'react';
import { withRouter, Link, useParams } from 'react-router-dom';
import PageContext from '../../../config/page-settings.js';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';

import * as API from '../../../services/api';
import moment from 'moment';
import { Notification, PriceFormat } from '../../../utils';

import { Form, FormGroup, Row, Col, Input, FormFeedback } from 'reactstrap';
import { Button } from '../../../components/common';

import CurrencyInput from 'react-currency-input-field';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isEmpty, isNull } from 'lodash';
import ReactSelect from 'react-select';
import LoadingSpinner from '../../../components/common/LoadingSpinner.js';
const MerchantPerksList = lazy(() => import('./components/MerchantPerksList.js'));
const FormInformationEdit = lazy(() => import('./components/FormInformationEdit.js'));

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      {...props}
      checked={props.checked}
      disabled={props.disabled || false}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const schemaRemarks = yup.object().shape({
  remarks: yup.string().nullable()
});

const PartnersView = (props) => {
  const pageContext = useContext(PageContext);
  const { id } = useParams();

  const [merchant, setMerchant] = useState({});

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullHeight(true);
    pageContext.handleSetPageContentFullWidth(true);

    API.readMerchant(id).then((res) => {
      setMerchant(res.data.data);
    });
  }, []);

  const [tabs, setActiveTab] = useState({
    summary: true,
    profile: false,
    activities: false,
    transactions: false,
    investments: false,
    referrals: false,
    commissions: false,
    autoInvestmentRules: false
  });

  //   const toggleAutoInvestSettings = (value) => {
  //     setInvestor({ ...investor, auto_invest_enable: value.target.checked });
  //     const params = new URLSearchParams();
  //     params.append('auto_invest_enable', value.target.checked ? 1 : 0);
  //     API.investorSettingsAutoInvest(id, params)
  //       .then((res) => {
  //         Notification.success(
  //           'Auto Invest Settings',
  //           `Auto Invest Successfully ${value.target.checked ? 'enabled' : 'disabled'}`
  //         );
  //       })
  //       .catch((err) => {
  //         setInvestor({ ...investor, auto_invest_enable: !value.target.checked });
  //         Notification.error(err);
  //       });
  //   };

  const showTab = (e, tab) => {
    e.preventDefault();
    setActiveTab({
      summary: tab === 'summary' ? true : false,
      profile: tab === 'profile' ? true : false,
      activities: tab === 'activities' ? true : false,
      transactions: tab === 'transactions' ? true : false,
      investments: tab === 'investments' ? true : false,
      referrals: tab === 'referrals' ? true : false,
      funds: tab === 'funds' ? true : false,
      autoInvestmentRules: tab === 'auto' ? true : false,
      settings: tab === 'settings' ? true : false
    });
  };

  const profileStatus = useCallback(
    (id) => {
      switch (id) {
        case 3:
          return 'Pending';
        case 4:
          return 'Approved';
        case 5:
          return 'Rejected';
        case 6:
          return 'Deleted';
        default:
          return 'Incomplete';
      }
    },
    [merchant, merchant.status]
  );

  //   const onReject = ({ reason, type }) => {
  //     const params = {
  //       status: String(5),
  //       remarks: reason,
  //       reject_type: type,
  //       user_id: id
  //     };

  //     API.updateInvestorStatus(params).then(
  //       () => {
  //         API.investorView(id).then((res) => {
  //           setInvestor(res.data.data);
  //         });
  //       },
  //       (err) => {
  //         console.log(err.response);
  //       }
  //     );
  //   };

  //   const onApprove = (reason) => {
  //     const params = {
  //       status: String(4),
  //       remarks: '',
  //       user_id: id
  //     };
  //     API.updateInvestorStatus(params).then(() => {
  //       API.investorView(id).then((res) => {
  //         setInvestor(res.data.data);
  //       });
  //     });
  //   };

  //   const onRejectBank = (bankId, reason) => {
  //     const params = {
  //       status: String(3),
  //       remarks: reason
  //     };

  //     API.updateInvestorBankStatus(bankId, params).then(() => {
  //       API.getInvestorBanks(id).then((res) => {
  //         setBanks(res.data.data);
  //       });
  //     });
  //   };

  //   const onApproveBank = (bankId) => {
  //     const params = {
  //       status: String(2),
  //       remarks: ''
  //     };
  //     API.updateInvestorBankStatus(bankId, params).then(() => {
  //       API.getInvestorBanks(id).then((res) => {
  //         setBanks(res.data.data);
  //       });
  //     });
  //   };

  const onViewContract = async () => {
    var token = localStorage.getItem('access_token');

    var win = window.open('_blank');

    downloadFile(merchant.agreement, function (blob) {
      var url = URL.createObjectURL(blob);
      win.location = url;
    });

    function downloadFile(url, success) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (success) success(xhr.response);
        } else {
        }
      };
      xhr.send(null);
    }
  };

  //   const {
  //     register,
  //     control,
  //     formState: { errors },
  //     handleSubmit,
  //     setError
  //   } = useForm({
  //     resolver: yupResolver(schemaChangePassword)
  //   });

  //   const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

  //   const toggleChangePasswordForm = () => {
  //     setShowChangePasswordForm(!showChangePasswordForm);
  //   };
  //   const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  //   const onChangePassword = (data) => {
  //     setLoadingChangePassword(true);

  //     API.changeUserPassword(id, data)
  //       .then((res) => {
  //         setLoadingChangePassword(false);
  //         Notification.success('Change Password', 'Successfully change password');
  //       })
  //       .catch((err) => {
  //         setLoadingChangePassword(false);
  //         if (err.response.status === 422) {
  //           for (const [key, value] of Object.entries(err.response.data.errors)) {
  //             setError(key, {
  //               type: 'manual',
  //               message: value
  //             });
  //           }
  //         } else {
  //           Notification.error(err);
  //         }
  //       });
  //   };

  //   const toggleOTPRequired = (value) => {
  //     setInvestor({ ...investor, is_required_otp: value.target.checked });

  //     const params = {
  //       is_required_otp: value.target.checked ? 1 : 0
  //     };

  //     API.changeSecurity(id, params)
  //       .then((res) => {
  //         Notification.success(
  //           'Security OTP',
  //           `Security OTP Successfully ${value.target.checked ? 'enabled' : 'disabled'}`
  //         );
  //       })
  //       .catch((err) => {
  //         setInvestor({ ...investor, auto_invest_enable: !value.target.checked });
  //         Notification.error(err);
  //       });
  //   };

  //   const [otpDestination, setOTPDestination] = useState({ value: 1, label: 'SMS' });

  //   useEffect(() => {
  //     merchant &&
  //       setOTPDestination(OPTION_OTP_DESINATION.find((c) => c.value === investor?.otp_platform_id));
  //   }, [investor]);

  //   const onChangeOTPDestination = (value) => {
  //     setOTPDestination(value);
  //     setInvestor({ ...investor, otp_platform_id: value.value });

  //     const params = {
  //       otp_platform_id: value.value
  //     };
  //     API.changeSecurity(id, params)
  //       .then((res) => {
  //         Notification.success(
  //           'Security OTP Destination',
  //           `Security OTP Destination Successfully switch to ${value.label}`
  //         );
  //       })
  //       .catch((err) => {
  //         setInvestor({ ...investor, otp_platform_id: merchant.otp_platform_id });
  //         Notification.error(err);
  //       });
  //   };

  //   const toggleBiometric = (value) => {
  //     setInvestor({ ...investor, bio_enable: value.target.checked });

  //     const params = {
  //       bio_enable: value.target.checked ? 1 : 0
  //     };
  //     API.changeSecurity(id, params)
  //       .then((res) => {
  //         Notification.success(
  //           'Security Biometric',
  //           `Security Biometric Successfully ${value.target.checked ? 'enabled' : 'disabled'}`
  //         );
  //       })
  //       .catch((err) => {
  //         setInvestor({ ...investor, bio_enable: !value.target.checked });
  //         Notification.error(err);
  //       });
  //   };

  return (
    <div>
      <div className="profile">
        <div className="profile-header">
          <div className="profile-header-cover"></div>
          <div className="profile-header-content">
            <div className="profile-header-img">
              <img
                src={merchant && merchant?.logo && merchant.logo?.view}
                alt=""
                style={{ objectFit: 'cover', width: '100%' }}
              />
            </div>
            <div className="profile-header-info">
              <h4 className="m-t-10 m-b-5">{merchant.trade_name}</h4>
              {/* <p className="m-b-10">UXUI + Frontend Developer</p> */}
              {/* <div style={{ width: '300px', marginBottom: '10px' }}>
                <div className="progress h-10px rounded-pill mb-5px">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-orange fs-10px fw-bold"
                    style={{
                      width: `${merchant && merchant.progress_percentage}%`
                    }}
                  >
                    Progress ({merchant && merchant.progress_percentage}
                    %)
                  </div>
                </div>
              </div> */}
              {merchant && merchant.status && merchant.status.id == 3 && (
                <>
                  <ButtonApprove onConfirm={onApprove} />
                  {/* <ButtonReject onConfirm={onReject} /> */}
                </>
              )}
              <a onClick={onViewContract} className="btn btn-xs btn-danger m-l-5">
                Preview Aggreement
              </a>
              <ButtonSendMessage merchant={merchant} />
            </div>
          </div>
          <ul className="profile-header-tab nav nav-tabs">
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'summary')}
                className={'nav-link ' + (tabs.summary ? 'active ' : '')}
              >
                SUMMARY
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'profile')}
                className={'nav-link ' + (tabs.profile ? 'active ' : '')}
              >
                PROFILE
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'transactions')}
                className={'nav-link ' + (tabs.transactions ? 'active ' : '')}
              >
                PERKS
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'settings')}
                className={'nav-link ' + (tabs.settings ? 'active ' : '')}
              >
                SETTINGS
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="profile-content">
        <div className="tab-content p-0">
          <div className={'tab-pane fade ' + (tabs.summary ? 'show active ' : '')}>
            <div className="table-responsive form-inline">
              <table className="table table-profile">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <h4>{merchant && merchant.business_name}</h4>
                      <p style={{ width: '40%' }}>{merchant.overview}</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="field">Country(s):</td>
                    <td>
                      {merchant &&
                        merchant.countries &&
                        JSON.parse(merchant?.countries).map((country) => (
                          <span key={country.value}>
                            {country.label}
                            <br />
                          </span>
                        ))}
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Category(s):</td>
                    <td>
                      {merchant &&
                        merchant.categories &&
                        JSON.parse(merchant?.categories).map((category) => (
                          <span key={category.value}>
                            {category.label}
                            <br />
                          </span>
                        ))}
                    </td>
                  </tr>

                  <tr>
                    <td className="field">Email:</td>
                    <td>
                      <Link to="/extra/profile" className="">
                        {merchant && merchant.email} <i className="fa fa-envelope fa-lg m-l-5" />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Mobile:</td>
                    <td>
                      <i className="fa fa-mobile fa-lg m-r-5"></i>{' '}
                      {merchant && `${merchant.mobile_code}${merchant.mobile_number}`}
                      <Link to="/extra/profile" className="m-l-5">
                        <i className="fa fa-paper-plane fa-lg" />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Landline:</td>
                    <td>
                      <i className="fa fa-phone fa-lg m-r-5"></i>{' '}
                      {(merchant && `${merchant.landline_number}`) || 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Address:</td>
                    <td>{(merchant && `${merchant.business_address}`) || 'N/A'}</td>
                  </tr>
                  <tr className="">
                    <td colSpan="2">
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Registered Date:</td>
                    <td>
                      {merchant &&
                        moment(merchant.created_at).utc(true).format('YYYY-MM-DD hh:mm A')}
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Last Update:</td>
                    <td>
                      {merchant &&
                        moment(merchant.updated_at).utc(true).format('YYYY-MM-DD hh:mm A')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={'tab-pane fade ' + (tabs.profile ? 'show active ' : '')}>
            <div className="col-lg-10 offset-lg-1">
              <Suspense fallback={<LoadingSpinner />}>
                <FormInformationEdit formData={merchant} />
              </Suspense>
            </div>
          </div>

          <div className={'tab-pane fade ' + (tabs.transactions ? 'show active ' : '')}>
            <Suspense fallback={<LoadingSpinner />}>
              <MerchantPerksList />
            </Suspense>
          </div>
          {/* <div className={'tab-pane fade ' + (tabs.settings ? 'show active ' : '')}>
            <div className="col-lg-8 offset-lg-2">
              <h3>Security</h3>
              <div className="table-responsive form-inline">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="field">Web OTP Enabled:</td>
                      <td>
                        <div className="switcher">
                          <input type="checkbox" name="wed" id="web" />
                          <label htmlFor="web"></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="field">Mobile OTP Enabled:</td>
                      <td className="float-right">
                        <Switchery
                          checked={merchant.is_required_otp}
                          onChange={toggleOTPRequired}
                          for="is_required_otp"
                        />
                      </td>
                    </tr>
                    {merchant.is_required_otp && (
                      <tr>
                        <td className="field">OTP Destination:</td>
                        <td>
                          <ReactSelect
                            className="text-black"
                            value={otpDestination}
                            onChange={onChangeOTPDestination}
                            options={OPTION_OTP_DESINATION}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td className="field">Biometrics Enabled:</td>
                      <td className="float-right">
                        <Switchery
                          checked={merchant.bio_enable == 1}
                          onChange={toggleBiometric}
                          for="bio_enabled"
                        />
                      </td>
                    </tr>
                    <tr className="highlight">
                      <td className="field">Password</td>
                      <td className="p-t-10 p-b-10">
                        {showChangePasswordForm ? (
                          <Form onSubmit={handleSubmit(onChangePassword)} autoComplete="off">
                            <FormGroup row className="mb-2">
                              <Label>New Password</Label>
                              <Col>
                                <Controller
                                  name="password"
                                  control={control}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      className="float-right"
                                      autoComplete="false"
                                      type="password"
                                      invalid={!isEmpty(errors.password)}
                                    />
                                  )}
                                />
                                {errors.password && (
                                  <FormFeedback>{errors.password?.message}</FormFeedback>
                                )}
                              </Col>
                            </FormGroup>
                            <FormGroup row className="mb-2">
                              <Label>Confirm Password</Label>
                              <Col>
                                <Controller
                                  name="password_confirmation"
                                  control={control}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      className="float-right"
                                      autoComplete="false"
                                      type="password"
                                      invalid={!isEmpty(errors.password)}
                                    />
                                  )}
                                />
                                {errors.password && (
                                  <FormFeedback>{errors.password?.message}</FormFeedback>
                                )}
                              </Col>
                            </FormGroup>
                            <Row>
                              <Col>
                                <Button
                                  type="submit"
                                  isloading={loadingChangePassword}
                                  className={`btn float-right ${
                                    loadingChangePassword ? 'btn-disabled btn-gray' : 'btn-primary'
                                  }`}
                                  disabled={loadingChangePassword}
                                  title="Submit"
                                />
                              </Col>
                            </Row>
                          </Form>
                        ) : (
                          <Button
                            className={`btn float-right btn-primary`}
                            onClick={toggleChangePasswordForm}
                            disabled={loadingChangePassword}
                            title="Reset Password"
                          />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(PartnersView);

const ButtonReject = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = (type) => () => {
    setModalReject(false);
    toggleModalReject().then(() => {
      props.onConfirm({ reason, type });
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-xs btn-red m-l-5" type="button" onClick={toggleModalReject}>
        Reject
      </button>

      <SweetAlert
        title={<span className="text-black text-wrap">Reject Investor Application</span>}
        danger
        showCancel
        // confirmBtnText="Reject"
        // confirmBtnBsStyle="danger"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        customButtons={
          <>
            <button className="btn btn-red m-l-5 m-b-5" type="button" onClick={onReject(2)}>
              Unsuitable Customer
            </button>
            <button className="btn btn-red m-l-5 m-b-5" type="button" onClick={onReject(3)}>
              Insufficient Profile Info
            </button>
            <button
              className="btn btn-outline-danger m-l-5 m-b-5"
              type="button"
              onClick={toggleModalReject}
            >
              Cancel
            </button>
          </>
        }
        // onCancel={toggleModalReject}
        show={modalReject}
        dependencies={[reason]}
      >
        <form>
          <textarea
            type="text"
            className="form-control"
            value={reason}
            onChange={onChangeText}
            placeholder="Please indicate the reason for rejecting investor application"
          />
        </form>
      </SweetAlert>
    </>
  );
};

const ButtonApprove = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    setModalReject(false);
    toggleModalReject().then(() => {
      props.onConfirm(reason);
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-xs btn-green m-l-5" type="button" onClick={toggleModalReject}>
        Approve
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Approve Investor Application</span>}
        success
        showCancel
        confirmBtnText="Approve"
        confirmBtnBsStyle="success"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <p style={{ color: 'black' }}>
          Are you sure you want to approve this investor application?
        </p>
      </SweetAlert>
    </>
  );
};

const schemaAdjustFunds = yup.object().shape({
  amount: yup.string(),
  description: yup.string().required()
});

const ButtonAddFund = (props) => {
  const { id } = useParams();

  const [modalReject, setModalReject] = useState(false);
  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onSubmit = (data) => {
    API.userAddFunds(id, data)
      .then((res) => {
        Notification.success('Add Funds', 'Funds successfully adjusted');
        toggleModalReject();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const {
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    resolver: yupResolver(schemaAdjustFunds),
    defaultValues: {
      amount: '',
      description: ''
    }
  });

  return (
    <>
      <button
        className="btn btn-md btn-primary m-l-5 no-border"
        type="button"
        onClick={toggleModalReject}
      >
        <i className="fa fa-plus mr-1"></i>&nbsp;Add Funds
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Fund Adjustment : Add</span>}
        customIcon={<i className="fa fa-plus fa-5x text-primary"></i>}
        custom
        showCancel
        confirmBtnText="Confirm Add Funds"
        confirmBtnBsStyle="primary"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <Form>
          <FormGroup row className="mt-2">
            <Col md="12">
              <Controller
                name="amount"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CurrencyInput
                    value={value}
                    prefix="₱"
                    placeholder="Amount"
                    decimalsLimit={2}
                    onValueChange={(value, name) => onChange(value)}
                    className="form-control"
                  />
                )}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="12">
              <Controller
                name="description"
                isClearable
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="textarea"
                    placeholder="Description"
                    invalid={!isEmpty(errors.description)}
                  />
                )}
              />
            </Col>
          </FormGroup>
        </Form>
      </SweetAlert>
    </>
  );
};

const ButtonDeductFund = (props) => {
  const { id } = useParams();

  const [modalReject, setModalReject] = useState(false);
  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onSubmit = (data) => {
    const params = new URLSearchParams();
    params.append('amount', data.amount);
    params.append('description', data.description);
    API.userDeductFunds(id, params)
      .then((res) => {
        Notification.success('Deduct Funds', 'Funds successfully adjusted');
        toggleModalReject();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const {
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    resolver: yupResolver(schemaAdjustFunds),
    defaultValues: {
      amount: '',
      description: ''
    }
  });

  return (
    <>
      <button
        className="btn btn-md btn-green m-l-5 no-border text-white"
        type="button"
        onClick={toggleModalReject}
      >
        <i className="fa fa-minus text-white mr-1"></i>&nbsp;Deduct Funds
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Fund Adjustment : Deduct</span>}
        customIcon={<i className="fa fa-minus fa-5x text-green"></i>}
        custom
        showCancel
        confirmBtnText="Confirm Deduct Funds"
        confirmBtnBsStyle="green"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <Form>
          <FormGroup row className="mt-2">
            <Col md="12">
              <Controller
                name="amount"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CurrencyInput
                    value={value}
                    prefix="₱"
                    placeholder="Amount"
                    decimalsLimit={2}
                    onValueChange={(value, name) => onChange(value)}
                    className="form-control"
                  />
                )}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="12">
              <Controller
                name="description"
                isClearable
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="textarea"
                    placeholder="Description"
                    invalid={!isEmpty(errors.description)}
                  />
                )}
              />
            </Col>
          </FormGroup>
        </Form>
      </SweetAlert>
    </>
  );
};

const schemaSendMessage = yup.object().shape({
  type: yup.string(),
  message: yup.string().required('Message is required'),
  subject: yup.string().when('type', {
    is: (optionType) => optionType == 2,
    then: yup.string().required('Subject is required')
  })
});

const ButtonSendMessage = (props) => {
  const { merchant } = props;
  const [modalReject, setModalReject] = useState(false);
  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const optionType = [
    { value: 1, label: 'SMS' },
    { value: 2, label: 'Email' }
  ];

  const onSubmit = (data) => {
    const recipient =
      data.type == 2
        ? { email_address: [merchant.email] }
        : { mobile_number: [merchant.mobile_number] };

    const params = {
      ...data,
      ...recipient
    };
    API.sendMessage(params)
      .then((res) => {
        Notification.success(
          `Send ${data.type == 1 ? 'SMS' : 'Email'}`,
          `${data.type == 1 ? 'SMS' : 'Email'} successfully sent`
        );
        toggleModalReject();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch
  } = useForm({
    resolver: yupResolver(schemaSendMessage),
    defaultValues: {
      type: 1,
      subject: '',
      message: ''
    }
  });
  const selectedType = watch('type', 1);

  return (
    <>
      <button
        className="btn btn-xs btn-primary m-l-5 no-border text-white"
        type="button"
        onClick={toggleModalReject}
      >
        Send Message
      </button>
      <SweetAlert
        title={
          <span className="text-black text-wrap">
            Send Message to: <br /> {merchant.full_name}
          </span>
        }
        customIcon={<i className="fa fa-paper-plane fa-4x text-primary"></i>}
        custom
        showCancel
        confirmBtnText="Send Message"
        confirmBtnBsStyle="primary"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <Form>
          <FormGroup row>
            <Col md="12">
              <Controller
                name="type"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <ReactSelect
                    inputRef={ref}
                    className="text-black"
                    value={optionType.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                    options={optionType}
                    isOptionDisabled={(option) => option.disabled}
                  />
                )}
              />
            </Col>
          </FormGroup>
          {selectedType == 2 && (
            <FormGroup row>
              <Col md="12">
                <Controller
                  name="subject"
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input {...field} placeholder="Subject" invalid={!isEmpty(errors.subject)} />
                  )}
                />
                {errors.subject && (
                  <FormFeedback style={{ display: 'block' }}>
                    {errors.subject?.message}
                  </FormFeedback>
                )}
              </Col>
            </FormGroup>
          )}

          <FormGroup row>
            <Col md="12">
              <Controller
                name="message"
                isClearable
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    rows="5"
                    type="textarea"
                    placeholder="Enter Message..."
                    invalid={!isEmpty(errors.message)}
                  />
                )}
              />
              {errors.message && (
                <FormFeedback style={{ display: 'block' }}>{errors.message?.message}</FormFeedback>
              )}
            </Col>
          </FormGroup>
        </Form>
      </SweetAlert>
    </>
  );
};

const AddRemarks = (props) => {
  const { merchant } = props;

  const [showAddRemarksForm, setShowAddRemarksForm] = useState(false);

  const toggleAddRemarksForm = () => {
    setShowAddRemarksForm(!showAddRemarksForm);
  };
  const [loadingAddRemarks, setLoadingAddRemarks] = useState(false);

  const onSubmitRemarks = (data) => {
    setLoadingAddRemarks(true);

    API.patchInvestor(merchant.id, data)
      .then((res) => {
        setLoadingAddRemarks(false);
        Notification.success('Update Remarks', 'Successfully update remarks');
      })
      .catch((err) => {
        setLoadingAddRemarks(false);
        if (err.response.status === 422) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            setError(key, {
              type: 'manual',
              message: value
            });
          }
        } else {
          Notification.error(err);
        }
      });
  };

  const onRemoveRemarks = () => {
    setLoadingAddRemarks(true);
    API.patchInvestor(merchant.id, { remarks: null })
      .then((res) => {
        setLoadingAddRemarks(false);
        Notification.success('Update Remarks', 'Successfully remove remarks');
      })
      .catch((err) => {
        setLoadingAddRemarks(false);
        if (err.response.status === 422) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            setError(key, {
              type: 'manual',
              message: value
            });
          }
        } else {
          Notification.error(err);
        }
      });
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch
  } = useForm({
    resolver: yupResolver(schemaRemarks),
    defaultValues: {
      remarks: ''
    }
  });

  return (
    <tr>
      <td className="field">Remarks:</td>
      <td className="float-left">
        {showAddRemarksForm ? (
          <Form onSubmit={handleSubmit(onSubmitRemarks)} autoComplete="off">
            <FormGroup row className="mb-2">
              <Col>
                <Controller
                  name="remarks"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="float-right"
                      autoComplete="false"
                      type="textarea"
                      invalid={!isEmpty(errors.remarks)}
                    />
                  )}
                />
              </Col>
            </FormGroup>
            <Row>
              <Col>
                <Button
                  type="submit"
                  isloading={loadingAddRemarks}
                  className={`btn float-right ${
                    loadingAddRemarks ? 'btn-disabled btn-gray' : 'btn-primary'
                  }`}
                  disabled={loadingAddRemarks}
                  title="Submit"
                />
              </Col>
            </Row>
          </Form>
        ) : isNull(merchant.remarks) ? (
          <Button
            className={`btn float-right ${
              loadingAddRemarks ? 'btn-disabled btn-gray' : 'btn-primary'
            }`}
            onClick={toggleAddRemarksForm}
            disabled={loadingAddRemarks}
            isloading={loadingAddRemarks}
            title="Add Remarks"
          />
        ) : (
          <>
            <p>{merchant.remarks}</p>
            <Button
              className={`btn float-right ${
                loadingAddRemarks ? 'btn-disabled btn-gray' : 'btn-danger'
              }`}
              onClick={onRemoveRemarks}
              disabled={loadingAddRemarks}
              isloading={loadingAddRemarks}
              title="Remove Remarks"
            />
          </>
        )}
      </td>
    </tr>
  );
};
