import React, { memo } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import Chart from 'react-apexcharts';

const TotalLogin = (props) => {
  const convertNumberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const storeSessionChart = {
    options: {
      chart: {
        type: 'line',
        width: 160,
        height: 28,
        sparkline: {
          enabled: true
        },
        stacked: false
      },
      stroke: {
        curve: 'smooth',
        width: 3
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [
            {
              offset: 0,
              color: '#00acac',
              opacity: 1
            },
            {
              offset: 50,
              color: '#348fe2',
              opacity: 1
            },
            {
              offset: 100,
              color: '#5AC8FA',
              opacity: 1
            }
          ]
        }
      },
      labels: ['Jun 23', 'Jun 24', 'Jun 25', 'Jun 26', 'Jun 27', 'Jun 28', 'Jun 29'],
      xaxis: {
        crosshairs: {
          width: 1
        }
      },
      tooltip: {
        theme: 'dark',
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return '';
            }
          },
          formatter: (value) => {
            return convertNumberWithCommas(value);
          }
        },
        marker: {
          show: false
        }
      },
      responsive: [
        {
          breakpoint: 1500,
          options: {
            chart: {
              width: 120
            }
          }
        },
        {
          breakpoint: 1300,
          options: {
            chart: {
              width: 100
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 160
            }
          }
        },
        {
          breakpoint: 900,
          options: {
            chart: {
              width: 120
            }
          }
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              width: 180
            }
          }
        },
        {
          breakpoint: 400,
          options: {
            chart: {
              width: 120
            }
          }
        }
      ]
    },
    series: [
      {
        data: [10812, 11393, 7311, 6834, 9612, 11200, 13557]
      }
    ]
  };

  return (
    <div className="card border-0 text-white bg-blue-dark mb-3 overflow-hidden">
      <div className="card-body" style={{ backgroundColor: 'gray' }}>
        <div className="mb-3 text-grey">
          <b className="mb-3">TOTAL SESSIONS</b>
          <span className="ml-2">
            <i className="fa fa-info-circle" id="popover3"></i>
            <UncontrolledPopover trigger="hover" placement="top" target="popover3">
              <PopoverHeader>Store Sessions</PopoverHeader>
              <PopoverBody>
                Number of sessions on your online store. A session is a period of continuous
                activity from a visitor.
              </PopoverBody>
            </UncontrolledPopover>
          </span>
        </div>
        <div className="d-flex align-items-center mb-1">
          <h2 className="text-white mb-2">N/A</h2>
          {/* <div className="ml-auto">
            <Chart
              type="line"
              height={'28px'}
              options={storeSessionChart.options}
              series={storeSessionChart.series}
            />
          </div> */}
        </div>
        {/* <div className="mb-4 text-grey">
          <i className="fa fa-caret-up"></i> 9.5% compare to last week
        </div> */}
        <div className="d-flex mb-2">
          <div className="d-flex align-items-center">
            <i className="fa fa-circle text-teal f-s-8 mr-2"></i>
            Mobile
          </div>
          <div className="d-flex align-items-center ml-auto">
            {/* <div className="text-grey f-s-11">
              <i className="fa fa-caret-up"></i> 25.7%
            </div> */}
            <div className="width-50 text-right pl-2 f-w-600">N/A</div>
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="d-flex align-items-center">
            <i className="fa fa-circle text-blue f-s-8 mr-2"></i>
            Desktop
          </div>
          <div className="d-flex align-items-center ml-auto">
            {/* <div className="text-grey f-s-11">
              <i className="fa fa-caret-up"></i> 16.0%
            </div> */}
            <div className="width-50 text-right pl-2 f-w-600">N/A</div>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <i className="fa fa-circle text-aqua f-s-8 mr-2"></i>
            Tablet
          </div>
          <div className="d-flex align-items-center ml-auto">
            {/* <div className="text-grey f-s-11">
              <i className="fa fa-caret-up"></i> 7.9%
            </div> */}
            <div className="width-50 text-right pl-2 f-w-600">N/A</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TotalLogin);
