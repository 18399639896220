// import React, { memo, useEffect, useState } from 'react';
// import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
// import { useLocation, useHistory, useParams } from 'react-router';
// import { Row, Col, Label, Form, FormGroup, Input, Media, Button } from 'reactstrap';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import { PriceFormat } from '../../utils/format.js';
// // import * as API from '../../../services/api';
// import { UnControlled as CodeMirror } from 'react-codemirror2';
// import { Notification } from '../../utils/index.js';
// import * as API from '../../services/api';
// import moment from 'moment';
// import LoadingSpinner from '../../components/common/LoadingSpinner.js';
// require('codemirror/mode/xml/xml.js');
// require('codemirror/mode/javascript/javascript.js');
// import SweetAlert from 'react-bootstrap-sweetalert';

// const ViewDetailTopup = (props) => {
//   const { id } = useParams();
//   const location = useLocation();

//   const { state } = props.location;
//   const [loadingTopUp, setLoadingTopUp] = useState(true);
//   const [topup, setTopUp] = useState(state);

//   useEffect(() => {
//     API.requestTopUp(id)
//       .then((res) => {
//         setTopUp(res.data.data);
//         setLoadingTopUp(false);
//       })
//       .catch((err) => {
//         setTopUp(state);
//         setLoadingTopUp(false);
//       });
//   }, []);

//   return (
//     <div>
//       <h1 className="page-header">Top Up Detail</h1>
//       <div className="row">
//         <div className="col-xl-12">
//           <Panel>
//             <PanelHeader>Details</PanelHeader>
//             <PanelBody>
//               {loadingTopUp ? (
//                 <LoadingSpinner />
//               ) : (
//                 <>
//                   <FormGroup tag="fieldset">
//                     <Row>
//                       <Col>
//                         <FormGroup row>
//                           <Col md="3">Status</Col>
//                           <Col md="9">Pending</Col>
//                         </FormGroup>
//                         <FormGroup row>
//                           <Col md="3">Account Name</Col>
//                           <Col md="9">Micah Dorene Bulatao</Col>
//                         </FormGroup>
//                         <FormGroup row>
//                           <Col md="3">Email</Col>
//                           <Col md="9">micah.bulatao@gmail.com</Col>
//                         </FormGroup>
//                         <FormGroup row>
//                           <Col md="3">Contact Number</Col>
//                           <Col md="9"></Col>
//                         </FormGroup>
//                         <FormGroup row>
//                           <Col md="3">Payment Type</Col>
//                           <Col md="9">Bank Transfer</Col>
//                         </FormGroup>
//                         <FormGroup row>
//                           <Col md="3">Amount</Col>
//                           <Col md="9">₱119.00</Col>
//                         </FormGroup>
//                         <FormGroup row>
//                           <Col md="3">Remarks</Col>
//                           <Col md="9">Lorem Ipsum</Col>
//                         </FormGroup>
//                         <FormGroup row>
//                           <Col md="3">Attachment</Col>
//                           <Col md="9">Download</Col>
//                         </FormGroup>
//                       </Col>
//                       <Col>
//                         <FormGroup row>
//                           <Col md="12">
//                             <h5>
//                               <strong>Top Up Details</strong>
//                             </h5>
//                           </Col>
//                         </FormGroup>
//                         {Object.entries(withdrawDetail).map(([key, value]) => (
//                           <FormGroup row>
//                             <Col md="3">{key}</Col>
//                             <Col md="9">{value}</Col>
//                           </FormGroup>
//                         ))}
//                       </Col>
//                       {/* <Col>
//                         <FormGroup row>
//                           <Col md="12">
//                             <h5>
//                               <strong>Additional Information</strong>
//                             </h5>
//                           </Col>
//                         </FormGroup>
//                         <FormGroup row>
//                           <Col md="3">Description/My Initial</Col>
//                           <Col md="9">119</Col>
//                         </FormGroup>
//                         <FormGroup row>
//                           <Col md="12">
//                             <h5>
//                               <strong>Bank Details</strong>
//                             </h5>
//                           </Col>
//                         </FormGroup>
//                         <FormGroup row>
//                           <Col md="3">Account Name</Col>
//                           <Col md="9">Micah Dorene A. Bulatao</Col>
//                         </FormGroup>
//                         <FormGroup row>
//                           <Col md="3">Bank Name</Col>
//                           <Col md="9">BDO</Col>
//                         </FormGroup>
//                         <FormGroup row>
//                           <Col md="3">Account Number</Col>
//                           <Col md="9">432934202342</Col>
//                         </FormGroup>
//                         <FormGroup row>
//                           <Col md="3">Swift Code</Col>
//                           <Col md="9">2335</Col>
//                         </FormGroup>
//                       </Col> */}
//                     </Row>
//                   </FormGroup>
//                   <Row>
//                     <Col className="d-flex justify-content-end">
//                       <ButtonReject />
//                       <ButtonApprove />
//                     </Col>
//                   </Row>
//                 </>
//               )}
//             </PanelBody>
//           </Panel>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default memo(ViewDetailTopup);

// const ButtonReject = (props) => {
//   const [modalReject, setModalReject] = useState(false);

//   const toggleModalReject = async () => {
//     await setModalReject(!modalReject);
//   };

//   const onReject = () => {
//     setModalReject(false);
//     toggleModalReject().then(() => {
//       props.onConfirm(reason);
//     });
//   };

//   const [reason, setReason] = useState('');

//   const optionReasons = [
//     { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
//     { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
//     { value: 'Others', label: 'Others' }
//   ];

//   const onChangeText = (e) => {
//     setReason(e.target.value);
//   };

//   const onChangeSelect = (e) => {
//     setReason(e.value);
//   };

//   return (
//     <>
//       <button className="btn btn-danger mr-2" type="button" onClick={toggleModalReject}>
//         Reject
//       </button>
//       <SweetAlert
//         title={<span className="text-black text-wrap">Top Up Request</span>}
//         danger
//         showCancel
//         confirmBtnText="Reject"
//         confirmBtnBsStyle="danger"
//         focusCancelBtn={false}
//         focusConfirmBtn={false}
//         onConfirm={onReject}
//         onCancel={toggleModalReject}
//         show={modalReject}
//         dependencies={[reason]}
//       >
//         <form>
//           <textarea
//             type="text"
//             className="form-control"
//             value={reason}
//             onChange={onChangeText}
//             placeholder="Please indicate the reason for rejecting top up request"
//           />
//         </form>
//       </SweetAlert>
//     </>
//   );
// };

// const ButtonApprove = (props) => {
//   const [modalReject, setModalReject] = useState(false);

//   const toggleModalReject = async () => {
//     await setModalReject(!modalReject);
//   };

//   const onReject = () => {
//     setModalReject(false);
//     toggleModalReject().then(() => {
//       props.onConfirm(reason);
//     });
//   };

//   const [reason, setReason] = useState('');

//   const optionReasons = [
//     { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
//     { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
//     { value: 'Others', label: 'Others' }
//   ];

//   const onChangeText = (e) => {
//     setReason(e.target.value);
//   };

//   const onChangeSelect = (e) => {
//     setReason(e.value);
//   };

//   return (
//     <>
//       <button className="btn btn-green text-white" type="button" onClick={toggleModalReject}>
//         Approve
//       </button>
//       <SweetAlert
//         title={<span className="text-black text-wrap">Top Up Request</span>}
//         success
//         showCancel
//         confirmBtnText="Approve"
//         confirmBtnBsStyle="success"
//         focusCancelBtn={false}
//         focusConfirmBtn={false}
//         onConfirm={onReject}
//         onCancel={toggleModalReject}
//         show={modalReject}
//       >
//         <p style={{ color: 'black' }}>Are you sure you want to approve this top up request?</p>
//       </SweetAlert>
//     </>
//   );
// };

import React, { memo, useEffect, useMemo, useState } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useLocation, useHistory, useParams } from 'react-router';
import { Row, Col, Label, Form, FormGroup, Input, Media, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PriceFormat } from '../../../utils/format.js';
// import * as API from '../../../services/api';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { Notification } from '../../../utils/index.js';
import * as API from '../../../services/api';
import moment from 'moment';
import LoadingSpinner from '../../../components/common/LoadingSpinner.js';
require('codemirror/mode/xml/xml.js');
require('codemirror/mode/javascript/javascript.js');
import SweetAlert from 'react-bootstrap-sweetalert';

const ViewDetailTopup = (props) => {
  const { id } = useParams();
  const location = useLocation();

  const { state } = props.location;
  const [loadingTopUp, setLoadingTopUp] = useState(true);
  const [topup, setTopup] = useState(state);

  useEffect(() => {
    API.viewFundRequest(id)
      .then((res) => {
        setTopup(res.data.data);
        setLoadingTopUp(false);
      })
      .catch((err) => {
        setTopup(state);
        setLoadingTopUp(false);
      });
  }, []);

  const withdrawDetail = useMemo(() => {
    var withdrawalDetail = topup && JSON.parse(topup?.additional_data);

    // return withdrawalDetail.map((wd) => (
    //   <FormGroup row>
    //     <Col md="3">Withdrawal Type</Col>
    //     <Col md="9">{withdrawal.payment_label}</Col>
    //   </FormGroup>
    // ));

    return withdrawalDetail;
  }, [topup, loadingTopUp]);
  // console.log(withdrawDetail);

  const statusLabel = (status) => {
    switch (status) {
      case 1:
        return 'Approved';
      case 2:
        return 'Rejected';
      default:
        return 'Pending';
    }
  };

  return (
    <div>
      <h1 className="page-header">Top Up Detail</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader>Details</PanelHeader>
            <PanelBody>
              {loadingTopUp ? (
                <LoadingSpinner />
              ) : (
                <FormGroup tag="fieldset">
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Col md="3">
                          <strong>Amount</strong>
                        </Col>
                        <Col md="9">
                          <strong>{PriceFormat(topup.amount)}</strong>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">Type</Col>
                        <Col md="9">{topup.payment_label}</Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">Status</Col>
                        <Col md="9">{topup && statusLabel(topup.status)}</Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">Account Name</Col>
                        <Col md="9">{topup.user_full_name}</Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">Date Requested</Col>
                        <Col md="9">{moment(topup.created_at).format('MMMM DD, YYYY')}</Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">Attachment</Col>
                        <Col md="9">
                          <Media>
                            <Media object src={topup.attachment?.view} alt="attachment" />
                          </Media>
                        </Col>
                      </FormGroup>
                      {/* <FormGroup row>
                      <Col md="3">Contact Number</Col>
                      <Col md="9">+639164289004</Col>
                    </FormGroup> */}
                    </Col>
                    <Col>
                      <FormGroup row>
                        <Col md="12">
                          <h5>
                            <strong>Top Account Details</strong>
                          </h5>
                        </Col>
                      </FormGroup>
                      {Object.entries(withdrawDetail).map(([key, value]) => (
                        <FormGroup row>
                          {key == 'Payment Type' ? (
                            <>
                              <Col md="3">{key}</Col>
                              <Col md="9">{topup.payment_label}</Col>
                            </>
                          ) : (
                            <>
                              <Col md="3">{key}</Col>
                              <Col md="9">{value}</Col>
                            </>
                          )}
                        </FormGroup>
                      ))}
                      <FormGroup row>
                        <Col md="3">Date Transferred</Col>
                        <Col md="9">{moment(topup.date_transferred).format('MMMM DD, YYYY')}</Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              )}
              {topup?.status == 0 && (
                <Row>
                  <Col className="d-flex justify-content-end">
                    <ButtonReject />
                    <ButtonApprove />
                  </Col>
                </Row>
              )}
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default memo(ViewDetailTopup);

const ButtonReject = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    setModalReject(false);
    toggleModalReject().then(() => {
      API.requestTopUpReject(id, { reason })
        .then((res) => {
          history.goBack();
          Notification.success('Top Up Request Reject', 'Top up request successfully rejected!');
        })
        .catch((err) => {
          Notification.error(err);
        });
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-danger mr-2" type="button" onClick={toggleModalReject}>
        Reject
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Top Up Request</span>}
        danger
        showCancel
        confirmBtnText="Reject"
        confirmBtnBsStyle="danger"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
        dependencies={[reason]}
      >
        <form>
          <textarea
            type="text"
            className="form-control"
            value={reason}
            onChange={onChangeText}
            placeholder="Please indicate the reason for rejecting top up request"
          />
        </form>
      </SweetAlert>
    </>
  );
};

const ButtonApprove = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    setModalReject(false);
    toggleModalReject().then(() => {
      API.requestTopUpApprove(id)
        .then((res) => {
          Notification.success('Top Up Request Approve', 'Top up request successfully approved!');
          history.goBack();
        })
        .catch((err) => {
          Notification.error(err);
        });
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-green text-white" type="button" onClick={toggleModalReject}>
        Approve
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Top Up Request</span>}
        success
        showCancel
        confirmBtnText="Approve"
        confirmBtnBsStyle="success"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <p style={{ color: 'black' }}>Are you sure you want to approve this top up request?</p>
      </SweetAlert>
    </>
  );
};

// const ButtonReject = (props) => {
//   const { id } = useParams();
//   const history = useHistory();
//   const [modalReject, setModalReject] = useState(false);

//   const toggleModalReject = async () => {
//     await setModalReject(!modalReject);
//   };

//   const [reason, setReason] = useState('');

//   const onReject = () => {
//     setModalReject(false);
//     API.requestWithdrawReject(id, { reason });
//     toggleModalReject().then(() => {
//       history.goBack();
//     });
//   };

//   const optionReasons = [
//     { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
//     { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
//     { value: 'Others', label: 'Others' }
//   ];

//   const onChangeText = (e) => {
//     setReason(e.target.value);
//   };

//   const onChangeSelect = (e) => {
//     setReason(e.value);
//   };

//   return (
//     <>
//       <button className="btn btn-danger mr-2" type="button" onClick={toggleModalReject}>
//         Reject
//       </button>
//       <SweetAlert
//         title={<span className="text-black text-wrap">Withdraw Request</span>}
//         danger
//         showCancel
//         confirmBtnText="Reject"
//         confirmBtnBsStyle="danger"
//         focusCancelBtn={false}
//         focusConfirmBtn={false}
//         onConfirm={onReject}
//         onCancel={toggleModalReject}
//         show={modalReject}
//         dependencies={[reason]}
//       >
//         <form>
//           <textarea
//             type="text"
//             className="form-control"
//             value={reason}
//             onChange={onChangeText}
//             placeholder="Please indicate the reason for rejecting withdraw request"
//           />
//         </form>
//       </SweetAlert>
//     </>
//   );
// };

// const ButtonApprove = (props) => {
//   const { id } = useParams();
//   const history = useHistory();
//   const [modalReject, setModalReject] = useState(false);

//   const toggleModalReject = async () => {
//     await setModalReject(!modalReject);
//   };

//   const onReject = () => {
//     setModalReject(false);
//     API.requestWithdrawApprove(id)
//       .then(() => {
//         toggleModalReject().then(() => {
//           history.goBack();
//         });
//       })
//       .catch((err) => {
//         console.log(err.response);
//         toggleModalReject();
//       });
//   };

//   const [reason, setReason] = useState('');

//   const optionReasons = [
//     { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
//     { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
//     { value: 'Others', label: 'Others' }
//   ];

//   const onChangeText = (e) => {
//     setReason(e.target.value);
//   };

//   const onChangeSelect = (e) => {
//     setReason(e.value);
//   };

//   return (
//     <>
//       <button className="btn btn-green text-white" type="button" onClick={toggleModalReject}>
//         Approve
//       </button>
//       <SweetAlert
//         title={<span className="text-black text-wrap">Withdraw Request</span>}
//         success
//         showCancel
//         confirmBtnText="Approve"
//         confirmBtnBsStyle="success"
//         focusCancelBtn={false}
//         focusConfirmBtn={false}
//         onConfirm={onReject}
//         onCancel={toggleModalReject}
//         show={modalReject}
//       >
//         <p style={{ color: 'black' }}>Are you sure you want to approve this withdraw request?</p>
//       </SweetAlert>
//     </>
//   );
// };
