import React, { memo, useMemo, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Form,
  FormGroup,
  FormText,
  FormFeedback,
  Tooltip,
  Table,
  Row,
  Col,
  Label,
  Input
} from 'reactstrap';
import { isEmpty } from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';

const SupportingDocuments = ({ docs }) => {
  const { control, reset } = useForm({
    defaultValues: useMemo(() => {
      return docs;
    }, [docs])
  });

  useEffect(() => {
    reset(docs);
  }, [docs]);

  return (
    <Form>
      <FormGroup tag="fieldset" disabled>
        <h3>
          Business Plan <TooltipItem placement="top" id="item_2" content="SEC Form CF : Item 2" />
        </h3>
        <FormGroup row>
          <Label md="3">Nature of Business</Label>
          <Col md="9">
            <Controller
              name="bpf_nature_business"
              control={control}
              render={({ field }) => <Input {...field} type="textarea" rows="5" />}
            />
            <FormText>Describe nature of business in detail.</FormText>
          </Col>
        </FormGroup>
      </FormGroup>
      <hr />
      <FormGroup tag="fieldset" disabled>
        <h3 className="mb-0">
          Financial Condition{' '}
          <TooltipItem placement="top" id="investment_id" content="SEC Form CF: Item 7.2" />
        </h3>
        <FormText>
          Discuss Issuer’s financial condition, including, the extent material, liquidity, capital
          resources and historical results of operation.
        </FormText>

        <FormGroup row>
          <Label md="3">Liquidity</Label>
          <Col md="9">
            <Controller
              name="fc_liquidity"
              control={control}
              render={({ field }) => <Input {...field} type="textarea" rows="5" />}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">Capital Resources</Label>
          <Col md="9">
            <Controller
              name="fc_capital_resources"
              control={control}
              render={({ field }) => <Input {...field} type="textarea" rows="5" />}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">Historical Operations</Label>
          <Col md="9">
            <Controller
              defaultValue={docs.fc_historical_operations}
              name="fc_historical_operations"
              control={control}
              render={({ field }) => <Input {...field} type="textarea" rows="5" />}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">Others</Label>
          <Col md="9">
            <Controller
              name="fc_others"
              control={control}
              render={({ field }) => <Input {...field} type="textarea" rows="5" />}
            />
          </Col>
        </FormGroup>
      </FormGroup>
      <hr />
      <FormGroup tag="fieldset" disabled>
        <h3 className="mb-0">Disqualification Provisions</h3>
        <br />
        <FormGroup row>
          <Table bordered>
            <thead>
              <tr>
                <th colSpan="2">
                  (a) Article One Chapter Two – Exemption from Registration of Securities shall not
                  be available for issuer if any predecessor of the issuer; any affiliated issuer;
                  any director, officer, general partner or managing member of the issuer; any
                  beneficial owner of twenty (10) percent or more of the issuer’s outstanding voting
                  equity securities, calculated on the basis of voting power; any promoter connected
                  with the issuer in any capacity at the time of such sale; any person that has been
                  or will be paid (directly or indirectly) remuneration for solicitation of
                  purchasers in connection with such sale of securities; or any general partner,
                  director, officer or managing member of any such solicitor:
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th></th>
                <td style={{ textAlign: 'center' }}>State if applicable</td>
              </tr>
              <tr>
                <td>
                  (1) Has been convicted, within ten (10) years before the filing of the offering
                  statement or five (5) years, in the case of issuers, their predecessors and
                  affiliated issuers), of any felony or misdemeanor:
                  <br />
                  <ul>
                    <li>In connection with the purchase or sale of any security;</li>
                    <li>Involving the making of any false filing with the Commission;or</li>
                    <li>
                      Arising out of the conduct of the business of an underwriter, broker, dealer,
                      investment house, funding portal or purchasers of securities;
                    </li>
                  </ul>
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  <Controller
                    control={control}
                    name="dp_1_is_checked"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="checkbox"
                        checked={docs.dp_1_is_checked}
                        style={{ margin: 0, position: 'relative' }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  (2) Is subject to any order, judgment or decree of any court of competent
                  jurisdiction, entered within five years before the filing of the information
                  required that, at the time of such filing, restrains or enjoins such person from
                  engaging or continuing to engage in any conduct or practice:
                  <br />
                  <ul>
                    <li>In connection with the purchase or sale of any security;</li>
                    <li>Involving the making of any false filing with the Commission; or</li>
                    <li>
                      Arising out of the conduct of the business of an underwriter, broker, dealer,
                      investment house, funding portal or purchasers of securities;
                    </li>
                  </ul>
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  <Controller
                    control={control}
                    name="dp_2_is_checked"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="checkbox"
                        checked={docs.dp_2_is_checked}
                        style={{ margin: 0, position: 'relative' }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  (3) Is subject to a final order of the Securities and Exchange Commission (SEC),
                  Bangko Sentral ng Pilipinas (BSP), Insurance Commission (IC), and any other
                  appropriate banking agency:
                  <br />
                  <ul>
                    <li>
                      At the time of the filing of the information required, bars the person from:
                    </li>
                    <li style={{ listStyle: 'none' }}>
                      <ul>
                        <li>Association with an entity regulated by such regulatory agencies;</li>
                        <li>Engaging in the business of securities, insurance or banking; or</li>
                        <li>Engaging in savings association or credit union activities; or</li>
                      </ul>
                    </li>
                    <li>
                      Constitutes a final order based on a violation of any law or regulation that
                      prohibits fraudulent, manipulative or deceptive conduct entered within ten
                      years before such filing of the offering statement;
                    </li>
                  </ul>
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  <Controller
                    control={control}
                    name="dp_3_is_checked"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="checkbox"
                        checked={docs.dp_3_is_checked}
                        style={{ margin: 0, position: 'relative' }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  (4) Is subject to an order of the Commission entered that, at the time of the
                  filing of the information required:
                  <br />
                  <ul>
                    <li>
                      Suspends or revokes such person’s registration as a broker, dealer, investment
                      house or funding portal; or
                    </li>
                    <li>
                      Places limitations on the activities, functions or operations of such person.
                    </li>
                  </ul>
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  <Controller
                    control={control}
                    name="dp_4_is_checked"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="checkbox"
                        checked={docs.dp_4_is_checked}
                        style={{ margin: 0, position: 'relative' }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  (5) Is subject to any order of the Commission entered within five (5) years before
                  the filing of the information required that, at the time of such filing, orders
                  the person to cease and desist from committing or causing a violation or future
                  violation of any anti-fraud provision of the SRC and its IRR;
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  <Controller
                    control={control}
                    name="dp_5_is_checked"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="checkbox"
                        checked={docs.dp_5_is_checked}
                        style={{ margin: 0, position: 'relative' }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  (6) Is suspended or expelled from membership in, or suspended or barred from
                  association with a member of, a registered securities exchange or a registered
                  national or affiliated securities association for any act or omission to act
                  constituting conduct inconsistent with just and equitable principles of trade;
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  <Controller
                    control={control}
                    name="dp_6_is_checked"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="checkbox"
                        checked={docs.dp_6_is_checked}
                        style={{ margin: 0, position: 'relative', color: 'blue !important' }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  (7) Has filed (as an issuer), or was or was named as an underwriter in, any
                  registration statement or offering statement filed with the Commission that,
                  within five (5) years before the filing of the information required was the
                  subject of a refusal order, stop order, or order suspending the any exemption, or
                  is, at the time of such filing, the subject of an investigation or proceeding to
                  determine whether a stop order or suspension order should be issued; or
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  <Controller
                    control={control}
                    name="dp_7_is_checked"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="checkbox"
                        checked={docs.dp_7_is_checked}
                        style={{ margin: 0, position: 'relative' }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  (8) Is subject to a false representation order entered within five (5) years
                  before the filing of the information required, or is, at the time of such filing,
                  subject to a temporary restraining order or preliminary injunction with respect to
                  conduct alleged by to constitute a scheme or device for obtaining money or
                  property through the mail by means of false representations.
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  <Controller
                    control={control}
                    name="dp_8_is_checked"
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="checkbox"
                        checked={docs.dp_8_is_checked}
                        style={{ margin: 0, position: 'relative' }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>
                    (b) <em>Crowdfunding Intermediaries </em>
                  </strong>
                  - A person that is subject to above disqualifications may not act as, or be an
                  associated person of, an intermediary in a transaction involving the offer or sale
                  of securities unless so permitted pursuant to Commission rule or order.
                </td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                  <Controller
                    control={control}
                    name="dp_9_is_checked"
                    render={({ field }) => (
                      <Input
                        {...field}
                        checked={docs.dp_9_is_checked}
                        type="checkbox"
                        style={{ margin: 0, position: 'relative' }}
                      />
                    )}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </FormGroup>
      </FormGroup>
      {/* <Row>
        <Col className="d-flex justify-content-end">
          <ButtonReject />
          <button className="btn btn-primary" type="button">
            Approve
          </button>
        </Col>
      </Row> */}
    </Form>
  );
};

export default memo(SupportingDocuments);

const schemaIssuerSecForm = yup.object().shape({
  bpf_nature_business: yup.string(),
  fc_liquidity: yup.string(),
  fc_capital_resources: yup.string()
});

const TooltipItem = (props) => {
  const { placement, id, content } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <i className="fa fa-exclamation-circle text-info" id={'Tooltip-' + id} />
      <Tooltip placement={placement} isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
        {content}
      </Tooltip>
    </span>
  );
};

const ButtonReject = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onConfirm = () => {
    setModalReject(false);
    // toggleModalReject().then(() => {
    //   // setTimeout(() => {
    //   //   props.onConfirm();
    //   // }, 300);
    // });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-danger mr-2" type="button" onClick={toggleModalReject}>
        Reject
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Issuer Information</span>}
        danger
        showCancel
        confirmBtnText="Reject"
        confirmBtnBsStyle="danger"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onConfirm}
        onCancel={toggleModalReject}
        show={modalReject}
        dependencies={[reason]}
      >
        <form>
          {/* <div className="form-group row m-b-10 align-items-start">
            <div className="col-md-12">
              <div className="checkbox checkbox-css">
                <input type="checkbox" id="cssCheckbox1" value="" defaultChecked />
                <label htmlFor="cssCheckbox1">CSS Checkbox Label 1</label>
              </div>
              <div className="checkbox checkbox-css disabled">
                <input type="checkbox" id="cssCheckbox2" value="" disabled />
                <label htmlFor="cssCheckbox2">Disabled State</label>
              </div>
              <div className="checkbox checkbox-css is-valid">
                <input type="checkbox" id="cssCheckbox3" value="" />
                <label htmlFor="cssCheckbox3">Success State</label>
              </div>
              <div className="checkbox checkbox-css is-invalid">
                <input type="checkbox" id="cssCheckbox5" value="" />
                <label htmlFor="cssCheckbox5">Error State</label>
              </div>
            </div>
          </div> */}

          <textarea
            type="text"
            className="form-control"
            value={reason}
            onChange={onChangeText}
            placeholder="Please indicate the reason for rejecting issuer information"
          />
        </form>
      </SweetAlert>
    </>
  );
};
