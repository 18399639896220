import React, { memo, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import { store } from 'react-notifications-component';
// import * as API from '../../../../services/api';
import { Form, FormGroup, FormText, FormFeedback, Row, Col, Label, Input } from 'reactstrap';
import { isEmpty } from 'lodash';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import * as API from '../../../services/api';
import DatePicker from 'react-datepicker';
import DateTime from 'react-datetime';
import Dropzone from 'react-dropzone';

// import 'react-datepicker/dist/react-datepicker.css';

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      checked={props.checked}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const schemaIncome = yup.object().shape({
  source_type: yup.object().shape({
    label: yup.string(),
    value: yup.string()
  }),
  gross_annual_income: yup.object().shape({
    label: yup.string(),
    value: yup.string()
  })
});

const FormWithdrawalAgent = (props) => {
  const { investor } = props;

  if (props.currentStep !== 4) {
    return null;
  }

  const [optionWithdrawType, setOptionWithdrawType] = useState([]);
  const [optionDigitalWallet, setOptionDigitalWallet] = useState([]);
  const [optionBanks, setOptionBanks] = useState([]);

  useEffect(() => {
    API.dataSets(['withdrawal_type', 'banks', 'digital_wallet']).then((res) => {
      setOptionWithdrawType(res.data.withdrawal_type);
      setOptionBanks(res.data.banks);
      setOptionDigitalWallet(res.data.digital_wallet);
    });
  }, []);

  const {
    watch,
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    defaultValues: {
      gross_annual_income: { value: '', label: '' },
      source_type: { value: '', label: '' }
    },
    resolver: yupResolver(schemaIncome)
  });

  const sourceType = watch('source_type', false);

  const grossAnnual = watch('gross_annual_income', 0);

  const isQualified = watch('is_qualified');

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onStepOneSubmit = (data) => {
    // props.onSubmit();
    props.history.push('/investors');
  };

  return (
    <Form onSubmit={handleSubmit(onStepOneSubmit)}>
      <FormGroup tag="fieldset">
        <h3>Temporary Credential</h3>

        <FormGroup row>
          <Label md="3">Email</Label>
          <Col md="9">
            <Controller
              control={control}
              type="email"
              name="email"
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  invalid={!isEmpty(errors.email)}
                  disabled
                  value={investor.email}
                />
              )}
            />
            {errors.email && <FormFeedback>{errors.email?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Username</Label>
          <Col md="9">
            <Controller
              control={control}
              type="email"
              name="email"
              // defaultValue={investor.}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  invalid={!isEmpty(errors.username)}
                  disabled
                  value={investor.username}
                />
                //     <div className="input-group">
                //     <input
                //       {...register('account_name', { required: false })}
                //       type="text"
                //       disabled
                //       className={`form-control form-control-md ${
                //         errors.account_name ? 'is-invalid' : ''
                //       }`}
                //     />
                //     <div className="input-group-append">
                //       <button
                //         className="btn btn-secondary"
                //         onClick={() => {
                //           alert('Generate Temporary Username');
                //         }}
                //         type="button"
                //       >
                //         Generate
                //       </button>
                //     </div>
                //     {errors.account_name && (
                //       <div className="invalid-feedback">{errors.account_name?.message}</div>
                //     )}
                //   </div>
              )}
            />
            {errors.username && <FormFeedback>{errors.username?.message}</FormFeedback>}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Password</Label>
          <Col md="9">
            <Controller
              control={control}
              type="password"
              name="password"
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.password)} disabled />
                //     <div className="input-group">
                //     <input
                //       {...register('account_name', { required: false })}
                //       type="text"
                //       disabled
                //       className={`form-control form-control-md ${
                //         errors.account_name ? 'is-invalid' : ''
                //       }`}
                //     />
                //     <div className="input-group-append">
                //       <button
                //         className="btn btn-secondary"
                //         onClick={() => {
                //           alert('Generate Temporary Password');
                //         }}
                //         type="button"
                //       >
                //         Generate
                //       </button>
                //     </div>
                //     {errors.account_name && (
                //       <div className="invalid-feedback">{errors.account_name?.message}</div>
                //     )}
                //   </div>
              )}
            />
            {errors.password && <FormFeedback>{errors.password?.message}</FormFeedback>}
          </Col>
        </FormGroup>
      </FormGroup>
      <hr />
      <FormGroup fieldset>
        <h3>Contract</h3>
        <div className="row form-group m-b-10">
          <div className="col">
            <a className="btn btn-primary" href={investor.contract_url} target="_blank">
              View Contract
            </a>
          </div>
        </div>
      </FormGroup>

      <hr />

      <Row>
        <Col>
          {props.currentStep > 1 && (
            <button className="btn btn-primary float-left" type="button" onClick={props.prevStep}>
              Previous
            </button>
          )}
          <button className="btn btn-primary float-right" type="submit">
            Finish
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default withRouter(FormWithdrawalAgent);
