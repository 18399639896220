import React, { useContext, useState, useEffect } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import PageContext from '../../../config/page-settings.js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { store } from 'react-notifications-component';
import * as API from '../../../services/api';

import CheckboxTree from 'react-checkbox-tree';
import { useMemo } from 'react';

const schemaRoleCreate = yup.object().shape({
  name: yup.string().required('Role name is required')
});

const nodes = [
  {
    value: 'mars',
    label: 'Mars',
    children: [
      { value: 'phobos', label: 'Phobos' },
      { value: 'deimos', label: 'Deimos' }
    ]
  }
];

const RoleCreate = (props) => {
  const history = useHistory();
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  const [nodes, setNodes] = useState([]);

  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);

    API.getPermissions().then((res) => {
      const nodesList = res.data.data.map((parentPermission) => {
        return {
          value: parentPermission.id,
          label: parentPermission.name,
          children: parentPermission.permissions.map((childPermission) => ({
            value: childPermission.name,
            label: childPermission.name
          }))
        };
      });
      setNodes(nodesList);
    });
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schemaRoleCreate)
  });

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onSubmit = (data) => {
    console.log(data);
    const params = {
      role: data.name,
      permission: JSON.stringify(checked)
    };

    setLoadingSubmit(true);
    API.createRole(params)
      .then((res) => {
        store.addNotification({
          title: 'Role Created',
          message: `Role successfully created!`,
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        setLoadingSubmit(false);
        history.goBack();
      })
      .catch((err) => {
        setLoadingSubmit(false);
        console.log(err.response);
      });
  };
  const PermissionList = useMemo(() => {
    return (
      <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={(checked) => setChecked(checked)}
        onExpand={(expanded) => setExpanded(expanded)}
        icons={{
          check: <i className="fa fa-check-square" />,
          uncheck: <i className="fa fa-square" />,
          halfCheck: <i className="fa fa-check-square" />,
          expandClose: <i className="fa fa-chevron-right" />,
          expandOpen: <i className="fa fa-chevron-down" />,
          expandAll: <i className="fa fa-plus-square" />,
          collapseAll: <i className="fa fa-minus-square" />
          //   parentClose: <i className="fa fa-folder" />,
          //   parentOpen: <i className="fa fa-folder-open" />,
          //   leaf: <i className="fa fa-file" />
        }}
      />
    );
  }, [checked, expanded, nodes]);

  return (
    <div>
      {/* <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/user/list">User</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/user/create">User Create</Link>
        </li>
      </ol> */}
      <h1 className="page-header">Role Create</h1>

      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader>Information</PanelHeader>
            <PanelBody>
              <div className="row">
                <div className="col-xl-6">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row form-group m-b-10">
                      <label className="col-md-3 col-form-label">Role Name</label>
                      <div className="col-md-9">
                        <div className="input-group">
                          <input
                            {...register('name', { required: true })}
                            type="text"
                            className={`form-control form-control-md ${
                              errors.first_name ? 'is-invalid' : ''
                            }`}
                          />
                          {errors.first_name && (
                            <div className="invalid-feedback">{errors.first_name?.message}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row form-group m-b-10">
                      <label className="col-md-3 col-form-label">Permissions</label>
                      <div className="col-md-9">{PermissionList}</div>
                    </div>

                    {/* <div className="row form-group m-b-10">
                      <label className="col-md-3 col-form-label">Required OTP</label>
                      <div className="col-md-9">
                        <div className="input-group">
                          <div className="switcher">
                            <input
                              {...register('is_otp_required', { required: false })}
                              type="checkbox"
                              name="is_otp_required"
                              id="is_otp_required"
                            />
                            <label htmlFor="is_otp_required"></label>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="form-group row m-b-10">
                      <label className="col-md-3 col-form-label">&nbsp;</label>
                      <div className="col-md-9">
                        <button
                          type="submit"
                          className={`btn ${loadingSubmit ? 'btn-secondary' : 'btn-primary'}`}
                          disabled={loadingSubmit}
                        >
                          {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(RoleCreate);
