import React, { useState, useEffect } from 'react';
import { PageSettings } from './config/page-settings.js';

import Header from './components/header/header.js';
import Sidebar from './components/sidebar/sidebar.js';
import SidebarRight from './components/sidebar-right/sidebar-right.jsx';
import Content from './components/content/content.js';
import { useLocalStorage } from './utils';
import { setHeaderToken } from './services';
import * as API from './services/api';
import { store } from 'react-notifications-component';
import { useAppState } from './overmind/index.js';
import FloatSubMenu from './components/float-sub-menu/float-sub-menu.jsx';
import Footer from './components/footer/footer.jsx';

const App = () => {
  const state = useAppState();
  const [storedToken, setToken] = useLocalStorage('access_token', '');

  const toggleSidebarMinify = (e) => {
    setPageSettings({ ...pageSettings, pageSidebarMinify: !pageSettings.pageSidebarMinify });
  };

  const handleSetPageSidebarMinified = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageSidebarMinify: value };
    });
  };

  const toggleMobileSidebar = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageSidebarToggled: !prevState.pageSidebarToggled };
    });
  };

  const handleSetPageHeader = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageHeader: value };
    });
  };

  const handleSetPageSidebar = (value) => {
    // setPageSettings((prevState) => {
    //   return { ...prevState, pageSidebar: value };
    // });
  };

  const handleSetPageRightSidebar = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageRightSidebar: value };
    });
  };

  const handleSetPageSidebarWide = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageSidebarWide: value };
    });
  };

  const handleSetPageSidebarLight = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageSidebarLight: value };
    });
  };

  const handleSidebarOnMouseOver = (e, menu) => {
    if (pageSettings.pageSidebarMinify) {
      if (menu.children) {
        var left =
          document.getElementById('sidebar').offsetWidth +
          document.getElementById('sidebar').offsetLeft +
          'px';

        clearTimeout(floatSubMenuRemove);
        clearTimeout(floatSubMenuCalculate);

        setPageSettings((prevState) => {
          return {
            ...prevState,
            pageFloatSubMenu: menu,
            pageFloatSubMenuActive: true,
            pageFloatSubMenuLeft: left
          };
        });

        var offset = e.currentTarget.offsetParent.getBoundingClientRect();

        var floatSubMenuCalculate = setTimeout(() => {
          var targetTop = offset.top;
          var windowHeight = window.innerHeight;
          var targetHeight = document.querySelector('.float-sub-menu-container').offsetHeight;
          var top, bottom, arrowTop, arrowBottom, lineTop, lineBottom;

          if (windowHeight - targetTop > targetHeight) {
            top = offset.top + 'px';
            bottom = 'auto';
            arrowTop = '20px';
            arrowBottom = 'auto';
            lineTop = '20px';
            lineBottom = 'auto';
          } else {
            var aBottom = windowHeight - targetTop - 21;
            top = 'auto';
            bottom = '0';
            arrowTop = 'auto';
            arrowBottom = aBottom + 'px';
            lineTop = '20px';
            lineBottom = aBottom + 'px';
          }

          setPageSettings((prevState) => {
            return {
              ...prevState,
              pageFloatSubMenuTop: top,
              pageFloatSubMenuBottom: bottom,
              pageFloatSubMenuLineTop: lineTop,
              pageFloatSubMenuLineBottom: lineBottom,
              pageFloatSubMenuArrowTop: arrowTop,
              pageFloatSubMenuArrowBottom: arrowBottom,
              pageFloatSubMenuOffset: offset
            };
          });
        }, 0);
      } else {
        var floatSubMenuRemove = setTimeout(() => {
          setPageSettings((prevState) => {
            return {
              ...prevState,
              pageFloatSubMenu: '',
              pageFloatSubMenuActive: false
            };
          });
        }, floatSubMenuRemove);
      }
    }
  };

  const handleSidebarOnMouseOut = (e) => {
    if (pageSettings.pageSidebarMinify) {
      var floatSubMenuRemoveTime = setTimeout(() => {
        setPageSettings((prevState) => {
          return { ...prevState, pageFloatSubMenuActive: false };
        });
      }, floatSubMenuRemoveTime);
    }
  };

  const handleFloatSubMenuClick = () => {
    if (pageSettings.pageSidebarMinify) {
      const windowHeight = window.innerHeight;
      const targetHeight = document.getElementById('float-sub-menu').offsetHeight;
      const targetTop = this.state.pageFloatSubMenuOffset.top;
      const top = windowHeight - targetTop > targetHeight ? targetTop : 'auto';
      const left =
        this.state.pageFloatSubMenuOffset.left +
        document.getElementById('sidebar').offsetWidth +
        'px';
      const bottom = windowHeight - targetTop > targetHeight ? 'auto' : '0';
      const arrowTop = windowHeight - targetTop > targetHeight ? '20px' : 'auto';
      const arrowBottom =
        windowHeight - targetTop > targetHeight ? 'auto' : windowHeight - targetTop - 21 + 'px';
      const lineTop = windowHeight - targetTop > targetHeight ? '20px' : 'auto';
      const lineBottom =
        windowHeight - targetTop > targetHeight ? 'auto' : windowHeight - targetTop - 21 + 'px';

      setPageSettings((prevState) => {
        return {
          ...prevState,
          pageFloatSubMenuTop: top,
          pageFloatSubMenuLeft: left,
          pageFloatSubMenuBottom: bottom,
          pageFloatSubMenuLineTop: lineTop,
          pageFloatSubMenuLineBottom: lineBottom,
          pageFloatSubMenuArrowTop: arrowTop,
          pageFloatSubMenuArrowBottom: arrowBottom
        };
      });
    }
  };

  const handleSetPageTopMenu = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageTopMenu: value };
    });
  };

  const handleSetPageContentFullWidth = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageContentFullWidth: value };
    });
  };

  const handleSetPageContentFullHeight = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageContentFullHeight: value };
    });
  };

  const toggleMobileTopMenu = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageMobileTopMenu: !prevState.pageMobileTopMenu };
    });
  };

  const handleSetPageSidebarTransparent = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageSidebarTransparent: value };
    });
  };

  const handleSetPageHeaderLanguageBar = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageHeaderLanguageBar: value };
    });
  };

  const handleSetPageHeaderMegaMenu = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageHeaderMegaMenu: value };
    });
  };

  const handleSetPageSidebarSearch = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageSidebarSearch: value };
    });
  };

  var floatSubMenuRemove;
  var floatSubMenuCalculate;
  var floatSubMenuRemoveTime = 250;
  const handleFloatSubMenuOnMouseOver = (e) => {
    clearTimeout(floatSubMenuRemove);
    clearTimeout(floatSubMenuCalculate);
  };

  const handleFloatSubMenuOnMouseOut = (e) => {
    floatSubMenuRemove = setTimeout(() => {
      setPageSettings((prevState) => {
        return { ...prevState, pageFloatSubMenuActive: false };
      });
    }, floatSubMenuRemoveTime);
  };

  const handleSetPageContent = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageContent: value };
    });
  };

  const handleSetPageContentClass = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageContentClass: value };
    });
  };

  const handleSetPageContentInverseMode = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageContentInverseMode: value };
    });
  };

  const handleSetPageFooter = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageFooter: value };
    });
  };

  const handleSetPageTwoSidebar = (value) => {
    setPageSettings((prevState) => {
      return { ...prevState, pageTwoSidebar: value };
    });
  };

  const toggleRightSidebar = (e) => {
    e.preventDefault();
    setPageSettings((prevState) => {
      return { ...prevState, pageRightSidebarCollapsed: !prevState.pageRightSidebarCollapsed };
    });
  };

  const toggleMobileRightSidebar = (e) => {
    e.preventDefault();
    setPageSettings((prevState) => {
      return {
        ...prevState,
        pageMobileRightSidebarToggled: !prevState.pageMobileRightSidebarToggled
      };
    });
  };

  const handleSetBodyWhiteBg = (value) => {
    if (value === true) {
      document.body.classList.add('bg-white');
    } else {
      document.body.classList.remove('bg-white');
    }
  };

  const handleSetPageBoxedLayout = (value) => {
    if (value === true) {
      document.body.classList.add('boxed-layout');
    } else {
      document.body.classList.remove('boxed-layout');
    }
  };

  const [pageSettings, setPageSettings] = useState({
    pageHeader: true,
    pageheaderMegaMenu: false,
    pageHeaderLanguageBar: false,
    hasScroll: false,
    handleSetPageHeader: handleSetPageHeader,
    handleSetPageHeaderLanguageBar: handleSetPageHeaderLanguageBar,
    handleSetPageHeaderMegaMenu: handleSetPageHeaderMegaMenu,

    pageSidebar: true,
    pageSidebarWide: false,
    pageSidebarLight: false,
    pageSidebarMinify: false,
    pageSidebarToggled: false,
    pageSidebarTransparent: false,
    pageSidebarSearch: false,
    handleSetPageSidebar: handleSetPageSidebar,
    handleSetPageSidebarWide: handleSetPageSidebarWide,
    handleSetPageSidebarLight: handleSetPageSidebarLight,
    handleSetPageSidebarMinified: handleSetPageSidebarMinified,
    handleSetPageSidebarTransparent: handleSetPageSidebarTransparent,
    handleSetPageSidebarSearch: handleSetPageSidebarSearch,
    handleSidebarOnMouseOut: handleSidebarOnMouseOut,
    handleSidebarOnMouseOver: handleSidebarOnMouseOver,
    toggleSidebarMinify: toggleSidebarMinify,
    toggleMobileSidebar: toggleMobileSidebar,

    pageFloatSubMenuActive: false,
    pageFloatSubMenu: '',
    pageFloatSubMenuTop: 'auto',
    pageFloatSubMenuLeft: 'auto',
    pageFloatSubMenuBottom: 'auto',
    pageFloatSubMenuLineTop: 'auto',
    pageFloatSubMenuLineBottom: 'auto',
    pageFloatSubMenuArrowTop: 'auto',
    pageFloatSubMenuArrowBottom: 'auto',
    pageFloatSubMenuOffset: '',
    handleFloatSubMenuOnMouseOver: handleFloatSubMenuOnMouseOver,
    handleFloatSubMenuOnMouseOut: handleFloatSubMenuOnMouseOut,
    handleFloatSubMenuClick: handleFloatSubMenuClick,

    pageContent: true,
    pageContentClass: '',
    pageContentFullHeight: false,
    pageContentFullWidth: false,
    pageContentInverseMode: false,
    handleSetPageContent: handleSetPageContent,
    handleSetPageContentClass: handleSetPageContentClass,
    handleSetPageContentFullHeight: handleSetPageContentFullHeight,
    handleSetPageContentFullWidth: handleSetPageContentFullWidth,
    handleSetPageContentInverseMode: handleSetPageContentInverseMode,

    pageFooter: false,
    handleSetPageFooter: handleSetPageFooter,

    pageTopMenu: false,
    pageMobileTopMenu: false,
    toggleMobileTopMenu: toggleMobileTopMenu,
    handleSetPageTopMenu: handleSetPageTopMenu,

    pageTwoSidebar: false,
    handleSetPageTwoSidebar: handleSetPageTwoSidebar,

    pageRightSidebar: false,
    pageRightSidebarToggled: true,
    pageMobileRightSidebarToggled: false,
    toggleRightSidebar: toggleRightSidebar,
    toggleMobileRightSidebar: toggleMobileRightSidebar,
    handleSetPageRightSidebar: handleSetPageRightSidebar,

    handleSetBodyWhiteBg: handleSetBodyWhiteBg,
    handleSetPageBoxedLayout: handleSetPageBoxedLayout
  });

  if (state.isAuthenticating) {
    return (
      <div id="page-loader" className="fade show">
        <span className="spinner"></span>
      </div>
    );
  }

  return (
    <PageSettings value={pageSettings}>
      <div
        className={
          'fade page-sidebar-fixed show page-container ' +
          (pageSettings.pageHeader ? 'page-header-fixed ' : '') +
          (pageSettings.pageSidebar ? '' : 'page-without-sidebar ') +
          (pageSettings.pageRightSidebar ? 'page-with-right-sidebar ' : '') +
          (pageSettings.pageSidebarWide ? 'page-with-wide-sidebar ' : '') +
          (pageSettings.pageSidebarLight ? 'page-with-light-sidebar ' : '') +
          (pageSettings.pageSidebarMinify ? 'page-sidebar-minified ' : '') +
          (pageSettings.pageSidebarToggled ? 'page-sidebar-toggled ' : '') +
          (pageSettings.pageTopMenu ? 'page-with-top-menu ' : '') +
          (pageSettings.pageContentFullHeight ? 'page-content-full-height ' : '') +
          (pageSettings.pageTwoSidebar ? 'page-with-two-sidebar ' : '') +
          (pageSettings.pageRightSidebarCollapsed ? 'page-right-sidebar-collapsed ' : '') +
          (pageSettings.pageMobileRightSidebarToggled ? 'page-right-sidebar-toggled ' : '') +
          (pageSettings.hasScroll ? 'has-scroll ' : '')
        }
      >
        {state.isAuthenticated && pageSettings.pageHeader && <Header />}
        {state.isAuthenticated && pageSettings.pageSidebar && <Sidebar />}
        {/* {state.isAuthenticated && pageSettings.pageTwoSidebar && <SidebarRight />} */}
        <Content />
        <FloatSubMenu value={pageSettings} />
        {/* <Footer /> */}
      </div>
    </PageSettings>
  );
};

export default App;
