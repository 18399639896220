import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import PageContext from '../../../config/page-settings.js';
import * as API from '../../../services/api';
import { Table, RowActionItems, RowItemDelete, RowItemView } from '../../../components/table';
import { Notification } from '../../../utils/index.js';

const MessageBlastList = (props) => {
  const history = useHistory();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  useEffect(() => {
    fetchIndustyList();
  }, [props]);

  const fetchIndustyList = () => {
    API.getIndustyList().then((res) => {
      setData([
        {
          id: 1,
          created_date: 'Feb 17 2021 03:00 AM',
          name: 'Loan Published (Funding ID: 202102165933)',
          type: 'email',
          recipients: 'rule7 : All Approved Investors',
          status: 'Blasted'
        }
      ]);
    });
  };

  const columns = useMemo(
    () => [
      {
        header: 'Date',
        accessorKey: 'created_date',
        sortable: true
      },
      {
        header: 'Name',
        accessorKey: 'name',
        sortable: true
      },
      {
        header: 'Type',
        accessorKey: 'type',
        sortable: true
      },
      {
        header: 'Recipients',
        accessorKey: 'recipients',
        sortable: true
      },
      {
        header: 'Status',
        accessorKey: 'status',
        sortable: true
      },
      {
        header: 'Action Items',
        cell: ({ cell }) => {
          const deleteIndustry = () => {
            API.deleteIndustry(cell.value).then((res) => {
              const dataCopy = [...data];
              dataCopy.splice(cell.row.index, 1);
              setData(dataCopy);
              Notification.success('Industry Deleted', 'Industry successfully deleted!');
            });
          };

          const viewIndusry = () => {
            history.push(`/settings/industry/${cell.value}`, cell.row.values);
          };

          return (
            <RowActionItems>
              <RowItemView onClick={viewIndusry} />
              <RowItemDelete onConfirm={deleteIndustry} />
            </RowActionItems>
          );
        }
      }
    ],
    []
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Panel>
      <PanelHeader>Message HAHAHAHAHAH</PanelHeader>
      <PanelBody>
        <Table columns={columns} data={data} updateRow={updateRow} skipPageReset={skipPageReset} />
      </PanelBody>
    </Panel>
  );
};

export default memo(MessageBlastList);
