import React from 'react';

import DropZone from 'react-dropzone';
import { isUndefined, isNull, isEmpty } from 'lodash';
const FileUpload = ({ onDrop, imagefile = [], onChange, invalid = false, accept }) => {
  const onDelete = () => {
    onChange([]);
  };

  return (
    <div className="preview-container">
      <DropZone
        // accept={
        //   accept ||
        //   'image/jpeg, image/png, image/gif, image/bmp, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        // }
        multiple={false}
        className="upload-container"
        onDrop={onDrop}
        onChange={onChange}
      >
        {({ getRootProps, getInputProps }) => {
          if (isEmpty(imagefile) || isNull(imagefile.path) || isNull(imagefile.view)) {
            return (
              // <section className={`${invalid ? 'dropzone-invalid' : 'dropzone'} needsclick`}>
              <section className={`${'dropzone'} needsclick`}>
                <div {...getRootProps()} className="dz-message">
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop or click to select file here</p>
                </div>
              </section>
            );
          } else {
            return <ImagePreview imagefile={imagefile} onRemove={onDelete} />;
          }
        }}
      </DropZone>
      {/* {touched && error && <ShowError error={error} />} */}
    </div>
  );
};

export default FileUpload;

const ImagePreview = ({ imagefile = [], onRemove }) => {
  if (imagefile.length > 0) {
    return imagefile.map((file, index) => (
      <div key={file.name}>
        {isFileImage(file) ? (
          <div style={thumb}>
            <div style={thumbInner}>
              <img
                src={file.hasOwnProperty('status') ? file.view : URL.createObjectURL(file)}
                style={img}
              />
            </div>
          </div>
        ) : (
          <i className="fa fa-file fa-7x"></i>
        )}
        <div className="d-flex">
          <p className="mr-3">{file.name || file.file_name}</p>
          <button className="btn btn-danger btn-icon btn-circle btn-sm" onClick={onRemove}>
            <i className="fa fa-times"></i>
          </button>
        </div>
      </div>
    ));
  }

  return (
    <div className="d-flex">
      <p className="mr-3">Not Available</p>
      <button className="btn btn-danger btn-icon btn-circle btn-sm" onClick={onRemove}>
        <i className="fa fa-times"></i>
      </button>
    </div>
  );
};

const isFileImage = (file) => {
  if (file && file.view != null) {
    if (file && file.status) {
      return file && file['mime'].split('/')[0] === 'image';
    }

    return file && file['type'].split('/')[0] === 'image';
  }
  return false;
};

const Placeholder = ({ error, touched }) => (
  <div className={`placeholder-preview ${error && touched ? 'has-error' : ''}`}>
    {/* <MdCloudUpload style={{ fontSize: 100, paddingTop: 70 }} /> */}
    <p>Click or drag image file to this area to upload.</p>
  </div>
);

const ShowError = ({ error }) => (
  <div className="error">
    {/* <MdInfoOutline style={{ position: 'relative', top: -2, marginRight: 2 }} /> */}
    {error}
  </div>
);

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const dropzoneInvalid = {
  border: '2px solid red !important',
  backgroup: 'none !important'
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

/* FileUpload Component */

// img {
//   max-height: 240px;
//   margin: 0 auto;
// }

// .app-container {
//   width: 500px;
//   margin: 30px auto;
// }

// .clear {
//   clear: both;
// }

// .details,
// .title {
//   text-align: center;
// }

// .error {
//   margin-top: 4px;
//   color: red;
// }

// .has-error {
//   border: 1px dotted red;
// }

// .image-container {
//   align-items: center;
//   display: flex;
//   width: 85%;
//   height: 80%;
//   float: left;
//   margin: 15px 10px 10px 37px;
//   text-align: center;
// }

// .preview-container {
//   height: 335px;
//   width: 100%;
//   margin-bottom: 10px;
// }

// .placeholder-preview,
// .render-preview {
//   text-align: center;
//   background-color: #efebeb;
//   height: 100%;
//   width: 100%;
//   border-radius: 5px;
// }

// .upload-container {
//   cursor: pointer;
//   height: 300px;
// }
