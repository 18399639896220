import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { store } from 'react-notifications-component';
import * as API from '../../../../services/api';
import { Form, FormGroup, FormFeedback, Col, Label, Input } from 'reactstrap';
import { isEmpty } from 'lodash';
import ReactSelect from 'react-select';

const schemaIndustryCreate = yup.object().shape({
  os: yup.string().required('OS is required'),
  description: yup.string(),
  is_active: yup.boolean(),
  prompt_message: yup.string().nullable()
});

const FormAppVersion = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
    control
  } = useForm({
    defaultValues: {
      force_update: false,
      is_expired: false,
      is_active: true,
      prompt_message: ''
    },
    resolver: yupResolver(schemaIndustryCreate)
  });

  useEffect(() => {
    if (props.action === 'edit') {
      reset(location.state.values);
    }
  }, [props.action]);

  const isExpired = watch('is_expired', false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onIndustrySubmit = (data) => {
    if (props.action == 'create') {
      const params = {
        ...data,
        force_update: data.force_update ? 1 : 0,
        is_active: data.is_active ? 1 : 0,
        is_expired: data.is_expired ? 1 : 0
      };

      API.createAppVersion(params)
        .then((res) => {
          store.addNotification({
            title: 'App Version Submitted',
            message: `App version successfully created!`,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          history.goBack();
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    } else {
      const params = new URLSearchParams();
      for (let [key, value] of Object.entries({
        ...data,
        force_update: data.force_update ? '1' : '0',
        is_active: data.is_active ? '1' : '0',
        is_expired: data.is_expired ? '1' : '0',
        prompt_message: data.prompt_message == null ? '' : data.prompt_message
      })) {
        params.append(key, value || '');
      }

      API.updateAppVersion(id, params)
        .then((res) => {
          store.addNotification({
            title: 'App Version Updated',
            message: `App version successfully updated!`,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          history.goBack();
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    }
  };

  const optionOS = [
    {
      value: 'android',
      label: 'Android'
    },
    {
      value: 'ios',
      label: 'iOS'
    }
  ];

  const optionEnvironment = [
    {
      value: 'development',
      label: 'Development'
    },
    {
      value: 'staging',
      label: 'Staging'
    },
    {
      value: 'production',
      label: 'Production'
    }
  ];

  return (
    <Form onSubmit={handleSubmit(onIndustrySubmit)}>
      <FormGroup row>
        <Label md="3">OS</Label>
        <Col md="9">
          <Controller
            name="os"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <ReactSelect
                inputRef={ref}
                value={optionOS.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                options={optionOS}
                isOptionDisabled={(option) => option.disabled}
              />
            )}
          />
          {errors.name && <FormFeedback>{errors.name?.message}</FormFeedback>}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">API Version</Label>
        <Col md="9">
          <Controller
            control={control}
            name="api_version"
            render={({ field }) => <Input {...field} />}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">APP Version</Label>
        <Col md="9">
          <Controller
            control={control}
            name="app_version"
            render={({ field }) => <Input {...field} />}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Environment</Label>
        <Col md="9">
          <Controller
            name="environment"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <ReactSelect
                inputRef={ref}
                value={optionEnvironment.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                options={optionEnvironment}
                isOptionDisabled={(option) => option.disabled}
              />
            )}
          />
          {errors.name && <FormFeedback>{errors.name?.message}</FormFeedback>}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Description</Label>
        <Col md="9">
          <Controller
            control={control}
            name="description"
            render={({ field }) => <Input {...field} type="textarea" />}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Is Expired</Label>
        <Col md="9">
          <Controller
            control={control}
            name="is_expired"
            render={({ field: { value, onChange } }) => (
              <div className="switcher">
                <input
                  checked={value}
                  onChange={onChange}
                  type="checkbox"
                  name="is_expired"
                  id="is_expired"
                />
                <label htmlFor="is_expired"></label>
              </div>
            )}
          />
        </Col>
      </FormGroup>
      {isExpired && (
        <FormGroup row>
          <Label md="3">Prompt Message</Label>
          <Col md="9">
            <Controller
              defaultValue="We have release new version, please download the latest app"
              control={control}
              name="prompt_message"
              render={({ field }) => <Input {...field} type="textarea" />}
            />
          </Col>
        </FormGroup>
      )}

      <FormGroup row>
        <Label md="3">Force Update</Label>
        <Col md="9">
          <Controller
            control={control}
            name="force_update"
            render={({ field: { value, onChange } }) => (
              <div className="switcher">
                <input
                  checked={value}
                  onChange={onChange}
                  type="checkbox"
                  name="force_update"
                  id="force_update"
                />
                <label htmlFor="force_update"></label>
              </div>
            )}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Is Active</Label>
        <Col md="9">
          <Controller
            control={control}
            name="is_active"
            render={({ field: { value, onChange } }) => (
              <div className="switcher">
                <input
                  checked={value}
                  onChange={onChange}
                  type="checkbox"
                  name="is_active"
                  id="is_active"
                />
                <label htmlFor="is_active"></label>
              </div>
            )}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3" />
        <Col md="9">
          <button
            type="submit"
            className={`btn ${loadingSubmit ? 'btn-secondary' : 'btn-primary'}`}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
          </button>
        </Col>
      </FormGroup>
    </Form>
  );
};

export default withRouter(FormAppVersion);
