import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PageContext from '../../../../config/page-settings.js';

import PaymentOptionList from './PaymentOptionList.js';
const PaymentOptionManagement = (props) => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const create = () => {
    props.history.push('/settings/payment/options/create');
  };

  return (
    <div>
      <h1 className="page-header">
        Payment Options{' '}
        <button onClick={create} className="btn btn-primary btn-icon btn-circle btn-md ml-2">
          <i className="fa fa-plus"></i>
        </button>
      </h1>
      <PaymentOptionList />
    </div>
  );
};

export default withRouter(PaymentOptionManagement);
