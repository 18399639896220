import React, { useContext, useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import PageContext from '../../../config/page-settings.js';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { store } from 'react-notifications-component';
import * as API from '../../../services/api';

import { useForm, Controller } from 'react-hook-form';
import { Form, FormGroup, FormFeedback, Row, Col, Label, Input } from 'reactstrap';
import { isEmpty } from 'lodash';
import ReactSelect from 'react-select';
import { Notification } from '../../../utils/index.js';

const schemaDocumentRequirementsCreate = yup.object().shape({
  name: yup.string().required('Document name is required'),
  document_type: yup.number().required('Document type is required')
});

const DocumentRequirementsCreate = () => {
  const history = useHistory();
  const pageContext = useContext(PageContext);

  const [optionDocumentTypes, setOptionsDocumentTypes] = useState([]);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);

    API.dataSets(['document_requirement_type']).then((res) => {
      setOptionsDocumentTypes(res.data.document_requirement_type);
    });
  }, []);

  const {
    formState: { errors },
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver(schemaDocumentRequirementsCreate)
  });

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onSubmitForm = (data) => {
    setLoadingSubmit(true);

    const params = {
      ...data,
      is_active: data.is_active ? 1 : 0
    };

    API.createDocumentRequirement(params)
      .then(() => {
        Notification.success(
          'Document Requirements Created',
          'Document requirement successfully created!'
        );
        setLoadingSubmit(false);
        history.goBack();
      })
      .catch((err) => {
        Notification.error(
          'Document Requirements Failed',
          'Document requirement failed to submit!'
        );
        setLoadingSubmit(false);
      });
  };

  return (
    <div>
      <h1 className="page-header">Document Requirements Create</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader>Information</PanelHeader>
            <PanelBody>
              <div className="row">
                <div className="col-xl-6">
                  <Form onSubmit={handleSubmit(onSubmitForm)}>
                    <FormGroup row>
                      <Label md="3">Document Name</Label>
                      <Col md="9">
                        <Controller
                          name="name"
                          control={control}
                          render={({ field }) => (
                            <Input {...field} type="text" invalid={!isEmpty(errors.name)} />
                          )}
                        />
                        <FormFeedback style={{ display: errors.name ? 'block' : 'none' }}>
                          {errors.name?.message}
                        </FormFeedback>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label md="3">Document Type</Label>
                      <Col md="9">
                        <Controller
                          name="document_type"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <ReactSelect
                              inputRef={ref}
                              isSearchable={false}
                              isClearable={false}
                              value={optionDocumentTypes.find((c) => c.value === Number(value))}
                              onChange={(val) => onChange(val.value)}
                              options={optionDocumentTypes}
                              isOptionDisabled={(option) => option.disabled}
                            />
                          )}
                        />
                        <FormFeedback style={{ display: errors.document_type ? 'block' : 'none' }}>
                          {errors.document_type?.message}
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label md="3">Is Active</Label>
                      <Col md="9">
                        <Controller
                          control={control}
                          name="is_active"
                          render={({ field: { value, onChange } }) => (
                            <div className="switcher">
                              <input
                                checked={value}
                                onChange={onChange}
                                type="checkbox"
                                name="is_active"
                                id="is_active"
                              />
                              <label htmlFor="is_active"></label>
                            </div>
                          )}
                        />
                      </Col>
                    </FormGroup>

                    <Row>
                      <Col>
                        <button
                          type="submit"
                          className={`btn ${
                            loadingSubmit ? 'btn-gray' : 'btn-primary'
                          } float-right`}
                          disabled={loadingSubmit}
                        >
                          {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(DocumentRequirementsCreate);
