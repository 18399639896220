import React, { useState, useEffect, useContext } from 'react';
import { withRouter, Link, useParams, useHistory } from 'react-router-dom';
import PageContext from '../../config/page-settings.js';
import RequestLoanInformation from './components/Review/RequestLoanInformation.js';
import * as API from '../../services/api';
import { Notification, PriceFormat } from '../../utils/index.js';
import SweetAlert from 'react-bootstrap-sweetalert';

const ReviewLoan = () => {
  const pageContext = useContext(PageContext);

  const { id } = useParams();

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullHeight(true);
    pageContext.handleSetPageContentFullWidth(true);
  }, []);

  const [tabs, setActiveTab] = useState({
    summary: true,
    activities: false
  });

  const showTab = (e, tab) => {
    e.preventDefault();
    setActiveTab({
      summary: tab === 'summary' ? true : false,
      activities: tab === 'activities' ? true : false
    });
  };

  const [loan, setLoan] = useState({});

  useEffect(() => {
    if (id) {
      API.getLoanRequest(id).then((res) => {
        setLoan(res.data.data);
      });
    }
  }, [id]);

  const onPreviewFormCF = async () => {
    var token = localStorage.getItem('access_token');

    var win = window.open('_blank');

    downloadFile(loan.sec_cf_link, function (blob) {
      var url = URL.createObjectURL(blob);
      win.location = url;
    });

    function downloadFile(url, success) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (success) success(xhr.response);
        } else {
        }
      };
      xhr.send(null);
    }
  };

  const onPreviewFormCFO = async () => {
    var token = localStorage.getItem('access_token');

    var win = window.open('_blank');

    downloadFile(loan.sec_cfo_link, function (blob) {
      var url = URL.createObjectURL(blob);
      win.location = url;
    });

    function downloadFile(url, success) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (success) success(xhr.response);
        } else {
        }
      };
      xhr.send(null);
    }
  };

  return (
    <div>
      <div className="profile">
        <div className="profile-header">
          <div className="profile-header-cover"></div>
          <div className="profile-header-content">
            <div className="profile-header-img">
              <img src="/assets/img/logos/logo_seedin_icon.png" alt="" />
            </div>
            <div className="profile-header-info">
              <h4 className="m-t-10 m-b-5">{loan.company_name}</h4>

              <h6 className="m-b-10">{loan && loan.status && loan.status.name}</h6>
              {/* <div style={{ width: '300px', marginBottom: '10px' }}>
                <div className="progress h-10px rounded-pill mb-5px">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-orange fs-10px fw-bold"
                    style={{ width: '30%' }}
                  >
                    Progress (30%)
                  </div>
                </div>
              </div> */}
              {/* <Link to="/extra/profile" className="btn btn-xs btn-green m-l-5">
                Approve
              </Link> */}
              {/* <Link to="/extra/profile" className="btn btn-xs btn-red m-l-5">
                Reject
              </Link> */}
              {/* <div style={{ flex: 1 }}> */}
              {loan && loan.status && loan.status.id === 2 && (
                <ButtonReview
                  onSubmit={() => {
                    setLoan({
                      ...loan,
                      status: {
                        id: 4,
                        name: 'Reviewing'
                      }
                    });
                  }}
                  amount={loan.amount}
                  loan_tenor={loan.loan_tenor}
                />
              )}
              <Link onClick={onPreviewFormCF} className="btn btn-xs btn-danger mr-2">
                Preview SEC Form CF
              </Link>
              <Link onClick={onPreviewFormCFO} className="btn btn-xs btn-danger mr-2">
                Preview SEC Form CF-O
              </Link>
              {/* {loan && loan.status && loan.status.id === 6 && (
                <Link
                  to={`/projects/${loan.user_id}/start/${loan.id}`}
                  className="btn btn-xs btn-green m-l-5 text-white"
                >
                  Start As Project
                </Link>
              )} */}
              {/* </div> */}
            </div>
          </div>
          <ul className="profile-header-tab nav nav-tabs">
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'summary')}
                className={'nav-link ' + (tabs.summary ? 'active ' : '')}
              >
                LOAN REQUEST
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'activities')}
                className={'nav-link ' + (tabs.activities ? 'active ' : '')}
              >
                ACTIVITY LOGS
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="profile-content">
        <div className="tab-content p-0">
          <div className={'tab-pane fade ' + (tabs.summary ? 'show active ' : '')}>
            <div className="col-lg-10 offset-lg-1">
              {loan && <RequestLoanInformation loan={loan} />}
            </div>
          </div>
          {/* <div className={'tab-pane fade ' + (tabs.activities ? 'show active ' : '')}>
            <div className="col-lg-10 offset-lg-1">
              <ActivityLogs />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ReviewLoan);

const ButtonReview = (props) => {
  console.log(props);
  const { id } = useParams();
  const history = useHistory();
  const [modal, setModal] = useState(false);

  const toggleModal = async () => {
    await setModal(!modal);
  };

  const onConfirm = () => {
    API.loanRequestStatus(id, 'review').then((res) => {
      Notification.success('Loan Request Reviewing!', 'Loan request set as reviewing');
      setModal(false);
      props.onSubmit();
    });
  };

  return (
    <>
      <button className="btn btn-xs btn-primary mr-2" type="button" onClick={toggleModal}>
        Start Review
        <SweetAlert
          title={<span className="text-black text-wrap">Review Loan Request?</span>}
          info
          showCancel
          confirmBtnText="Yes, Review!"
          confirmBtnBsStyle="info"
          focusCancelBtn={false}
          focusConfirmBtn={false}
          onConfirm={onConfirm}
          onCancel={toggleModal}
          show={modal}
        >
          <p className="text-black text-wrap">Are you sure you want to review this loan request?</p>
          <b className="text-black text-wrap">
            {PriceFormat(props.amount)} for {props.loan_tenor} months
          </b>
        </SweetAlert>
      </button>
    </>
  );
};
