import React, { memo } from 'react';

const RowItemReview = (props) => {
  return (
    <button className="btn btn-default btn-icon btn-primary m-r-2" onClick={props.onClick}>
      <i className="fa fa-search"></i>
    </button>
  );
};

export default memo(RowItemReview);
