import React, { useState, useEffect, memo } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from './../../../../components/panel/panel.jsx';
import * as API from '../../../../services/api';
import { Notification } from '../../../../utils/index.js';
import { Form, FormGroup, FormText, FormFeedback, Row, Col, Label, Input } from 'reactstrap';

import LoadingSpinner from '../../../../components/common/LoadingSpinner.js';
import { has } from 'lodash';
const BanksList = () => {
  const { id } = useParams();
  const [banks, setBanks] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getBanks();
  }, []);

  const getBanks = () => {
    setLoading(true);
    API.getUserBank(id)
      .then((res) => {
        setBanks(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setBanks([]);
      });
  };

  const [addBank, setAddBank] = useState(false);

  const showAddBank = () => {
    setAddBank(!addBank);
  };

  const onSubmitBank = () => {
    setAddBank(!addBank);
    getBanks();
  };

  const onReject = (bank_id, reason) => () => {
    const params = {
      status: String(3),
      remarks: reason,
      user_id: id
    };
    API.updateInvestorBankStatus(params)
      .then(() => {
        getBanks();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const onApprove = (bank_id) => {
    const paramsObj = {
      status: String(2),
      remarks: '',
      user_id: id
    };

    // const params = new URLSearchParams();
    // for (let [key, value] of Object.entries(paramsObj)) {
    //   params.append(key, value || '');
    // }

    API.updateInvestorBankStatus(bank_id, paramsObj)
      .then(() => {
        getBanks();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const onPrimary = (bank_id) => {
    const paramsObj = {
      status: String(2),
      remarks: '',
      user_id: id,
      is_primary: 1
    };

    // const params = new URLSearchParams();
    // for (let [key, value] of Object.entries(paramsObj)) {
    //   params.append(key, value || '');
    // }

    API.updateInvestorBankStatus(bank_id, paramsObj)
      .then(() => {
        getBanks();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const onDelete = (bank_id) => {
    API.deleteUserBank(bank_id)
      .then(() => {
        getBanks();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  return (
    <>
      <h3>Banks</h3>
      <Panel>
        <PanelHeader>{addBank ? 'Add Bank' : 'Bank List'}</PanelHeader>
        <PanelBody>
          {addBank ? (
            <FormAddBank onSubmitBank={onSubmitBank} onCancel={showAddBank} />
          ) : (
            <div className="table-responsive">
              <table className="table table-striped mb-0 align-middle">
                <thead>
                  <tr>
                    <th>Bank</th>
                    <th>Account Name</th>
                    <th>Account Number</th>
                    <th>Status</th>
                    <th>Attachment</th>
                    <th width="20%" style={{ textAlign: 'center' }}>
                      Action Items
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={4} style={{ textAlign: 'center' }}>
                        <LoadingSpinner />
                      </td>
                    </tr>
                  ) : banks.length ? (
                    banks.map((bank) => (
                      <tr key={bank.id}>
                        <td>
                          {bank.bank_name}{' '}
                          {bank.is_primary == 1 && (
                            <>
                              <br /> <span className="badge bg-primary rounded-pill">Primary</span>
                            </>
                          )}
                        </td>
                        <td>{bank.account_name}</td>
                        <td>{bank.account_number}</td>
                        <td>{bank.status?.name}</td>
                        <td style={{ textAlign: 'center' }}>
                          {bank.attachment.view != null ? (
                            <div className="with-btn text-nowrap d-flex justify-content-center">
                              <a
                                className="btn btn-sm btn-primary w-60px mr-1"
                                href={bank.attachment.view}
                                target="_blank"
                              >
                                <i className="fa fa-eye"></i>
                              </a>
                              <a
                                className="btn btn-sm btn-primary w-60px mr-1"
                                href={bank.attachment.download}
                              >
                                <i className="fa fa-download"></i>
                              </a>
                            </div>
                          ) : (
                            'Not Available'
                          )}
                        </td>
                        <td nowrap="true">
                          <div
                            style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}
                          >
                            {bank && bank?.status && bank?.status?.id == 1 && (
                              <>
                                <ButtonReject onReject={onReject} id={bank.id} />
                                <ButtonApprove onApprove={onApprove} id={bank.id} />
                              </>
                            )}

                            {bank && bank?.status && bank?.status?.id == 5 && (
                              <>
                                <ButtonApprove onApprove={onApprove} id={bank.id} />
                              </>
                            )}

                            {bank && bank?.status && bank?.status.id == 2 && bank?.is_primary == 0 && (
                              <>
                                <ButtonPrimary onConfirm={onPrimary} id={bank.id} />
                                <ButtonDelete onConfirm={onDelete} id={bank.id} />
                              </>
                            )}
                            {bank && bank?.status && bank?.status?.id == 3 && bank.is_primary == 0 && (
                              <>
                                <ButtonDelete onConfirm={onDelete} id={bank.id} />
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} style={{ textAlign: 'center' }}>
                        No bank(s) recorded
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </PanelBody>
      </Panel>
      <Row>
        <Col>
          {/* {props.currentStep > 1 && (
            <button className="btn btn-primary float-left" type="button" onClick={props.prevStep}>
              Previous
            </button>
          )} */}
          {!addBank && (
            <button onClick={showAddBank} className={`btn btn-primary float-right`}>
              Add Bank
            </button>
          )}
        </Col>
      </Row>
    </>
  );
};

const ButtonReject = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onConfirm = () => {
    // setModalReject(false);
    toggleModalReject().then(() => {
      props.onReject(props.id, reason);
      // setTimeout(() => {
      //   props.onConfirm();
      // }, 300);
    });
  };

  const [reason, setReason] = useState('');

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  return (
    <>
      <button className="btn btn-sm btn-danger w-60px float-right mx-1" onClick={toggleModalReject}>
        Reject
      </button>

      <SweetAlert
        title={<span className="text-black text-wrap">Bank Item</span>}
        danger
        showCancel
        confirmBtnText="Reject"
        confirmBtnBsStyle="danger"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onConfirm}
        onCancel={toggleModalReject}
        show={modalReject}
        dependencies={[reason]}
      >
        <form>
          <textarea
            type="text"
            className="form-control"
            value={reason}
            onChange={onChangeText}
            placeholder="Please indicate the reason for rejecting bank item"
          />
        </form>
      </SweetAlert>
    </>
  );
};

const ButtonApprove = (props) => {
  console.log('ButtonApprove', props);
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    // setModalReject(false);
    toggleModalReject().then(() => {
      props.onApprove(props.id);
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button
        className="btn btn-sm btn-primary float-right mx-1"
        type="button"
        onClick={toggleModalReject}
      >
        Approve
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Bank Details</span>}
        success
        showCancel
        confirmBtnText="Approve"
        confirmBtnBsStyle="success"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <p style={{ color: 'black' }}>Are you sure you want to approve this bank details?</p>
      </SweetAlert>
    </>
  );
};

const ButtonPrimary = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    // setModalReject(false);
    toggleModalReject().then(() => {
      props.onConfirm(props.id);
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button
        className="btn btn-sm btn-primary float-right mx-1"
        type="button"
        onClick={toggleModalReject}
      >
        Set as Primary
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Bank - Set as Primary</span>}
        info
        showCancel
        confirmBtnText="Set as Primary"
        confirmBtnBsStyle="info"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <p style={{ color: 'black' }}>Are you sure you want to set this bank as primary?</p>
      </SweetAlert>
    </>
  );
};

const ButtonDelete = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    toggleModalReject().then(() => {
      props.onConfirm(props.id);
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button
        className="btn btn-sm btn-danger float-right mx-1"
        type="button"
        onClick={toggleModalReject}
      >
        Delete
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Bank - Delete</span>}
        danger
        showCancel
        confirmBtnText="Delete"
        confirmBtnBsStyle="danger"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <p style={{ color: 'black' }}>Are you sure you want to delete this bank details?</p>
      </SweetAlert>
    </>
  );
};
export default memo(BanksList);

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      checked={props.checked}
      disabled={props.disabled || false}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
    />
    <label htmlFor={props.for}></label>
  </div>
));

import CreatableSelect from 'react-select/creatable';
import FileUpload from '../../../../components/forms/FileUpload';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isEmpty } from 'lodash';

const schemaWithdrawalMethod = yup.object().shape({
  // bank_name: yup.string().required('Bank Name is required'),
  bank_name: yup.string().when('withdrawal_type', {
    is: (withdrawalType) => withdrawalType == 1,
    then: yup.string().required('Bank name is required')
  }),
  wallet_name: yup.string().when('withdrawal_type', {
    is: (withdrawalType) => withdrawalType == 2,
    then: yup.string().required('E-Wallet is required')
  }),
  bank_address: yup.string().when('withdrawal_type', {
    is: (withdrawalType) => withdrawalType == 1,
    then: yup.string().required('Branch is required')
  }),
  swift_code: yup.string().when('withdrawal_type', {
    is: (withdrawalType) => withdrawalType == 1,
    then: yup.string().required('Swift code is required')
  }),
  account_name: yup.string().required('Account Name is required'),
  account_number: yup.string().typeError('Must be a number').required('Account Number is required')
});

const WITHDRAW_TYPE = [
  {
    value: 1,
    label: 'Bank'
  },
  {
    value: 2,
    label: 'E-Wallet'
  }
];

const E_WALLET = [
  {
    value: 'GCash',
    label: 'GCash',
    disabled: false
  },
  {
    value: 'Coins',
    label: 'Coins.ph',
    disabled: true
  }
];

const FormAddBank = (props) => {
  const { id } = useParams();
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      withdrawal_type: 1,
      bank_name: '',
      wallet_name: '',
      account_number: '',
      account_name: '',
      swift_code: '',
      bank_address: '',
      attachment: '',
      is_primary: false
    },
    resolver: yupResolver(schemaWithdrawalMethod)
  });

  const withdrawalType = watch('withdrawal_type', null);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const onSubmit = async (data) => {
    setLoadingSubmit(true);
    // const formData = new FormData();

    // for (let [key, value] of Object.entries({
    //   ...data,
    //   user_id: id,
    //   is_primary: data.is_primary ? 1 : 0,
    //   withdrawal_type: 1
    // })) {
    //   formData.append(`${key}`, key == 'attachment' ? value[0] : value);
    // }

    const isFile = (input) => 'File' in window && input instanceof File;
    // console.log(data.attachment);
    // if (data.attachment && isFile(data.attachment[0])) {
    //   let res = await uploadFiles([data.attachment[0]]);
    //   console.log(res);
    //   formData.append('attachment', res[0].id);
    // }

    // const fileCondition = (file) => {
    //   return file && (!isEmpty(file) || !isUndefined(file)) && file.status == 0;
    // };

    const bankParams = {
      user_id: id,
      withdrawal_type: data.withdrawal_type,
      bank_name: data.withdrawal_type == 1 ? data.bank_name : data.wallet_name,
      account_name: data.account_name,
      account_number: String(data.account_number),
      bank_address: data.bank_address ?? '',
      swift_code: data.swift_code ?? '',
      is_primary: 0
    };

    // if (fileCondition(data.attachment)) {
    //   let res = await uploadFiles([data.attachment]);
    //   Object.assign(bankParams, { attachment: res[0].id });
    // } else {
    //   Object.assign(bankParams, {
    //     attachment: !isEmpty(data.attachment) ? data.attachment.id : ''
    //   });
    // }

    if (data.attachment && isFile(data.attachment[0])) {
      let res = await uploadFiles([data.attachment[0]]);
      Object.assign(bankParams, { attachment: res[0].id });
    }

    API.userCreateBank(bankParams)
      .then((res) => {
        props.onSubmitBank();
        Notification.success('Add Bank', 'Successfully added bank');
        setLoadingSubmit(false);
      })
      .catch((err) => {
        Notification.error(err);
        setLoadingSubmit(false);
      });
  };

  const uploadFiles = async (files) => {
    const promises = await files.map(async (file, index) => {
      if (!has(file, 'status')) {
        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('dir', 'Attachments');
        return await API.fileUpload(formData)
          .then((res) => {
            files[index] = { label: file.label, ...res.data.data };
            return { label: file.label, status: 1, ...res.data.data };
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        return file;
      }
    });

    const responses = await Promise.all(promises);
    return responses;
  };

  const [optionBanks, setOptionBanks] = useState([]);
  useEffect(() => {
    API.dataSets(['banks']).then((res) => {
      setOptionBanks(res.data.banks);
    });
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label md="3">Account Type</Label>
        <Col md="9">
          <Controller
            name={`withdrawal_type`}
            isClearable
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <CreatableSelect
                inputRef={ref}
                value={WITHDRAW_TYPE.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                options={WITHDRAW_TYPE}
                isOptionDisabled={(option) => option.disabled}
              />
            )}
          />
          <FormFeedback style={{ display: errors.withdrawal_type ? 'block' : 'none' }}>
            {errors.withdrawal_type?.message}
          </FormFeedback>
        </Col>
      </FormGroup>
      {withdrawalType === 1 && (
        <>
          <FormGroup row>
            <Label md="3">Bank</Label>
            <Col md="9">
              <Controller
                name={`bank_name`}
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CreatableSelect
                    inputRef={ref}
                    value={optionBanks.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                    options={optionBanks}
                    isOptionDisabled={(option) => option.disabled}
                  />
                )}
              />
              <FormFeedback style={{ display: errors.bank_name ? 'block' : 'none' }}>
                {errors.bank_name?.message}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="3">Account Name</Label>
            <Col md="9">
              <Controller
                name={`account_name`}
                isClearable
                control={control}
                render={({ field }) => (
                  <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                )}
              />
              <FormFeedback style={{ display: errors.account_name ? 'block' : 'none' }}>
                {errors.account_name?.message}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="3">Account Number</Label>
            <Col md="9">
              <Controller
                name={`account_number`}
                isClearable
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="number"
                    className="number-only-field"
                    invalid={!isEmpty(errors.position)}
                  />
                )}
              />
              <FormFeedback style={{ display: errors.account_number ? 'block' : 'none' }}>
                {errors.account_number?.message}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="3">Branch</Label>
            <Col md="9">
              <Controller
                name={`bank_address`}
                isClearable
                control={control}
                render={({ field }) => (
                  <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                )}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="3">Swift Code</Label>
            <Col md="9">
              <Controller
                name={`swift_code`}
                isClearable
                control={control}
                render={({ field }) => (
                  <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                )}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="3">Attachment</Label>
            <Col md="9">
              <Controller
                name={`attachment`}
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => {
                  return (
                    <FileUpload
                      inputRef={ref}
                      onDrop={onChange}
                      onChange={onChange}
                      imagefile={value}
                      // invalid={!isEmpty(errors.proof_identity_file)}
                    />
                  );
                }}
              />
            </Col>
          </FormGroup>
        </>
      )}

      {withdrawalType === 2 && (
        <>
          <FormGroup row>
            <Label md="3">Bank</Label>
            <Col md="9">
              <Controller
                name={`wallet_name`}
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CreatableSelect
                    inputRef={ref}
                    value={E_WALLET.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                    options={E_WALLET}
                    isOptionDisabled={(option) => option.disabled}
                  />
                )}
              />
              <FormFeedback style={{ display: errors.bank_name ? 'block' : 'none' }}>
                {errors.bank_name?.message}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="3">Account Name</Label>
            <Col md="9">
              <Controller
                name={`account_name`}
                isClearable
                control={control}
                render={({ field }) => (
                  <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                )}
              />
              <FormFeedback style={{ display: errors.account_name ? 'block' : 'none' }}>
                {errors.account_name?.message}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="3">Account Number</Label>
            <Col md="9">
              <Controller
                name={`account_number`}
                isClearable
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="number"
                    className="number-only-field"
                    invalid={!isEmpty(errors.position)}
                  />
                )}
              />
              <FormFeedback style={{ display: errors.account_number ? 'block' : 'none' }}>
                {errors.account_number?.message}
              </FormFeedback>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md="3">Attachment</Label>
            <Col md="9">
              <Controller
                name={`attachment`}
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => {
                  return (
                    <FileUpload
                      inputRef={ref}
                      onDrop={onChange}
                      onChange={onChange}
                      imagefile={value}
                      // invalid={!isEmpty(errors.proof_identity_file)}
                    />
                  );
                }}
              />
            </Col>
          </FormGroup>
        </>
      )}

      <FormGroup row>
        <Label md="3">Set As Primary</Label>
        <Col md="9">
          <Controller
            name={`is_primary`}
            isClearable
            control={control}
            render={({ field: { value, onChange } }) => (
              <Switchery checked={value} onChange={onChange} for={`is_primary`} />
            )}
          />
        </Col>
      </FormGroup>

      <Row>
        <Col>
          <button className="btn btn-primary float-left" type="button" onClick={props.onCancel}>
            Cancel
          </button>
          <button
            type="submit"
            className={`btn ${loadingSubmit ? 'btn-gray' : 'btn-primary'} float-right`}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
          </button>
        </Col>
      </Row>
    </Form>
  );
};
