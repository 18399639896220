import React, { memo } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import FormIndustry from './components/FormIndustry.js';
import { useLocation } from 'react-router';

const IndustryView = () => {
  const location = useLocation();

  return (
    <div>
      <h1 className="page-header">Industry View</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader>Information</PanelHeader>
            <PanelBody>
              <FormIndustry action="update" values={{ ...location.state, attribute: '' }} />
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default memo(IndustryView);
