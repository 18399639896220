import React, { memo, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import * as API from '../../../services/api';
import { useActions, useAppState } from '../../../overmind';

const DropdownProfile = (props) => {
  const { currentUser } = useAppState();
  const actions = useActions();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const logout = () => {
    actions.userLogout().then(() => {
      props.history.push('/login');
    });
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown navbar-user" tag="li">
      <DropdownToggle tag="a">
        <img loading="lazy" src={currentUser && currentUser.photo.view} alt="" />
        <span className="d-none d-md-inline">{currentUser.full_name}</span>{' '}
        <b className="caret"></b>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
        <DropdownItem
          onClick={() => {
            props.history.push('/user/me');
          }}
        >
          Profile
        </DropdownItem>
        {/* <DropdownItem>
          <span className="badge badge-danger pull-right">2</span> Inbox
        </DropdownItem> */}
        <DropdownItem
          onClick={() => {
            props.history.push('/user/me/change-password');
          }}
        >
          Change Password
        </DropdownItem>
        {/* <DropdownItem>Setting</DropdownItem> */}
        <div className="dropdown-divider"></div>
        <DropdownItem onClick={logout}>Log Out</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default withRouter(DropdownProfile);
