import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { store } from 'react-notifications-component';
import * as API from '../../../../services/api';
import { Form, FormGroup, FormFeedback, Col, Label, Input } from 'reactstrap';
import { isEmpty } from 'lodash';
import Dropzone from 'react-dropzone';

import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import ReactSelect from 'react-select';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const schemaBannerCreate = yup.object().shape({
  title: yup.string().required('Title is required'),
  description: yup.string(),
  link: yup.string(),
  is_scheduled: yup.boolean(),
  with_expiry: yup.boolean()
});

const FormMessageBlast = (props) => {
  const {
    watch,
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      link: '',
      is_scheduled: false,
      with_expiry: false,
      ...props.values
    },
    resolver: yupResolver(schemaBannerCreate)
  });

  const withSchedule = watch('is_scheduled', false);
  const withExpiry = watch('with_expiry', false);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onIndustrySubmit = (data) => {
    if (props.action == 'create') {
      API.createIndustry(data)
        .then((res) => {
          store.addNotification({
            title: 'Industry Submitted',
            message: `Industry successfully created!`,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          props.history.push('/settings/industry');
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    } else {
      API.updateIndustry(props.values.id, data)
        .then((res) => {
          store.addNotification({
            title: 'Industry Updated',
            message: `Industry successfully updated!`,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          props.history.push('/settings/industry');
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    }
  };

  const optionType = [
    { value: 'email', label: 'Email' },
    { value: 'sms', label: 'SMS' },
    { value: 'push', label: 'Push Notification' }
  ];

  return (
    <Form onSubmit={handleSubmit(onIndustrySubmit)}>
      <FormGroup row>
        <Label md="3">Type</Label>
        <Col md="9">
          <Controller
            name="type"
            isClearable
            control={control}
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={optionType}
                isOptionDisabled={(option) => option.disabled}
              />
            )}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Subject</Label>
        <Col md="9">
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <Input {...field} type="text" invalid={!isEmpty(errors.title)} />
            )}
          />
          {errors.title && <FormFeedback>{errors.title?.message}</FormFeedback>}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Recipient</Label>
        <Col md="9">
          <Controller
            control={control}
            name="receipient"
            render={({ field }) => <Input {...field} type="textarea" />}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Compose</Label>
        <Col md="9">
          <Controller
            control={control}
            name="link"
            render={({ field }) => (
              <CKEditor
                editor={ClassicEditor}
                data="<p>Hello from CKEditor 5!</p>"
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                  console.log('Focus.', editor);
                }}
              />
            )}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3" />
        <Col md="9">
          <button
            type="submit"
            className={`btn ${loadingSubmit ? 'btn-secondary' : 'btn-primary'}`}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
          </button>
        </Col>
      </FormGroup>
    </Form>
  );
};

export default withRouter(FormMessageBlast);
