import React, { memo, useEffect, useMemo, useState, Suspense, lazy } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useLocation, useHistory, useParams } from 'react-router';
import { Row, Col, Label, Form, FormGroup, Input, Media, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PriceFormat } from '../../../utils/format.js';
// import * as API from '../../../services/api';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { Notification } from '../../../utils/index.js';
import * as API from '../../../services/api';
import moment from 'moment';
import LoadingSpinner from '../../../components/common/LoadingSpinner.js';
import { useAppState } from '../../../overmind/index.js';
require('codemirror/mode/xml/xml.js');
require('codemirror/mode/javascript/javascript.js');
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';

import BanksList from './BanksList.js';

// const BanksList = lazy(() => import('../BanksList.js'));

const ViewDetailWithdraw = (props) => {
  const { id } = useParams();
  const location = useLocation();

  const { state } = props.location;
  const [loadingTopUp, setLoadingTopUp] = useState(true);
  const [withdrawal, setWithdrawal] = useState({});

  const [banks, setBanks] = useState([]);

  useEffect(() => {
    API.viewFundRequest(id)
      .then((res) => {
        console.log(res);
        setWithdrawal({ ...withdrawal, ...res.data.data });
        setLoadingTopUp(false);
      })
      .catch((err) => {
        setWithdrawal(state);
        setLoadingTopUp(false);
      });
  }, []);

  const withdrawDetail = useMemo(() => {
    if (!loadingTopUp) {
      var withdrawalDetail = JSON.parse(withdrawal?.user_bank_details);
    }

    // return withdrawalDetail.map((wd) => (
    //   <FormGroup row>
    //   <Col md="3">Withdrawal Type</Col>
    //   <Col md="9">{withdrawal.payment_label}</Col>
    // </FormGroup>
    // ))

    return withdrawalDetail;
  }, [withdrawal, loadingTopUp]);

  const statusLabel = (status) => {
    switch (status) {
      case 1:
        return 'Approved';
      case 2:
        return 'Rejected';
      default:
        return 'Pending';
    }
  };
  return (
    <div>
      <h1 className="page-header">Withdraw Detail</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader>Details</PanelHeader>
            <PanelBody>
              {loadingTopUp ? (
                <LoadingSpinner />
              ) : (
                <FormGroup tag="fieldset">
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Col md="3">
                          <strong>Amount</strong>
                        </Col>
                        <Col md="9">
                          <strong>₱{withdrawal.amount}</strong>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">Withdrawal Type</Col>
                        <Col md="9">{withdrawal.payment_label}</Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">Status</Col>
                        <Col md="9">{statusLabel(withdrawal.status)}</Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">Account Name</Col>
                        <Col md="9">
                          <Link
                            to={`/investors/${withdrawal.user_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {withdrawal.user_full_name}
                          </Link>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col md="3">Date Requested</Col>
                        <Col md="9">
                          {moment(withdrawal.created_at).format('MMMM DD, YYYY hh:mm A')}
                        </Col>
                      </FormGroup>
                      {/* {withdrawal.status > 0 && (
                        <FormGroup row>
                          <Col md="3">Date Approved/Rejected</Col>
                          <Col md="9">
                            {moment(withdrawal.updated_at).format('MMMM DD, YYYY hh:mm A')}
                          </Col>
                        </FormGroup>
                      )} */}

                      {/* <FormGroup row>
                      <Col md="3">Contact Number</Col>
                      <Col md="9">+639164289004</Col>
                    </FormGroup> */}
                    </Col>
                    <Col>
                      <FormGroup row>
                        <Col md="12">
                          <h5>
                            <strong>Withdraw Account Details</strong>
                          </h5>
                        </Col>
                      </FormGroup>
                      {Object.entries(withdrawDetail).map(([key, value]) => (
                        <FormGroup row>
                          <Col md="3">{key}</Col>
                          <Col md="9">{value}</Col>
                        </FormGroup>
                      ))}
                    </Col>
                  </Row>
                </FormGroup>
              )}
              {withdrawal.status == 0 && (
                <Row>
                  <Col className="d-flex justify-content-end">
                    <ButtonReject />
                    <ButtonApprove />
                  </Col>
                </Row>
              )}
            </PanelBody>
          </Panel>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <Suspense fallback={<LoadingSpinner />}>
            <BanksList id={withdrawal.user_id} />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default memo(ViewDetailWithdraw);

const ButtonReject = (props) => {
  const { id } = useParams();
  const { badges } = useAppState();

  const history = useHistory();
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const [reason, setReason] = useState('');

  const onReject = () => {
    setModalReject(false);
    if (badges.jobs > 0) {
      Notification.info('Server Background Processing', 'Please try again later.');
    } else {
      API.requestWithdrawReject(id, { reason })
        .then((res) => {
          Notification.success(
            'Withdraw Request Rejected',
            'Withdraw request successfully rejected!'
          );
        })
        .catch((err) => {
          Notification.error(err);
        });
      toggleModalReject().then(() => {
        history.goBack();
      });
    }
  };

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-danger mr-2" type="button" onClick={toggleModalReject}>
        Reject
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Withdraw Request</span>}
        danger
        showCancel
        confirmBtnText="Reject"
        confirmBtnBsStyle="danger"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
        dependencies={[reason]}
      >
        <form>
          <textarea
            type="text"
            className="form-control"
            value={reason}
            onChange={onChangeText}
            placeholder="Please indicate the reason for rejecting withdraw request"
          />
        </form>
      </SweetAlert>
    </>
  );
};

const ButtonApprove = (props) => {
  const { id } = useParams();
  const { badges } = useAppState();

  const history = useHistory();
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    setModalReject(false);

    if (badges.jobs > 0) {
      Notification.info('Server Background Processing', 'Please try again later.');
    } else {
      API.requestWithdrawApprove(id)
        .then(() => {
          Notification.success(
            'Withdraw Request Approved',
            'Withdraw request successfully approved!'
          );
          toggleModalReject().then(() => {
            history.goBack();
          });
        })
        .catch((err) => {
          Notification.error(err);
          toggleModalReject();
        });
    }
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-green text-white" type="button" onClick={toggleModalReject}>
        Approve
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Withdraw Request</span>}
        success
        showCancel
        confirmBtnText="Approve"
        confirmBtnBsStyle="success"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <p style={{ color: 'black' }}>Are you sure you want to approve this withdraw request?</p>
      </SweetAlert>
    </>
  );
};
