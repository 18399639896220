import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel.jsx';
import { Table, RowActionItems, RowItemDelete, RowItemView } from '../../../../components/table';
import PageContext from '../../../../config/page-settings.js';
import { Notification } from '../../../../utils/index.js';
import * as API from '../../../../services/api';

const PaymentOptionList = () => {
  const history = useHistory();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const onChangeKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const getRequestParams = (keyword, page, pageSize) => {
    let params = {};

    if (keyword) {
      params['filter[name]'] = keyword;
    }

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    return params;
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    const params = getRequestParams(keyword, page, pageSize);
    setIsLoading(true);
    API.getPaymentOptions(params)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        setData([]);
        setMeta({});
        setIsLoading(false);
      });
  };

  useEffect(fetchData, [page, pageSize]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        sortable: true
      },
      {
        header: 'Payment Type',
        accessorFn: (d) => {
          return d.payment_type && d.payment_type.name;
        },
        sortable: true
      },
      {
        header: 'Payment Gateway',
        accessorFn: (d) => {
          return d.payment_gateway && d.payment_gateway.name;
        },
        sortable: true
      },
      {
        header: 'Top Up Enabled',
        cell: ({ cell }) => {
          const { original, index } = cell.row;
          const onChange = () => {
            updateRow(index, cell.column.id, !original.is_active);
            const params = new URLSearchParams();
            params.append('is_active', !original.is_active ? 1 : 0);
            API.patchPaymentOption(original.id, params)
              .then((res) => {
                Notification.success(
                  'Update Payment Option',
                  `Payment Option set to ${!original.is_active ? 'active' : 'inactive'}`
                );
              })
              .catch((err) => {
                Notification.error('Error Action', `Unable to Submit`);
              });
          };

          return (
            <div className="with-btn text-nowrap d-flex justify-content-center">
              <div className="input-group justify-content-center">
                <div className="switcher">
                  <input
                    checked={original.is_active}
                    onChange={onChange}
                    type="checkbox"
                    name={`${index}_is_active`}
                    id={`${index}_is_active`}
                  />
                  <label htmlFor={`${index}_is_active`}></label>
                </div>
              </div>
            </div>
          );
        }
      },
      {
        header: 'Action Items',
        cell: ({ cell }) => {
          const { original, index } = cell.row;

          const onDelete = () => {
            API.deletePaymentOption(original.id).then((res) => {
              const dataCopy = [...data];
              dataCopy.splice(index, 1);
              setData(dataCopy);
              Notification.success('Payment Option Delete', 'Payment Options successfully deleted');
            });
          };

          const onView = () => {
            history.push(`/settings/payment/options/${original.id}`, original);
          };

          return (
            <RowActionItems>
              <RowItemView onClick={onView} />
              <RowItemDelete onConfirm={onDelete} />
            </RowActionItems>
          );
        }
      }
    ],
    [data]
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Panel>
      <PanelHeader>Payment Option List</PanelHeader>
      <PanelBody>
        <Table
          columns={columns}
          data={data}
          meta={meta}
          isLoading={isLoading}
          onSetPageSize={handlePageSizeChange}
          updateRow={updateRow}
          onNextPage={handlePageChange('next')}
          onPrevPage={handlePageChange('prev')}
          skipPageReset={skipPageReset}
          onChangeKeyword={onChangeKeyword}
          keyword={keyword}
          onSearchKeyword={handleSearch}
        />
      </PanelBody>
    </Panel>
  );
};

export default memo(PaymentOptionList);
