import React, { memo, useEffect, useState } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useLocation, useHistory, useParams } from 'react-router';
import { Row, Col, Label, Form, FormGroup, Input, Media, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { PriceFormat } from '../../../utils/format.js';
// import * as API from '../../../services/api';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { Notification } from '../../../utils/index.js';
import * as API from '../../../services/api';
import moment from 'moment';
import LoadingSpinner from '../../../components/common/LoadingSpinner.js';
require('codemirror/mode/xml/xml.js');
require('codemirror/mode/javascript/javascript.js');

const CoinsDetail = (props) => {
  const { id } = useParams();
  const location = useLocation();

  const { state } = props.location;
  console.log('state', state);

  const [coinsph, setCoinsph] = useState(state);

  //   const [loadingHistory, setLoadingHistory] = useState(false);
  //   const [history, setHistory] = useState([]);

  //   useEffect(() => {
  //     API.getPaynamicsTransaction(id)
  //       .then((res) => {
  //         setPaynamics(res.data.data);
  //         setLoadingHistory(false);
  //       })
  //       .catch((err) => {
  //         setPaynamics(state);
  //         setHistory([]);
  //         setLoadingHistory(false);
  //       });
  //   }, []);

  return (
    <div>
      <h1 className="page-header">CoinsPH Detail</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader>Details</PanelHeader>
            <PanelBody>
              <FormGroup tag="fieldset">
                <Row>
                  <Col>
                    <FormGroup row>
                      <Col md="3">Date</Col>
                      <Col md="9">{coinsph.created_at}</Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md="3">Customer</Col>
                      <Col md="9">{coinsph.user_full_name}</Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md="3">Amount</Col>
                      <Col md="9">₱{coinsph.amount}</Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup row>
                      <Col md="3">Reference ID</Col>
                      <Col md="9">{coinsph.ref_id}</Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md="3">Type</Col>
                      <Col md="9">{coinsph.type_name}</Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md="3">Status</Col>
                      <Col md="9">{coinsph.status_name}</Col>
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default memo(CoinsDetail);
