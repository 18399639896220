const Menu = (count) => {
  return [
    { path: '/', icon: 'fa fa-th', exact: true, title: 'Dashboard' },
    {
      path: '/requests/*',
      icon: 'fa fa-tasks',
      exact: true,
      title: 'Request',
      children: [
        {
          path: '/request/investors',
          exact: true,
          title: 'Investors',
          badge: count && String(count.investors)
        },
        {
          path: '/request/issuers',
          exact: true,
          title: 'Issuers',
          badge: count && String(count.issuers)
        },
        {
          path: '/request/banks',
          exact: true,
          title: 'Banks',
          badge: count && String(count.banks)
        },
        {
          path: '/request/topups',
          exact: true,
          title: 'Top Ups',
          badge: count && String(count['top-ups'])
        },
        {
          path: '/request/withdrawals',
          exact: true,
          title: 'Withdrawals',
          badge: count && String(count.withdrawals)
        },
        { path: '/request/loans', exact: true, title: 'Loans', badge: count && String(count.loans) }
      ]
    },
    { path: '/investors', icon: 'fa fa-users', exact: true, title: 'Investors' },
    { path: '/issuers', icon: 'fa fa-building', exact: true, title: 'Issuers' },
    { path: '/projects', icon: 'fa fa-chart-bar', exact: true, title: 'Projects' },
    {
      path: '/transactions/*',
      icon: 'fa fa-calculator',
      exact: true,
      title: 'Transactions',
      children: [
        { path: '/transactions/investors', exact: true, title: 'Investors' },
        { path: '/transactions/issuers', exact: true, title: 'Issuers' },
        { path: '/transactions/paynamics', exact: true, title: 'Paynamics' },
        { path: '/transactions/coinsph', exact: true, title: 'CoinsPH' },
        { path: '/transactions/xendit', exact: true, title: 'Xendit' },
        { path: '/transactions/denarii', exact: true, title: 'Denarii' }
      ]
    },
    {
      path: '/logs/*',
      icon: 'fa fa-list-alt',
      exact: true,
      title: 'Logs',
      children: [
        { path: '/logs/activity', exact: true, title: 'Activity Logs' },
        { path: '/logs/system', exact: true, title: 'System Logs' },
        { path: '/logs/notification', exact: true, title: 'Notification Logs' }
      ]
    },
    {
      path: '/marketing/*',
      icon: 'fa fa-list-alt',
      exact: true,
      title: 'Marketing',
      children: [
        { path: '/marketing/news', exact: true, title: 'News' },
        { path: '/marketing/blast', exact: true, title: 'Message Blast' }
      ]
    },
    {
      path: '/reports/*',
      icon: 'fa fa-clipboard-list',
      exact: true,
      title: 'Reports',
      children: [
        { path: '/reports/generate', exact: true, title: 'Report Generate' },
        { path: '/reports/charts', exact: true, title: 'Report Chart' }
      ]
    },
    {
      path: '/merchants/*',
      icon: 'fa fa-handshake',
      exact: true,
      title: 'Merchants',
      children: [
        { path: '/merchants/partners', exact: true, title: 'Partners' },
        { path: '/merchants/perks', exact: true, title: 'Perks' }
        // { path: '/merchants/categories', exact: true, title: 'Categories' }
      ]
    },
    {
      path: '/settings/*',
      icon: 'fa fa-cogs',
      exact: true,
      title: 'Settings',
      children: [
        { path: '/settings/users', exact: true, title: 'Users' },
        { path: '/settings/roles', exact: true, title: 'Roles' },
        { path: '/settings/countries', exact: true, title: 'Countries' },
        { path: '/settings/banks', exact: true, title: 'Banks' },
        { path: '/settings/documents', exact: true, title: 'Document Requirements' },
        {
          path: '/settings/payment',
          exact: true,
          title: 'Payment',
          children: [
            {
              path: '/settings/payment/types',
              exact: true,
              title: 'Types'
            },
            { path: '/settings/payment/options', exact: true, title: 'Options' }
          ]
        },
        { path: '/settings/entity', exact: true, title: 'Entity' },
        { path: '/settings/industry', exact: true, title: 'Industry' },
        { path: '/settings/global-settings', exact: true, title: 'Global Settings' },
        { path: '/settings/app-versions', exact: true, title: 'App Versions' }
      ]
    }
  ];
};
export default Menu;
