import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import PageContext from '../../../config/page-settings.js';
import * as API from '../../../services/api.js';
import {
  Table,
  RowActionItems,
  RowItemDelete,
  RowItemView
} from '../../../components/table/index.js';
import { Notification } from '../../../utils/index.js';

const PerksList = (props) => {
  const history = useHistory();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [skipPageReset, setSkipPageReset] = useState(false);

  useEffect(() => {
    fetchIndustyList();
  }, [props]);

  const fetchIndustyList = () => {
    API.getPerks().then((res) => {
      console.log(res);
      setData(res.data.data);
      setMeta(res.data.meta);
    });
  };

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'business_name',
        sortable: true
      },
      {
        header: 'Perk',
        cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <>
              <span>{original.title}</span>
              <br />
              <small>{original.subtext}</small>
            </>
          );
        }
      },
      {
        header: 'Countries',
        cell: ({ cell }) => {
          const { original } = cell.row;
          return JSON.parse(original.countries).map((country) => <span>{country.label}</span>);
        }
      },
      {
        header: 'Categories',
        cell: ({ cell }) => {
          const { original } = cell.row;
          return JSON.parse(original.categories).map((category) => <span>{category.label}</span>);
        }
      },
      {
        header: 'Action Items',
        cell: ({ cell }) => {
          const { original } = cell.row;
          const viewIndusry = () => {
            history.push(`/merchants/perks/${original.id}`, original);
          };

          return (
            <RowActionItems>
              <RowItemView onClick={viewIndusry} />
            </RowActionItems>
          );
        }
      }
    ],
    []
  );

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  const onCreate = () => {
    history.push('/merchants/perks/create');
  };

  return (
    <>
      <h1 className="page-header">
        Perks{' '}
        <button onClick={onCreate} className="btn btn-primary btn-icon btn-circle btn-md ml-2">
          <i className="fa fa-plus"></i>
        </button>
        <button className="btn btn-secondary btn-icon btn-circle btn-md ml-2">
          <i className="fa fa-download"></i>
        </button>
      </h1>
      <Panel>
        <PanelHeader>Perks List</PanelHeader>
        <PanelBody>
          <Table
            columns={columns}
            data={data}
            meta={meta}
            updateRow={updateRow}
            skipPageReset={skipPageReset}
            onSetPageSize={handlePageSizeChange}
            updateRow={updateRow}
            onNextPage={handlePageChange('next')}
            onPrevPage={handlePageChange('prev')}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default memo(PerksList);
