import React, { memo, useState, useMemo, useEffect, useContext } from 'react';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, FormGroup, FormFeedback, Row, Col, Label, Input, Tooltip } from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';

import { isEmpty, isUndefined, has } from 'lodash';
import ReactSelect from 'react-select';
import PageContext from '../../../config/page-settings.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as API from '../../../services/api';
import moment from 'moment';
import FileUpload from '../../../components/forms/FileUpload.js';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import PageHeader from '../../../components/common/PageHeader.js';
import { Notification, PriceFormat } from '../../../utils/index.js';

import DateTime from 'react-datetime';

import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';

import AsyncSelect from 'react-select/async';

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      checked={props.checked}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
      disabled={props.disabled || false}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const TooltipItem = (props) => {
  const { placement, id, content } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <i className="fa fa-exclamation-circle text-info ml-1" id={'Tooltip-' + id} />
      <Tooltip placement={placement} isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
        {content}
      </Tooltip>
    </span>
  );
};

const schemaMerchant = yup.object().shape({
  business_name: yup.string().required('Business name is required'),
  business_address: yup.string().required('Business Address is required'),
  trade_name: yup.string().required('Trade name is required'),
  overview: yup.string().required('Overview is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  mobile_number: yup
    .string()
    .required('Mobile number is required')
    .min(8, 'Mobile number is too short - Must be minimum 10 characters')
    .matches(/^\d+$/, 'Mobile number is invalid'),
  landline_number: yup.string().matches(/^\d+$/, 'Landline is invalid'),
  logo: yup
    .mixed()
    .required('Logo is required')
    .test('fileSize', 'The file is too large', (value) => {
      return value && value[0].size <= 2000000;
    })
    .test('type', 'Only the following formats are accepted:.png .jpeg, .jpg,', (value) => {
      return (
        value &&
        (value[0].type === 'image/jpeg' ||
          value[0].type === 'image/jpg' ||
          value[0].type === 'image/png')
      );
    })
});

const PartnersCreate = () => {
  const { user_id, loan_request_id } = useParams();
  const history = useHistory();

  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const {
    watch,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    control,
    reset
  } = useForm({
    // defaultValues: useMemo(() => {
    //   return {
    //     funding_id: `${moment().format('YMD')}${Math.floor(1000 + Math.random() * 9999)}`,
    //     has_guarantor: false,
    //     has_pledge: false,
    //     has_visitation: false,
    //     subscription_days: 30,
    //     repayment_mode: 1,
    //     title: '',
    //     overview: '',
    //     loan_display_investors: 1,
    //     minimum_lend_amount: 1000,
    //     auto_invest_enabled: true,
    //     allow_priority_investor: false
    //   };
    // }, []),
    resolver: yupResolver(schemaMerchant)
  });

  //   const watchIssuer = watch('issuer', { id: null });
  //   const watchLoan = watch('loan', { id: null });
  //   const watchReturns = watch('returns');
  //   const watchSubDays = watch('subscription_days');

  //   const [optionIssuers, setOptionIssuers] = useState([]);

  //   const [loanScheduleList, setLoanScheduleList] = useState([]);
  //   const [loanScheduleTotal, setLoanScheduleTotal] = useState({});

  const [optionCategories, setOptionCategories] = useState([]);
  const [optionCountries, setOptionCountries] = useState([]);

  const [optionCountryCode, setCountryCodes] = useState([]);

  useEffect(() => {
    API.dataSets(['merchant_category', 'countries', 'country_codes']).then((res) => {
      console.log(res);
      setOptionCategories(res.data.merchant_category);
      setOptionCountries(res.data.countries);
      setCountryCodes(res.data.country_codes);
    });
  }, []);

  const promiseOptions = (inputValue) => {
    new Promise((resolve) => {
      const params = getRequestParams(inputValue);
      setTimeout(() => {
        API.searchIssuer(params).then((res) => {
          console.log(res.data.data);
          var optionIssuers = res.data.data.map((issuer) => ({
            value: issuer.id,
            label: issuer && issuer.company_name
          }));

          console.log('optionIssuers', optionIssuers);

          resolve(optionIssuers);
        });
      }, 1000);
    });
  };

  const getRequestParams = (keyword) => {
    let params = {};

    if (keyword) {
      params['filter[company_name]'] = keyword;
    }

    return params;
  };

  // const fetchData = () => {
  //   const params = getRequestParams(watchIssuer.value);
  //   API.searchIssuer(params)
  //     .then((response) => {
  //       const { data, meta } = response.data;
  //       console.log(data);
  //       // setData(data);
  //       // setMeta(meta);
  //       // setIsLoading(false);
  //     })
  //     .catch((e) => {
  //       // setData([]);
  //       // setMeta({});
  //       // setIsLoading(false);
  //     });
  // };
  // useEffect(fetchData, [watchIssuer]);

  //   useEffect(() => {
  //     setValue(
  //       'issuer',
  //       optionIssuers.find((issuer) => issuer.id == user_id)
  //     );
  //   }, [user_id, optionIssuers]);

  //   const watchPublishDate = watch('publish_date', moment().format('YYYY-MM-DD'));

  //   const watchRepaymentMode = watch('repayment_mode');

  //   const [optionIssuerLoans, setOptionIssuerLoans] = useState([]);

  //   useEffect(() => {
  //     if (!isEmpty(watchIssuer)) {
  //       API.getIssuerLoans(watchIssuer.id).then((res) => {
  //         var optionIssuerLoans = res.data.data.map((loan) => ({
  //           ...loan,
  //           value: loan.id,
  //           label: `LOAN ID: ${loan.id} - ${loan.purpose} (${
  //             loan && loan.status && loan.status.name
  //           })`,
  //           disabled: loan && loan.status && loan.status.id != 6
  //         }));
  //         setOptionIssuerLoans(optionIssuerLoans);
  //       });
  //     }
  //   }, [watchIssuer]);

  //   useEffect(() => {
  //     setValue('loan_tenor', watchLoan && watchLoan.loan_tenor);
  //   }, [watchLoan]);

  //   useEffect(() => {
  //     setValue(
  //       'repayment_start_date',
  //       watchPublishDate &&
  //         moment(watchPublishDate, 'YYYY-MM-DD')
  //           .add(Number(getValues('subscription_days')) - 1, 'days')
  //           .format('YYYY-MM-DD')
  //     ) || moment().add(30, 'days').format('YYYY-MM-DD');
  //   }, [watchPublishDate]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  console.log(errors);
  const onSubmitForm = async (data) => {
    setLoadingSubmit(true);
    const isFile = (input) => 'File' in window && input instanceof File;

    const params = new FormData();

    if (data.logo && isFile(data.logo[0])) {
      let res = await uploadFiles([data.logo[0]]);
      params.append('logo', res[0].id);
    }

    params.append('business_name', data.business_name);
    params.append('business_address', data.business_address);
    params.append('trade_name', data.trade_name);
    params.append('email', data.email);
    params.append('mobile_code', data.mobile_code);
    params.append('mobile_number', data.mobile_number);
    params.append('landline_number', data.landline_number);
    params.append('overview', data.overview);
    params.append('countries', JSON.stringify(data.countries));
    params.append('categories', JSON.stringify(data.categories));

    if (data.agreement && isFile(data.agreement[0])) {
      let res = await uploadFiles([data.agreement[0]]);
      params.append('agreement', res[0].id);
    }

    params.append('is_active', data.is_active ? 1 : 0);

    API.createMerchant(params)
      .then((res) => {
        Notification.success('Merchant Created', 'Merchant successfully created!');
        history.push('/merchants/partners');
      })
      .catch((err) => {
        Notification.error(err);
      })
      .finally(() => setLoadingSubmit(false));
  };

  const uploadFiles = async (files) => {
    const promises = await files.map(async (file, index) => {
      if (!has(file, 'status')) {
        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('dir', 'Banners');
        return await API.fileUpload(formData)
          .then((res) => {
            files[index] = { label: file.label, ...res.data.data };
            return { label: file.label, status: 1, ...res.data.data };
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        return file;
      }
    });

    const responses = await Promise.all(promises);
    return responses;
  };

  return (
    <>
      <PageHeader title="Partners Create" />
      <Panel>
        <PanelHeader></PanelHeader>
        <PanelBody>
          <Row>
            <Col sm="12" md="12" lg={{ size: 10, offset: 1 }}>
              <Form onSubmit={handleSubmit(onSubmitForm)}>
                <FormGroup tag="fieldset">
                  <h3>Merchant Details</h3>
                  <FormGroup row>
                    <Label md="3">Logo</Label>
                    <Col md="9">
                      <Controller
                        name="logo"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <FileUpload
                            onDrop={onChange}
                            onChange={onChange}
                            imagefile={value}
                            invalid={!isEmpty(errors.logo)}
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.logo ? 'block' : 'none' }}>
                        {errors.logo?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Business Name</Label>
                    <Col md="9">
                      <Controller
                        name="business_name"
                        control={control}
                        render={({ field }) => (
                          <Input {...field} type="text" invalid={!isEmpty(errors.business_name)} />
                        )}
                      />
                      <FormFeedback style={{ display: errors.business_name ? 'block' : 'none' }}>
                        {errors.business_name?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Business Address</Label>
                    <Col md="9">
                      <Controller
                        name="business_address"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            invalid={!isEmpty(errors.business_address)}
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.business_address ? 'block' : 'none' }}>
                        {errors.business_address?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Trading As</Label>
                    <Col md="9">
                      <Controller
                        name="trade_name"
                        control={control}
                        render={({ field }) => (
                          <Input {...field} type="text" invalid={!isEmpty(errors.trade_name)} />
                        )}
                      />
                      <FormFeedback style={{ display: errors.trade_name ? 'block' : 'none' }}>
                        {errors.trade_name?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Overview</Label>
                    <Col md="9">
                      <Controller
                        name="overview"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="textarea"
                            rows="5"
                            invalid={!isEmpty(errors.overview)}
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.overview ? 'block' : 'none' }}>
                        {errors.overview?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Countries</Label>
                    <Col md="9">
                      <Controller
                        name="countries"
                        control={control}
                        defaultValue={[]}
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            inputRef={ref}
                            isMulti
                            value={value}
                            onChange={onChange}
                            options={optionCountries}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Categories</Label>
                    <Col md="9">
                      <Controller
                        name="categories"
                        control={control}
                        defaultValue={[]}
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            inputRef={ref}
                            isMulti
                            value={value}
                            onChange={onChange}
                            options={optionCategories}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Merchant Agreement</Label>
                    <Col md="9">
                      <Controller
                        name="agreement"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <FileUpload
                            onDrop={onChange}
                            onChange={onChange}
                            imagefile={value}
                            invalid={!isEmpty(errors.banner)}
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.banner ? 'block' : 'none' }}>
                        {errors.banner?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <hr />

                  <h3>Merchant Account Details</h3>

                  <FormGroup row>
                    <Label md="3">Email</Label>
                    <Col md="9">
                      <Controller
                        control={control}
                        name="email"
                        render={({ field }) => (
                          <Input {...field} type="email" invalid={!isEmpty(errors.email)} />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Mobile Number</Label>
                    <Col md="3" sm="4">
                      <Controller
                        control={control}
                        name="mobile_code"
                        isClearable
                        defaultValue={optionCountryCode.map((c) => c.value)}
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            inputRef={ref}
                            value={optionCountryCode.find((c) => c.value === value)}
                            onChange={(val) => onChange(val.value)}
                            options={optionCountryCode}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        )}
                      />
                      {errors.mobile_code && (
                        <FormFeedback>{errors.mobile_code?.message}</FormFeedback>
                      )}
                    </Col>
                    <Col md="6" sm="8">
                      <Controller
                        control={control}
                        name="mobile_number"
                        render={({ field }) => (
                          <Input {...field} type="text" invalid={!isEmpty(errors.mobile_number)} />
                        )}
                      />
                      {errors.mobile_number && (
                        <FormFeedback>{errors.mobile_number?.message}</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Landline Number</Label>

                    <Col md="9">
                      <Controller
                        control={control}
                        name="landline_number"
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            invalid={!isEmpty(errors.landline_number)}
                          />
                        )}
                      />
                      {errors.landline_number && (
                        <FormFeedback>{errors.landline_number?.message}</FormFeedback>
                      )}
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Is Active</Label>
                    <Col md="9">
                      <Controller
                        name="is_active"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Switchery checked={value} onChange={onChange} for="has_pledge" />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3" />
                    <Col md="9">
                      <button
                        type="submit"
                        className={`btn ${loadingSubmit ? 'btn-secondary' : 'btn-primary'}`}
                        disabled={loadingSubmit}
                      >
                        {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
                      </button>
                    </Col>
                  </FormGroup>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
};

export default PartnersCreate;

const ButtonApprove = ({ onSubmit, disabled, isLoading, loan, project }) => {
  const [modal, setModal] = useState(false);

  const toggleModal = async () => {
    await setModal(!modal);
  };

  const onConfirm = () => {
    toggleModal().then(() => {
      onSubmit();
    });
  };

  return (
    <>
      <button
        className={`btn mr-2 text-white ${disabled || isLoading ? 'btn-gray' : 'btn-success'}`}
        type="button"
        disabled={disabled}
        onClick={toggleModal}
      >
        {isLoading ? <i className="fas fa-spinner fa-spin"></i> : 'Start Project'}
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Start Project?</span>}
        success
        showCancel
        confirmBtnText="Start"
        confirmBtnBsStyle="success"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onConfirm}
        onCancel={toggleModal}
        show={modal}
      >
        <p>Are you sure you want to start this project?</p>
      </SweetAlert>
    </>
  );
};
