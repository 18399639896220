import React, { useState, useEffect, memo } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Row,
  Col,
  Label,
  Form,
  FormGroup,
  Tooltip,
  Input,
  FormText,
  FormFeedback
} from 'reactstrap';

import { isEmpty } from 'lodash';
import CreatableSelect from 'react-select/creatable';
import * as API from '../../../services/api';
import FileUpload from '../../../components/forms/FileUpload';

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      checked={props.checked}
      disabled={props.disabled || false}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const schemaWithdrawalMethod = yup.object().shape({
  methods: yup.array().of(
    yup.object().shape({
      full_name: yup.string().required('Full name is required.'),
      position: yup.string().required('Current position is required.'),
      function: yup.string().required('Current function is required.'),
      address: yup.string().required('Address is required.')
    })
  )
});

const FormWithdrawalMethod = (props) => {
  const { investor } = props;

  const defaultValues = {
    banks: [
      {
        is_primary: true
      }
    ]
  };

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch
  } = useForm({
    defaultValues,
    resolver: yupResolver(schemaWithdrawalMethod)
  });

  if (props.currentStep !== 3) {
    return null;
  }
  const onSubmit = (data) => {
    const formData = new FormData();

    data.banks.forEach((bank, index) => {
      for (let [key, value] of Object.entries({
        ...bank,
        user_id: investor.id,
        is_primary: bank.is_primary ? 1 : 0,
        withdrawal_type: 1
      })) {
        formData.append(key, key == 'attachment' ? value[0] : value);
      }
    });

    API.userCreateBank(formData)
      .then((res) => {
        props.onSubmit();
        console.log('userCreateBankRes', res);
      })
      .catch((err) => {
        console.log('userCreateBankErr', err.response);
      });
  };

  const enableBanks = watch('enable_banks', false);
  const enableGcash = watch('enable_gcash', false);
  const enableCoinsPH = watch('enable_coinsph', false);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup tag="fieldset">
        <FormGroup row>
          <Label md="3">
            <h3 className="my-0">Bank Transfer</h3>
          </Label>
          <Col md="9" className="d-flex align-items-center">
            <Controller
              name="enable_banks"
              isClearable
              control={control}
              render={({ field: { value, onChange } }) => (
                <Switchery checked={value} onChange={onChange} for="enable_banks" />
              )}
            />
          </Col>
        </FormGroup>
        {enableBanks && (
          <FieldArray {...{ control, register, defaultValues, getValues, setValue, errors }} />
        )}
        <hr />
        <FormGroup row>
          <Label md="3">
            <h3>GCash</h3>
          </Label>
          <Col md="9" className="d-flex align-items-center">
            <Controller
              name="enable_gcash"
              isClearable
              control={control}
              render={({ field: { value, onChange } }) => (
                <Switchery
                  checked={value}
                  onChange={(e) => {
                    API.patchOtherBank(investor.id, 'gcash', { is_active: value ? 1 : 0 });
                    onChange(e);
                  }}
                  for="enable_gcash"
                />
              )}
            />
          </Col>
        </FormGroup>
        {enableGcash && (
          <>
            <FormGroup row>
              <Label md="3">Account Name</Label>
              <Col md="9">
                <Controller
                  name="gcash_name"
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      disabled
                      invalid={!isEmpty(errors.gcash_name)}
                      value={`${investor.first_name} ${investor.last_name}`}
                    />
                  )}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Mobile Number</Label>
              <Col md="9">
                <Controller
                  name="gcash_number"
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      disabled
                      value={investor.otp_number}
                      invalid={!isEmpty(errors.gcash_number)}
                    />
                  )}
                />
              </Col>
            </FormGroup>
          </>
        )}

        <hr />
        {/* <h3>Coins PH</h3> */}
        <FormGroup row>
          <Label md="3">
            <h3>Coins PH</h3>
          </Label>
          <Col md="9" className="d-flex align-items-center">
            <Controller
              name="enable_coinsph"
              isClearable
              control={control}
              render={({ field: { value, onChange } }) => (
                <Switchery
                  checked={value}
                  onChange={(e) => {
                    API.patchOtherBank(investor.id, 'coinsph', { is_active: value ? 1 : 0 });
                    onChange(e);
                  }}
                  for="enable_coinsph"
                />
              )}
            />
          </Col>
        </FormGroup>
        {enableCoinsPH && (
          <>
            <FormGroup row>
              <Label md="3">Account Name</Label>
              <Col md="9">
                <Controller
                  name="coinsph_name"
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      disabled
                      value={`${investor.first_name} ${investor.last_name}`}
                      invalid={!isEmpty(errors.coinsph_name)}
                    />
                  )}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Mobile Number</Label>
              <Col md="9">
                <Controller
                  name="coinsph_number"
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      disabled
                      value={investor.otp_number}
                      invalid={!isEmpty(errors.coinsph_number)}
                    />
                  )}
                />
              </Col>
            </FormGroup>
          </>
        )}

        <hr />
        <Row>
          <Col>
            {props.currentStep > 1 && (
              <button className="btn btn-primary float-left" type="button" onClick={props.prevStep}>
                Previous
              </button>
            )}
            <button className="btn btn-primary float-right" type="submit">
              Next
            </button>
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
};

export default FormWithdrawalMethod;

const FieldArray = ({ control, register, errors, setValue, getValues }) => {
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'banks'
  });

  const [optionBanks, setOptionBanks] = useState([]);
  useEffect(() => {
    API.dataSets(['banks']).then((res) => {
      setOptionBanks(res.data.banks);
    });
  }, []);

  const setAsPrimary = (indexToBePrimary) => {
    getValues().banks.forEach((_value, index) => {
      setValue(`banks.${index}.is_primary`, index === indexToBePrimary);
    });
  };

  return (
    <>
      {fields.map((item, index) => {
        return (
          <FormGroup tag="fieldset" key={index}>
            {fields.length > 1 && index > 0 && <hr style={{ border: `1px dashed black` }} />}
            <FormGroup row>
              <Label md="3">Bank</Label>
              <Col md="9">
                <Controller
                  name={`banks.${index}.bank_name`}
                  isClearable
                  control={control}
                  // render={({ field }) => (
                  //   <CreatableSelect
                  //     {...field}
                  //     options={optionBanks}
                  //     isOptionDisabled={(option) => option.disabled}
                  //   />
                  // )}
                  render={({ field: { onChange, value, ref } }) => (
                    <CreatableSelect
                      inputRef={ref}
                      value={optionBanks.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.value)}
                      options={optionBanks}
                      isOptionDisabled={(option) => option.disabled}
                    />
                  )}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Account Name</Label>
              <Col md="9">
                <Controller
                  name={`banks.${index}.account_name`}
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                  )}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Account Number</Label>
              <Col md="9">
                <Controller
                  name={`banks.${index}.account_number`}
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                  )}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Branch</Label>
              <Col md="9">
                <Controller
                  name={`banks.${index}.bank_address`}
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                  )}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Swift Code</Label>
              <Col md="9">
                <Controller
                  name={`banks.${index}.swift_code`}
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                  )}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Attachment</Label>
              <Col md="9">
                <Controller
                  name={`banks.${index}.attachment`}
                  isClearable
                  control={control}
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <FileUpload
                        inputRef={ref}
                        onDrop={onChange}
                        onChange={onChange}
                        imagefile={value}
                        // invalid={!isEmpty(errors.proof_identity_file)}
                      />
                    );
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="3">Set As Primary</Label>
              <Col md="9">
                <Controller
                  name={`banks.${index}.is_primary`}
                  isClearable
                  control={control}
                  render={({ field: { value = fields.length > 1 ? false : true, onChange } }) => (
                    <Switchery
                      checked={value}
                      // disabled={fields.length == 1}
                      // defaultChecked={item.is_primary}
                      onChange={(val) => {
                        // fields.map((item, i) => {
                        //   return { ...item, is_primary: index === i };
                        // });
                        // onChange(val);
                        setAsPrimary(index);
                      }}
                      for={`banks.${index}.is_primary`}
                    />
                  )}
                  // render={({ field }) => (
                  //   <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                  // )}
                />
              </Col>
            </FormGroup>
            {/* <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label">Full Name</label>
              <div className={index > 0 ? 'col-md-8' : 'col-md-9'}>
                <div className="input-group">
                  <input
                    {...register(`methods.${index}.full_name`)}
                    type="text"
                    className={`form-control form-control-md ${
                      errors.methods && errors.methods[index] && errors.methods[index].full_name
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  {index == 0 && (
                    <div class="input-group-append">
                      <span class="input-group-text" id="basic-addon2">
                        Primary
                      </span>
                    </div>
                  )}
                  {errors.methods && errors.methods[index] && errors.methods[index].full_name && (
                    <div className="invalid-feedback">
                      {errors.methods[index].full_name?.message}
                    </div>
                  )}
                </div>
              </div>
              {index > 0 && (
                <div className="col">
                  <button
                    className="btn btn-danger btn-block float-right"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    <i className="fa fa-lg fa-times" />
                  </button>
                </div>
              )}
            </div>
            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label">Current Position</label>
              <div className="col-md-9">
                <div className="input-group">
                  <input
                    {...register(`methods.${index}.position`)}
                    type="text"
                    className={`form-control form-control-md ${
                      errors.methods && errors.methods[index] && errors.methods[index].position
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  {errors.methods && errors.methods[index] && errors.methods[index].position && (
                    <div className="invalid-feedback">
                      {errors.methods[index].position?.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label">Current Function</label>
              <div className="col-md-9">
                <div className="input-group">
                  <input
                    {...register(`methods.${index}.function`)}
                    type="text"
                    className={`form-control form-control-md ${
                      errors.methods && errors.methods[index] && errors.methods[index].function
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  {errors.methods && errors.methods[index] && errors.methods[index].function && (
                    <div className="invalid-feedback">
                      {errors.methods[index].function?.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label">Address</label>
              <div className="col-md-9">
                <div className="input-group">
                  <input
                    {...register(`methods.${index}.address`)}
                    type="text"
                    className={`form-control form-control-md ${
                      errors.methods && errors.methods[index] && errors.methods[index].address
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  {errors.methods && errors.methods[index] && errors.methods[index].address && (
                    <div className="invalid-feedback">{errors.methods[index].address?.message}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label">Contact Number</label>
              <div className="col-md-9">
                <div className="input-group">
                  <input
                    {...register(`methods.${index}.contact_number`)}
                    type="text"
                    className={`form-control form-control-md ${
                      errors.methods &&
                      errors.methods[index] &&
                      errors.methods[index].contact_number
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  {errors.methods &&
                    errors.methods[index] &&
                    errors.methods[index].contact_number && (
                      <div className="invalid-feedback">
                        {errors.methods[index].contact_number?.message}
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label">Employment in the last three years</label>
              <div className="col-sm-2 col-md-1 d-flex align-items-center">Year 1</div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register('last_name', { required: true })}
                    type="text"
                    placeholder="Occupation/Position"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register('last_name', { required: true })}
                    type="text"
                    placeholder="Name of Former Office/Employer"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label"></label>
              <div className="col-sm-2 col-md-1 d-flex align-items-center">Year 2</div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register('last_name', { required: true })}
                    type="text"
                    placeholder="Occupation/Position"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register('last_name', { required: true })}
                    type="text"
                    placeholder="Name of Former Office/Employer"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="row form-group m-b-10">
              <label className="col-md-3 col-form-label"></label>
              <div className="col-sm-2 col-md-1 d-flex align-items-center">Year 3</div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register('last_name', { required: true })}
                    type="text"
                    placeholder="Occupation/Position"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register('last_name', { required: true })}
                    type="text"
                    placeholder="Name of Former Office/Employer"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="row form-group m-b-10 d-flex align-items-center">
              <label className="col-md-3 col-form-label">
                Is beneficial owner of more than 10%?
              </label>
              <div className="col-sm-2 col-md-1 d-flex align-items-center">
                <div className="input-group">
                  <div className="switcher">
                    <input
                      {...register('is_beneficial', { required: true })}
                      type="checkbox"
                      name="is_beneficial"
                      id="is_beneficial"
                    />
                    <label htmlFor="is_beneficial"></label>
                  </div>
                </div>
              </div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register('last_name', { required: true })}
                    type="text"
                    placeholder="Voting Power"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
              <div className="col-sm-7 col-md-4">
                <div className="input-group">
                  <input
                    {...register('last_name', { required: true })}
                    type="text"
                    placeholder="Ownership"
                    className={`form-control form-control-md ${
                      errors.last_name ? 'is-invalid' : ''
                    }`}
                  />
                  {errors.last_name && (
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  )}
                </div>
              </div>
            </div> */}
          </FormGroup>
        );
      })}
      {/* {enableBanks && ( */}
      <>
        <Row>
          <Col>
            <button
              className="btn btn-primary float-right"
              type="button"
              onClick={() => {
                append({});
              }}
            >
              <i className="fa fa-plus" style={{ marginRight: 10 }} />
              Add Another Bank
            </button>
          </Col>
        </Row>
      </>
      {/* )} */}
    </>
  );
};
