import React, { useContext, useState, useEffect } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import PageContext from '../../config/page-settings.js';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { store } from 'react-notifications-component';
import * as API from '../../services/api';
import Dropzone from 'react-dropzone';
import { Switch, Route, Prompt, Redirect, matchPath } from 'react-router-dom';
import { forwardRef } from 'react';
import { Row, Col, Form, FormGroup, Label, Tooltip } from 'reactstrap';
import ReactSelect from 'react-select';
import FormIssuerInformation from './components/FormIssuerInformation.js';
import FormDirectors from './components/FormDirectors.js';
import FormSecCompliance from './components/FormSecCompliance.js';
import FormBank from './components/FormBank.js';
import FormSummary from './components/FormSummary.js';

const TooltipItem = (props) => {
  const { placement, id, content } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <i className="fa fa-exclamation-circle text-info" id={'Tooltip-' + id} />
      <Tooltip placement={placement} isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
        {content}
      </Tooltip>
    </span>
  );
};

const IssuerCreate = (props) => {
  const history = useHistory();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [currentStep, setCurrentStep] = useState(1);

  const [issuer, setIssuer] = useState({});

  const onSubmit = (data) => {
    if (currentStep == 1) {
      setCurrentStep(currentStep + 1);
      setIssuer(data);
      API.getIssuer(data.id).then((res) => {
        setIssuer(res.data.data);
      });
    }

    if (currentStep == 5) {
      history.push(`/issuers/${issuer.id}`);
    }
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const [showNote, setShowNote] = useState(true);

  return (
    <div>
      <h1 className="page-header">Issuer - Create</h1>
      <div className="row">
        <div className="col-xl-12">
          <div className={`note note-orange mt-2 fade ${showNote ? 'show' : 'hide'}`}>
            <div
              onClick={() => {
                setShowNote(false);
              }}
              style={{ position: 'absolute', right: 20, top: 20, alignSelf: 'center' }}
            >
              <i className="fa fa-times-circle fa-2x text-white"></i>
            </div>
            <div className="note-icon">
              <i className="fa fa-asterisk text-white"></i>
            </div>
            <div className="note-content">
              <h5 className="text-white">
                <b>Issuers of Crowdfunding Securities in accordance with SEC Crowdfunding Rules</b>
              </h5>
              <p className="text-white">
                SEC Forms CF:&nbsp;
                <a
                  href="https://www.sec.gov.ph/wp-content/uploads/2020/09/2019MCNo14.pdf"
                  target="_blank"
                >
                  SEC Crowdfunding Circular 14 s.2019, p.37
                </a>
                <span>
                  All inputs with asterisk (*) in the form will be use by Issuers of Crowdfunding
                  Securities in accordance with SEC Crowdfunding Rules.
                </span>
              </p>
            </div>
          </div>
          <Panel>
            <PanelHeader />
            <PanelBody>
              <div className="row">
                <div className="col-xl-12 nav-wizards-container">
                  <nav className="nav nav-wizards-1 mb-2">
                    <div className="nav-item col">
                      <div
                        className={
                          'nav-link ' +
                          (currentStep == 1
                            ? 'active '
                            : currentStep > 1
                            ? 'completed '
                            : 'disabled ')
                        }
                      >
                        <div className="nav-no">1</div>
                        <div className="nav-text">Issuer Information</div>
                      </div>
                    </div>

                    <div className="nav-item col">
                      <div
                        className={
                          'nav-link ' +
                          (currentStep == 2
                            ? 'active '
                            : currentStep > 2
                            ? 'completed '
                            : 'disabled ')
                        }
                      >
                        <div className="nav-no">2</div>
                        <div className="nav-text">Director & Officers</div>
                      </div>
                    </div>

                    <div className="nav-item col">
                      <div
                        className={
                          'nav-link ' +
                          (currentStep == 3
                            ? 'active '
                            : currentStep > 3
                            ? 'completed '
                            : 'disabled ')
                        }
                      >
                        <div className="nav-no">3</div>
                        <div className="nav-text">SEC Compliance</div>
                      </div>
                    </div>

                    <div className="nav-item col">
                      <div
                        className={
                          'nav-link ' +
                          (currentStep == 4
                            ? 'active '
                            : currentStep > 4
                            ? 'completed '
                            : 'disabled ')
                        }
                      >
                        <div className="nav-no">4</div>
                        <div className="nav-text">Bank Details</div>
                      </div>
                    </div>

                    <div className="nav-item col">
                      <div
                        className={
                          'nav-link ' +
                          (currentStep == 5
                            ? 'active'
                            : currentStep > 5
                            ? 'completed '
                            : 'disabled ')
                        }
                      >
                        <div className="nav-no">5</div>
                        <div className="nav-text">Document Requirements</div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <Row>
                <Col sm="12" md="12" lg={{ size: 10, offset: 1 }}>
                  <FormIssuerInformation currentStep={currentStep} onSubmit={onSubmit} />
                  <FormDirectors
                    currentStep={currentStep}
                    issuer={issuer}
                    prevStep={prevStep}
                    onSubmit={onSubmit}
                  />
                  <FormSecCompliance
                    currentStep={currentStep}
                    prevStep={prevStep}
                    issuer={issuer}
                    onSubmit={onSubmit}
                  />
                  <FormBank
                    currentStep={currentStep}
                    prevStep={prevStep}
                    onSubmit={onSubmit}
                    issuer={issuer}
                  />
                  <FormSummary
                    currentStep={currentStep}
                    prevStep={prevStep}
                    issuer={issuer}
                    onSubmit={onSubmit}
                  />
                </Col>
              </Row>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(IssuerCreate);
