import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel.jsx';
import PageContext from '../../../../config/page-settings.js';
import * as API from '../../../../services/api';
import { Table } from '../../../../components/table';
import { Notification } from '../../../../utils/index.js';
const PaymentTypesList = (props) => {
  const history = useHistory();

  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [meta, setMeta] = useState({});
  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  useEffect(() => {
    fetchDataList();
  }, [props]);

  const fetchDataList = () => {
    API.getPaymentCategories()
      .then((res) => {
        setData(res.data.data);
        setMeta(res.data.meta)
      })
      .catch((err) => {
        setData([]);
        Notification.error(err);
      });
  };

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        sortable: true
      },
      {
        header: 'Active',
        cell: ({ cell }) => {
          const { original, index } = cell.row;
          const onChange = () => {
            updateRow(cell.row.index, cell.column.id, !cell.value);
            const params = new URLSearchParams();
            params.append('is_active', original.is_active ? 0 : 1);
            API.patchPaymentCategory(original.id, params)
              .then((res) => {
                Notification.success(
                  'Update Payment Category',
                  `Payment category status set to ${original.is_active ? 'inactive' : 'active'}`
                );
              })
              .catch((err) => {
                Notification.error(err);
              });
          };

          return (
            <div className="with-btn text-nowrap d-flex justify-content-center">
              <div className="input-group justify-content-center">
                <div className="switcher">
                  <input
                    checked={original.is_active}
                    onChange={onChange}
                    type="checkbox"
                    name={`${index}_is_active`}
                    id={`${index}_is_active`}
                  />
                  <label htmlFor={`${index}_is_active`}></label>
                </div>
              </div>
            </div>
          );
        }
      }
      // {
      //   Header: 'Action Items',
      //   accessor: 'id',
      //   Cell: ({ cell }) => {
      //     const deleteRow = () => {
      //       API.deletePaymentCategory(cell.value).then((res) => {
      //         const dataCopy = [...data];
      //         dataCopy.splice(cell.row.index, 1);
      //         setData(dataCopy);
      //         store.addNotification({
      //           title: 'Payment Category Deleted',
      //           message: `Payment Category successfully deleted!`,
      //           type: 'success',
      //           insert: 'top',
      //           container: 'top-right',
      //           animationIn: ['animate__animated', 'animate__fadeIn'],
      //           animationOut: ['animate__animated', 'animate__fadeOut'],
      //           dismiss: {
      //             duration: 3000,
      //             onScreen: true
      //           }
      //         });
      //       });
      //     };

      //     return (
      //       <RowActionItems>
      //         <RowItemDelete onConfirm={deleteRow} />
      //       </RowActionItems>
      //     );
      //   }
      // }
    ],
    []
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Panel>
      <PanelHeader>Payment Types List</PanelHeader>
      <PanelBody>
        <Table columns={columns} data={data}
        meta={meta}
        updateRow={updateRow} skipPageReset={skipPageReset}/>
      </PanelBody>
    </Panel>
  );
};

export default memo(PaymentTypesList);
