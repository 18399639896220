import React, { memo, useState } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { store } from 'react-notifications-component';
import * as API from '../../../services/api';
import { Form, FormGroup, FormFeedback, Col, Label, Input } from 'reactstrap';
import { isEmpty } from 'lodash';

const schemaIndustryCreate = yup.object().shape({
  name: yup.string().required('Name is required'),
  attribute: yup.string(),
  is_public: yup.boolean(),
  is_active: yup.boolean()
});

const CategoryCreate = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      is_active: false,
      is_public: false,
      ...props.values
    },
    resolver: yupResolver(schemaIndustryCreate)
  });

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onIndustrySubmit = (data) => {
    if (props.action == 'create') {
      API.createIndustry(data)
        .then((res) => {
          store.addNotification({
            title: 'Industry Submitted',
            message: `Industry successfully created!`,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          props.history.push('/settings/industry');
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    } else {
      API.updateIndustry(props.values.id, data)
        .then((res) => {
          store.addNotification({
            title: 'Industry Updated',
            message: `Industry successfully updated!`,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          props.history.push('/settings/industry');
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    }
  };

  return (
    <div>
      <h1 className="page-header">Create Category</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader>Information</PanelHeader>
            <PanelBody>
              <Form onSubmit={handleSubmit(onIndustrySubmit)}>
                <FormGroup row>
                  <Label md="3">Category Name</Label>
                  <Col md="9">
                    <Controller
                      control={control}
                      name="name"
                      render={({ field }) => (
                        <Input {...field} type="text" invalid={!isEmpty(errors.name)} />
                      )}
                    />
                    {errors.name && <FormFeedback>{errors.name?.message}</FormFeedback>}
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label md="3">Category Description</Label>
                  <Col md="9">
                    <Controller
                      control={control}
                      name="description"
                      render={({ field }) => <Input {...field} type="textarea" />}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label md="3">Is Active</Label>
                  <Col md="9">
                    <Controller
                      control={control}
                      name="is_active"
                      render={({ field: { value, onChange } }) => (
                        <div className="switcher">
                          <input
                            checked={value}
                            onChange={onChange}
                            type="checkbox"
                            name="is_active"
                            id="is_active"
                          />
                          <label htmlFor="is_active"></label>
                        </div>
                      )}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label md="3" />
                  <Col md="9">
                    <button
                      type="submit"
                      className={`btn ${loadingSubmit ? 'btn-secondary' : 'btn-primary'}`}
                      disabled={loadingSubmit}
                    >
                      {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
                    </button>
                  </Col>
                </FormGroup>
              </Form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default memo(CategoryCreate);
