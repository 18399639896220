import React, { useContext, useState, useEffect } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import PageContext from '../../config/page-settings.js';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { store } from 'react-notifications-component';
import * as API from '../../services/api';
import Dropzone from 'react-dropzone';
import { Switch, Route, Prompt, Redirect, matchPath, useParams } from 'react-router-dom';
import { forwardRef } from 'react';
import { Row, Col, Form, FormGroup, Label, Tooltip } from 'reactstrap';
import ReactSelect from 'react-select';
import FormIssuerInformation from './components/FormIssuerInformation.js';
import FormDirectors from './components/FormDirectors.js';
import FormSecCompliance from './components/FormSecCompliance.js';
import FormBank from './components/FormBank.js';
import FormSummary from './components/FormSummary.js';
import FormRequestLoan from './components/FormRequestLoan.js';

const TooltipItem = (props) => {
  const { placement, id, content } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <i className="fa fa-exclamation-circle text-info" id={'Tooltip-' + id} />
      <Tooltip placement={placement} isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
        {content}
      </Tooltip>
    </span>
  );
};

const IssuerRequestLoan = () => {
  const { id, loan_id } = useParams();
  const history = useHistory();
  const pageContext = useContext(PageContext);

  const [loan, setLoan] = useState({});

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
    API.getIssuer(id)
      .then((res) => {
        setIssuer(res.data.data);
      })
      .then(() => {
        API.getLoanRequest(loan_id)
          .then((res) => {
            setLoan(res.data.data);
          })
          .catch((err) => {
            setLoan([]);
          });
      });
  }, [id]);

  const [currentStep, setCurrentStep] = useState(1);

  const [issuer, setIssuer] = useState({});

  const onSubmit = (data) => {
    if (currentStep == 1) {
      // setCurrentStep(currentStep + 1);
      setIssuer(data);
      history.push(`/issuers/${issuer.id}`);
      // API.getIssuer(data.id).then((res) => {
      //   setIssuer(res.data.issuer);
      // });
    }

    if (currentStep == 5) {
      history.push(`/issuers/${issuer.id}`);
    }
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const [showNote, setShowNote] = useState(true);

  return (
    <div>
      <h1 className="page-header">Issuer - Request Loan</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader />
            <PanelBody>
              <Row>
                <Col sm="12" md="12" lg={{ size: 10, offset: 1 }}>
                  {issuer && loan && (
                    <FormRequestLoan issuer={issuer} loan={loan} onSubmit={onSubmit} />
                  )}
                </Col>
              </Row>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(IssuerRequestLoan);
