import React, { useState, useContext } from 'react';
import { Route, Link } from 'react-router-dom';
import PageContext from '../../config/page-settings.js';

const SidebarNavList = (props) => {
  const pageContext = useContext(PageContext);
  const [active, setActive] = useState(-1);
  const [clicked, setClicked] = useState(-1);

  const handleExpand = (e, i, match) => {
    e.preventDefault();

    setActive(active === i ? -1 : i);
    setClicked(1);
  };

  var icon = props.data.icon && <i className={props.data.icon}></i>;
  var img = props.data.img && (
    <div className="icon-img">
      <img src={props.data.img} alt="" />
    </div>
  );
  var caret = props.data.children && !props.data.badge && <b className="caret"></b>;
  var label = props.data.label && (
    <span className="label label-theme m-l-5">{props.data.label}</span>
  );
  var badge = props.data.badge && <span className="badge pull-right">{props.data.badge}</span>;
  var title = props.data.title && (
    <span>
      {props.data.title} {label}
    </span>
  );
  var highlight = props.data.highlight && <i className="fa fa-paper-plane text-theme"></i>;

  return (
    <Route
      path={props.data.path}
      exact={props.data.exact}
      children={({ match }) => (
        <li
          className={
            (match ? 'active ' : '') +
            (props.active || (props.clicked === -1 && match) || props.data.search
              ? 'expand '
              : 'closed ') +
            (props.data.children ? 'has-sub ' : '')
          }
        >
          {props.data.children ? (
            <Link
              to={props.data.path}
              onMouseOver={(e) => pageContext.handleSidebarOnMouseOver(e, props.data)}
              onMouseOut={(e) => pageContext.handleSidebarOnMouseOut(e, props.data)}
              onClick={props.expand}
            >
              {caret} {badge} {img} {icon} {title} {highlight}
            </Link>
          ) : (
            <Link to={props.data.path}>
              {caret} {img} {icon} {badge} {title} {highlight}
            </Link>
          )}
          {props.data.children && (
            <ul
              className={
                'sub-menu ' +
                ((props.active || (props.clicked === -1 && match) || props.data.search) &&
                !pageContext.pageSidebarMinified
                  ? 'd-block '
                  : 'd-none')
              }
            >
              {props.data.children &&
                props.data.children.map((submenu, i) => (
                  <SidebarNavList
                    data={submenu}
                    key={i}
                    expand={(e) => handleExpand(e, i, match)}
                    active={i === active}
                    clicked={clicked}
                  />
                ))}
            </ul>
          )}
        </li>
      )}
    />
  );
};

export default SidebarNavList;
