import React, { useState, memo } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const RowItemDelete = (props) => {
  const [modalDelete, setModalDelete] = useState(false);

  const toggleModalDelete = async () => {
    await setModalDelete(!modalDelete);
  };

  const onConfirm = () => {
    setModalDelete(false);
    toggleModalDelete().then(() => {
      setTimeout(() => {
        props.onConfirm();
      }, 300);
    });
  };

  return (
    <>
      <button className="btn btn-sm btn-danger m-r-2" onClick={toggleModalDelete}>
        <i className="fa fa-trash"></i>
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Are you sure you want to delete it?</span>}
        danger
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        focusCancelBtn
        onConfirm={onConfirm}
        onCancel={toggleModalDelete}
        show={modalDelete}
      />
    </>
  );
};

export default memo(RowItemDelete);
