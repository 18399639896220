import React, { useState } from 'react';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { useForm } from 'react-hook-form';
import * as API from './../../services/api.js';
import { withRouter, Link } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Notification } from '../../utils';

const schemaChangePassword = yup.object().shape({
  current_password: yup
    .string()
    .required('Current password is required.')
    .min(8, 'Current password must be at least 8 characters'),
  password: yup
    .string()
    .required('New password is required')
    .min(8, 'New password must be at least 8 characters'),
  password_confirmation: yup
    .string('Confirm password is required')
    .required()
    .min(8, 'New password must be at least 8 characters')
});

const ChangePassword = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm({
    resolver: yupResolver(schemaChangePassword)
  });

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onChangePassword = (data) => {
    setLoadingSubmit(true);

    const params = new URLSearchParams();
    params.append('current_password', data.current_password);
    params.append('password', data.password);
    params.append('password_confirmation', data.password_confirmation);

    API.userChangePassword(params)
      .then((res) => {
        Notification.success('Change Password', 'Successfully change password');
        props.history.push('/');
      })
      .catch((err) => {
        if (err.response.status === 422) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            setError(key, {
              type: 'manual',
              message: value
            });
          }
        }
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  return (
    <div>
      {/* <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/change-password">Change Password</Link>
        </li>
      </ol> */}
      <h1 className="page-header">Change Password</h1>
      <div className="row">
        <div className="col-xl-6">
          <Panel>
            <PanelHeader></PanelHeader>
            <PanelBody>
              <form onSubmit={handleSubmit(onChangePassword)}>
                <div className="row form-group m-b-10">
                  <label className="col-md-3 col-form-label">Current Password</label>
                  <div className="col-md-9">
                    <div className="input-group">
                      <input
                        {...register('current_password', { required: true })}
                        type="password"
                        className={`form-control ${errors.current_password ? 'is-invalid' : ''}`}
                      />
                      {errors.current_password && (
                        <div className="invalid-feedback">{errors.current_password?.message}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row form-group m-b-10">
                  <label className="col-md-3 col-form-label">New Password</label>
                  <div className="col-md-9">
                    <div className="input-group">
                      <input
                        {...register('password', { required: true })}
                        type="password"
                        className={`form-control ${errors.password ? 'is-invalid' : ''} `}
                      />
                      {errors.password && (
                        <div className="invalid-feedback">{errors.password?.message}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row form-group m-b-10">
                  <label className="col-md-3 col-form-label">Confirm Password</label>
                  <div className="col-md-9">
                    <div className="input-group">
                      <input
                        {...register('password_confirmation', { required: true })}
                        type="password"
                        className={`form-control ${
                          errors.password_confirmation ? 'is-invalid' : ''
                        }`}
                      />
                      {errors.password_confirmation && (
                        <div className="invalid-feedback">
                          {errors.password_confirmation?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group row m-b-10">
                  <label className="col-md-3 col-form-label">&nbsp;</label>
                  <div className="col-md-9">
                    {/* <button type="submit" className="btn btn-primary">
                      Submit
                    </button> */}

                    <button
                      type="submit"
                      className={`btn ${loadingSubmit ? 'btn-gray' : 'btn-primary'}`}
                      disabled={loadingSubmit}
                    >
                      {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
                    </button>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ChangePassword);
