import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import PageContext from '../../config/page-settings.js';
import { Table, RowActionItems, RowItemDelete, RowItemView } from '../../components/table';
import { Notification, PriceFormat } from '../../utils/index.js';
import * as API from '../../services/api';
import moment from 'moment';
import { isNull } from 'lodash';
import { Tooltip } from 'reactstrap';
import PageHeader from '../../components/common/PageHeader.js';

const SEARCH_ARRAY = [
  { label: 'Name', value: 'full_name' },
  { label: 'Email', value: 'email' },
  { label: 'Mobile', value: 'otp_number' }
];

const FILTER_ARRAY = [
  {
    name: 'status',
    value: 0
  }
];

const InvestorPriorityList = (props) => {
  const history = useHistory();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');

  const onChangeKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const [searchKeyword, setKeywordSearch] = useState(SEARCH_ARRAY[0]);

  const getRequestParams = (keyword, page, pageSize, filters) => {
    let params = {};

    if (keyword) {
      params[`filter[search]`] = keyword;
    }

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    if (filters) {
      filters.forEach((f) => {
        params[`filter[${f.name}]`] = f.value.value;
      });
    }

    return params;
  };

  const [filters, setFilters] = useState(FILTER_ARRAY);

  const onChangeFilter = (i) => (e) => {
    const newState = filters.map((obj, index) => {
      if (index === i) {
        return { ...obj, value: e };
      }
      return obj;
    });

    setFilters(newState);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [summary, setSummary] = useState({});

  const fetchData = () => {
    const params = getRequestParams(keyword, page, pageSize, filters);
    setIsLoading(true);

    API.getPriorityInvestorsFunds()
      .then((res) => {
        setSummary(res.data.data);
      })
      .catch((err) => {
        setSummary({});
      });

    API.getPriorityInvestors(params)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        setData([]);
        setMeta({});
        setIsLoading(false);
      });
  };

  // const fetchInvestors = () => {
  //   const params = getRequestParams(keyword, page, pageSize);
  //   API.getInvestors(params)
  //     .then((response) => {
  //       const { data, meta } = response.data;
  //       setData(data);
  //       setMeta(meta);
  //     })
  //     .catch((e) => {
  //       setData([]);
  //       setMeta({});
  //     });
  // };

  useEffect(fetchData, [page, pageSize, filters]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Date Joined',
        accessorFn: (d) => {
          return moment(d.created_at).local().format('MMM DD, YYYY');
        }
      },
      {
        header: 'Name',
        cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <div>
              {original.first_name} {original.last_name}{' '}
              {!isNull(original.remarks) && (
                <TooltipItem placement="top" id={original.id} content={original.remarks} />
              )}
              {original.is_qualified === 1 && (
                <span className="badge bg-yellow text-black">Qualified</span>
              )}
            </div>
          );
        }
      },
      {
        header: 'Email',
        accessorKey: 'email'
      },
      {
        header: 'Mobile',
        accessorFn: (d) => {
          return d.otp_number ? d.otp_number : `N/A`;
        }
      },
      {
        header: 'Available Funds',
        accessorFn: (d) => {
          return PriceFormat(d.fund?.available_funds);
        }
      },
      {
        header: 'Action Items',
        cell: ({ cell }) => {
          const { original } = cell.row;

          const onDelete = () => {
            API.deleteInvestor(original.id)
              .then((res) => {
                const dataCopy = [...data];
                dataCopy.splice(cell.row.index, 1);
                setData(dataCopy);
                Notification.success('Investor Deleted', 'Investor successfully deleted!');
              })
              .catch((err) => {
                Notification.error(err);
              });
          };

          const onView = () => {
            history.push(`/investors/${original.id}`, original);
          };

          return (
            <RowActionItems>
              <RowItemView onClick={onView} />
            </RowActionItems>
          );
        }
      }
    ],
    [data]
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <PageHeader title="Priority Investors" />
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-green">
            <div className="stats-icon">
              <i className="ion ion-ios-wallet"></i>
            </div>
            <div className="stats-info">
              <h4>Available Funds</h4>
              <p>{summary && PriceFormat(summary.total_available_funds)}</p>
            </div>
            <div className="stats-link">&nbsp;</div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-orange">
            <div className="stats-icon">
              <i className="ios ion-ios-hand"></i>
            </div>
            <div className="stats-info">
              <h4>On Hold Funds</h4>
              <p>{summary && PriceFormat(summary.total_onhold_funds)}</p>
            </div>
            <div className="stats-link">&nbsp;</div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-indigo">
            <div className="stats-icon">
              <i className="ion ion-ios-trending-up"></i>
            </div>
            <div className="stats-info">
              <h4>Pending Withdrawals </h4>
              <p>{summary && PriceFormat(summary.total_withdrawal_funds)}</p>
            </div>
            <div className="stats-link">&nbsp;</div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className="widget widget-stats bg-info">
            <div className="stats-icon">
              <i className="fa fa-wheelchair"></i>
            </div>
            <div className="stats-info">
              <h4>Total Investor Count</h4>
              <p>{summary && summary.total_vip_count}</p>
            </div>
            <div className="stats-link">&nbsp;</div>
          </div>
        </div>
      </div>
      <Panel>
        <PanelHeader>Investors List</PanelHeader>
        <PanelBody>
          <Table
            columns={columns}
            data={data}
            meta={meta}
            isLoading={isLoading}
            onSetPageSize={handlePageSizeChange}
            updateRow={updateRow}
            onNextPage={handlePageChange('next')}
            onPrevPage={handlePageChange('prev')}
            skipPageReset={skipPageReset}
            onChangeKeyword={onChangeKeyword}
            keyword={keyword}
            onSearchKeyword={handleSearch}
            filterAPI={[
              {
                name: 'status',
                options: [
                  { value: 2, label: 'Incomplete', is_active: true },
                  { value: 3, label: 'Pending', is_active: true },
                  { value: 4, label: 'Approved', is_active: true },
                  { value: 5, label: 'Rejected', is_active: true },
                  { value: 6, label: 'Deleted', is_active: true }
                ],
                onChange: onChangeFilter(0),
                value: filters[0]?.value
              }
            ]}
            // keyword={keyword}
            // onSearchKeyword={handleSearch}
            // searchByGroup={SEARCH_ARRAY}
            // searchValueBy={searchKeyword}
            // onSelectSearch={setKeywordSearch}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default memo(InvestorPriorityList);

const TooltipItem = (props) => {
  const { placement, id, content } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <i
        className="fas fa-exclamation-triangle fa-fw"
        id={'Tooltip-' + id}
        style={{ color: 'orange' }}
      ></i>
      <Tooltip placement={placement} isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
        {content}
      </Tooltip>
    </span>
  );
};
