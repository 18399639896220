import React, { useState, useMemo } from 'react';
import { useParams, useHistory, withRouter } from 'react-router-dom';

import { store } from 'react-notifications-component';
import { Form, FormGroup, FormFeedback, FormText, Col, Label, Input } from 'reactstrap';
import { isEmpty } from 'lodash';
import ReactSelect from 'react-select';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import * as API from '../../../../services/api';

const schemaIndustryCreate = yup.object().shape({
  name: yup.string().required('Name is required'),
  key: yup.string().required('Key Name is required'),
  type: yup.string(),
  value: yup.string().when('type', {
    is: (type) => type === 'select',
    then: yup.string().test('value', 'Must be JSON Format', (value, validationContext) => {
      const { createError } = validationContext;

      try {
        JSON.parse(value);
      } catch (e) {
        return createError({ message: 'Must be JSON Format' });
      }
      return true;
    }),
    otherwise: yup.string().required('Value is required')
  }),
  attribute: yup.string(),
  is_public: yup.boolean(),
  is_active: yup.boolean()
});

const FormGlobalSettings = (props) => {
  const { action } = props;

  const history = useHistory();
  const { id } = useParams();
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch
  } = useForm({
    defaultValues: useMemo(() => {
      if (id) {
        return {
          ...props.values,
          is_active: props.values.is_active === 1,
          is_public: props.values.is_public === 1,
          value:
            props.values.type === 'select' ? JSON.stringify(props.values.value) : props.values.value
        };
      }

      return {
        name: '',
        type: 'text',
        attribute: '',
        is_active: false,
        is_public: false
      };
    }, [id]),
    resolver: yupResolver(schemaIndustryCreate)
  });

  const dataType = watch('type', 'text');

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const optionDataType = [
    { value: 'text', label: 'Text' },
    { value: 'number', label: 'Number' },
    { value: 'select', label: 'Select' }
  ];

  const onSubmit = (data) => {
    if (action == 'create') {
      API.createGlobalData(data)
        .then((res) => {
          store.addNotification({
            title: 'Global Settings Submitted',
            message: `Global settings successfully created!`,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          history.goBack();
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    } else {
      const params = {
        ...data,
        is_public: data.is_public ? 1 : 0,
        is_active: data.is_active ? 1 : 0
      };
      API.updateGlobalData(id, params)
        .then((res) => {
          store.addNotification({
            title: 'Global Settings Submitted',
            message: `Global settings successfully updated!`,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          history.goBack();
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label md="3">Name</Label>
        <Col md="9">
          <Controller
            control={control}
            name="name"
            render={({ field }) => <Input {...field} type="text" invalid={!isEmpty(errors.name)} />}
          />
          {errors.name && <FormFeedback>{errors.name?.message}</FormFeedback>}
          <FormText color="muted">
            Meta Name is the name of the setting. Maximum length 200 characters.
          </FormText>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Key Name</Label>
        <Col md="9">
          <Controller
            control={control}
            name="key"
            render={({ field }) => <Input {...field} type="text" invalid={!isEmpty(errors.key)} />}
          />
          {errors.key && <FormFeedback>{errors.key?.message}</FormFeedback>}
          <FormText color="muted">
            Meta Key is the identifier of the setting. It requires to be only string, no space or
            special characters except _ (underscore). Maximum length 100 characters.
          </FormText>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Description</Label>
        <Col md="9">
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <Input {...field} type="textarea" invalid={!isEmpty(errors.name)} />
            )}
          />
          {errors.name && <FormFeedback>{errors.name?.message}</FormFeedback>}
          <FormText color="muted">
            Description is to describe what the setting is about. Maximum length 1000 characters.
          </FormText>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Type</Label>
        <Col md="9">
          <Controller
            name="type"
            isClearable
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <ReactSelect
                inputRef={ref}
                value={optionDataType.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                options={optionDataType}
                isOptionDisabled={(option) => option.disabled}
              />
            )}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Value</Label>
        <Col md="9">
          <Controller
            name="value"
            defaultValue=""
            isClearable
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type={dataType === 'number' ? 'number' : 'textarea'}
                className={dataType === 'number' ? 'number-only-field' : ''}
                invalid={!isEmpty(errors.value)}
              />
            )}
          />
          {errors.value && <FormFeedback>{errors.value?.message}</FormFeedback>}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Attribute</Label>
        <Col md="9">
          <Controller
            control={control}
            name="attribute"
            render={({ field }) => <Input {...field} type="text" />}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Is Public</Label>
        <Col md="9">
          <Controller
            control={control}
            name="is_public"
            render={({ field: { value, onChange } }) => (
              <div className="switcher">
                <input
                  checked={value}
                  onChange={onChange}
                  type="checkbox"
                  name="is_public"
                  id="is_public"
                />
                <label htmlFor="is_public"></label>
              </div>
            )}
          />
          <FormText color="muted">
            Is Public field is a configuration whether the setting is public or not public.
          </FormText>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Is Active</Label>
        <Col md="9">
          <Controller
            control={control}
            name="is_active"
            render={({ field: { value, onChange } }) => (
              <div className="switcher">
                <input
                  checked={value}
                  onChange={onChange}
                  type="checkbox"
                  name="is_active"
                  id="is_active"
                />
                <label htmlFor="is_active"></label>
              </div>
            )}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3" />
        <Col md="9">
          <button
            type="submit"
            className={`btn ${loadingSubmit ? 'btn-secondary' : 'btn-primary'}`}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
          </button>
        </Col>
      </FormGroup>
    </Form>
  );
};

export default withRouter(FormGlobalSettings);
