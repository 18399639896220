import React, { memo, useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import * as API from '../../../services/api';

import { Notification } from '../../../utils/index.js';

import { Form, FormGroup, Col, Input, Label } from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import * as yup from 'yup';
import DateTime from 'react-datetime';
import moment from 'moment';
const schemaAdjustFunds = yup.object().shape({
  paid_at: yup.string()
});

const IssuerPayNow = ({ data }) => {
  const { project_id } = useParams();
  const [modalReject, setModalReject] = useState(false);
  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onSubmit = (formData) => {
    // const params = new URLSearchParams();
    // params.append('paid_at', data.date);
    // params.append('interest', data.interest);

    const params = {
      paid_at: formData.date,
      interest: formData.interest,
      penalty: formData.penalty,
      principal: formData.principal,
      description: formData.description ?? '',
      period: data._period
    };

    API.issuerPayNow(project_id, params)
      .then((res) => {
        Notification.success('Issuer Pay Now', 'Issuer pay now successfully submitted');
        toggleModalReject();
      })
      .catch((err) => {
        console.log(err.response);
        Notification.error(err);
      });
  };

  const {
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    resolver: yupResolver(schemaAdjustFunds),
    defaultValues: useMemo(() => {
      return {
        date: data?.due_date,
        interest: data?.interest,
        principal: data?.principal,
        penalty: 0,
        period: data?._period
      };
    }, [data, project_id])
  });

  console.log(errors);

  return (
    <>
      <button className="btn btn-xs btn-indigo m-l-5" type="button" onClick={toggleModalReject}>
        <i className="fa fa-money-check-alt mr-1"></i>&nbsp;Issuer Pay Now
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Loan Payment</span>}
        customIcon={<i className="fa fa-money-check-alt fa-5x text-indigo"></i>}
        custom
        showCancel
        confirmBtnText="Confirm Loan Payment"
        confirmBtnBsStyle="indigo"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={toggleModalReject}
        show={modalReject}
        style={{ width: '40vw' }}
      >
        <Form>
          <FormGroup row>
            <Label md="4" className="text-black text-left">
              Date
            </Label>
            <Col md="8">
              <Controller
                name="date"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DateTime
                    className="text-black"
                    initialViewDate={moment()}
                    inputProps={{ placeholder: 'Select Date' }}
                    closeOnSelect={true}
                    timeFormat={false}
                    value={value}
                    dateFormat="YYYY-MM-DD"
                    onChange={(e) => {
                      onChange(moment(e).format('YYYY-MM-DD'));
                    }}
                    // isValidDate={(currentDate) => currentDate < moment().subtract(18, 'years')}
                  />
                )}
              />
              {errors.date && <FormFeedback>{errors.date?.message}</FormFeedback>}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="4" className="text-black text-left">
              Interest
            </Label>
            <Col md="8">
              <Controller
                name="interest"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CurrencyInput
                    value={value}
                    prefix="₱"
                    decimalsLimit={2}
                    onValueChange={(value, name) => onChange(value)}
                    className="form-control"
                  />
                )}
              />
              {errors.interest && <FormFeedback>{errors.interest?.message}</FormFeedback>}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="4" className="text-black text-left">
              Principal
            </Label>
            <Col md="8">
              <Controller
                name="principal"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CurrencyInput
                    value={value}
                    prefix="₱"
                    decimalsLimit={2}
                    onValueChange={(value, name) => onChange(value)}
                    className="form-control"
                  />
                )}
              />
              {errors.principal && <FormFeedback>{errors.principal?.message}</FormFeedback>}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="4" className="text-black text-left">
              Penalty
            </Label>
            <Col md="8">
              <Controller
                name="penalty"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CurrencyInput
                    value={value}
                    prefix="₱"
                    decimalsLimit={2}
                    onValueChange={(value, name) => onChange(value)}
                    className="form-control"
                  />
                )}
              />
              {errors.penalty && <FormFeedback>{errors.penalty?.message}</FormFeedback>}
            </Col>
          </FormGroup>
        </Form>
      </SweetAlert>
    </>
  );
};

export default memo(IssuerPayNow);
