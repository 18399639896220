import React, { memo, useState, useEffect } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
// import Chart from 'react-apexcharts';
import moment from 'moment';
import NVD3Chart from 'react-nvd3';
import * as API from '../../../services/api';
import { LoadingSpinner } from '../../../components/common';

const NewUsers = () => {
  const getDate = (date) => {
    var d = new Date();
    d = moment(date).unix();
    return d;
  };

  const [users, setUsers] = useState({});

  const [borrowers, setBorrowers] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const params = new URLSearchParams();
    params.append('start_date', moment().subtract(31, 'days').format('YYYY-MM-DD'));
    params.append('end_date', moment().format('YYYY-MM-DD'));

    API.newUsers(params)
      .then((res) => {
        setUsers(res.data.data);
        setBorrowers(
          res.data.data.borrowers.map((item) => {
            return {
              x: getDate(item.date),
              y: item.count
            };
          })
        );
        setInvestors(
          res.data.data.investors.map((item) => {
            return {
              x: getDate(item.date),
              y: item.count
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const areaChartOptions = {
    pointSize: 1,
    useInteractiveGuideline: true,
    direction: 300,
    showControls: false,
    controlLabels: {
      stacked: 'Stacked'
    },
    yAxis: {
      tickFormat: d3.format(',.0f')
    },
    xAxis: {
      tickFormat: function (d) {
        var day = moment.unix(d);
        return moment(day).format('MMM DD');
      }
    }
  };

  const areaChartData = [
    {
      key: 'Borrowers',
      color: '#5AC8FA',
      values: borrowers
    },
    {
      key: 'Investors',
      color: '#348fe2',
      values: investors
    }
  ];

  return (
    <div className="card border-0 text-white bg-blue-dark mb-3 overflow-hidden">
      <div className="card-body">
        <div className="mb-3 text-grey">
          <b>NEW USERS</b>
          <span className="ml-2">
            <i className="fa fa-info-circle" id="popover4"></i>
            <UncontrolledPopover trigger="hover" placement="top" target="popover4">
              <PopoverHeader>From Last 31 Days</PopoverHeader>
              <PopoverBody>Calculated based from last 31 calendar days.</PopoverBody>
            </UncontrolledPopover>
          </span>
        </div>
        <div className="row">
          <div className="col-xl-3 col-4">
            <h3 className="mb-1">{users.new_total_investors}</h3>
            <div>New Investors</div>
            {/* <div className="text-grey f-s-11 text-truncate">
              <i className="fa fa-caret-up"></i> 25.5% from previous 7 days
            </div> */}
          </div>
          <div className="col-xl-3 col-4">
            <h3 className="mb-1">{users.new_total_borrowers}</h3>
            <div>New Issuers</div>
            {/* <div className="text-grey f-s-11 text-truncate">
              <i className="fa fa-caret-up"></i> 5.33% from previous 7 days
            </div> */}
          </div>
          <div className="col-xl-3 col-4">
            <h3 className="mb-1">{users.new_total_registered}</h3>
            <div>Total Register</div>
            {/* <div className="text-grey f-s-11 text-truncate">
              <i className="fa fa-caret-up"></i> 0.323% from previous 7 days
            </div> */}
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        <div style={{ height: '269px' }}>
          <div className="widget-chart-full-width nvd3-inverse-mode" style={{ height: '254px' }}>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <NVD3Chart
                type="stackedAreaChart"
                datum={areaChartData}
                height={260}
                options={areaChartOptions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(NewUsers);
