import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import PageContext from '../../config/page-settings.js';
import { RowActionItems, RowItemView, Table } from '../../components/table';
import * as API from '../../services/api';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import { PriceFormat } from '../../utils/format.js';

const RequestTopUps = () => {
  const pageContext = useContext(PageContext);
  const history = useHistory();

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const onChangeKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const getRequestParams = (keyword, page, pageSize) => {
    let params = {};

    if (keyword) {
      params['filter[search]'] = keyword;
    }

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    params['filter[type]'] = 0;
    params['filter[status]'] = 0;

    return params;
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    setIsLoading(true);
    const params = getRequestParams(keyword, page, pageSize);
    API.getFundRequests(params)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        setData([]);
        setMeta({});
        setIsLoading(false);
      });
  };

  useEffect(fetchData, [page, pageSize]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Date',
        accessorFn: (d) => {
          return moment(d.created_at).local().format('MMM DD, YYYY');
        },
        sortable: true
      },
      {
        header: 'Account Name',
        cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <Link to={`/investors/${original.user_id}`} target="_blank" rel="noopener noreferrer">
              {original.user_full_name}
            </Link>
          );
        },
        sortable: true
      },
      {
        header: 'Amount',
        accessorFn: (d) => {
          return PriceFormat(d.amount);
        },
        sortable: true
      },
      {
        header: 'Action Items',
        cell: ({ cell }) => {
          const { original } = cell.row;
          const onView = () => {
            history.push(`/request/topups/${original.id}`, original);
          };

          return (
            <RowActionItems>
              <RowItemView onClick={onView} />
            </RowActionItems>
          );
        }
      }
    ],
    []
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <h1 className="page-header">Top Ups</h1>
      <Panel>
        <PanelHeader>Request Top Ups</PanelHeader>
        <PanelBody>
          <Table
            isLoading={isLoading}
            columns={columns}
            data={data}
            meta={meta}
            onSetPageSize={handlePageSizeChange}
            updateRow={updateRow}
            onNextPage={handlePageChange('next')}
            onPrevPage={handlePageChange('prev')}
            skipPageReset={skipPageReset}
            onChangeKeyword={onChangeKeyword}
            keyword={keyword}
            onSearchKeyword={handleSearch}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default memo(RequestTopUps);
