import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import PageContext, { PageSettings } from './../../config/page-settings.js';
import ReactSelect from 'react-select';
import ENV from '../../config/env.config.js';
import { isNull } from 'lodash';
import { Notification } from '../../utils/index.js';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

const REPORTS = [
  {
    value: 'investors',
    label: 'Investors'
  },
  {
    value: 'issuers',
    label: 'Issuers'
  },
  {
    value: 'projects',
    label: 'Projects'
  },
  {
    value: 'sunlife',
    label: 'Sunlife'
  }
];

const STATUS = [
  { value: 2, label: 'Incomplete', is_active: true },
  { value: 3, label: 'Pending', is_active: true },
  { value: 4, label: 'Approved', is_active: true },
  { value: 5, label: 'Rejected', is_active: true },
  { value: 6, label: 'Deleted', is_active: true }
];

const Reports = (props) => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [report, setReport] = useState(null);

  const handleChange = (selectedOption) => {
    setReport(selectedOption);
  };

  const [status, setStatus] = useState(null);

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption);
  };

  // const [startDate, setStartDate] = useState(Moment().subtract(7, 'days'));
  const [startDate, setStartDate] = useState(new Date());

  const [endDate, setEndDate] = useState(Moment());

  const [dateRange, setDateRange] = useState({
    currentWeek:
      Moment().subtract('days', 7).format('D MMMM YYYY') + ' - ' + Moment().format('D MMMM YYYY'),
    prevWeek:
      Moment().subtract('days', 15).format('D MMMM') +
      ' - ' +
      Moment().subtract('days', 8).format('D MMMM YYYY')
  });

  const handleDateApplyEvent = (event, picker) => {
    var startDate = picker.startDate;
    var endDate = picker.endDate;
    var gap = endDate.diff(startDate, 'days');

    var currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY');
    var prevWeek =
      Moment(startDate).subtract('days', gap).format('D MMMM') +
      ' - ' +
      Moment(startDate).subtract('days', 1).format('D MMMM YYYY');

    dateRange.currentWeek = currentWeek;
    dateRange.prevWeek = prevWeek;

    setDateRange((prevState) => ({
      ...prevState,
      currentWeek: currentWeek,
      prevWeek: prevWeek
    }));

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const [downloading, setDownloading] = useState(false);
  const downloadCSV = () => {
    setDownloading(true);

    const params = new URLSearchParams();
    params.append('download_type', report?.value);
    params.append('date_start', Moment(startDate).format('YYYY-MM-DD'));
    params.append('date_end', Moment(endDate).format('YYYY-MM-DD'));

    if (!isNull(status)) {
      params.append('status', status?.value == 2 ? '0,2' : status?.value);
    }

    fetch(`${ENV.API_URL}downloads?${params}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage['access_token']
      }
    })
      .then((response) => response.blob())
      .then((response) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${report?.value}-report.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        Notification.error(err);
        setDownloading(false);
      });
  };

  return (
    <div>
      <h1 className="page-header">Reports</h1>
      <Row className="d-flex justify-content-start">
        <Col className="col-3">
          <ReactSelect value={report} onChange={handleChange} options={REPORTS} />
        </Col>
        <Col className="col-3">
          <ReactSelect
            className="text-black"
            placeholder="Select Status"
            value={status}
            onChange={handleStatusChange}
            options={STATUS}
          />
        </Col>
        <Col className="w-auto">
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onApply={handleDateApplyEvent}
            initialSettings={{
              maxDate: Moment(),
              ranges: {
                Today: [moment(), moment()],
                Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [
                  moment().subtract(1, 'month').startOf('month'),
                  moment().subtract(1, 'month').endOf('month')
                ],
                'This Year': [moment().startOf('year'), moment().endOf('year')],
                'From Beginning': [moment('2017').startOf('year'), moment()]
              }
            }}
          >
            <button className="btn btn-inverse mr-2 text-truncate">
              <i className="fa fa-calendar fa-fw text-white-transparent-5 ml-n1"></i>
              <span>{dateRange.currentWeek}</span>
              <b className="caret"></b>
            </button>
          </DateRangePicker>
          {/* <DatePicker
            selected={startDate}
            onChange={(date) => handleDateApplyEvent(date)}
            // monthsShown={2}
            showQuarterYearPicker
            className="form-control"
          /> */}
          <button
            onClick={downloadCSV}
            className="btn btn-secondary btn-md ml-2"
            disabled={isNull(report)}
          >
            {downloading ? (
              <span>
                Downloading... <i className="fas fa-spinner fa-pulse"></i>
              </span>
            ) : (
              <span>
                Download <i className="fa fa-download"></i>
              </span>
            )}
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default Reports;
