import React, { memo, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, FormGroup, FormFeedback, Row, Col, Label, Input } from 'reactstrap';
import { isEmpty } from 'lodash';
import ReactSelect from 'react-select';
import * as API from '../../../services/api';
import Dropzone from 'react-dropzone';
import FileUpload from '../../../components/forms/FileUpload';

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      checked={props.checked}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const schemaIncome = yup.object().shape({
  source_type: yup.string(),
  gross_annual_income: yup.string()
});

const FormSourceOfIncome = (props) => {
  const { investor } = props;

  if (props.currentStep !== 2) {
    return null;
  }

  const {
    watch,
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    defaultValues: {
      gross_annual_income: { value: '', label: '' },
      source_type: { value: '', label: '' }
    },
    resolver: yupResolver(schemaIncome)
  });

  const sourceType = watch('source_type', false);

  const grossAnnual = watch('gross_annual_income', 0);

  const isQualified = watch('is_qualified');

  const optionSourceType = [
    { value: 'Business', label: 'Business' },
    { value: 'Employment', label: 'Employment' }
  ];

  const [optionIndustries, setOptionIndustries] = useState([]);
  const [optionProof, setOptionProof] = useState([]);
  const [optionAnnualIncome, setOptionAnnualIncome] = useState([]);

  useEffect(() => {
    API.dataSets(['industries', 'proof_income', 'gross_annual_income']).then((res) => {
      setOptionIndustries(res.data.industries);
      setOptionProof(res.data.proof_income);
      setOptionAnnualIncome(res.data.gross_annual_income);
    });
  }, []);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onStepOneSubmit = (data) => {
    const params = new URLSearchParams();
    for (let [key, value] of Object.entries({
      ...data,
      is_qualified: data.is_qualified ? 1 : 0,
      first_name: investor.first_name,
      last_name: investor.last_name,
      middle_name: investor.middle_name,
      suffix_name: investor.suffix_name
      // email: investor.email,
      // mobile_code: investor.mobile_code,
      // mobile_number: investor.mobile_number
    })) {
      params.append(key, value || '');
    }
    API.investorUpdate(investor.id, params)
      .then((res) => {
        props.onSubmit();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <Form onSubmit={handleSubmit(onStepOneSubmit)}>
      <FormGroup tag="fieldset">
        <h3>Source of Income</h3>

        <FormGroup row>
          <Label md="3">Source Type</Label>
          <Col md="9">
            <Controller
              name="source_type"
              isClearable
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  value={optionSourceType.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={optionSourceType}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Gross Annual Income</Label>
          <Col md="9">
            <Controller
              name="gross_annual_income"
              isClearable
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  value={optionAnnualIncome.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={optionAnnualIncome}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
          </Col>
        </FormGroup>

        {grossAnnual === 8 && (
          <FormGroup row>
            <Label md="3">Qualified Investor</Label>
            <Col md="9">
              <Controller
                control={control}
                name="is_qualified"
                render={({ field: { value, onChange } }) => (
                  <Switchery checked={value} onChange={onChange} for="is_qualified" />
                )}
              />
            </Col>
          </FormGroup>
        )}

        {/* {grossAnnual.value != '0' && (
          <div className="row form-group m-b-10">
            <div className="col offset-md-3">
              <div className="note note-orange mt-2">
                <div className="note-icon">
                  <i className="fa fa-calculator text-white"></i>
                </div>
                <div className="note-content">
                  <h5 className="text-white">
                    <b>Investing Amount Limit Rules</b>
                  </h5>
                  <p className="text-white">
                    As per:&nbsp;
                    <a
                      href="https://www.sec.gov.ph/wp-content/uploads/2020/09/2019MCNo14.pdf"
                      target="_blank"
                    >
                      SEC Crowdfunding Circular 14 s.2019 art. I, § 3, p.7
                    </a>
                  </p>
                  <div className="invoice" style={{ padding: 0 }}>
                    <div className="invoice-content">
                      <div className="table-responsive">
                        <table className="table table-invoice">
                          <tbody>
                            <tr>
                              <td>
                                <span className="text-inverse">
                                  Crowdfunding Project Amount Cap
                                </span>
                                <br />
                                <small>
                                  Crowdfunding project value that can allow to invest the investor
                                  within a 12-month period
                                </small>
                              </td>
                              <td className="text-right">
                                {isQualified
                                  ? 'Maximum of Php50,000,000.00'
                                  : 'Maximum of Php10,000,000.00'}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span className="text-inverse">Maximum Gross Annual Income</span>
                                <br />
                                <small>Based on the selected Gross Annual Income</small>
                              </td>
                              <td className="text-right">{investingLimitation.max_gross}</td>
                            </tr>
                            <tr>
                              <td>
                                <span className="text-inverse">Maximum Percentage</span>
                                <br />
                                <small>
                                  Allowable maximum value of percentage of their total income per
                                  year
                                </small>
                              </td>
                              <td className="text-right">{investingLimitation.max_percentage}</td>
                            </tr>
                            <tr>
                              <td>
                                <span className="text-inverse">
                                  <b>Maximum Investing Amount</b>
                                </span>
                                <br />
                                <small>Maximum amount that can invest in a 12-month period</small>
                              </td>
                              <td className="text-right">
                                <b>{investingLimitation.max_amount}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}

        {isQualified && (
          <>
            <fieldset>
              <h3>Proof of Qualified Investor</h3>
              <div className="row form-group m-b-10">
                <label className="col-md-3 col-form-label">Attachment File</label>
                <div className="col-md-9">
                  <Dropzone onDrop={(acceptedFiles) => console.log(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <section className="dropzone needsclick">
                        <div {...getRootProps()} className="dz-message">
                          <input {...getInputProps()} />
                          <p>Drag 'n' drop or click to select file here</p>
                          <span className="dz-note needsclick">
                            (This is just a demo dropzone. Selected files are <strong>not</strong>{' '}
                            actually uploaded.)
                          </span>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </div>
            </fieldset>
            <hr />
          </>
        )}
      </FormGroup>

      <hr />

      {sourceType === 'Employment' ? (
        <FormGroup tag="fieldset">
          <h3>Employment Information</h3>
          <FormGroup row>
            <Label md="3">Company Name</Label>
            <Col md="9">
              <Controller
                name="company_name"
                isClearable
                control={control}
                render={({ field }) => (
                  <Input {...field} type="text" invalid={!isEmpty(errors.company_name)} />
                )}
              />
              {errors.company_name && <FormFeedback>{errors.company_name?.message}</FormFeedback>}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="3">Employee Position</Label>
            <Col md="9">
              <Controller
                name="employee_position"
                isClearable
                control={control}
                render={({ field }) => (
                  <Input {...field} type="text" invalid={!isEmpty(errors.employee_position)} />
                )}
              />
              {errors.employee_position && (
                <FormFeedback>{errors.employee_position?.message}</FormFeedback>
              )}
            </Col>
          </FormGroup>
        </FormGroup>
      ) : (
        <FormGroup tag="fieldset">
          <h3>Business Information</h3>
          <FormGroup row>
            <Label md="3">Business Name</Label>
            <Col md="9">
              <Controller
                name="business_name"
                isClearable
                control={control}
                render={({ field }) => (
                  <Input {...field} type="text" invalid={!isEmpty(errors.business_name)} />
                )}
              />
              {errors.business_name && <FormFeedback>{errors.business_name?.message}</FormFeedback>}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md="3">Business Type</Label>
            <Col md="9">
              <Controller
                name="business_type"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <ReactSelect
                    inputRef={ref}
                    value={optionIndustries.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                    options={optionIndustries}
                    isOptionDisabled={(option) => option.disabled}
                  />
                )}
                // render={({ field }) => (
                //   <Input {...field} type="text" invalid={!isEmpty(errors.business_type)} />
                // )}
              />
              {errors.business_type && <FormFeedback>{errors.business_type?.message}</FormFeedback>}
            </Col>
          </FormGroup>
        </FormGroup>
      )}

      <hr />

      <FormGroup tag="fieldset">
        <h3>Proof of your Income</h3>
        <FormGroup row>
          <Label md="3">Document Type</Label>
          <Col md="9">
            <Controller
              name="document_type"
              isClearable
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  value={optionProof.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={optionProof}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
            {errors.document_type && <FormFeedback>{errors.document_type?.message}</FormFeedback>}
          </Col>
        </FormGroup>
        <Controller
          name="proof_income_file"
          control={control}
          render={({ field: { onChange, value, ref } }) => {
            return (
              <FileUpload
                inputRef={ref}
                onDrop={onChange}
                onChange={onChange}
                imagefile={value}
                invalid={!isEmpty(errors.proof_income_file)}
              />
            );
          }}
        />
        <div className="row form-group m-b-10">
          <label className="col-md-3 col-form-label">Attachment File</label>
          <div className="col-md-9">
            <Dropzone onDrop={(acceptedFiles) => console.log(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone needsclick">
                  <div {...getRootProps()} className="dz-message">
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop or click to select file here</p>
                    <span className="dz-note needsclick">
                      (This is just a demo dropzone. Selected files are <strong>not</strong>{' '}
                      actually uploaded.)
                    </span>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
      </FormGroup>

      <hr />

      <Row>
        <Col>
          {props.currentStep > 1 && (
            <button className="btn btn-primary float-left" type="button" onClick={props.prevStep}>
              Previous
            </button>
          )}
          <button className="btn btn-primary float-right" type="submit">
            Next
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default withRouter(FormSourceOfIncome);
