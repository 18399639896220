import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import PageContext from '../../config/page-settings.js';
import {
  Table,
  RowActionItems,
  RowItemView,
  RowItemApprove,
  RowItemReject,
  RowItemCancel
} from '../../components/table';
import * as API from '../../services/api';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { isNull } from 'lodash';
import { PriceFormat } from '../../utils/format.js';
import { Notification } from '../../utils/index.js';
import { useAppState } from '../../overmind/index.js';
import ENV from '../../config/env.config.js';
import { Row, Col, Form, FormGroup, Label, Tooltip } from 'reactstrap';

const TooltipItem = (props) => {
  const { placement, id, content } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <i
        className="fas fa-exclamation-triangle fa-fw"
        id={'Tooltip-' + id}
        style={{ color: 'orange' }}
      ></i>
      <Tooltip placement={placement} isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
        {content}
      </Tooltip>
    </span>
  );
};

const RequestWithdrawals = () => {
  const pageContext = useContext(PageContext);
  const history = useHistory();
  const { badges } = useAppState();

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const onChangeKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const getRequestParams = (keyword, page, pageSize) => {
    let params = {};

    if (keyword) {
      params['filter[user]'] = keyword;
    }

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }
    params['filter[type]'] = 1;
    params['filter[status]'] = 0;

    return params;
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    setIsLoading(true);
    const params = getRequestParams(keyword, page, pageSize);
    API.getFundRequests(params)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        setData([]);
        setMeta({});
        setIsLoading(false);
      });
  };

  useEffect(fetchData, [page, pageSize]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const onView = (id) => {
    history.push(`/request/withdrawals/${cell.value}`, original);
  };

  const onApproveWithdrawal = (id) => () => {
    if (badges.jobs > 0) {
      Notification.info('Server Background Processing', 'Please try again later.');
    } else {
      API.requestWithdrawApprove(id)
        .then(() => {
          Notification.success(
            'Withdraw Request Approved',
            'Withdraw request successfully approved!'
          );
          fetchData();
        })
        .catch((err) => {
          Notification.error(err);
          toggleModalReject();
        });
    }
  };

  const columns = useMemo(
    () => [
      {
        header: 'Date',
        accessorFn: (d) => {
          return moment(d.created_at).local().format('MMM DD, YYYY');
        },
        sortable: true
      },
      {
        header: 'Investor ID',
        cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <Link to={`/investors/${original.user_id}`} target="_blank" rel="noopener noreferrer">
              {original.user_id}
            </Link>
          );
        },
        sortable: true
      },
      {
        header: 'Account Name',
        cell: ({ cell }) => {
          const { original } = cell.row;
          console.log(original);

          return (
            <>
              <Link
                to={`/request/withdrawals/${original.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {original.user_full_name}
              </Link>
              {!isNull(original?.user?.remarks) && (
                <TooltipItem
                  placement="top"
                  id={original.user_id}
                  content={original?.user?.remarks}
                />
              )}
            </>
          );
        },
        sortable: true
      },
      {
        header: 'Amount',
        accessorFn: (d) => {
          return PriceFormat(d.amount);
        },
        sortable: true
      },
      {
        header: 'Type',
        accessorKey: 'payment_label',
        sortable: true
      },
      {
        header: 'Action Items',
        width: 10,
        cell: ({ cell }) => {
          const { original } = cell.row;
          const onView = () => {
            history.push(`/request/withdrawals/${original.id}`, original);
          };

          const onRejectWithdrawal = (reason) => {
            if (badges.jobs > 0) {
              Notification.info('Server Background Processing', 'Please try again later.');
            } else {
              API.requestWithdrawReject(original.id, { reason })
                .then((res) => {
                  fetchData();
                  Notification.success(
                    'Withdraw Request Rejected',
                    'Withdraw request successfully rejected!'
                  );
                })
                .catch((err) => {
                  Notification.error(err);
                });
            }
          };

          return (
            <RowActionItems>
              <RowItemApprove onConfirm={onApproveWithdrawal(original.id)} />
              <RowItemReject onConfirm={onRejectWithdrawal} />
            </RowActionItems>
          );
        }
      }
    ],
    []
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  const [downloading, setDownloading] = useState(false);
  const downloadCSV = () => {
    setDownloading(true);
    fetch(`${ENV.API_URL}downloads?filter[type]=1&filter[status]=0&download_type=withdrawal`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage['access_token']
      }
    })
      .then((response) => response.blob())
      .then((response) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Withdrawal-Request.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  // const downloadCSV = () => {
  //   fetch(`${ENV.BASE_URL}downloads?filter[type]=1&filter[status]=0&download_type=withdrawal`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: 'Bearer ' + window.localStorage['access_token']
  //     }
  //   })
  //     .then((response) => response.blob())
  //     .then((response) => {

  //       // const href = URL.createObjectURL(response);
  //       // console.log(response);
  //       // // create "a" HTML element with href to file & click
  //       // const link = document.createElement('a');
  //       // link.href = href;
  //       // link.setAttribute('download', 'withdrawal.csv'); //or any other extension
  //       // document.body.appendChild(link);
  //       // link.click();

  //       // // clean up "a" element & remove ObjectURL
  //       // document.body.removeChild(link);
  //       // URL.revokeObjectURL(href);
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', 'withdrawal.csv');
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //     });

  //   // API.downloadWithdrawals();
  //   // // .then((res) => {})
  //   // // .catch((err) => {
  //   // //   Notification.error(err);
  //   // // });
  // };

  return (
    <>
      <h1 className="page-header">
        Withdrawals{' '}
        <button onClick={downloadCSV} className="btn btn-secondary btn-icon btn-circle btn-md ml-2">
          {downloading ? (
            <i className="fas fa-spinner fa-pulse"></i>
          ) : (
            <i className="fa fa-download"></i>
          )}
        </button>
      </h1>
      <Panel>
        <PanelHeader>Request Withdrawals</PanelHeader>
        <PanelBody>
          <Table
            columns={columns}
            data={data}
            meta={meta}
            isLoading={isLoading}
            onSetPageSize={handlePageSizeChange}
            updateRow={updateRow}
            onNextPage={handlePageChange('next')}
            onPrevPage={handlePageChange('prev')}
            skipPageReset={skipPageReset}
            onChangeKeyword={onChangeKeyword}
            keyword={keyword}
            onSearchKeyword={handleSearch}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default memo(RequestWithdrawals);

const ButtonReject = (id) => {
  const history = useHistory();
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const [reason, setReason] = useState('');

  const onReject = () => {
    setModalReject(false);
    API.requestWithdrawReject(id, { reason })
      .then((res) => {
        Notification.success(
          'Withdraw Request Rejected',
          'Withdraw request successfully rejected!'
        );
      })
      .catch((err) => {
        Notification.error(err);
      });
    toggleModalReject().then(() => {
      history.goBack();
    });
  };

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-danger mr-2" type="button" onClick={toggleModalReject}>
        Reject
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Withdraw Request</span>}
        danger
        showCancel
        confirmBtnText="Reject"
        confirmBtnBsStyle="danger"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
        dependencies={[reason]}
      >
        <form>
          <textarea
            type="text"
            className="form-control"
            value={reason}
            onChange={onChangeText}
            placeholder="Please indicate the reason for rejecting withdraw request"
          />
        </form>
      </SweetAlert>
    </>
  );
};

const ButtonApprove = (id) => {
  const history = useHistory();
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    setModalReject(false);
    API.requestWithdrawApprove(id)
      .then(() => {
        Notification.success(
          'Withdraw Request Approved',
          'Withdraw request successfully approved!'
        );
        toggleModalReject().then(() => {
          history.goBack();
        });
      })
      .catch((err) => {
        Notification.error(err);
        toggleModalReject();
      });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-green text-white" type="button" onClick={toggleModalReject}>
        Approve
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Withdraw Request</span>}
        success
        showCancel
        confirmBtnText="Approve"
        confirmBtnBsStyle="success"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <p style={{ color: 'black' }}>Are you sure you want to approve this withdraw request?</p>
      </SweetAlert>
    </>
  );
};
