import React, { useState, useEffect, memo } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useParams } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import * as API from '../../../services/api.js';
import { Notification } from '../../../utils/index.js';
import { Form, FormGroup, FormText, FormFeedback, Row, Col, Label, Input } from 'reactstrap';

import LoadingSpinner from '../../../components/common/LoadingSpinner.js';
const BanksList = ({ id }) => {
  const [banks, setBanks] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getBanks();
  }, [id]);

  const getBanks = () => {
    setLoading(true);
    API.getUserBank(id)
      .then((res) => {
        setBanks(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setBanks([]);
      });
  };

  const [addBank, setAddBank] = useState(false);

  const showAddBank = () => {
    setAddBank(!addBank);
  };

  const onSubmitBank = () => {
    setAddBank(!addBank);
    getBanks();
  };

  const onReject = (bank_id, reason) => () => {
    const params = {
      status: String(3),
      remarks: reason,
      user_id: id
    };
    API.updateInvestorBankStatus(params)
      .then(() => {
        getBanks();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const onApprove = (bank_id) => {
    const paramsObj = {
      status: String(2),
      remarks: '',
      user_id: id
    };

    // const params = new URLSearchParams();
    // for (let [key, value] of Object.entries(paramsObj)) {
    //   params.append(key, value || '');
    // }

    API.updateInvestorBankStatus(bank_id, paramsObj)
      .then(() => {
        getBanks();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const onPrimary = (bank_id) => {
    const paramsObj = {
      status: String(2),
      remarks: '',
      user_id: id,
      is_primary: 1
    };

    // const params = new URLSearchParams();
    // for (let [key, value] of Object.entries(paramsObj)) {
    //   params.append(key, value || '');
    // }

    API.updateInvestorBankStatus(bank_id, paramsObj)
      .then(() => {
        getBanks();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const onDelete = (bank_id) => {
    API.deleteUserBank(bank_id)
      .then(() => {
        getBanks();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  return (
    <>
      <h3>Banks</h3>
      <Panel>
        <PanelHeader>{addBank ? 'Add Bank' : 'Bank List'}</PanelHeader>
        <PanelBody>
          <div className="table-responsive">
            <table className="table table-striped mb-0 align-middle">
              <thead>
                <tr>
                  <th>Bank</th>
                  <th>Account Name</th>
                  <th>Account Number</th>
                  <th>Status</th>
                  <th>Attachment</th>
                  <th width="20%" style={{ textAlign: 'center' }}>
                    Action Items
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                      <LoadingSpinner />
                    </td>
                  </tr>
                ) : banks.length ? (
                  banks.map((bank) => (
                    <tr key={bank.id}>
                      <td>
                        {bank.bank_name}{' '}
                        {bank.is_primary == 1 && (
                          <>
                            <br /> <span className="badge bg-primary rounded-pill">Primary</span>
                          </>
                        )}
                      </td>
                      <td>{bank.account_name}</td>
                      <td>{bank.account_number}</td>
                      <td>{bank.status?.name}</td>
                      <td style={{ textAlign: 'center' }}>
                        {bank.attachment.view != null ? (
                          <div className="with-btn text-nowrap d-flex justify-content-center">
                            <a
                              className="btn btn-sm btn-primary w-60px mr-1"
                              href={bank.attachment.view}
                              target="_blank"
                            >
                              <i className="fa fa-eye"></i>
                            </a>
                            <a
                              className="btn btn-sm btn-primary w-60px mr-1"
                              href={bank.attachment.download}
                            >
                              <i className="fa fa-download"></i>
                            </a>
                          </div>
                        ) : (
                          'Not Available'
                        )}
                      </td>
                      <td nowrap="true">
                        <div
                          style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}
                        >
                          {bank && bank?.status && bank?.status?.id == 1 && (
                            <>
                              <ButtonReject onReject={onReject} id={bank.id} />
                              <ButtonApprove onApprove={onApprove} id={bank.id} />
                            </>
                          )}

                          {bank && bank?.status && bank?.status?.id == 5 && (
                            <>
                              <ButtonApprove onApprove={onApprove} id={bank.id} />
                            </>
                          )}

                          {bank && bank?.status && bank?.status.id == 2 && bank?.is_primary == 0 && (
                            <>
                              <ButtonPrimary onConfirm={onPrimary} id={bank.id} />
                              <ButtonDelete onConfirm={onDelete} id={bank.id} />
                            </>
                          )}
                          {bank && bank?.status && bank?.status?.id == 3 && bank.is_primary == 0 && (
                            <>
                              <ButtonDelete onConfirm={onDelete} id={bank.id} />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                      No bank(s) recorded
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </PanelBody>
      </Panel>
    </>
  );
};

const ButtonReject = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onConfirm = () => {
    // setModalReject(false);
    toggleModalReject().then(() => {
      props.onReject(props.id, reason);
      // setTimeout(() => {
      //   props.onConfirm();
      // }, 300);
    });
  };

  const [reason, setReason] = useState('');

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  return (
    <>
      <button className="btn btn-sm btn-danger w-60px float-right mx-1" onClick={toggleModalReject}>
        Reject
      </button>

      <SweetAlert
        title={<span className="text-black text-wrap">Bank Item</span>}
        danger
        showCancel
        confirmBtnText="Reject"
        confirmBtnBsStyle="danger"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onConfirm}
        onCancel={toggleModalReject}
        show={modalReject}
        dependencies={[reason]}
      >
        <form>
          <textarea
            type="text"
            className="form-control"
            value={reason}
            onChange={onChangeText}
            placeholder="Please indicate the reason for rejecting bank item"
          />
        </form>
      </SweetAlert>
    </>
  );
};

const ButtonApprove = (props) => {
  console.log('ButtonApprove', props);
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    // setModalReject(false);
    toggleModalReject().then(() => {
      props.onApprove(props.id);
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button
        className="btn btn-sm btn-primary float-right mx-1"
        type="button"
        onClick={toggleModalReject}
      >
        Approve
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Bank Details</span>}
        success
        showCancel
        confirmBtnText="Approve"
        confirmBtnBsStyle="success"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <p style={{ color: 'black' }}>Are you sure you want to approve this bank details?</p>
      </SweetAlert>
    </>
  );
};

const ButtonPrimary = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    // setModalReject(false);
    toggleModalReject().then(() => {
      props.onConfirm(props.id);
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button
        className="btn btn-sm btn-primary float-right mx-1"
        type="button"
        onClick={toggleModalReject}
      >
        Set as Primary
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Bank - Set as Primary</span>}
        info
        showCancel
        confirmBtnText="Set as Primary"
        confirmBtnBsStyle="info"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <p style={{ color: 'black' }}>Are you sure you want to set this bank as primary?</p>
      </SweetAlert>
    </>
  );
};

const ButtonDelete = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    toggleModalReject().then(() => {
      props.onConfirm(props.id);
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button
        className="btn btn-sm btn-danger float-right mx-1"
        type="button"
        onClick={toggleModalReject}
      >
        Delete
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Bank - Delete</span>}
        danger
        showCancel
        confirmBtnText="Delete"
        confirmBtnBsStyle="danger"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <p style={{ color: 'black' }}>Are you sure you want to delete this bank details?</p>
      </SweetAlert>
    </>
  );
};
export default memo(BanksList);
