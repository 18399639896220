import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PageContext from '../../../config/page-settings.js';

import NotificationLogsList from './NotificationLogsList.js';

const NotificationLogs = (props) => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  return (
    <div>
      <h1 className="page-header">Notification Logs</h1>
      <NotificationLogsList />
    </div>
  );
};

export default withRouter(NotificationLogs);
