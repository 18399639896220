import axios from 'axios';
import ENV from '../config/env.config.js';
import { useAxios } from './axios.js';

export const csrfAPI = axios.create({
  baseURL: ENV.BASE_URL,
  withCredentials: true
});

export const api = axios.create({
  baseURL: ENV.API_URL,
  withCredentials: true,
  // timeout: 20000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const apiForm = axios.create({
  baseURL: ENV.API_URL,
  withCredentials: true,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

export const apiFile = axios.create({
  baseURL: ENV.API_URL,
  withCredentials: true,
  timeout: 10000,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

export const setHeaderToken = async (token) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  apiForm.defaults.headers.common.Authorization = `Bearer ${token}`;
  apiFile.defaults.headers.common.Authorization = `Bearer ${token}`;
  apiFile.defaults.headers.common['Content-Type'] = `multipart/form-data`;
};

// export { useAxios };

// export const apiForm = axios.create({
//   baseURL: ENV.API_URL,
//   timeout: 10000,
//   headers: {
//     'CLIENT-ID': 'Hy5FEW3_f3071Vr-',
//     'APP-VERSION': APP_VERSION,
//     'APP-OS': Platform.OS,
//     'Content-Type': 'application/x-www-form-urlencoded'
//   }
// });
