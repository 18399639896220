import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import PageContext from '../../config/page-settings.js';
import * as API from '../../services/api';
import { Table, RowActionItems, RowItemDelete, RowItemView } from '../../components/table';
import { Notification } from '../../utils/index.js';
import moment from 'moment';

// const SEARCH_ARRAY = [
//   { label: 'Company Name', value: 'company_name' },
//   { label: 'Email', value: 'email' },
//   { label: 'Mobile', value: 'otp_number' }
// ];

const FILTER_ARRAY = [
  {
    name: 'status',
    value: 0
  }
];

const IssuerList = () => {
  const history = useHistory();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const onChangeKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  // const [searchKeyword, setKeywordSearch] = useState(SEARCH_ARRAY[0]);

  const getRequestParams = (keyword, page, pageSize, filters) => {
    let params = {};

    if (keyword) {
      params[`filter[search]`] = keyword;
    }

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    if (filters) {
      filters.forEach((f) => {
        params[`filter[${f.name}]`] = f.value.value;
      });
    }

    return params;
  };

  const [filters, setFilters] = useState(FILTER_ARRAY);

  const onChangeFilter = (i) => (e) => {
    const newState = filters.map((obj, index) => {
      if (index === i) {
        return { ...obj, value: e };
      }
      return obj;
    });

    setFilters(newState);
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    const params = getRequestParams(keyword, page, pageSize, filters);
    setIsLoading(true);
    API.getIssuers(params)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        setData([]);
        setMeta({});
        setIsLoading(false);
      });
  };

  useEffect(fetchData, [page, pageSize, filters]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Date Joined',
        accessorFn: (d) => {
          return moment(d.created_at).local().format('MMM DD, YYYY');
        }
      },
      {
        header: 'Company Name',
        accessorFn: (d) => {
          return (d.company_details && d.company_details.company_name) || 'N/A';
        },
        sortable: true
      },
      {
        header: 'Contact Person',
        accessorFn: (d) => {
          return `${d.full_name}`;
        },
        sortable: true
      },
      {
        header: 'Email',
        accessorKey: 'email',
        sortable: true
      },
      {
        header: 'Mobile',
        accessorFn: (d) => {
          return d.otp_number || 'N/A';
        },
        sortable: true
      },
      {
        header: 'Status',
        accessorFn: (d) => {
          return d.status.name;
        },
        sortable: true
      },
      {
        header: 'Action Items',
        cell: ({ cell }) => {
          const { original } = cell.row;

          const onView = () => {
            history.push(`/issuers/${original.id}`, original);
          };

          return (
            <RowActionItems>
              <RowItemView onClick={onView} />
            </RowActionItems>
          );
        }
      }
    ],
    [data]
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Panel>
      <PanelHeader>Issuers</PanelHeader>
      <PanelBody>
        <Table
          columns={columns}
          data={data}
          meta={meta}
          isLoading={isLoading}
          onSetPageSize={handlePageSizeChange}
          updateRow={updateRow}
          onNextPage={handlePageChange('next')}
          onPrevPage={handlePageChange('prev')}
          skipPageReset={skipPageReset}
          onChangeKeyword={onChangeKeyword}
          keyword={keyword}
          onSearchKeyword={handleSearch}
          filterAPI={[
            {
              name: 'status',
              options: [
                { value: 2, label: 'Incomplete', is_active: true },
                { value: 3, label: 'Pending', is_active: true },
                { value: 4, label: 'Approved', is_active: true },
                { value: 5, label: 'Rejected', is_active: true },
                { value: 6, label: 'Deleted', is_active: true }
              ],
              onChange: onChangeFilter(0),
              value: filters[0]?.value
            }
          ]}
          // searchByGroup={SEARCH_ARRAY}
          // searchValueBy={searchKeyword}
          // onSelectSearch={setKeywordSearch}
        />
      </PanelBody>
    </Panel>
  );
};

export default memo(IssuerList);
