import React from 'react';
import { Redirect } from 'react-router';

import Dashboard from '../pages/dashboard';
import Login from '../pages/login';

import ChangePassword from '../pages/changepassword';
import Profile from '../pages/profile';
import ActivityLogs from '../pages/logs/activity';
import SystemLogs from '../pages/logs/system';
import NotificationLogs from '../pages/logs/notification';

import UserManagement from '../pages/settings/users';
import RolesManagement from '../pages/settings/roles';
import BanksManagement from '../pages/settings/banks';
import CountryManagement from '../pages/settings/countries';
import PaymentTypes from '../pages/settings/payment/types';
import PaymentOptionManagement from '../pages/settings/payment/options';
import DocumentRequirementsManagement from '../pages/settings/documents';
import EntityManagement from '../pages/settings/entity';
import IndustryMangement from '../pages/settings/industry';
import GlobalSettingsManagement from '../pages/settings/global-settings';

import Error404 from '../pages/404';
import UserCreate from '../pages/settings/users/UserCreate';
import RoleCreate from '../pages/settings/roles/RoleCreate';
import CountryCreate from '../pages/settings/countries/CountryCreate';
import DocumentRequirementsCreate from '../pages/settings/documents/DocumentRequirementsCreate';
import IndustryCreate from '../pages/settings/industry/IndustryCreate';
import GlobalSettingsCreate from '../pages/settings/global-settings/GlobalSettingsCreate';

import UserView from '../pages/settings/users/UserView';

import Investors from '../pages/investors';
import InvestorPriorityList from '../pages/investors/InvestorPriorityList';

import InvestorCreate from '../pages/investors/InvestorCreate';
import InvestorView from '../pages/investors/InvestorView';

import Issuers from '../pages/issuers';
import IssuerCreate from '../pages/issuers/IssuerCreate';
import IssuerView from '../pages/issuers/IssuerView';

import Projects from '../pages/projects';
import ProjectCreate from '../pages/projects/ProjectCreate';

import RequestInvestors from '../pages/requests/RequestInvestors';
import RequestIssuers from '../pages/requests/RequestIssuers';
import RequestBanks from '../pages/requests/RequestBanks';
import RequestTopUps from '../pages/requests/RequestTopUps';
import RequestWithdrawals from '../pages/requests/RequestWithdrawals';
import RequestLoans from '../pages/requests/RequestLoans';
import IndustryView from '../pages/settings/industry/IndustryView';
//partners
import PartnersManagement from '../pages/partners';
//marketing
import MessageBlastManagement from '../pages/marketing/messageblast';
import MessageBlastCreate from '../pages/marketing/messageblast/MessageBlastCreate';
import NewsManagement from '../pages/marketing/news';
import NewsCreate from '../pages/marketing/news/NewsCreate';

//transactions
import InvestorTransactions from '../pages/transactions/investors';
import IssuerTransactions from '../pages/transactions/issuers';
import PaynamicsTransaction from '../pages/transactions/paynamics';
import CoinsTransactions from '../pages/transactions/coinsph';
import XenditTransaction from '../pages/transactions/xendit';
import DenariiTransactions from '../pages/transactions/denarii';

import ReviewLoan from '../pages/loans/ReviewLoan';
import IssuerRequestLoan from '../pages/issuers/IssuerRequestLoan';
import ReviewBank from '../pages/requests/components/ReviewBank';
import ProjectView from '../pages/projects/ProjectView';
import LoanSchedule from '../pages/projects/components/LoanSchedule';
import ProjectDetails from '../pages/projects/components/ProjectDetails';
import ProjectUpdate from '../pages/projects/ProjectUpdate';
import AppVersionsManagement from '../pages/settings/app-versions';
import AppVersionCreate from '../pages/settings/app-versions/AppVersionCreate';
import GlobalSettingsView from '../pages/settings/global-settings/GlobalSettingsView';
import NewsView from '../pages/marketing/news/NewsView';
import PaymentOptionCreate from '../pages/settings/payment/options/PaymentOptionCreate';
import AppVersionView from '../pages/settings/app-versions/AppVersionView';
import IssuerApplication from '../pages/issuers/IssuerApplication';
import ProjectStart from '../pages/projects/ProjectStart';
import PaymentOptionView from '../pages/settings/payment/options/PaymentOptionView';
import PaynamicsDetail from '../pages/transactions/paynamics/PaynamicsDetail';
import CoinsDetails from '../pages/transactions/coinsph/CoinsDetails';
import XenditDetail from '../pages/transactions/xendit/XenditDetail';
import RequestTopUpDetail from '../pages/requests/RequestTopUpDetail';
import ViewDetailWithdraw from '../pages/requests/components/ViewDetailWithdraw';
import DenariiDetail from '../pages/transactions/denarii/DenariiDetail';
import RoleUpdate from '../pages/settings/roles/RoleUpdate';
import ViewDetailTopup from '../pages/requests/components/ViewDetailTopup';
import Reports from '../pages/reports';
import ReportChart from '../pages/reports/charts';

import PartnersList from '../pages/merchants/partners/PartnersList';
import CategoryList from '../pages/merchants/category/CategoryList';
import PerksList from '../pages/merchants/perks/PerksList';

import PartnersCreate from '../pages/merchants/partners/PartnersCreate';
import PartnersView from '../pages/merchants/partners/PartnersView';

import CategoryCreate from '../pages/merchants/category/CategoryCreate';
import PerksCreate from '../pages/merchants/perks/PerksCreate';
import PerksUpdate from '../pages/merchants/perks/PerksUpdate';
import PerksView from '../pages/merchants/perks/PerksView';

// import PaymentCategoryList from '../pages/settings/payment-gateways/categories/PaymentCategoryList';

const routes = [
  {
    path: '/login',
    exact: true,
    title: 'Login',
    component: () => <Login />
  },
  {
    path: '/',
    exact: true,
    title: 'Dashboard',
    component: () => <Dashboard />
  },
  {
    path: '/request/investors',
    exact: true,
    title: 'Request Investors',
    component: RequestInvestors
  },
  {
    path: '/request/issuers',
    exact: true,
    title: 'Request Issuers',
    component: RequestIssuers
  },
  {
    path: '/request/banks',
    exact: true,
    title: 'Request Banks',
    component: RequestBanks
  },
  {
    path: '/request/banks/:id',
    exact: true,
    title: 'Review Bank',
    component: ReviewBank
  },
  {
    path: '/request/topups',
    exact: true,
    title: 'Request Top Ups',
    component: RequestTopUps
  },
  {
    path: '/request/topups/:id?',
    exact: true,
    title: 'Request Top Up Detail',
    component: ViewDetailTopup
  },
  {
    path: '/request/withdrawals',
    exact: true,
    title: 'Request Withdrawals',
    component: RequestWithdrawals
  },
  {
    path: '/request/withdrawals/:id?',
    exact: true,
    title: 'Request Withdraw Detail',
    component: ViewDetailWithdraw
  },
  {
    path: '/request/loans',
    exact: true,
    title: 'Request Issuers',
    component: RequestLoans
  },
  {
    path: '/request/loans/review/:id',
    exact: true,
    title: 'Loan Review',
    component: () => <ReviewLoan />
  },
  {
    path: '/partners',
    exact: true,
    title: 'Seedin Partners',
    component: PartnersManagement
  },
  {
    path: '/investors',
    exact: true,
    title: 'Investors',
    component: Investors
  },
  {
    path: '/investors/priority',
    exact: true,
    title: 'Investors - Priority',
    component: InvestorPriorityList
  },
  {
    path: '/investor/create',
    exact: true,
    title: 'Investors',
    component: InvestorCreate
  },
  {
    path: '/investors/:id',
    exact: true,
    title: 'Investor Profile',
    component: InvestorView
  },
  {
    path: '/issuers',
    exact: true,
    title: 'Investors',
    component: () => <Issuers />
  },
  {
    path: '/issuers/create',
    exact: true,
    title: 'Issuer Create',
    component: () => <IssuerCreate />
  },
  {
    path: '/issuers/:id/application',
    exact: true,
    title: 'Issuer Application',
    component: () => <IssuerApplication />
  },
  {
    path: '/issuers/:id',
    exact: true,
    title: 'Issuer Profile',
    component: () => <IssuerView />
  },
  {
    path: '/issuers/:id/request/loan',
    exact: true,
    title: 'Issuer Request Loan',
    component: () => <IssuerRequestLoan />
  },
  {
    path: '/issuers/:id/request/loan/:loan_id',
    exact: true,
    title: 'Issuer Request Loan',
    component: () => <IssuerRequestLoan />
  },
  {
    path: '/projects',
    exact: true,
    title: 'Projects',
    component: () => <Projects />
  },
  {
    path: '/projects/:user_id?/start/:loan_request_id?',
    exact: true,
    title: 'Project Create',
    component: () => <ProjectStart />
  },
  {
    path: '/projects/create/:user_id?',
    exact: true,
    title: 'Project Create',
    component: () => <ProjectCreate />
  },
  {
    path: '/projects/update/:project_id?',
    exact: true,
    title: 'Project Update',
    component: () => <ProjectUpdate />
  },
  {
    path: '/projects/:project_id',
    exact: true,
    title: 'Project View',
    component: ProjectView,
    routes: [
      {
        path: '/projects/:project_id',
        exact: true,
        title: 'Project Details',
        component: ProjectDetails
      },
      {
        path: `/projects/:id/schedule`,
        exact: true,
        title: 'Loan Schedule',
        component: LoanSchedule
      }
    ]
  },
  {
    path: '/transactions/investors',
    exact: true,
    title: 'Investor Transactions',
    component: InvestorTransactions
  },
  {
    path: '/transactions/issuers',
    exact: true,
    title: 'Issuer Transactions',
    component: IssuerTransactions
  },
  {
    path: '/transactions/paynamics',
    exact: true,
    title: 'Paynamics Transactions',
    component: PaynamicsTransaction
  },
  {
    path: '/transactions/paynamics/:id?',
    exact: true,
    title: 'Paynamics Transactions Details',
    component: PaynamicsDetail
  },
  {
    path: '/transactions/coinsph',
    exact: true,
    title: 'CoinsPH Transactions',
    component: CoinsTransactions
  },
  {
    path: '/transactions/coinsph/:id?',
    exact: true,
    title: 'CoinsPH Transactions Detail',
    component: CoinsDetails
  },
  {
    path: '/transactions/xendit',
    exact: true,
    title: 'Xendit Transactions',
    component: XenditTransaction
  },
  {
    path: '/transactions/xendit/:id?',
    exact: true,
    title: 'Xendit Transactions Details',
    component: XenditDetail
  },
  {
    path: '/transactions/denarii',
    exact: true,
    title: 'Denarii Transactions',
    component: DenariiTransactions
  },
  {
    path: '/transactions/denarii/:id?',
    exact: true,
    title: 'Denarii Transactions Detail',
    component: DenariiDetail
  },
  {
    path: '/reports/generate',
    exact: true,
    title: 'Report Generate',
    component: () => <Reports />
  },
  {
    path: '/reports/charts',
    exact: true,
    title: 'Report Chart',
    component: () => <ReportChart />
  },
  {
    path: '/settings/users',
    exact: true,
    title: 'Users',
    component: () => <UserManagement />
  },
  {
    path: '/settings/users/create',
    exact: true,
    title: 'Users Create',
    component: () => <UserCreate />
  },
  {
    path: '/settings/users/:id',
    exact: true,
    title: 'User Profile',
    component: () => <UserView />
  },
  // {
  //   path: '/user/:id',
  //   exact: true,
  //   title: 'User Profile',
  //   component: () => <UserView />
  // },
  {
    path: '/user/me',
    exact: true,
    title: 'Profile',
    component: () => <Profile />
  },
  {
    path: '/user/me/change-password',
    exact: true,
    title: 'Change Password',
    component: () => <ChangePassword />
  },
  {
    path: '/marketing/news',
    exact: true,
    title: 'News Management',
    component: NewsManagement
  },
  {
    path: '/marketing/news/create',
    exact: true,
    title: 'Create News',
    component: () => <NewsCreate />
  },
  {
    path: '/marketing/news/:id',
    exact: true,
    title: 'View News',
    component: () => <NewsView />
  },
  {
    path: '/marketing/blast',
    exact: true,
    title: 'Message Blast Management',
    component: MessageBlastManagement
  },
  {
    path: '/marketing/blast/create',
    exact: true,
    title: 'Create Message Blast',
    component: () => <MessageBlastCreate />
  },
  {
    path: '/merchants/partners',
    exact: true,
    title: 'Partner Merchants',
    component: PartnersList
  },
  {
    path: '/merchants/partners/create',
    exact: true,
    title: 'Create Partner Merchants',
    component: () => <PartnersCreate />
  },
  {
    path: '/merchants/partners/:id',
    exact: true,
    title: 'View Partner Merchant',
    component: PartnersView
  },
  {
    path: '/merchants/categories',
    exact: true,
    title: 'Perks Categories',
    component: CategoryList
  },
  {
    path: '/merchants/categories/create',
    exact: true,
    title: 'Create Category',
    component: () => <CategoryCreate />
  },
  {
    path: '/merchants/perks',
    exact: true,
    title: 'Perks',
    component: PerksList
  },
  {
    path: '/merchants/perks/create',
    exact: true,
    title: 'Perks Create ',
    component: () => <PerksCreate />
  },
  {
    path: '/merchants/perks/:id?/update',
    exact: true,
    title: 'Perks Update',
    component: () => <PerksUpdate />
  },
  {
    path: '/merchants/perks/:id',
    exact: true,
    title: 'View Perks',
    component: PerksView
  },
  {
    path: '/settings/roles',
    exact: true,
    title: 'Roles',
    component: () => <RolesManagement />
  },
  {
    path: '/settings/roles/create',
    exact: true,
    title: 'Create Role',
    component: () => <RoleCreate />
  },
  {
    path: '/settings/roles/:id',
    exact: true,
    title: 'View Role',
    component: () => <RoleUpdate />
  },
  {
    path: '/settings/countries',
    exact: true,
    title: 'Create Role',
    component: () => <CountryManagement />
  },
  {
    path: '/settings/countries/create',
    exact: true,
    title: 'Create Country',
    component: () => <CountryCreate />
  },
  {
    path: '/settings/documents',
    exact: true,
    title: 'Document Requirements Management',
    component: () => <DocumentRequirementsManagement />
  },
  {
    path: '/settings/documents/create',
    exact: true,
    title: 'Document Requirements Management',
    component: () => <DocumentRequirementsCreate />
  },
  {
    path: '/settings/payment/types',
    exact: true,
    title: 'Payment Types',
    component: () => <PaymentTypes />
  },
  {
    path: '/settings/payment/options',
    exact: true,
    title: 'Payment Options',
    component: () => <PaymentOptionManagement />
  },
  {
    path: '/settings/payment/options/create',
    exact: true,
    title: 'Create Payment Options',
    component: () => <PaymentOptionCreate />
  },
  {
    path: '/settings/payment/options/:id',
    exact: true,
    title: 'View Payment Option',
    component: () => <PaymentOptionView />
  },
  {
    path: '/settings/banks',
    exact: true,
    title: 'Banks Management',
    component: () => <BanksManagement />
  },
  {
    path: '/settings/entity',
    exact: true,
    title: 'Create Role',
    component: () => <EntityManagement />
  },
  {
    path: '/settings/industry',
    exact: true,
    title: 'Create Role',
    component: () => <IndustryMangement />
  },
  {
    path: '/settings/industry/create',
    exact: true,
    title: 'Create Industry',
    component: () => <IndustryCreate />
  },
  {
    path: '/settings/industry/:id',
    exact: true,
    title: 'View Industry',
    component: () => <IndustryView />
  },
  {
    path: '/settings/global-settings',
    exact: true,
    title: 'Global Settings Management',
    component: () => <GlobalSettingsManagement />
  },
  {
    path: '/settings/global-settings/create',
    exact: true,
    title: 'Create Global Settings',
    component: () => <GlobalSettingsCreate />
  },
  {
    path: '/settings/global-settings/:id',
    exact: true,
    title: 'View Global Settings',
    component: () => <GlobalSettingsView />
  },
  {
    path: '/settings/app-versions',
    exact: true,
    title: 'App Versions Management',
    component: () => <AppVersionsManagement />
  },
  {
    path: '/settings/app-versions/create',
    exact: true,
    title: 'Create App Version',
    component: () => <AppVersionCreate />
  },
  {
    path: '/settings/app-versions/:id',
    exact: true,
    title: 'View App Version',
    component: () => <AppVersionView />
  },
  {
    path: '/settings/permissions',
    exact: true,
    title: 'Roles',
    component: () => <RolesManagement />
  },
  {
    path: '/logs/activity',
    exact: true,
    title: 'Activity Logs',
    component: () => <ActivityLogs />
  },
  {
    path: '/logs/system',
    exact: true,
    title: 'System Logs',
    component: () => <SystemLogs />
  },
  {
    path: '/logs/notification',
    exact: true,
    title: 'Notification Logs',
    component: () => <NotificationLogs />
  },
  {
    path: '*',
    exact: true,
    title: 'Not Found',
    component: () => <Error404 />
  }
];

export default routes;
