import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import PageContext from '../../../config/page-settings.js';
import * as API from '../../../services/api.js';
import {
  Table,
  RowActionItems,
  RowItemDelete,
  RowItemView
} from '../../../components/table/index.js';
import { Notification } from '../../../utils/index.js';

const PartnersList = (props) => {
  const history = useHistory();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [meta, setMeta] = useState({});
  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  useEffect(() => {
    fetchIndustyList();
  }, [props]);

  const fetchIndustyList = () => {
    API.getMerchants().then((res) => {
      setData(res.data.data);
      setMeta(res.data.meta);
    });
  };

  const columns = useMemo(
    () => [
      {
        header: 'Business Name',
        accessorKey: 'business_name',
        sortable: true
      },
      {
        header: 'Trade Name',
        accessorKey: 'trade_name',
        sortable: true
      },
      {
        header: 'Category(s)',
        accessorFn: (d) => {
          var categories = JSON.parse(d.categories);
          var cat = categories.map((ct) => ct.label);
          return cat.toString();
        },
        sortable: true
      },
      {
        header: 'Country(s)',
        accessorFn: (d) => {
          var countries = JSON.parse(d.countries);
          var cat = countries.map((ct) => ct.label);
          return cat.toString();
        },
        sortable: true
      },
      {
        header: 'Action Items',
        cell: ({ cell }) => {
          const { original } = cell.row;
          const onView = () => {
            history.push(`/merchants/partners/${original.id}`, original);
          };

          return (
            <RowActionItems>
              <RowItemView onClick={onView} />
            </RowActionItems>
          );
        }
      }
    ],
    []
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  const onCreate = () => {
    history.push('/merchants/partners/create');
  };

  return (
    <>
      <h1 className="page-header">
        Partners{' '}
        <button onClick={onCreate} className="btn btn-primary btn-icon btn-circle btn-md ml-2">
          <i className="fa fa-plus"></i>
        </button>
        <button className="btn btn-secondary btn-icon btn-circle btn-md ml-2">
          <i className="fa fa-download"></i>
        </button>
      </h1>
      <Panel>
        <PanelHeader>Merchant List</PanelHeader>
        <PanelBody>
          <Table
            columns={columns}
            data={data}
            meta={meta}
            updateRow={updateRow}
            skipPageReset={skipPageReset}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default memo(PartnersList);
