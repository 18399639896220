import React, {
  memo,
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
  lazy,
  Suspense
} from 'react';
import { withRouter, Link, useParams } from 'react-router-dom';
import PageContext from '../../../config/page-settings.js';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';

import * as API from '../../../services/api';
import moment from 'moment';
// import BanksList from '../issuers/components/Review/BanksList.js';
// import { PriceFormat } from '../utils/format';
// import FormInformationEdit from './components/FormInformationEdit.js';
import { Notification, PriceFormat } from '../../../utils';

import { Form, FormGroup, Row, Col, Input, FormFeedback, Label } from 'reactstrap';
import { Button } from '../../../components/common';

import CurrencyInput from 'react-currency-input-field';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isEmpty, isNull } from 'lodash';
import ReactSelect from 'react-select';
import LoadingSpinner from '../../../components/common/LoadingSpinner.js';
const PerkCodesList = lazy(() => import('./components/PerkCodesList.js'));
// const FormInformationEdit = lazy(() => import('./components/FormInformationEdit.js'));
// const FormEditSourceOfIncome = lazy(() => import('./components/FormEditSourceOfIncome.js'));
// const InvestmentList = lazy(() => import('./components/InvestmentList.js'));
// const AutoInvestList = lazy(() => import('./components/AutoInvestList.js'));
// const ActivityLogsList = lazy(() => import('../logs/activity/ActivityLogsList.js'));
// const InvestorTransactionList = lazy(() =>
//   import('../transactions/investors/InvestorTransactionList.js')
// );

// const ListSupportingDocuments = lazy(() => import('./components/ListSupportingDocuments.js'));
const Switchery = memo((props) => (
  <div className="switcher">
    <input
      {...props}
      checked={props.checked}
      disabled={props.disabled || false}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const OPTION_OTP_DESINATION = [
  { value: 1, label: 'SMS' },
  { value: 2, label: 'Email' }
];

const schemaChangePassword = yup.object().shape({
  password: yup
    .string()
    .required('New password is required')
    .min(8, 'New password must be at least 8 characters'),
  password_confirmation: yup
    .string('Confirm password is required')
    .required()
    .min(8, 'New password must be at least 8 characters')
});

const schemaRemarks = yup.object().shape({
  remarks: yup.string().nullable()
});

const PerksView = (props) => {
  const pageContext = useContext(PageContext);
  const { id } = useParams();

  const [perk, setPerk] = useState({});
  const [banks, setBanks] = useState([]);

  const [wallet, setWallet] = useState({});

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullHeight(true);
    pageContext.handleSetPageContentFullWidth(true);

    API.readPerk(id).then((res) => {
      setPerk(res.data.data);
    });
  }, []);

  const [tabs, setActiveTab] = useState({
    summary: true,
    transactions: false
  });

  const showTab = (e, tab) => {
    e.preventDefault();
    setActiveTab({
      summary: tab === 'summary' ? true : false,
      transactions: tab === 'transactions' ? true : false
    });
  };

  return (
    <div>
      <div className="profile">
        <div className="profile-header">
          <div className="profile-header-cover"></div>
          <div className="profile-header-content">
            <div className="profile-header-img">
              <img
                src={perk && perk?.business_logo && perk.business_logo?.view}
                alt=""
                style={{ objectFit: 'cover', width: '100%' }}
              />
            </div>
            <div className="profile-header-info">
              <h4 className="m-t-10 m-b-5">{perk.title}</h4>

              {perk && perk.status && perk.status.id == 3 && (
                <>
                  <ButtonApprove onConfirm={onApprove} />
                  {/* <ButtonReject onConfirm={onReject} /> */}
                </>
              )}
              {/* <a onClick={onViewContract} className="btn btn-xs btn-danger m-l-5">
                Preview Aggreement
              </a> */}

              <Link to={`/merchants/perks/${perk.id}/update`} className="btn btn-xs btn-blue m-l-5">
                <i className="fa fa-edit mr-1"></i>Edit
              </Link>
            </div>
          </div>
          <ul className="profile-header-tab nav nav-tabs">
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'summary')}
                className={'nav-link ' + (tabs.summary ? 'active ' : '')}
              >
                SUMMARY
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'transactions')}
                className={'nav-link ' + (tabs.transactions ? 'active ' : '')}
              >
                CODES
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="profile-content">
        <div className="tab-content p-0">
          <div className={'tab-pane fade ' + (tabs.summary ? 'show active ' : '')}>
            <div className="table-responsive form-inline">
              <table className="table table-profile">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <h4>{perk && perk.title}</h4>
                      <p style={{ width: '40%' }}>{perk.subtext}</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="field">Country(s):</td>
                    <td>
                      {perk &&
                        perk.countries &&
                        JSON.parse(perk?.countries).map((country) => (
                          <span>
                            {country.label}
                            <br />
                          </span>
                        ))}
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Category(s):</td>
                    <td>
                      {perk &&
                        perk.categories &&
                        JSON.parse(perk?.categories).map((category) => (
                          <span>
                            {category.label}
                            <br />
                          </span>
                        ))}
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Value</td>
                    <td>{perk && perk.value}</td>
                  </tr>
                  <tr>
                    <td className="field">Value Type</td>
                    <td>{perk && perk.value_type}</td>
                  </tr>
                  <tr>
                    <td className="field">Count</td>
                    <td>{perk && perk.count}</td>
                  </tr>
                  <tr>
                    <td className="field">Start Date:</td>
                    <td>
                      {perk && moment(perk.start_date).utc(true).format('YYYY-MM-DD hh:mm A')}
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Valid Until:</td>
                    <td>{perk && moment(perk.end_date).utc(true).format('YYYY-MM-DD hh:mm A')}</td>
                  </tr>
                  <tr className="">
                    <td colSpan="2">
                      <hr />
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Terms & Conditions:</td>
                    <td>
                      {perk &&
                        perk.terms &&
                        perk?.terms.map((term, index) => (
                          <span>
                            {index + 1}. {term.term}
                            <br />
                          </span>
                        ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className={'tab-pane fade ' + (tabs.transactions ? 'show active ' : '')}>
            <Suspense fallback={<LoadingSpinner />}>
              <PerkCodesList />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PerksView);
