import React, { memo, useState, useMemo, useEffect, useContext } from 'react';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Form,
  FormGroup,
  FormFeedback,
  FormText,
  Row,
  Col,
  Label,
  Input,
  Tooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown,
  ButtonDropdown,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import CurrencyInput from 'react-currency-input-field';

import { isEmpty, isUndefined, has } from 'lodash';
import ReactSelect from 'react-select';
import PageContext from '../../../config/page-settings.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as API from '../../../services/api';
import moment from 'moment';
import FileUpload from '../../../components/forms/FileUpload.js';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import PageHeader from '../../../components/common/PageHeader.js';
import { Notification, PriceFormat } from '../../../utils/index.js';

import DateTime from 'react-datetime';

import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';

import AsyncSelect from 'react-select/async';

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      checked={props.checked}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
      disabled={props.disabled || false}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const TooltipItem = (props) => {
  const { placement, id, content } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <i className="fa fa-exclamation-circle text-info ml-1" id={'Tooltip-' + id} />
      <Tooltip placement={placement} isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
        {content}
      </Tooltip>
    </span>
  );
};

const schemaPerk = yup.object().shape({
  merchant: yup.object(),
  title: yup.string().required('Title is required'),
  subtext: yup.string().required('Subtext is required'),
  countries: yup.object(),
  start_date: yup
    .date()
    .required('Start Date is required')
    .min(moment(), 'Date cannot be in the past'),
  banner: yup
    .mixed()
    .required('Banner is required')
    .test('fileSize', 'The file is too large', (value) => {
      return value && value[0].size <= 2000000;
    })
    .test('type', 'Only the following formats are accepted:.png .jpeg, .jpg,', (value) => {
      return (
        value &&
        (value[0].type === 'image/jpeg' ||
          value[0].type === 'image/jpg' ||
          value[0].type === 'image/png')
      );
    })
});

const PerksCreate = () => {
  const { user_id, loan_request_id } = useParams();
  const history = useHistory();

  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);
  const defaultValues = {
    terms: [{}]
  };

  const {
    watch,
    formState: { errors },
    getValues,
    setError,
    register,
    setValue,
    handleSubmit,
    control,
    reset
  } = useForm({
    defaultValues
    // defaultValues: useMemo(() => {
    //   return {
    //     funding_id: `${moment().format('YMD')}${Math.floor(1000 + Math.random() * 9999)}`,
    //     has_guarantor: false,
    //     has_pledge: false,
    //     has_visitation: false,
    //     subscription_days: 30,
    //     repayment_mode: 1,
    //     title: '',
    //     description: '',
    //     loan_display_investors: 1,
    //     minimum_lend_amount: 1000,
    //     auto_invest_enabled: true,
    //     allow_priority_investor: false
    //   };
    // }, []),
    // resolver: yupResolver(schemaPerk)
  });

  const appendForm = () => {
    setValue('terms', [...(getValues().terms || []), {}]);
  };

  const distributionType = watch('distribution_type', null);
  //   const watchLoan = watch('loan', { id: null });
  //   const watchReturns = watch('returns');
  //   const watchSubDays = watch('subscription_days');

  //   const [optionIssuers, setOptionIssuers] = useState([]);

  //   const [loanScheduleList, setLoanScheduleList] = useState([]);
  //   const [loanScheduleTotal, setLoanScheduleTotal] = useState({});

  const [optionCategories, setOptionCategories] = useState([]);
  const [optionCountries, setOptionCountries] = useState([]);

  const [optionPromoType, setOptionPromoType] = useState([]);
  const [optionDistributionType, setOptionDistributionType] = useState([]);

  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    API.dataSets(['merchant_category', 'distribution_type', 'countries']).then((res) => {
      console.log(res);
      setOptionCategories(res.data.merchant_category);
      setOptionCountries(res.data.countries);
      setOptionDistributionType([
        { value: 0, label: 'Default - Membership Perk' },
        { value: 1, label: 'Pre-Generated Unique Codes' },
        { value: 2, label: 'Single Code' }
      ]);
      setOptionPromoType([
        { value: 0, label: 'Free' },
        { value: 1, label: 'Coupon' },
        { value: 2, label: 'Voucher' }
      ]);
    });

    API.getMerchants().then((res) => {
      const merchantList = res.data.data.map((merchant) => ({
        value: merchant.id,
        label: `${merchant.business_name} - ${merchant.trade_name}`
      }));
      setMerchants(merchantList);
    });
  }, []);

  const filterCompany = (inputValue) => {
    return optionIssuers.filter((i) =>
      i.company_name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);

  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

  const toggleSplit = () => setSplitButtonOpen(!splitButtonOpen);

  const promiseOptions = (inputValue) => {
    new Promise((resolve) => {
      const params = getRequestParams(inputValue);
      setTimeout(() => {
        API.searchIssuer(params).then((res) => {
          console.log(res.data.data);
          var optionIssuers = res.data.data.map((issuer) => ({
            value: issuer.id,
            label: issuer && issuer.company_name
          }));

          console.log('optionIssuers', optionIssuers);

          resolve(optionIssuers);
        });
      }, 1000);
    });
  };

  const getRequestParams = (keyword) => {
    let params = {};

    if (keyword) {
      params['filter[company_name]'] = keyword;
    }

    return params;
  };

  // const fetchData = () => {
  //   const params = getRequestParams(watchIssuer.value);
  //   API.searchIssuer(params)
  //     .then((response) => {
  //       const { data, meta } = response.data;
  //       console.log(data);
  //       // setData(data);
  //       // setMeta(meta);
  //       // setIsLoading(false);
  //     })
  //     .catch((e) => {
  //       // setData([]);
  //       // setMeta({});
  //       // setIsLoading(false);
  //     });
  // };
  // useEffect(fetchData, [watchIssuer]);

  //   useEffect(() => {
  //     setValue(
  //       'issuer',
  //       optionIssuers.find((issuer) => issuer.id == user_id)
  //     );
  //   }, [user_id, optionIssuers]);

  //   const watchPublishDate = watch('publish_date', moment().format('YYYY-MM-DD'));

  //   const watchRepaymentMode = watch('repayment_mode');

  //   const [optionIssuerLoans, setOptionIssuerLoans] = useState([]);

  //   useEffect(() => {
  //     if (!isEmpty(watchIssuer)) {
  //       API.getIssuerLoans(watchIssuer.id).then((res) => {
  //         var optionIssuerLoans = res.data.data.map((loan) => ({
  //           ...loan,
  //           value: loan.id,
  //           label: `LOAN ID: ${loan.id} - ${loan.purpose} (${
  //             loan && loan.status && loan.status.name
  //           })`,
  //           disabled: loan && loan.status && loan.status.id != 6
  //         }));
  //         setOptionIssuerLoans(optionIssuerLoans);
  //       });
  //     }
  //   }, [watchIssuer]);

  //   useEffect(() => {
  //     setValue('loan_tenor', watchLoan && watchLoan.loan_tenor);
  //   }, [watchLoan]);

  //   useEffect(() => {
  //     setValue(
  //       'repayment_start_date',
  //       watchPublishDate &&
  //         moment(watchPublishDate, 'YYYY-MM-DD')
  //           .add(Number(getValues('subscription_days')) - 1, 'days')
  //           .format('YYYY-MM-DD')
  //     ) || moment().add(30, 'days').format('YYYY-MM-DD');
  //   }, [watchPublishDate]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const optionValueType = [
    { value: 1, label: 'Percantage' },
    { value: 2, label: 'Fixed Amount' },
    { value: 3, label: 'Points' },
    { value: 4, label: 'Free' }
  ];

  const onSubmitForm = async (data) => {
    setLoadingSubmit(true);
    const isFile = (input) => 'File' in window && input instanceof File;

    const params = new FormData();

    params.append('merchant_id', data.merchant.value);

    if (data.banner && isFile(data.banner[0])) {
      let res = await uploadFiles([data.banner[0]], 'Banners');
      params.append('banner', res[0].id);
    }

    params.append('title', data.title);
    params.append('subtext', data.subtext);
    params.append('overview', data.overview);
    params.append('countries', JSON.stringify(data.countries));
    params.append('categories', JSON.stringify(data.categories));

    params.append('promo_type', data.promo_type.value);
    params.append('value', data.value);
    params.append('value_type', data.value_type.value);
    params.append('start_date', data.start_date);
    params.append('end_date', data.end_date);
    params.append('distribution_type', data.distribution_type.value);
    params.append('count', data.count || 0);
    params.append('campaign_code', data.campaign_code);

    params.append('terms', JSON.stringify(data.terms));

    if (data.agreement && isFile(data.agreement[0])) {
      let res = await uploadFiles([data.agreement[0]], 'Attachments');
      params.append('agreement', res[0].id);
    }

    if (data.codes_attachment && isFile(data.codes_attachment[0])) {
      let res = await uploadFiles([data.codes_attachment[0]], 'PerkCodes');
      params.append('codes_attachment', res[0].id);
    }

    params.append('is_ref_required', data.is_ref_required ? 1 : 0);

    params.append('is_active', data.is_active ? 1 : 0);

    API.createPerk(params)
      .then((res) => {
        Notification.success('Perk Create', 'Perk successfully created!');
        setLoadingSubmit(false);
        history.goBack();
      })
      .catch((err) => {
        setLoadingSubmit(false);
        if (err.response.status === 422) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            setError(key, {
              type: 'manual',
              message: value[0]
            });
          }
        } else {
          Notification.error(err);
        }
      });
  };

  const uploadFiles = async (files, storage_path) => {
    console.log('storage', storage_path);
    const promises = await files.map(async (file, index) => {
      if (!has(file, 'status')) {
        console.log('storage inside await', storage_path);

        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('dir', storage_path);
        return await API.fileUpload(formData)
          .then((res) => {
            files[index] = { label: file.label, ...res.data.data };
            return { label: file.label, status: 1, ...res.data.data };
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        return file;
      }
    });

    const responses = await Promise.all(promises);
    return responses;
  };

  return (
    <>
      <PageHeader title="Create Perks" />
      <Panel>
        <PanelHeader></PanelHeader>
        <PanelBody>
          <Row>
            <Col sm="12" md="12" lg={{ size: 10, offset: 1 }}>
              <Form onSubmit={handleSubmit(onSubmitForm)}>
                <FormGroup tag="fieldset">
                  <h3>Perk Details</h3>

                  <FormGroup row>
                    <Label md="3">Merchant</Label>
                    <Col md="9">
                      <Controller
                        name="merchant"
                        control={control}
                        defaultValue={[]}
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            inputRef={ref}
                            value={value}
                            onChange={onChange}
                            options={merchants}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Banner</Label>
                    <Col md="9">
                      <Controller
                        name="banner"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <FileUpload
                            onDrop={onChange}
                            onChange={onChange}
                            imagefile={value}
                            invalid={!isEmpty(errors.banner)}
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.banner ? 'block' : 'none' }}>
                        {errors.banner?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Title</Label>
                    <Col md="9">
                      <Controller
                        name="title"
                        control={control}
                        render={({ field }) => (
                          <Input {...field} type="text" invalid={!isEmpty(errors.title)} />
                        )}
                      />
                      <FormFeedback style={{ display: errors.title ? 'block' : 'none' }}>
                        {errors.title?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Subtext</Label>
                    <Col md="9">
                      <Controller
                        name="subtext"
                        control={control}
                        render={({ field }) => (
                          <Input {...field} invalid={!isEmpty(errors.subtext)} />
                        )}
                      />
                      <FormFeedback style={{ display: errors.subtext ? 'block' : 'none' }}>
                        {errors.subtext?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Overview</Label>
                    <Col md="9">
                      <Controller
                        name="overview"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="textarea"
                            rows="5"
                            invalid={!isEmpty(errors.overview)}
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.overview ? 'block' : 'none' }}>
                        {errors.overview?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Applicable Country(s)</Label>
                    <Col md="9">
                      <Controller
                        name="countries"
                        control={control}
                        defaultValue={[]}
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            inputRef={ref}
                            isMulti
                            value={value}
                            onChange={onChange}
                            options={optionCountries}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Categories</Label>
                    <Col md="9">
                      <Controller
                        name="categories"
                        control={control}
                        defaultValue={[]}
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            inputRef={ref}
                            isMulti
                            value={value}
                            onChange={onChange}
                            options={optionCategories}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <hr />
                  <h3>Perk Settings</h3>

                  <FormGroup row>
                    <Label md="3">Promo Type</Label>
                    <Col md="9">
                      <Controller
                        name="promo_type"
                        control={control}
                        defaultValue={[]}
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            inputRef={ref}
                            value={value}
                            onChange={onChange}
                            options={optionPromoType}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Value</Label>
                    <Col md="5">
                      <Controller
                        name="value"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <Input placeholder="Enter Value" onChange={onChange} value={value} />
                        )}
                      />
                    </Col>
                    <Col md="4">
                      <Controller
                        name="value_type"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            inputRef={ref}
                            value={value}
                            onChange={onChange}
                            options={optionValueType}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  {/* <Row>
                    <Label md="3">Required Min/Max</Label>
                    <Col style={{ paddingLeft: 5, paddingRight: '0' }}>
                      <FormGroup row>
                        <Col>
                          <Controller
                            name="subscription_days"
                            control={control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                placeholder="Min"
                                className="number-only-field"
                                type="number"
                                invalid={!isEmpty(errors.subscription_days)}
                              />
                            )}
                          />
                          <FormFeedback
                            style={{ display: errors.subscription_days ? 'block' : 'none' }}
                          >
                            {errors.subscription_days?.message}
                          </FormFeedback>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col style={{ paddingLeft: '0', paddingRight: 5 }}>
                      <FormGroup row>
                        <Col>
                          <Controller
                            name="subscription_days"
                            control={control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                placeholder="Max"
                                className="number-only-field"
                                type="number"
                                invalid={!isEmpty(errors.subscription_days)}
                              />
                            )}
                          />
                          <FormFeedback
                            style={{ display: errors.subscription_days ? 'block' : 'none' }}
                          >
                            {errors.subscription_days?.message}
                          </FormFeedback>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row> */}

                  <FormGroup row>
                    <Label md="3">Start Date</Label>
                    <Col md="9">
                      <Controller
                        name="start_date"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <DateTime
                            inputProps={{ placeholder: 'Select Date and Time' }}
                            closeOnSelect={true}
                            timeFormat="HH:mm"
                            value={value}
                            dateFormat="YYYY-MM-DD"
                            onChange={(e) => {
                              onChange(moment(e).format('YYYY-MM-DD HH:mm'));
                            }}
                            timeConstraints={{
                              minutes: { step: 5 }
                            }}
                            isValidDate={(currentDate) =>
                              currentDate > moment().subtract(1, 'days')
                            }
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Valid Until</Label>
                    <Col md="9">
                      <Controller
                        name="end_date"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <DateTime
                            inputProps={{ placeholder: 'Select Date and Time' }}
                            closeOnSelect={true}
                            timeFormat="HH:mm"
                            value={value}
                            dateFormat="YYYY-MM-DD"
                            onChange={(e) => {
                              onChange(moment(e).format('YYYY-MM-DD HH:mm'));
                            }}
                            timeConstraints={{
                              minutes: { step: 5 }
                            }}
                            isValidDate={(currentDate) =>
                              currentDate > moment().subtract(1, 'days')
                            }
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Distribution Type</Label>
                    <Col md="9">
                      <Controller
                        name="distribution_type"
                        control={control}
                        defaultValue={[]}
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            inputRef={ref}
                            value={value}
                            onChange={onChange}
                            options={optionDistributionType}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  {distributionType && distributionType.value == 0 && (
                    <FormGroup row>
                      <Label md="3">Is Reference Required</Label>
                      <Col md="9">
                        <Controller
                          name="is_ref_required"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <Switchery checked={value} onChange={onChange} for="is_ref_required" />
                          )}
                        />
                        <FormText>
                          Switch on if merchant will required a reference code for transaction
                          record purposes.
                        </FormText>
                      </Col>
                    </FormGroup>
                  )}

                  {distributionType && distributionType.value == 1 && (
                    <>
                      <FormGroup row>
                        <Label md="3">Count</Label>
                        <Col md="9">
                          <Controller
                            name="count"
                            control={control}
                            render={({ field: { onChange, value, ref } }) => (
                              <Input
                                placeholder="Enter Value"
                                onChange={onChange}
                                value={value}
                                type="number"
                              />
                            )}
                          />
                          <FormText>Put 0 as value if unlimited</FormText>
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label md="3">Upload Promo Codes</Label>
                        <Col md="9">
                          <Controller
                            name="codes_attachment"
                            control={control}
                            render={({ field: { onChange, value, ref } }) => (
                              <FileUpload
                                onDrop={onChange}
                                onChange={onChange}
                                imagefile={value}
                                invalid={!isEmpty(errors.codes_attachment)}
                              />
                            )}
                          />
                          <FormText>
                            Accepting (.csv) file only.{' '}
                            <a
                              className="text-primary"
                              download="Template-CSV-Code"
                              target="_blank"
                              rel="noreferrer"
                              href="/assets/documents/unique_codes_template.csv"
                            >
                              Download Template
                            </a>{' '}
                            for reference
                          </FormText>
                          <FormFeedback
                            style={{ display: errors.codes_attachment ? 'block' : 'none' }}
                          >
                            {errors.codes_attachment?.message}
                          </FormFeedback>
                        </Col>
                      </FormGroup>
                    </>
                  )}

                  {distributionType && distributionType.value == 2 && (
                    <>
                      <FormGroup row>
                        <Label md="3">Count</Label>
                        <Col md="9">
                          <Controller
                            name="count"
                            control={control}
                            render={({ field: { onChange, value, ref } }) => (
                              <Input
                                placeholder="Enter Value"
                                onChange={onChange}
                                value={value}
                                type="number"
                              />
                            )}
                          />
                          <FormText>Put 0 as value if unlimited</FormText>
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label md="3">Code</Label>
                        <Col md="9">
                          <Controller
                            name="campaign_code"
                            control={control}
                            render={({ field }) => (
                              <Input {...field} type="text" invalid={!isEmpty(errors.title)} />
                            )}
                          />
                          <FormFeedback style={{ display: errors.title ? 'block' : 'none' }}>
                            {errors.title?.message}
                          </FormFeedback>
                        </Col>
                      </FormGroup>
                    </>
                  )}

                  {/* <FormGroup row>
                    <Label md="3">Campaign Code</Label>
                    <Col md="9">
                      <Controller
                        name="campaign_code"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <Input placeholder="Campaign Code" onChange={onChange} value={value} />
                        )}
                      />
                    </Col>
                  </FormGroup> */}

                  <hr />
                  <FormGroup tag="fieldset">
                    <h3>Terms and Conditions</h3>
                    <FieldArray
                      {...{
                        control,
                        register,
                        defaultValues,
                        getValues,
                        setValue,
                        errors
                      }}
                    />
                    <Row>
                      <Col>
                        <button
                          className="btn btn-primary float-right"
                          type="button"
                          onClick={appendForm}
                        >
                          <i className="fa fa-plus" style={{ marginRight: 10 }} />
                          Add Terms & Conditions
                        </button>
                      </Col>
                    </Row>
                    <hr />
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Merchant Agreement</Label>
                    <Col md="9">
                      <Controller
                        name="agreement"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <FileUpload
                            onDrop={onChange}
                            onChange={onChange}
                            imagefile={value}
                            invalid={!isEmpty(errors.agreement)}
                          />
                        )}
                      />
                      <FormFeedback style={{ display: errors.agreement ? 'block' : 'none' }}>
                        {errors.agreement?.message}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3">Is Active</Label>
                    <Col md="9">
                      <Controller
                        name="is_active"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Switchery checked={value} onChange={onChange} for="is_active" />
                        )}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label md="3" />
                    <Col md="9">
                      <button
                        type="submit"
                        className={`btn ${loadingSubmit ? 'btn-secondary' : 'btn-primary'}`}
                        disabled={loadingSubmit}
                      >
                        {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
                      </button>
                    </Col>
                  </FormGroup>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </PanelBody>
      </Panel>
    </>
  );
};

export default PerksCreate;

const ButtonApprove = ({ onSubmit, disabled, isLoading, loan, project }) => {
  const [modal, setModal] = useState(false);

  const toggleModal = async () => {
    await setModal(!modal);
  };

  const onConfirm = () => {
    toggleModal().then(() => {
      onSubmit();
    });
  };

  return (
    <>
      <button
        className={`btn mr-2 text-white ${disabled || isLoading ? 'btn-gray' : 'btn-success'}`}
        type="button"
        disabled={disabled}
        onClick={toggleModal}
      >
        {isLoading ? <i className="fas fa-spinner fa-spin"></i> : 'Start Project'}
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Start Project?</span>}
        success
        showCancel
        confirmBtnText="Start"
        confirmBtnBsStyle="success"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onConfirm}
        onCancel={toggleModal}
        show={modal}
      >
        <p>Are you sure you want to start this project?</p>
      </SweetAlert>
    </>
  );
};

const FieldArray = ({ control, register, errors, setValue, getValues, optionNationalities }) => {
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'terms'
  });

  return (
    <>
      {fields.map((item, index) => {
        return (
          <FormGroup tag="fieldset">
            <FormGroup row>
              <Label md="1">
                <h5>{index + 1}</h5>
              </Label>
              <Col md="10">
                <Controller
                  name={`terms.${index}.term`}
                  control={control}
                  render={({ field }) => (
                    <Input {...field} type="text" invalid={!isEmpty(errors.terms)} />
                  )}
                />
                {errors.nationality && <FormFeedback>{errors.nationality?.message}</FormFeedback>}
              </Col>
              <Col md="1">
                <Button color="danger">Remove</Button>
              </Col>
            </FormGroup>
          </FormGroup>
        );
      })}
    </>
  );
};
