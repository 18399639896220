import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PageContext from '../../../../config/page-settings.js';
import PaymentTypesList from './PaymentTypesList';

const PaymentTypes = (props) => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const create = () => {
    props.history.push('/settings/payment/types/create');
  };

  return (
    <div>
      <h1 className="page-header">
        Payment Types
      </h1>
      <PaymentTypesList />
    </div>
  );
};

export default withRouter(PaymentTypes);
