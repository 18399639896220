import React, { memo, useEffect, useState } from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import Chart from 'react-apexcharts';

import { useAppState } from '../../../overmind';
const TotalRequest = (props) => {
  const { badges } = useAppState();

  const [count, setCount] = useState({});

  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (badges) {
      var request = _.fromPairs(_.sortBy(_.toPairs(badges), 1).reverse());

      setCount(request);

      const values = Object.values(badges);

      const sum = values.reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);

      setTotal(sum);
    }
  }, [badges]);
  const conversionRateChart = {
    options: {
      chart: {
        type: 'line',
        width: 160,
        height: 28,
        sparkline: {
          enabled: true
        }
      },
      stroke: {
        curve: 'smooth',
        width: 3
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [
            {
              offset: 0,
              color: '#ff5b57',
              opacity: 1
            },
            {
              offset: 50,
              color: '#f59c1a',
              opacity: 1
            },
            {
              offset: 100,
              color: '#90ca4b',
              opacity: 1
            }
          ]
        }
      },
      labels: ['Jun 23', 'Jun 24', 'Jun 25', 'Jun 26', 'Jun 27', 'Jun 28', 'Jun 29'],
      xaxis: {
        crosshairs: {
          width: 1
        }
      },
      tooltip: {
        theme: 'dark',
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return '';
            }
          },
          formatter: (value) => {
            return value + '%';
          }
        },
        marker: {
          show: false
        }
      },
      responsive: [
        {
          breakpoint: 1500,
          options: {
            chart: {
              width: 120
            }
          }
        },
        {
          breakpoint: 1300,
          options: {
            chart: {
              width: 100
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 160
            }
          }
        },
        {
          breakpoint: 900,
          options: {
            chart: {
              width: 120
            }
          }
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              width: 180
            }
          }
        },
        {
          breakpoint: 400,
          options: {
            chart: {
              width: 120
            }
          }
        }
      ]
    },
    series: [
      {
        data: [2.68, 2.93, 2.04, 1.61, 1.88, 1.62, 2.8]
      }
    ]
  };

  const getStringValue = (key) => {
    switch (key) {
      case 'loans':
        return 'Loan Request';
      case 'banks':
        return 'Banks';
      case 'investors':
        return 'Investors';
      case 'issuers':
        return 'Issuers';
      case 'top-ups':
        return 'Top Ups';
      case 'withdrawals':
        return 'Withdrawals';
    }
  };

  const getColorByIndex = (index) => {
    switch (index) {
      case 2:
        return 'text-yellow';
      case 1:
        return 'text-warning';
      default:
        return 'text-red';
    }
  };

  return (
    <div className="card border-0 text-white bg-blue-dark mb-3 overflow-hidden">
      <div className="card-body">
        <div className="mb-3 text-grey">
          <b className="mb-3">TOTAL REQUEST</b>
          <span className="ml-2">
            <i className="fa fa-info-circle" id="popover2"></i>
            <UncontrolledPopover trigger="hover" placement="top" target="popover2">
              <PopoverHeader>Order by counts</PopoverHeader>
              <PopoverBody>
                Color coded and descending order of requests counts based on volumes. The top most
                must be address with urgency.
              </PopoverBody>
            </UncontrolledPopover>
          </span>
        </div>
        <div className="d-flex align-items-center mb-1">
          <h2 className="text-white mb-2">{total}</h2>
          {/* <div className="ml-auto">
            <Chart
              type="line"
              height={'28px'}
              options={conversionRateChart.options}
              series={conversionRateChart.series}
            />
          </div> */}
        </div>
        {/* <div className="mb-4 text-grey">
          <i className="fa fa-caret-down"></i> 0.50% compare to last week
        </div> */}

        {badges &&
          Object.entries(badges)
            .slice(0, 3)
            .map(([key, val] = entry, index) => (
              <div key={key} className={`d-flex ${index > 1 ? '' : 'mb-2'}`}>
                <div className="d-flex align-items-center">
                  <i className={`fa fa-circle ${getColorByIndex(index)} f-s-8 mr-2`}></i>
                  {getStringValue(key)}
                </div>
                <div className="d-flex align-items-center ml-auto">
                  <div className="width-50 text-right pl-2 f-w-600">{val}</div>
                </div>
              </div>
            ))}
        {/* <div className="d-flex mb-2">
          <div className="d-flex align-items-center">
            <i className="fa fa-circle text-warning f-s-8 mr-2"></i>
            Project Loan
          </div>
          <div className="d-flex align-items-center ml-auto">
            <div className="text-grey f-s-11">
              <i className="fa fa-caret-up"></i> 11%
            </div>
            <div className="width-50 text-right pl-2 f-w-600">34</div>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <i className="fa fa-circle text-lime f-s-8 mr-2"></i>
            Withdrawals
          </div>
          <div className="d-flex align-items-center ml-auto">
            <div className="text-grey f-s-11">
              <i className="fa fa-caret-up"></i> 57%
            </div>
            <div className="width-50 text-right pl-2 f-w-600">8</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default memo(TotalRequest);
