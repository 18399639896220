import React, { memo, useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import * as API from '../../../services/api';
import {
  Form,
  FormGroup,
  FormText,
  FormFeedback,
  Row,
  Col,
  Label,
  Input,
  Tooltip
} from 'reactstrap';
import { isEmpty, isNull, isUndefined, has } from 'lodash';
import ReactSelect from 'react-select';
import FileUpload from '../../../components/forms/FileUpload';
import Slider from 'rc-slider';
import RCTooltip from 'rc-tooltip';
import { isFile, Notification, PriceFormat } from '../../../utils';
import CurrencyInput from 'react-currency-input-field';

import 'rc-slider/assets/index.css';
import moment from 'moment';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Handle = Slider.Handle;

const schemaIssuerInformation = yup.object().shape({
  amount: yup
    .number()
    .positive()
    .min(1, 'Amount must be greater than ₱0.00')
    .required('Amount is required'),
  loan_tenor: yup.number(),
  purpose: yup.string(),
  accounts_receivable: yup
    .mixed()
    .test('required', 'You need to provide a file', (value) => {
      return value && value.length;
    })
    .test('fileSize', 'The file is too large', (value, context) => {
      return value && value[0] && value[0].size <= 2000000;
    })
    .test('type', 'We only support jpeg', function (value) {
      return value && value[0] && value[0].type === 'image/jpeg';
    })
  // .test('required', 'Account receivable document is required', (value) => {
  //   return value && value.length;
  // })
  // .test('size', 'The file is too large', (value) => {
  //   return value && value[0] && value[0].size <= 2000000;
  // })
  // .test(
  //   'type',
  //   'Only the following formats are accepted: png, jpg, jpeg, csv, txt, xlx, xlsx, xls, pdf',
  //   (value) => {
  //     return (
  //       (value &&
  //         value[0] &&
  //         (value[0].type === 'image/jpeg' ||
  //           value[0].type === 'image/jpg' ||
  //           value[0].type === 'image/png')) ||
  //       value[0].type === 'application/pdf' ||
  //       value[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  //     );
  //   }
  // )
});

const FormRequestLoan = (props) => {
  const { issuer, loan } = props;

  const {
    watch,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    setError,
    control,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        amount: loan.amount || 0,
        loan_tenor: loan.loan_tenor,
        purpose: loan.purpose,
        use_of_proceeds: loan.use_of_proceeds,
        exempt_offering: loan.exempt_offering,
        indebtedness: loan.indebtedness,
        ownership_capital_structure: loan.ownership_capital_structure,
        terms_of_securities: loan.terms_of_securities,
        exercise_rights: loan.exercise_rights,
        securities_offered: loan.securities_offered,
        risk_purchase: loan.risk_purchase,
        restriction_description: loan.restriction_description,
        material_interest: loan.material_factor,
        material_factor: loan.material_factor,
        accounts_receivable: []
      };
    }, [loan]),
    resolver: yupResolver(schemaIssuerInformation)
  });

  const watchAmount = watch('amount', '');
  const watchTenure = watch('loan_tenor', 6);
  const watchTerms = watch('terms_of_securities', '');

  useEffect(() => {
    setValue('terms_of_securities', `${PriceFormat(watchAmount)} for ${watchTenure} months`);
  }, [watchAmount, watchTenure]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingDraftSubmit, setLoadingDraftSubmit] = useState(false);

  const [optionLoanPurposes, setOptionLoanPurposes] = useState([]);

  useEffect(() => {
    API.dataSets(['loan_purposes']).then((res) => {
      setOptionLoanPurposes(res.data.loan_purposes);
    });
  }, [issuer, loan]);

  useEffect(() => {
    reset({
      amount: loan.amount || 0,
      loan_tenor: loan.loan_tenor || 6,
      purpose: loan.purpose || '',
      use_of_proceeds: loan.use_of_proceeds || '',
      exempt_offering: loan.exempt_offering || '',
      indebtedness: loan.indebtedness || '',
      ownership_capital_structure: loan.ownership_capital_structure || '',
      terms_of_securities: loan.terms_of_securities || watchTerms,
      exercise_rights: loan.exercise_rights || '',
      securities_offered: loan.securities_offered || '',
      risk_purchase: loan.risk_purchase || '',
      restriction_description: loan.restriction_description || '',
      material_interest: loan.material_factor || '',
      material_factor: loan.material_factor || '',
      ...parseDocuments(loan.documents)
    });
  }, [loan]);

  const parseDocuments = (array = []) => {
    const documentObjects = {};

    var objectName = (doc_type) => {
      switch (doc_type) {
        case 1:
          return 'accounts_receivable';
        case 2:
          return 'bank_account';
        case 3:
          return 'outstanding_loan';
        case 4:
          return 'collateral_statement';
        case 5:
          return 'saln';
        case 6:
          return 'board_resolution';
      }
    };

    var object = array.reduce(
      (obj, item) => (
        (obj[objectName(item.document_type.id)] = isNull(item.document_file.view)
          ? []
          : [
              {
                ...item.document_file,
                view: item.document_file.view,
                file_name: `${objectName(item.document_type.id)}_${moment(item.created_at).format(
                  'MMDDYYYYhhss'
                )}`,
                id: item.id,
                status: 2
              }
            ]),
        obj
      ),
      {}
    );

    return object;
  };

  const handleSlider = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <RCTooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </RCTooltip>
    );
  };

  // const onSaveAsDraft = () => {
  //   setLoadingDraftSubmit(true);

  //   const formValues = getValues();

  //   const params = new FormData();
  //   for (let [key, value] of Object.entries({
  //     ...formValues,
  //     is_draft: 1,
  //     user_id: issuer.id,
  //     amount: Number(getValues('amount'))
  //   })) {
  //     if (!isUndefined(value) || !isEmpty(value)) {
  //       params.append(key, value);
  //     }
  //   }

  //   const isFile = (input) => 'File' in window && input instanceof File;
  //   if (formValues.accounts_receivable && isFile(formValues.accounts_receivable[0])) {
  //     params.append('accounts_receivable', formValues.accounts_receivable[0]);
  //   }

  //   if (formValues.bank_account && isFile(formValues.bank_account[0])) {
  //     params.append('bank_account', formValues.bank_account[0]);
  //   }

  //   if (formValues.collateral_statement && isFile(formValues.collateral_statement[0])) {
  //     params.append('collateral_statement', formValues.collateral_statement[0]);
  //   }

  //   if (formValues.outstanding_loan && isFile(formValues.outstanding_loan[0])) {
  //     params.append('outstanding_loan', formValues.outstanding_loan[0]);
  //   }

  //   if (formValues.saln && isFile(formValues.saln[0])) {
  //     params.append('saln', formValues.saln[0]);
  //   }

  //   if (
  //     issuer.entity_type > 1 &&
  //     formValues.board_resolution &&
  //     isFile(formValues.board_resolution[0])
  //   ) {
  //     params.append('board_resolution', formValues.board_resolution[0]);
  //   } else {
  //     params.append('board_resolution', '');
  //   }

  //   if (!isUndefined(loan.id)) {
  //     API.putLoanRequest(loan.id, params).then((res) => {
  //       Notification.info('Loan Request as Draft', 'Loan request is saved as incomplete');
  //       props.onSubmit();
  //     });
  //   } else {
  //     API.postLoanRequest(params)
  //       .then((response) => {
  //         Notification.info('Loan Request as Draft', 'Loan request is saved as incomplete');
  //         props.onSubmit();
  //       })
  //       .catch((error) => {
  //         Notification.error(error);
  //       })
  //       .finally(() => {
  //         setLoadingSubmit(false);
  //       });
  //   }
  // };

  const onSaveAsDraft = async () => {
    setLoadingDraftSubmit(true);
    const formValues = getValues();
    const params = !isUndefined(loan.id) ? new URLSearchParams() : new FormData();

    for (let [key, value] of Object.entries({
      is_draft: 1,
      user_id: issuer.id,
      loan_tenor: formValues.loan_tenor,
      amount: Number(formValues.amount),
      purpose: formValues.purpose,
      use_of_proceeds: formValues.use_of_proceeds,
      exempt_offering: formValues.exempt_offering,
      indebtedness: formValues.indebtedness,
      ownership_capital_structure: formValues.ownership_capital_structure,
      terms_of_securities: formValues.terms_of_securities,
      exercise_rights: formValues.exercise_rights,
      securities_offered: formValues.securities_offered,
      risk_purchase: formValues.risk_purchase,
      restriction_description: formValues.restriction_description,
      material_interest: formValues.material_interest,
      material_factor: formValues.material_factor
    })) {
      params.append(key, value);
    }

    const fileCondition = (file) => {
      return file && isFile(file[0]) && !has(file[0], 'id');
    };

    if (fileCondition(formValues.accounts_receivable)) {
      let res = await uploadFiles([formValues.accounts_receivable[0]]);
      params.append('accounts_receivable', res[0].id);
    } else {
      params.append(
        'accounts_receivable',
        !isEmpty(formValues.accounts_receivable) ? formValues.accounts_receivable[0].id : ''
      );
    }

    if (fileCondition(formValues.bank_account)) {
      let res = await uploadFiles([formValues.bank_account[0]]);
      params.append('bank_account', res[0].id);
    } else {
      params.append(
        'bank_account',
        !isEmpty(formValues.bank_account) ? formValues.bank_account[0].id : ''
      );
    }

    if (fileCondition(formValues.collateral_statement)) {
      let res = await uploadFiles([formValues.collateral_statement[0]]);
      params.append('collateral_statement', res[0].id);
    } else {
      params.append(
        'collateral_statement',
        !isEmpty(formValues.collateral_statement) ? formValues.collateral_statement[0].id : ''
      );
    }

    if (fileCondition(formValues.outstanding_loan)) {
      let res = await uploadFiles([formValues.outstanding_loan[0]]);
      params.append('outstanding_loan', res[0].id);
    } else {
      params.append(
        'outstanding_loan',
        !isEmpty(formValues.outstanding_loan) ? formValues.outstanding_loan[0].id : ''
      );
    }

    if (fileCondition(formValues.saln)) {
      let res = await uploadFiles([formValues.saln[0]]);
      params.append('saln', res[0].id);
    } else {
      params.append('saln', !isEmpty(formValues.saln) ? formValues.saln[0].id : '');
    }

    if (
      issuer &&
      issuer.company_details &&
      issuer.company_details.entity_type &&
      issuer.company_details.entity_type.id > 1
    ) {
      if (fileCondition(formValues.board_resolution)) {
        let res = await uploadFiles([formValues.board_resolution[0]]);
        params.append('board_resolution', res[0].id);
      } else {
        params.append(
          'board_resolution',
          !isEmpty(formValues.board_resolution) ? board_resolution[0].id : ''
        );
      }
    } else {
      params.append('board_resolution', '');
    }

    if (!isUndefined(loan.id)) {
      API.putLoanRequest(loan.id, params)
        .then((res) => {
          Notification.info('Loan Request as Draft', 'Loan request is saved as incomplete');
          props.onSubmit();
          setLoadingDraftSubmit(false);
        })
        .catch((err) => {
          Notification.error(err);
          setLoadingDraftSubmit(false);
        });
    } else {
      API.postLoanRequest(params)
        .then((response) => {
          Notification.info('Loan Request as Draft', 'Loan request is saved as incomplete');
          props.onSubmit();
          setLoadingDraftSubmit(false);
        })
        .catch((err) => {
          setLoadingDraftSubmit(false);
          if (err.response.status === 422) {
            for (const [key, value] of Object.entries(err.response.data.errors)) {
              setError(key, {
                type: 'manual',
                message: value
              });
            }
          } else {
            Notification.error(err);
          }
        });
    }
  };

  const onSubmitForm = async (data) => {
    setLoadingSubmit(true);

    const params = !isUndefined(loan.id) ? new URLSearchParams() : new FormData();

    for (let [key, value] of Object.entries({
      is_draft: 0,
      user_id: issuer.id,
      loan_tenor: data.loan_tenor,
      amount: Number(data.amount),
      purpose: data.purpose,
      use_of_proceeds: data.use_of_proceeds,
      exempt_offering: data.exempt_offering,
      indebtedness: data.indebtedness,
      ownership_capital_structure: data.ownership_capital_structure,
      terms_of_securities: data.terms_of_securities,
      exercise_rights: data.exercise_rights,
      securities_offered: data.securities_offered,
      risk_purchase: data.risk_purchase,
      restriction_description: data.restriction_description,
      material_interest: data.material_interest,
      material_factor: data.material_factor
    })) {
      params.append(key, value);
    }

    const fileCondition = (file) => {
      return file && isFile(file[0]) && !has(file[0], 'id');
    };

    if (fileCondition(data.accounts_receivable)) {
      let res = await uploadFiles([data.accounts_receivable[0]]);
      params.append('accounts_receivable', res[0].id);
    } else {
      params.append('accounts_receivable', data.accounts_receivable[0].id);
    }

    if (fileCondition(data.bank_account)) {
      let res = await uploadFiles([data.bank_account[0]]);
      params.append('bank_account', res[0].id);
    } else {
      params.append('bank_account', data.bank_account[0].id);
    }

    if (fileCondition(data.collateral_statement)) {
      let res = await uploadFiles([data.collateral_statement[0]]);
      params.append('collateral_statement', res[0].id);
    } else {
      params.append('collateral_statement', data.collateral_statement[0].id);
    }

    if (fileCondition(data.outstanding_loan)) {
      let res = await uploadFiles([data.outstanding_loan[0]]);
      params.append('outstanding_loan', res[0].id);
    } else {
      params.append('outstanding_loan', data.outstanding_loan[0].id);
    }

    if (fileCondition(data.saln)) {
      let res = await uploadFiles([data.saln[0]]);
      params.append('saln', res[0].id);
    } else {
      params.append('saln', data.saln[0].id);
    }

    if (
      issuer &&
      issuer.company_details &&
      issuer.company_details.entity_type &&
      issuer.company_details.entity_type.id > 1
    ) {
      if (fileCondition(data.board_resolution)) {
        let res = await uploadFiles([data.board_resolution[0]]);
        params.append('board_resolution', res[0].id);
      } else {
        params.append('board_resolution', board_resolution[0].id);
      }
    } else {
      params.append('board_resolution', '');
    }

    if (!isUndefined(loan.id)) {
      API.putLoanRequest(loan.id, params)
        .then((res) => {
          Notification.success('Loan Request Updated', 'Loan request successfully updated');
          props.onSubmit();
          setLoadingSubmit(false);
          setLoadingDraftSubmit(false);
        })
        .catch(() => {
          setLoadingSubmit(false);
          setLoadingDraftSubmit(false);
        });
    } else {
      API.postLoanRequest(params)
        .then((response) => {
          Notification.success('Loan Request Submit', 'Loan request successfully submitted');
          props.onSubmit();
          setLoadingSubmit(false);
          setLoadingDraftSubmit(false);
        })
        .catch((err) => {
          setLoadingSubmit(false);
          setLoadingDraftSubmit(false);
          if (err.response.status === 422) {
            for (const [key, value] of Object.entries(err.response.data.errors)) {
              setError(key, {
                type: 'manual',
                message: value
              });
            }
          } else {
            Notification.error(err);
          }
        });
    }
  };

  const uploadFiles = async (files) => {
    const promises = await files.map(async (file, index) => {
      if (!has(file, 'status')) {
        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('dir', 'Attachments');
        return await API.fileUpload(formData)
          .then((res) => {
            files[index] = { label: file.label, ...res.data.data };
            return { label: file.label, status: 1, ...res.data.data };
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        return file;
      }
    });

    const responses = await Promise.all(promises);
    return responses;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitForm)}>
      {loan && loan.status && loan.status.id > 1 && (
        <>
          <FormGroup tag="fieldset">
            <h3>Loan Status: {loan && loan.status && loan.status.name}</h3>
            {!isEmpty(loan.remarks) && <h6>{loan.remarks}</h6>}
          </FormGroup>
          <hr />
        </>
      )}
      <FormGroup
        tag="fieldset"
        disabled={
          loan &&
          ((loan && loan.status && loan.status.id == 4) ||
            (loan && loan.status && loan.status.id == 6) ||
            (loan && loan.status && loan.status.id == 9))
        }
      >
        <h3>Loan Request Details</h3>
        <FormGroup row>
          <Label md="3">Loan Amount</Label>
          <Col md="9">
            <Controller
              name="amount"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <CurrencyInput
                  value={value}
                  prefix="₱ "
                  placeholder="Loan Amount"
                  decimalsLimit={2}
                  onValueChange={(value, name) => onChange(value)}
                  className={`form-control ${errors.amount ? 'is-invalid' : ''}`}
                />
              )}
            />
            <FormFeedback style={{ display: errors.amount ? 'block' : 'none' }}>
              {errors.amount?.message}
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">Tenure</Label>
          <Col md="9">
            <Controller
              name="loan_tenor"
              control={control}
              render={({ field }) => (
                <Row>
                  <Col md="1">
                    <h4 className="mb-0">{field.value}</h4>
                  </Col>
                  <Col className="px-2 d-flex align-items-center">
                    <Slider
                      min={1}
                      max={12}
                      handle={handleSlider}
                      {...field}
                      defaultValue={6}
                      disabled={loan && (loan.status == 4 || loan.status == 6 || loan.status == 9)}
                    />
                  </Col>
                </Row>
              )}
            />
            <FormFeedback style={{ display: errors.loan_tenor ? 'block' : 'none' }}>
              {errors.loan_tenor?.message}
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Purpose of Loan
            <TooltipItem placement="top" id="item_21" content="SEC Form CF : Item 2" />
          </Label>
          <Col md="9">
            <Controller
              name="purpose"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  inputRef={ref}
                  isSearchable={false}
                  isClearable={false}
                  value={optionLoanPurposes.find((c) => c.value === Number(value))}
                  onChange={(val) => onChange(val.value)}
                  options={optionLoanPurposes}
                  isOptionDisabled={(option) => option.disabled}
                />
              )}
            />
            <FormFeedback style={{ display: errors.purpose ? 'block' : 'none' }}>
              {errors.purpose?.message}
            </FormFeedback>
            <FormText>Describe business plan with respect to CF offering</FormText>
          </Col>
        </FormGroup>
      </FormGroup>
      <hr />
      <FormGroup
        tag="fieldset"
        disabled={loan && (loan.status == 4 || loan.status == 6 || loan.status == 9)}
      >
        <h3>SEC Compliance</h3>
        <FormGroup row>
          <Label md="3">
            Use of Proceeds
            <TooltipItem placement="top" id="item_3" content="SEC Form CF : Item 3" />
          </Label>
          <Col md="9">
            <Controller
              name="use_of_proceeds"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.use_of_proceeds)}
                />
              )}
            />
            <FormFeedback style={{ display: errors.use_of_proceeds ? 'block' : 'none' }}>
              {errors.use_of_proceeds?.message}
            </FormFeedback>
            <FormText>
              Provide above the purpose and intended use of the offering proceeds.
            </FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Exempt Offering in the Past
            <TooltipItem placement="top" id="item_4" content="SEC Form CF : Item 6" />
          </Label>
          <Col md="9">
            <Controller
              name="exempt_offering"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.exempt_offering)}
                />
              )}
            />
            <FormFeedback style={{ display: errors.exempt_offering ? 'block' : 'none' }}>
              {errors.exempt_offering?.message}
            </FormFeedback>
            <FormText>Describe exempt offering conducted within the past three years.</FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Indebtedness of the Issuer
            <TooltipItem placement="top" id="item_5" content="SEC Form CF : Item 7.1" />
          </Label>
          <Col md="9">
            <Controller
              name="indebtedness"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.indebtedness)}
                />
              )}
            />
            <FormFeedback style={{ display: errors.indebtedness ? 'block' : 'none' }}>
              {errors.indebtedness?.message}
            </FormFeedback>
            <FormText>
              Describe the material terms of any indebtedness of the issuer, including amount,
              interest rate, maturity date and any other material terms.
            </FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Ownership and Capital Structure
            <TooltipItem placement="top" id="item_6" content="SEC Form CF : Item 1.2(a)" />
          </Label>
          <Col md="9">
            <Controller
              name="ownership_capital_structure"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  placeholder="Description of ownership and capital structure of issuer"
                  rows="5"
                  invalid={!isEmpty(errors.ownership_capital_structure)}
                />
              )}
            />
            <FormFeedback
              style={{ display: errors.ownership_capital_structure ? 'block' : 'none' }}
            >
              {errors.ownership_capital_structure?.message}
            </FormFeedback>
            {/* <FormText>
              Describe the material terms of any indebtedness of the issuer, including amount,
              interest rate, maturity date and any other material terms.
            </FormText> */}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Terms of the securities being offered
            <TooltipItem placement="top" id="item_7" content="SEC Form CF : Item 1.2(a-1)" />
          </Label>
          <Col md="9">
            <Controller
              name="terms_of_securities"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  disabled
                  invalid={!isEmpty(errors.terms_of_securities)}
                />
              )}
            />
            <FormFeedback style={{ display: errors.terms_of_securities ? 'block' : 'none' }}>
              {errors.terms_of_securities?.message}
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            How the exercise of rights held by the principal shareholders could affect the
            purchasers of the securities?
            <TooltipItem placement="top" id="item_8" content="SEC Form CF : Item 1.2(a-2)" />
          </Label>
          <Col md="9">
            <Controller
              name="exercise_rights"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.exercise_rights)}
                />
              )}
            />
            <FormFeedback style={{ display: errors.exercise_rights ? 'block' : 'none' }}>
              {errors.exercise_rights?.message}
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            How the securities being offered are being valued
            <TooltipItem placement="top" id="item_9" content="SEC Form CF : Item 1.2(a-3)" />
          </Label>
          <Col md="9">
            <Controller
              name="securities_offered"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.securities_offered)}
                />
              )}
            />
            <FormFeedback style={{ display: errors.securities_offered ? 'block' : 'none' }}>
              {errors.securities_offered?.message}
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Risk to purchasers of the securities relating to minority ownership in the issuer and
            the risks associated with corporate actions
            <TooltipItem placement="top" id="item_10" content="SEC Form CF : Item 1.2(a-4)" />
          </Label>
          <Col md="9">
            <Controller
              name="risk_purchase"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.risk_purchase)}
                />
              )}
            />
            <FormFeedback style={{ display: errors.risk_purchase ? 'block' : 'none' }}>
              {errors.risk_purchase?.message}
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Description of the restrictions on transfer of the securities;
            <TooltipItem placement="top" id="item_11" content="SEC Form CF : Item 1.2(a-5)" />
          </Label>
          <Col md="9">
            <Controller
              name="restriction_description"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.restriction_description)}
                />
              )}
            />
            <FormFeedback style={{ display: errors.restriction_description ? 'block' : 'none' }}>
              {errors.restriction_description?.message}
            </FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Direct or indirect material interest
            <TooltipItem placement="top" id="item_12" content="SEC Form CF : Item 1.3" />
          </Label>
          <Col md="9">
            <Controller
              name="material_interest"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  placeholder="Description of any transaction since the beginning of the issuer’s last fiscal year,
                  or any currently proposed transaction, to which the issuer was or is to be a party
                  and the amount involved exceeds five (5) percent of the aggregate amount of capital
                  raised by the issuer during the 12-month period, inclusive of the amount the issuer
                  seeks to raise in the current offering, in which any of the following persons had or
                  is to have a direct or indirect material interest (e.g. director or officer,
                  beneficial owners, promoter, member of the family)."
                  rows="5"
                  invalid={!isEmpty(errors.material_interest)}
                />
              )}
            />
            <FormFeedback style={{ display: errors.material_interest ? 'block' : 'none' }}>
              {errors.material_interest?.message}
            </FormFeedback>
            <FormText>
              (r) A description of any transaction since the beginning of the issuer’s last fiscal
              year, or any currently proposed transaction, to which the issuer was or is to be a
              party and the amount involved exceeds five (5) percent of the aggregate amount of
              capital raised by the issuer during the 12-month period, inclusive of the amount the
              issuer seeks to raise in the current offering, in which any of the following persons
              had or is to have a direct or indirect material interest: <br />
              <table>
                <tr>
                  <td style={{ paddingLeft: 10 }}>(1) Any director or officer of the issuer;</td>
                </tr>
                <tr>
                  <td style={{ paddingLeft: 10 }}>
                    (2) Any person who is, as of the most recent practicable date but no earlier
                    than 120 days prior to the date the offering statement or report is filed, the
                    beneficial owner of 10 percent or more of the issuer’s outstanding voting equity
                    securities, calculated on the basis of voting power;
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingLeft: 10 }}>
                    (3) If the issuer was incorporated or organized within the past three years, any
                    promoter of the issuer; or
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingLeft: 10 }}>
                    (4) Any member of the family of any of the foregoing persons, which includes a
                    child, stepchild, grandchild, parent, stepparent, grandparent, spouse or spousal
                    equivalent, sibling, mother-in-law, father-in-law, son- in-law, daughter-in-law,
                    brother-in-law, or sister-in-law, and shall include adoptive relationships. The
                    term spousal equivalent means a cohabitant occupying a relationship generally
                    equivalent to that of a spouse.
                  </td>
                </tr>
              </table>
            </FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Material factors making an investment in the issuer risky or speculative
            <TooltipItem placement="top" id="item_13" content="SEC Form CF : Item 1.4" />
          </Label>
          <Col md="9">
            <Controller
              name="material_factor"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  placeholder="Discuss below the material factors that make an investment in the issuer speculative or risky."
                  rows="5"
                  invalid={!isEmpty(errors.material_factor)}
                />
              )}
            />
            <FormFeedback style={{ display: errors.material_factor ? 'block' : 'none' }}>
              {errors.material_factor?.message}
            </FormFeedback>
          </Col>
        </FormGroup>
      </FormGroup>
      <hr />
      <FormGroup
        tag="fieldset"
        disabled={loan && (loan.status == 4 || loan.status == 6 || loan.status == 9)}
      >
        <h3>Document Requirements</h3>
        <FormGroup row>
          <Label md="3">Latest Accounts Receivable Ageing Report</Label>
          <Col md="9">
            <Controller
              name="accounts_receivable"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <FileUpload
                    inputRef={ref}
                    onDrop={onChange}
                    onChange={onChange}
                    imagefile={value}
                    invalid={!isEmpty(errors.accounts_receivable)}
                  />
                );
              }}
            />
            {errors.accounts_receivable && (
              <FormFeedback style={{ display: 'block' }}>
                {errors.accounts_receivable?.message}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">Latest 6 months statement of account for all corporate bank account</Label>
          <Col md="9">
            <Controller
              name="bank_account"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <FileUpload
                    inputRef={ref}
                    onDrop={onChange}
                    onChange={onChange}
                    imagefile={value}
                    invalid={!isEmpty(errors.bank_account)}
                  />
                );
              }}
            />
            <FormText>
              (Online bank statements or passbooks "must include account name and number")
            </FormText>
            {errors.bank_account && (
              <FormFeedback style={{ display: 'block' }}>
                {errors.bank_account?.message}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">List of Outstanding Loans</Label>
          <Col md="9">
            <Controller
              name="outstanding_loan"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <FileUpload
                    inputRef={ref}
                    onDrop={onChange}
                    onChange={onChange}
                    imagefile={value}
                    invalid={!isEmpty(errors.outstanding_loan)}
                  />
                );
              }}
            />
            {errors.outstanding_loan && (
              <FormFeedback style={{ display: 'block' }}>
                {errors.outstanding_loan?.message}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">Statement of Collaterals</Label>
          <Col md="9">
            <Controller
              name="collateral_statement"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <FileUpload
                    inputRef={ref}
                    onDrop={onChange}
                    onChange={onChange}
                    imagefile={value}
                    invalid={!isEmpty(errors.collateral_statement)}
                  />
                );
              }}
            />
            {errors.collateral_statement && (
              <FormFeedback style={{ display: 'block' }}>
                {errors.collateral_statement?.message}
              </FormFeedback>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">SALN details</Label>
          <Col md="9">
            <Controller
              name="saln"
              control={control}
              render={({ field: { onChange, value, ref } }) => {
                return (
                  <FileUpload
                    inputRef={ref}
                    onDrop={onChange}
                    onChange={onChange}
                    imagefile={value}
                    invalid={!isEmpty(errors.saln)}
                  />
                );
              }}
            />
            <FormText>(Optional but Recommended)</FormText>
            {errors.saln && (
              <FormFeedback style={{ display: 'block' }}>{errors.saln?.message}</FormFeedback>
            )}
          </Col>
        </FormGroup>
        {issuer &&
          issuer.company_details &&
          issuer.company_details.entity_type &&
          issuer.company_details.entity_type.id > 1 && (
            <FormGroup row>
              <Label md="3">Board Resolution</Label>
              <Col md="9">
                <Controller
                  name="board_resolution"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <FileUpload
                        inputRef={ref}
                        onDrop={onChange}
                        onChange={onChange}
                        imagefile={value}
                        invalid={!isEmpty(errors.board_resolution)}
                      />
                    );
                  }}
                />
                <FormText>(Loan amount should be indicated)</FormText>
                {errors.board_resolution && (
                  <FormFeedback style={{ display: 'block' }}>
                    {errors.board_resolution?.message}
                  </FormFeedback>
                )}
              </Col>
            </FormGroup>
          )}
      </FormGroup>
      <hr />

      <Row>
        <Col>
          <button
            type="button"
            className={`btn ${loadingDraftSubmit ? 'btn-gray' : 'btn-info'} mr-2 ${
              loan.status > 1 && 'd-none'
            }`}
            disabled={loadingDraftSubmit}
            onClick={onSaveAsDraft}
          >
            {loadingDraftSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Save as Draft'}
          </button>
          <button
            type="submit"
            className={`btn ${loadingSubmit ? 'btn-gray' : 'btn-primary'} float-right`}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default withRouter(FormRequestLoan);

const TooltipItem = (props) => {
  const { placement, id, content } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <span>
      <i className="fa fa-exclamation-circle text-info ml-1" id={'Tooltip-' + id} />
      <Tooltip placement={placement} isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
        {content}
      </Tooltip>
    </span>
  );
};
