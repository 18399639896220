import React, { useEffect, useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PageContext from './../../../config/page-settings.js';

import UserList from './UserList';

const UserManagement = (props) => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const addUser = () => {
    props.history.push('/settings/users/create');
  };

  return (
    <div>
      {/* <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/table/data">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/table/data">Tables</Link>
        </li>
        <li className="breadcrumb-item active">Data Tables</li>
      </ol> */}
      <h1 className="page-header">
        User Management{' '}
        <button onClick={addUser} className="btn btn-primary btn-icon btn-circle btn-md ml-2">
          <i className="fa fa-plus"></i>
        </button>
        <button className="btn btn-secondary btn-icon btn-circle btn-md ml-2">
          <i className="fa fa-download" style={{ color: 'white' }}></i>
        </button>
      </h1>
      <UserList />
    </div>
  );
};

export default withRouter(UserManagement);
