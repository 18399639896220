import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import PageContext from '../../config/page-settings.js';
import {
  Table,
  RowActionItems,
  RowItemReview,
  RowItemApprove,
  RowItemReject
} from '../../components/table';
import { Notification } from '../../utils/index.js';
import * as API from '../../services/api';
import { Media, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isNull } from 'lodash';

const RequestBanks = (props) => {
  const history = useHistory();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const onChangeKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const getRequestParams = (keyword, page, pageSize) => {
    let params = {};

    if (keyword) {
      params['filter[search]'] = keyword;
    }

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    params['filter[status]'] = '1,5';

    return params;
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    const params = getRequestParams(keyword, page, pageSize);
    setIsLoading(true);
    API.getUserBanks(params)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        setData([]);
        setMeta({});
        setIsLoading(false);
      });
  };

  useEffect(fetchData, [page, pageSize]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Account Name',
        cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <Link to={`/investors/${original.user_id}`} target="_blank" rel="noopener noreferrer">
              {original.account_name}
            </Link>
          );
        },
        sortable: true
      },
      {
        header: 'Bank Name',
        accessorKey: 'bank_name',
        sortable: true
      },
      {
        header: 'Account Number',
        accessorKey: 'account_number',
        sortable: true
      },
      {
        header: 'Attachment',
        cell: ({ cell }) => {
          const { original } = cell.row;

          return !isNull(original?.attachment?.view) ? (
            <>
              <ModalImage image={original?.attachment?.view} />
            </>
          ) : (
            'No Attachment Available'
          );
        },
        sortable: true
      },
      {
        header: 'Status',
        accessorFn: (d) => {
          return d.status.name;
        },
        sortable: true
      },
      {
        header: 'Action Items',
        cell: ({ cell }) => {
          const { original } = cell.row;

          const onView = () => {
            history.push(`/request/banks/${original.id}`, original);
          };

          const onApprove = () => {
            const params = {
              status: String(2),
              remarks: '',
              force: 1
            };
            API.updateInvestorBankStatus(original.id, params)
              .then(() => {
                fetchData();
                Notification.success('Bank Request Approve', 'Bank request successfully approved!');
              })
              .catch((err) => {
                Notification.error(err);
              });
          };

          const onReject = (reason) => {
            const params = {
              status: String(3),
              remarks: reason,
              force: 1
            };

            API.updateInvestorBankStatus(original.id, params)
              .then(() => {
                fetchData();
                Notification.success('Bank Request Reject', 'Bank request successfully rejected!');
              })
              .catch((err) => {
                Notification.error(err);
              });
          };

          return (
            <RowActionItems>
              <RowItemApprove onConfirm={onApprove} />
              <RowItemReject onConfirm={onReject} />
              <RowItemReview onClick={onView} />
            </RowActionItems>
          );
        }
      }
    ],
    []
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <h1 className="page-header">Bank Requests</h1>
      <Panel>
        <PanelHeader>Pending Banks</PanelHeader>
        <PanelBody>
          <Table
            columns={columns}
            data={data}
            meta={meta}
            isLoading={isLoading}
            onSetPageSize={handlePageSizeChange}
            updateRow={updateRow}
            onNextPage={handlePageChange('next')}
            onPrevPage={handlePageChange('prev')}
            skipPageReset={skipPageReset}
            onChangeKeyword={onChangeKeyword}
            keyword={keyword}
            onSearchKeyword={handleSearch}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default memo(RequestBanks);

const ModalImage = ({ image }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const imgStyle = {
    minHeight: 256,
    minWidth: 256
  };

  return (
    <div>
      <Media>
        <Media object src={image} alt="attachment" onClick={toggle} />
      </Media>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Attachment</ModalHeader>
        <ModalBody>
          <Media style={{ justifyContent: 'center' }}>
            <Media object src={image} style={imgStyle} alt="attachment" />
          </Media>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
