import React, { lazy, useState, useEffect, useMemo, useContext, Suspense, memo } from 'react';
import { withRouter, Link, Switch, Route, Redirect } from 'react-router-dom';
import PageContext from '../../config/page-settings.js';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import IssuerInformation from './components/Review/IssuerInformation.js';
import DirectorsAndOfficersList from './components/Review/DirectorsAndOfficersList.js';
import SupportingDocuments from './components/Review/SupportingDocuments.js';
import BanksList from './components/Review/BanksList.js';
import FormAppSettings from './components/FormAppSettings.js';

import { Button } from '../../components/common';

import { useParams } from 'react-router-dom';
import * as API from '../../services/api';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import ActivityLogsList from '../logs/activity/ActivityLogsList.js';
import IssuerTransactionList from '../transactions/issuers/IssuerTransactionList.js';
import FormIssuerInformationEdit from './components/FormIssuerInformationEdit.js';
import { Notification, PriceFormat } from '../../utils/index.js';

import { Form, FormGroup, Row, Col, Input, FormFeedback, Label } from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import ReactSelect from 'react-select';

const LoanList = lazy(() => import('./components/LoanList.js'));
const ExtraDocuments = lazy(() => import('./components/Review/ExtraDocuments.js'));

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      {...props}
      checked={props.checked}
      disabled={props.disabled || false}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const OPTION_OTP_DESINATION = [
  { value: 1, label: 'SMS' },
  { value: 2, label: 'Email' }
];

const schemaChangePassword = yup.object().shape({
  password: yup
    .string()
    .required('New password is required')
    .min(8, 'New password must be at least 8 characters'),
  password_confirmation: yup
    .string('Confirm password is required')
    .required()
    .min(8, 'New password must be at least 8 characters')
});

const IssuerView = (props) => {
  const { id } = useParams();

  const pageContext = useContext(PageContext);

  const [issuer, setIssuer] = useState({});
  const [directors, setDirectors] = useState([]);
  const [banks, setBanks] = useState([]);
  const [docs, setDocs] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState({});

  useEffect(() => {
    setLoading(true);
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullHeight(true);
    pageContext.handleSetPageContentFullWidth(true);

    API.getIssuer(id)
      .then((res) => {
        setIssuer(res.data.data);
        setWallet(res.data.data.wallet);
        setLoading(false);
        return res.data.data;
      })
      .then((issuer) => {
        API.getIssuerDirectors(issuer.id).then((res) => {
          setDirectors(res.data.data);
        });

        API.getIssuerCompliance(issuer.id)
          .then((res) => {
            setDocs(res.data.data);
          })
          .catch((err) => {
            setDocs([]);
          });
      });
  }, []);

  const columnsInvestment = useMemo(
    () => [
      {
        Header: 'Project ID',
        accessor: 'project_id',
        sortable: true
      },
      {
        Header: 'Date Invested',
        accessor: 'date',
        sortable: true
      },
      {
        Header: 'Issuer',
        accessor: 'issuer',
        sortable: true
      },
      {
        Header: 'Amount Invested',
        accessor: 'amount',
        sortable: true
      },
      {
        Header: 'Outstanding',
        accessor: 'outstanding',
        sortable: true
      },
      {
        Header: 'On-hold',
        accessor: 'on_hold',
        sortable: true
      },
      {
        Header: 'Status',
        accessor: 'status',
        sortable: true
      },
      {
        Header: 'Action Items',
        accessor: 'id',
        width: 10,
        Cell: ({ cell }) => {
          return (
            <div className="with-btn text-nowrap d-flex justify-content-center">
              <button
                className="btn btn-default btn-icon btn-primary m-r-2"
                onClick={() => {
                  props.history.push(`/user/${cell.value}`);
                }}
              >
                <i className="fa fa-eye"></i>
              </button>
              <button className="btn btn-default btn-icon btn-red">
                <i className="fa fa-file-pdf"></i>
              </button>
            </div>
          );
        }
      }
    ],
    []
  );

  const [data, setData] = useState([]);

  const [tabs, setActiveTab] = useState({
    summary: true,
    profile: false,
    loans: false,
    activities: false,
    transactions: false,
    settings: false
  });

  const showTab = (e, tab) => {
    e.preventDefault();
    setActiveTab({
      summary: tab === 'summary' ? true : false,
      profile: tab === 'profile' ? true : false,
      funds: tab === 'funds' ? true : false,
      loans: tab === 'loans' ? true : false,
      activities: tab === 'activities' ? true : false,
      transactions: tab === 'transactions' ? true : false,
      settings: tab === 'settings' ? true : false
    });
  };

  const onReject = (reason) => {
    const params = {
      status: String(5),
      remarks: reason,
      user_id: id
    };
    API.updateInvestorStatus(params).then(() => {
      API.getIssuer(id).then((res) => {
        setIssuer(res.data.data);
      });
    });
  };

  const onApprove = (reason) => {
    const params = {
      status: String(4),
      remarks: '',
      user_id: id
    };
    API.updateInvestorStatus(params).then(() => {
      API.getIssuer(id).then((res) => {
        setIssuer(res.data.data);
      });
    });
  };

  const onViewContract = async () => {
    var token = localStorage.getItem('access_token');

    var win = window.open('_blank');

    downloadFile(issuer.contract_url, function (blob) {
      var url = URL.createObjectURL(blob);
      win.location = url;
    });

    function downloadFile(url, success) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      xhr.responseType = 'blob';
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (success) success(xhr.response);
        } else {
        }
      };
      xhr.send(null);
    }
  };

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm({
    resolver: yupResolver(schemaChangePassword)
  });

  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

  const toggleChangePasswordForm = () => {
    setShowChangePasswordForm(!showChangePasswordForm);
  };
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const onChangePassword = (data) => {
    setLoadingChangePassword(true);

    API.changeUserPassword(id, data)
      .then((res) => {
        setLoadingChangePassword(false);
        Notification.success('Change Password', 'Successfully change password');
      })
      .catch((err) => {
        setLoadingChangePassword(false);
        if (err.response.status === 422) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            setError(key, {
              type: 'manual',
              message: value
            });
          }
        } else {
          Notification.error(err);
        }
      });
  };

  const toggleOTPRequired = (value) => {
    setIssuer({ ...issuer, is_required_otp: value.target.checked });

    const params = {
      is_required_otp: value.target.checked ? 1 : 0
    };

    API.changeSecurity(id, params)
      .then((res) => {
        Notification.success(
          'Security OTP',
          `Security OTP Successfully ${value.target.checked ? 'enabled' : 'disabled'}`
        );
      })
      .catch((err) => {
        setIssuer({ ...issuer, auto_invest_enable: !value.target.checked });
        Notification.error(err);
      });
  };

  const [otpDestination, setOTPDestination] = useState({ value: 1, label: 'SMS' });

  useEffect(() => {
    issuer &&
      setOTPDestination(OPTION_OTP_DESINATION.find((c) => c.value === issuer?.otp_platform_id));
  }, [issuer]);

  const onChangeOTPDestination = (value) => {
    setOTPDestination(value);
    setIssuer({ ...issuer, otp_platform_id: value.value });

    const params = {
      otp_platform_id: value.value
    };
    API.changeSecurity(id, params)
      .then((res) => {
        Notification.success(
          'Security OTP Destination',
          `Security OTP Destination Successfully switch to ${value.label}`
        );
      })
      .catch((err) => {
        setIssuer({ ...issuer, otp_platform_id: issuer.otp_platform_id });
        Notification.error(err);
      });
  };

  const toggleBiometric = (value) => {
    setIssuer({ ...issuer, bio_enable: value.target.checked });

    const params = {
      bio_enable: value.target.checked ? 1 : 0
    };
    API.changeSecurity(id, params)
      .then((res) => {
        Notification.success(
          'Security Biometric',
          `Security Biometric Successfully ${value.target.checked ? 'enabled' : 'disabled'}`
        );
      })
      .catch((err) => {
        setIssuer({ ...issuer, bio_enable: !value.target.checked });
        Notification.error(err);
      });
  };

  if (loading) {
    return (
      <div>
        <span className="spinner"></span>
      </div>
    );
  }

  return (
    <div>
      <div className="profile">
        <div className="profile-header">
          <div className="profile-header-cover"></div>
          <div className="profile-header-content">
            <div className="profile-header-img">
              <img src="/assets/img/logos/logo_seedin_icon.png" alt="" />
            </div>
            <div className="profile-header-info">
              <h4 className="m-t-10 m-b-5">
                {issuer && issuer.company_details && issuer.company_details.company_name}
              </h4>
              {/* <p className="m-b-10">UXUI + Frontend Developer</p> */}
              <div style={{ width: '300px', marginBottom: '10px' }}>
                <div className="progress h-10px rounded-pill mb-5px">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-orange fs-10px fw-bold"
                    style={{
                      width: `${issuer && issuer.progress_percentage}%`
                    }}
                  >
                    Progress ({issuer && issuer.progress_percentage}%)
                  </div>
                </div>
              </div>
              {/* <Link to="/extra/profile" className="btn btn-xs btn-green m-l-5">
                Approve
              </Link> */}
              {/* <Link to="/extra/profile" className="btn btn-xs btn-red m-l-5">
                Reject
              </Link> */}

              {issuer && issuer.status && issuer.status.id == 3 && (
                <>
                  <ButtonApprove onConfirm={onApprove} />
                  <ButtonReject onConfirm={onReject} />
                </>
              )}

              {issuer && issuer.status && issuer.status.id == 0 && (
                <Link
                  to={{
                    pathname: `${id}/application`,
                    params: issuer
                  }}
                  className="btn btn-xs btn-primary m-l-5"
                >
                  Continue Application
                </Link>
              )}

              {issuer && issuer.status && issuer.status.id == 4 && (
                <a onClick={onViewContract} className="btn btn-xs btn-danger m-l-5">
                  Preview Contract
                </a>
              )}

              {/* <a
                // href="/extra/profile"
                // target="_blank"
                className="btn btn-xs btn-green m-l-5"
                onClick={() => {
                  alert('Feature Unavailable: Developers working in progress');
                }}
              >
                Access Profile
              </a> */}
              {issuer && issuer.status && issuer.status.id == 4 && (
                <Link
                  to={{
                    pathname: `${id}/request/loan`,
                    params: issuer
                  }}
                  className="btn btn-xs btn-primary m-l-5"
                >
                  Request Loan
                </Link>
              )}
              <ButtonSendMessage issuer={issuer} />
            </div>
          </div>
          <ul className="profile-header-tab nav nav-tabs">
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'summary')}
                className={'nav-link ' + (tabs.summary ? 'active ' : '')}
              >
                SUMMARY
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'profile')}
                className={'nav-link ' + (tabs.profile ? 'active ' : '')}
              >
                PROFILE{' '}
                <i className="fas fa-exclamation-triangle fa-fw" style={{ color: 'orange' }}></i>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'funds')}
                className={'nav-link ' + (tabs.funds ? 'active ' : '')}
              >
                FUNDS
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'loans')}
                className={'nav-link ' + (tabs.loans ? 'active ' : '')}
              >
                LOANS
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'activities')}
                className={'nav-link ' + (tabs.activities ? 'active ' : '')}
              >
                ACTIVITIES
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'transactions')}
                className={'nav-link ' + (tabs.transactions ? 'active ' : '')}
              >
                TRANSACTIONS
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/extra/profile"
                onClick={(e) => showTab(e, 'settings')}
                className={'nav-link ' + (tabs.settings ? 'active ' : '')}
              >
                SETTINGS
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="profile-content">
        <div className="tab-content p-0">
          <div className={'tab-pane fade ' + (tabs.summary ? 'show active ' : '')}>
            <div className="table-responsive form-inline">
              <table className="table table-profile">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <h4>
                        {issuer.company_name}{' '}
                        <small>
                          {issuer.address_line_1} {issuer.city} {issuer.province}
                        </small>
                      </h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="field">Mobile:</td>
                    <td>
                      <i className="fa fa-mobile fa-lg m-r-5"></i> {issuer.otp_number}
                      <Link to="/extra/profile" className="m-l-5">
                        <i className="fa fa-paper-plane fa-lg" />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Email:</td>
                    <td>
                      <Link to="/extra/profile" className="">
                        {issuer.email} <i className="fa fa-envelope fa-lg m-l-5" />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Registered Date:</td>
                    <td>{moment(issuer.created_at).local().format('MMM DD, YYYY')}</td>
                  </tr>
                  <tr>
                    <td className="field">Last Update:</td>
                    <td>{moment(issuer.updated_at).local().format('MMM DD, YYYY hh:mm A')}</td>
                  </tr>
                  <tr>
                    <td className="field">Profile Status:</td>
                    <td>{issuer && issuer.status && issuer.status.name}</td>
                  </tr>
                  <tr>
                    <td className="field">Authorize Person</td>
                    <td>{issuer.full_name}</td>
                  </tr>
                  <tr>
                    <td className="field">Website:</td>
                    <td>
                      {issuer.company_details && issuer.company_details.website ? (
                        <a
                          href={issuer.company_details && issuer.company_details.website}
                          target="_blank"
                        >
                          {issuer.company_details.website}
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="field">Social:</td>
                    <td>
                      {issuer.company_details && issuer.company_details.social_media ? (
                        <a
                          href={issuer.company_details && issuer.company_details.social_media}
                          target="_blank"
                        >
                          {issuer.company_details.social_media}
                        </a>
                      ) : (
                        'N/A'
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={'tab-pane fade ' + (tabs.profile ? 'show active ' : '')}>
            <div className="col-sm-12 col-md-12 col-lg-10 offset-lg-1">
              <FormIssuerInformationEdit issuer={issuer} />
              <hr />
              <DirectorsAndOfficersList directors={directors} />
              <hr />
              <SupportingDocuments docs={docs} />
              <hr />
              <BanksList banks={banks} />
              <hr />
              <ExtraDocuments documents={documents} />
            </div>
          </div>
          <div className={'tab-pane fade ' + (tabs.funds ? 'show active ' : '')}>
            <div className="row">
              <div className="col-xl-3 col-md-6">
                <div className="widget widget-stats bg-green">
                  <div className="stats-icon">
                    <i className="ion ion-ios-wallet"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Repayment Funds</h4>
                    <p>{PriceFormat(wallet.available_funds)}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="widget widget-stats bg-orange">
                  <div className="stats-icon">
                    <i className="ios ion-ios-hand"></i>
                  </div>
                  <div className="stats-info">
                    <h4>On Hold Funds</h4>
                    <p>{PriceFormat(wallet.on_hold)}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="widget widget-stats bg-info">
                  <div className="stats-icon">
                    <i className="ion ion-ios-stats"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Payable Balance</h4>
                    <p>{PriceFormat(wallet.current_funding)}</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="widget widget-stats bg-indigo">
                  <div className="stats-icon">
                    <i className="ion ion-ios-trending-up"></i>
                  </div>
                  <div className="stats-info">
                    <h4>Total Funding Raised</h4>
                    <p>{PriceFormat(wallet.total_funding_raised)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-6"></div>
              <div className="col-xl-3 col-md-6"></div>
              <div className="col-xl-3 col-md-6"></div>
              <div className="col-xl-3 col-md-6 d-flex justify-content-end">
                <ButtonAddFund />
                <ButtonDeductFund />
              </div>
            </div>
          </div>
          <div className={'tab-pane fade ' + (tabs.loans ? 'show active ' : '')}>
            <Suspense fallback={<div>Loading...</div>}>
              <LoanList issuer={issuer} />
            </Suspense>
          </div>
          <div className={'tab-pane fade ' + (tabs.activities ? 'show active ' : '')}>
            <ActivityLogsList />
          </div>
          <div className={'tab-pane fade ' + (tabs.transactions ? 'show active ' : '')}>
            <IssuerTransactionList />
          </div>
          <div className={'tab-pane fade ' + (tabs.settings ? 'show active ' : '')}>
            <div className="col-lg-8 offset-lg-2">
              <FormAppSettings />
              <hr />
              <h3>Security</h3>
              <div className="table-responsive form-inline">
                <table className="table">
                  <tbody>
                    {/* <tr>
                      <td className="field">Web OTP Enabled:</td>
                      <td>
                        <div className="switcher">
                          <input type="checkbox" name="wed" id="web" />
                          <label htmlFor="web"></label>
                        </div>
                      </td>
                    </tr> */}
                    <tr>
                      <td className="field">Mobile OTP Enabled:</td>
                      <td className="float-right">
                        <Switchery
                          checked={issuer.is_required_otp}
                          onChange={toggleOTPRequired}
                          for="is_required_otp"
                        />
                      </td>
                    </tr>
                    {issuer.is_required_otp && (
                      <tr>
                        <td className="field">OTP Destination:</td>
                        <td>
                          <ReactSelect
                            className="text-black"
                            value={otpDestination}
                            onChange={onChangeOTPDestination}
                            options={OPTION_OTP_DESINATION}
                            isOptionDisabled={(option) => option.disabled}
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td className="field">Biometrics Enabled:</td>
                      <td className="float-right">
                        <Switchery
                          checked={issuer.bio_enable == 1}
                          onChange={toggleBiometric}
                          for="bio_enabled"
                        />
                      </td>
                    </tr>
                    <tr className="highlight">
                      <td className="field">Password</td>
                      <td className="p-t-10 p-b-10">
                        {showChangePasswordForm ? (
                          <Form onSubmit={handleSubmit(onChangePassword)} autoComplete="off">
                            <FormGroup row className="mb-2">
                              <Label>New Password</Label>
                              <Col>
                                <Controller
                                  name="password"
                                  control={control}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      className="float-right"
                                      autoComplete="false"
                                      type="password"
                                      invalid={!isEmpty(errors.password)}
                                    />
                                  )}
                                />
                                {errors.password && (
                                  <FormFeedback>{errors.password?.message}</FormFeedback>
                                )}
                              </Col>
                            </FormGroup>
                            <FormGroup row className="mb-2">
                              <Label>Confirm Password</Label>
                              <Col>
                                <Controller
                                  name="password_confirmation"
                                  control={control}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      className="float-right"
                                      autoComplete="false"
                                      type="password"
                                      invalid={!isEmpty(errors.password)}
                                    />
                                  )}
                                />
                                {errors.password && (
                                  <FormFeedback>{errors.password?.message}</FormFeedback>
                                )}
                              </Col>
                            </FormGroup>
                            <Row>
                              <Col>
                                <Button
                                  type="submit"
                                  isloading={loadingChangePassword}
                                  className={`btn float-right ${
                                    loadingChangePassword ? 'btn-disabled btn-gray' : 'btn-primary'
                                  }`}
                                  disabled={loadingChangePassword}
                                  title="Submit"
                                />
                              </Col>
                            </Row>
                          </Form>
                        ) : (
                          <Button
                            className={`btn float-right btn-primary`}
                            onClick={toggleChangePasswordForm}
                            disabled={loadingChangePassword}
                            title="Reset Password"
                          />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <Panel>
                <PanelHeader>Security Options</PanelHeader>
                <PanelBody>
                  <div className="table-responsive">
                    <table className="table table-striped mb-0 align-middle">
                      <thead>
                        <tr>
                          <th>Options</th>
                          <th width="10%">Action Items</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Web OTP Enabled</td>
                          <td>
                            <div className="switcher">
                              <input type="checkbox" name="web" id="web" />
                              <label htmlFor="web"></label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Mobile OTP Enabled</td>
                          <td>
                            <div className="switcher">
                              <input type="checkbox" name="mobile" id="mobile" />
                              <label htmlFor="mobile"></label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Biometrics Enabled</td>
                          <td>
                            <div className="switcher">
                              <input type="checkbox" name="bio" id="bio" />
                              <label htmlFor="bio"></label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Reset Password</td>
                          <td nowrap="true">
                            <a className="btn btn-sm btn-danger w-60px me-1" href="/table/basic">
                              Reset
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </PanelBody>
              </Panel> */}
              <hr />
              <h3>Devices</h3>
              <Panel>
                <PanelHeader>Device List</PanelHeader>
                <PanelBody>
                  <div className="table-responsive">
                    <table className="table table-striped mb-0 align-middle">
                      <thead>
                        <tr>
                          <th>Last Login</th>
                          <th>Device</th>
                          <th>API version</th>
                          <th>API Key</th>
                          <th>IP</th>
                          <th width="10%">Action Items</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>02/02/2020</td>
                          <td>Android</td>
                          <td>2.0.0</td>
                          <th>cc7b94af056588d1ec716ba3e2029f6e_1626163611</th>
                          <td>65.33.232.232</td>
                          <td nowrap="true">
                            <a className="btn btn-sm btn-danger w-60px me-1" href="/table/basic">
                              Revoke
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>02/02/2020</td>
                          <td>Chrome</td>
                          <td>2.0.0</td>
                          <th>e88e1bbe17731a3cbb02b1524141b755_1626238356</th>
                          <td>192.434.232.232</td>
                          <td nowrap="true">
                            <a className="btn btn-sm btn-danger w-60px me-1" href="/table/basic">
                              Revoke
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(IssuerView);

const ButtonReject = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    setModalReject(false);
    toggleModalReject().then(() => {
      props.onConfirm(reason);
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-xs btn-red m-l-5" type="button" onClick={toggleModalReject}>
        Reject
      </button>

      <SweetAlert
        title={<span className="text-black text-wrap">Reject Issuer Application</span>}
        danger
        showCancel
        confirmBtnText="Reject"
        confirmBtnBsStyle="danger"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
        dependencies={[reason]}
      >
        <form>
          <textarea
            type="text"
            className="form-control"
            value={reason}
            onChange={onChangeText}
            placeholder="Please indicate the reason for rejecting issuer application"
          />
        </form>
      </SweetAlert>
    </>
  );
};

const ButtonApprove = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onReject = () => {
    setModalReject(false);
    toggleModalReject().then(() => {
      props.onConfirm(reason);
    });
  };

  const [reason, setReason] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-xs btn-green m-l-5" type="button" onClick={toggleModalReject}>
        Approve
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Approve Issuer Application</span>}
        success
        showCancel
        confirmBtnText="Approve"
        confirmBtnBsStyle="success"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onReject}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <p style={{ color: 'black' }}>Are you sure you want to approve this issuer application?</p>
      </SweetAlert>
    </>
  );
};

const schemaAdjustFunds = yup.object().shape({
  amount: yup.string(),
  description: yup.string().required()
});

const ButtonAddFund = (props) => {
  const { id } = useParams();

  const [modalReject, setModalReject] = useState(false);
  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onSubmit = (data) => {
    API.userAddFunds(id, data)
      .then((res) => {
        Notification.success('Add Funds', 'Funds successfully adjusted');
        toggleModalReject();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const {
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    resolver: yupResolver(schemaAdjustFunds),
    defaultValues: {
      amount: '',
      description: ''
    }
  });

  return (
    <>
      <button
        className="btn btn-md btn-primary m-l-5 no-border"
        type="button"
        onClick={toggleModalReject}
      >
        <i className="fa fa-plus mr-1"></i>&nbsp;Add Funds
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Fund Adjustment : Add</span>}
        customIcon={<i className="fa fa-plus fa-5x text-primary"></i>}
        custom
        showCancel
        confirmBtnText="Confirm Add Funds"
        confirmBtnBsStyle="primary"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <Form>
          <FormGroup row className="mt-2">
            <Col md="12">
              <Controller
                name="amount"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CurrencyInput
                    value={value}
                    prefix="₱"
                    placeholder="Amount"
                    decimalsLimit={2}
                    onValueChange={(value, name) => onChange(value)}
                    className="form-control"
                  />
                )}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="12">
              <Controller
                name="description"
                isClearable
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="textarea"
                    placeholder="Description"
                    invalid={!isEmpty(errors.description)}
                  />
                )}
              />
            </Col>
          </FormGroup>
        </Form>
      </SweetAlert>
    </>
  );
};

const ButtonDeductFund = (props) => {
  const { id } = useParams();

  const [modalReject, setModalReject] = useState(false);
  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onSubmit = (data) => {
    const params = new URLSearchParams();
    params.append('amount', data.amount);
    params.append('description', data.description);
    API.userDeductFunds(id, params)
      .then((res) => {
        Notification.success('Deduct Funds', 'Funds successfully adjusted');
        toggleModalReject();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const {
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    resolver: yupResolver(schemaAdjustFunds),
    defaultValues: {
      amount: '',
      description: ''
    }
  });

  return (
    <>
      <button
        className="btn btn-md btn-green m-l-5 no-border text-white"
        type="button"
        onClick={toggleModalReject}
      >
        <i className="fa fa-minus text-white mr-1"></i>&nbsp;Deduct Funds
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Fund Adjustment : Deduct</span>}
        customIcon={<i className="fa fa-minus fa-5x text-green"></i>}
        custom
        showCancel
        confirmBtnText="Confirm Deduct Funds"
        confirmBtnBsStyle="green"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <Form>
          <FormGroup row className="mt-2">
            <Col md="12">
              <Controller
                name="amount"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <CurrencyInput
                    value={value}
                    prefix="₱"
                    placeholder="Amount"
                    decimalsLimit={2}
                    onValueChange={(value, name) => onChange(value)}
                    className="form-control"
                  />
                )}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="12">
              <Controller
                name="description"
                isClearable
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="textarea"
                    placeholder="Description"
                    invalid={!isEmpty(errors.description)}
                  />
                )}
              />
            </Col>
          </FormGroup>
        </Form>
      </SweetAlert>
    </>
  );
};

const schemaSendMessage = yup.object().shape({
  type: yup.string(),
  message: yup.string().required('Message is required'),
  subject: yup.string().when('type', {
    is: (optionType) => optionType == 2,
    then: yup.string().required('Subject is required')
  })
});

const ButtonSendMessage = (props) => {
  const { issuer } = props;
  const [modalReject, setModalReject] = useState(false);
  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const optionType = [
    { value: 1, label: 'SMS' },
    { value: 2, label: 'Email' }
  ];

  const onSubmit = (data) => {
    const recipient =
      data.type == 2
        ? { email_address: [issuer.email] }
        : { mobile_number: [issuer.mobile_number] };

    const params = {
      ...data,
      ...recipient
    };
    console.log(params);
    API.sendMessage(params)
      .then((res) => {
        Notification.success(
          `Send ${data.type == 1 ? 'SMS' : 'Email'}`,
          `${data.type == 1 ? 'SMS' : 'Email'} successfully sent`
        );
        toggleModalReject();
      })
      .catch((err) => {
        Notification.error(err);
      });
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch
  } = useForm({
    resolver: yupResolver(schemaSendMessage),
    defaultValues: {
      type: 1,
      subject: '',
      message: ''
    }
  });
  const selectedType = watch('type', 1);

  return (
    <>
      <button
        className="btn btn-xs btn-primary m-l-5 no-border text-white"
        type="button"
        onClick={toggleModalReject}
      >
        Send Message
      </button>
      <SweetAlert
        title={
          <span className="text-black text-wrap">
            Send Message to: <br /> {selectedType === 1 ? issuer.otp_number : issuer.email}
          </span>
        }
        customIcon={<i className="fa fa-paper-plane fa-4x text-primary"></i>}
        custom
        showCancel
        confirmBtnText="Send Message"
        confirmBtnBsStyle="primary"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <Form>
          <FormGroup row>
            <Col md="12">
              <Controller
                name="type"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <ReactSelect
                    inputRef={ref}
                    className="text-black"
                    value={optionType.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                    options={optionType}
                    isOptionDisabled={(option) => option.disabled}
                  />
                )}
              />
            </Col>
          </FormGroup>
          {selectedType == 2 && (
            <FormGroup row>
              <Col md="12">
                <Controller
                  name="subject"
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input {...field} placeholder="Subject" invalid={!isEmpty(errors.subject)} />
                  )}
                />
                {errors.subject && (
                  <FormFeedback style={{ display: 'block' }}>
                    {errors.subject?.message}
                  </FormFeedback>
                )}
              </Col>
            </FormGroup>
          )}

          <FormGroup row>
            <Col md="12">
              <Controller
                name="message"
                isClearable
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    rows="5"
                    type="textarea"
                    placeholder="Enter Message..."
                    invalid={!isEmpty(errors.message)}
                  />
                )}
              />
              {errors.message && (
                <FormFeedback style={{ display: 'block' }}>{errors.message?.message}</FormFeedback>
              )}
            </Col>
          </FormGroup>
        </Form>
      </SweetAlert>
    </>
  );
};
