import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from './../../../components/panel/panel.jsx';
import PageContext from './../../../config/page-settings.js';
import FormUser from './components/FormUser.js';
import { PageHeader } from '../../../components/common';
import { Row, Col } from 'reactstrap';

const UserCreate = () => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  return (
    <>
      <PageHeader title="User Create" />
      <Row>
        <Col>
          <Panel>
            <PanelHeader>Information</PanelHeader>
            <PanelBody>
              <Row>
                <Col lg="8">
                  <FormUser />
                </Col>
              </Row>
            </PanelBody>
          </Panel>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(UserCreate);
