import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import PageContext from '../../config/page-settings.js';
import { Table, RowActionItems, RowItemDelete, RowItemReview } from '../../components/table';
import { Notification, PriceFormat } from '../../utils/index.js';
import { isNull } from 'lodash';
import * as API from '../../services/api';
import moment from 'moment';

const IssuerList = (props) => {
  const history = useHistory();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [meta, setMeta] = useState({});
  const [keyword, setKeyword] = useState('');
  const onChangeKeyword = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const getRequestParams = (keyword, page, pageSize) => {
    let params = {};

    params['filter[status]'] = `2,3,4,6,7,9`;

    if (keyword) {
      params['filter[search]'] = keyword;
    }

    if (page) {
      params['page'] = page;
    }

    if (pageSize) {
      params['size'] = pageSize;
    }

    return params;
  };

  const [isLoading, setIsLoading] = useState(false);
  const fetchData = () => {
    const params = getRequestParams(keyword, page, pageSize);
    setIsLoading(true);
    API.getLoans(params)
      .then((response) => {
        const { data, meta } = response.data;
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((e) => {
        setData([]);
        setMeta({});
        setIsLoading(false);
      });
  };

  useEffect(fetchData, [page, pageSize]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handlePageChange = (action) => (event, value) => {
    if (action === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        header: 'Date',
        accessorFn: (d) => {
          return moment(d.created_at).local().format('MMM DD, YYYY');
        },
        sortable: true
      },
      {
        header: 'Issuer',
        accessorKey: 'company_name',
        sortable: true
      },
      {
        header: 'Amount',
        accessorFn: (d) => {
          return PriceFormat(d.amount);
        },
        sortable: true
      },
      {
        header: 'Tenor',
        accessorKey: 'loan_tenor',
        sortable: true
      },
      {
        header: 'Ratings',
        accessorFn: (d) => {
          return !isNull(d.credit_rating)
            ? `${d.credit_rating} (${d.credit_score}%)`
            : 'Not Available';
        },
        sortable: true
      },
      {
        header: 'Status',
        accessorFn: (d) => {
          return d.status.name;
        },
        sortable: true
      },
      {
        header: 'Action Items',
        cell: ({ cell }) => {
          const { original } = cell.row;
          const onView = () => {
            history.push(`/request/loans/review/${original.id}`, original);
          };

          return (
            <RowActionItems>
              <RowItemReview onClick={onView} />
            </RowActionItems>
          );
        }
      }
    ],
    []
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <h1 className="page-header">Loan Requests</h1>
      <Panel>
        <PanelHeader>Pending Loan Requests</PanelHeader>
        <PanelBody>
          <Table
            columns={columns}
            data={data}
            meta={meta}
            isLoading={isLoading}
            onSetPageSize={handlePageSizeChange}
            updateRow={updateRow}
            onNextPage={handlePageChange('next')}
            onPrevPage={handlePageChange('prev')}
            skipPageReset={skipPageReset}
            onChangeKeyword={onChangeKeyword}
            keyword={keyword}
            onSearchKeyword={handleSearch}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default memo(IssuerList);
