import { isExportDeclaration } from 'typescript';
import { api, apiForm, apiFile, csrfAPI } from './index';

export const sanctumToken = () => {
  return csrfAPI.get('sanctum/csrf-cookie');
};

export const badges = () => {
  return api.get('badges');
};

export const userLogin = (params) => {
  return api.post('auth/admin', params);
};

export const userMe = () => {
  return api.get('me');
};

export const userUpdateMe = (params) => {
  return api.put('me', params);
};

export const userLogout = () => {
  return api.delete('me/logout');
};

//admin users

export const getAdmins = (params) => {
  return api.get('admin', { params });
};

export const userAdminCreate = (params) => {
  return apiForm.post('admin', params);
};

export const userAdminUpdate = (id, params) => {
  return apiForm.put(`admin/${id}`, params);
};

export const readAdmin = (id) => {
  return api.get(`admin/${id}`);
};

export const deleteAdmin = (id) => {
  return api.delete(`admin/${id}`);
};

export const getUsers = () => {
  return api.get('users');
};

export const getInvestor = (id) => {
  return api.get(`investors/${id}`);
};

export const patchInvestor = (id, params) => {
  return api.patch(`investors/${id}`, params);
};

export const deleteInvestor = (id) => {
  return api.delete(`investors/${id}`);
};

export const getUserInfo = (id) => {
  return api.get(`users/users/${id}?expand=role`);
};

export const userCreate = (params) => {
  return api.post('users/users', params);
};

export const userCreateDocument = (params) => {
  return apiFile.post('users/documents', params);
};

export const userUpdateDocument = (id, params) => {
  return api.put(`users/documents/${id}`, params);
};

export const userAddFunds = (id, params) => {
  return api.post(`users/${id}/funds`, params);
};

export const userDeductFunds = (id, params) => {
  return apiForm.put(`users/${id}/funds`, params);
};

export const getUserBanks = (params) => {
  return api.get('users/banks', { params });
};

export const getUserBankDetail = (id) => {
  return api.get(`users/banks/${id}`);
};

export const getUserBank = (id) => {
  return api.get(`users/banks?filter[user_id]=${id}`);
};

export const getUserDocument = (id) => {
  return api.get(`users/documents?filter[user_id]=${id}`);
};

export const userCreateBank = (params) => {
  return api.post('users/banks', params);
};

export const deleteUserBank = (id) => {
  return api.delete(`users/banks/${id}`);
};

export const patchOtherBank = (id, type, params) => {
  return api.patch(`user-management/users/${id}/banks/${type}`, params);
};

export const userChangePassword = (params) => {
  return apiForm.put('me/password', params);
};

export const userValidateOtp = (params) => {
  return api.post('user/validate-otp-login', params);
};

export const userResendOtp = (params) => {
  return api.post('user/resend-otp-login', params);
};

export const loanRepaymentPlan = (id) => {
  return api.get(`loans/repayment-schedule/${id}`);
};

//investor

export const getInvestors = (params, signal) => {
  return api.get('investors', { params , signal});
};

export const getPriorityInvestors = (params) => {
  return api.get('investors/priority', { params });
};

export const getPriorityInvestorsFunds = () => {
  return api.get('investors/priority/funds');
};

export const investorCreate = (params) => {
  return apiForm.post('investors', params);
};

export const investorUpdate = (id, params) => {
  return apiForm.put(`investors/${id}`, params);
};

export const investorPatch = (id, params) => {
  return apiForm.patch(`investors/${id}`, params);
};

export const investorUpdateSource = (id, params) => {
  return api.put(`investors/${id}/source`, params);
};

export const investorView = (id) => {
  return api.get(
    `investors/${id}?expand=account_progress,wallet,user_details,proof_of_income,proof_of_identity,proof_of_address`
  );
};

export const getInvestorsAIRules = (id, params) => {
  return api.get(`investors/${id}/auto-invest`, { params });
};
export const investorSettingsAutoInvest = (id, params) => {
  return apiForm.put(`investors/${id}/auto-invest`, params);
};

export const getInvestorsInvestments = (id, params) => {
  return api.get(`investors/${id}/investments`, { params });
};

export const getInvestorOnHold = (id, params) => {
  return api.get(`users/${id}/hold`, { params });
};

export const getInvestorDocuments = (id) => {
  return api.get(`users/documents?filter[user_id]=${id}`);
};

export const getInvestorBanks = (id) => {
  return api.get(`users/banks?filter[user_id]=${id}`);
};

export const updateInvestorStatus = (params) => {
  return api.put('users/status', params);
};

export const updateInvestorBankStatus = (id, params) => {
  return api.patch(`users/banks/${id}`, params);
};

//issuer
export const createIssuer = (params) => {
  return api.post('issuers', params);
};

export const searchIssuer = (params) => {
  return api.get(`issuers/search/companies`, { params });
};

export const getIssuers = (params) => {
  return api.get(`issuers`, { params });
};

export const getIssuer = (id) => {
  return api.get(`issuers/${id}?expand=wallet,company_details`);
};

export const deleteIssuer = (id) => {
  return api.delete(`issuers/${id}`);
};

export const getIssuerDirectors = (id) => {
  return api.get(`issuers/${id}/directors`);
};

export const getIssuerLoans = (id, params) => {
  return api.get(`issuers/${id}/loan-request`, { params });
};

export const getLoanRequest = (id) => {
  return api.get(`loan-requests/${id}`);
};

export const getLoanRequestLogs = (id, params) => {
  return api.get(`loan-requests/${id}/logs`, { params });
};

export const loanRequestStatus = (id, status, params) => {
  return api.patch(`loan-requests/${id}/${status}`, params);
};

export const getLoanGenerateSchedule = (params) => {
  return api.get('loans/generate-schedule', { params });
};

// export const getUserBank = (id) => {
//   return api.get(`user-management/users/${id}/banks`);
// };

export const getIssuerCompliance = (id) => {
  return api.get(`issuers/${id}/sec-form`);
};

export const createIssuerDirectors = (params) => {
  return apiForm.post(`issuers/directors`, params);
};
export const updateIssuer = (id, params) => {
  return apiForm.put(`issuers/${id}`, params);
};

export const updateIssuerAppSettings = (id, params) => {
  return apiForm.put(`issuers/${id}/app-display`, params);
};

export const patchIssuer = (id, params) => {
  return api.patch(`issuers/${id}`, params);
};
export const updateIssuerSec = (id, params) => {
  return api.put(`issuers/${id}/sec-form`, params);
};

export const postLoanRequest = (params) => {
  return apiForm.post('loan-requests', params);
};

export const putLoanRequest = (id, params) => {
  return apiForm.put(`loan-requests/${id}`, params);
};

export const putLoanCreditRating = (id, params) => {
  return apiForm.put(`loan-requests/${id}/credit-rating`, params);
};
//loans

export const getLoans = (params) => {
  return api.get('loan-requests', { params });
};

//projects

export const getProjects = (params, signal) => {
  return api.get('loans', { params , signal});
};

export const getProjectsList = (params, signal) => {
  return api.get('loans/list', { params , signal});
};

export const getProject = (id, params) => {
  return api.get(`loans/${id}`, { params });
};

export const getProjectLoanSchedule = (id, params) => {
  return api.get(`loans/${id}/schedule`, { params });
};

export const getProjectLoanStatement = (id, params) => {
  return api.get(`loans/${id}/statement`, { params });
};

export const getProjectPaymentRecord = (id, params) => {
  return api.get(`loans/${id}/paymentrecord`, { params });
};

export const getProjectInvestors = (id, params) => {
  return api.get(`loans/${id}/investors`, { params });
};

export const getProjectCancelledInvestors = (id, params) => {
  return api.get(`loans/${id}/cancel-investors`, { params });
};

export const postProject = (params) => {
  return apiForm.post('loans', params);
};

export const patchProject = (id, params) => {
  return apiForm.patch(`loans/${id}`, params);
};

export const putProject = (id, params) => {
  return apiForm.post(`loans/${id}/put`, params);
};

export const publishProject = (id) => {
  return api.patch(`loans/${id}/publish`);
};

export const approveProject = (id, params) => {
  return api.post(`loans/${id}/approve`, params);
};

export const cancelProject = (id) => {
  return api.patch(`loans/${id}/cancel`);
};

export const cancelByPlan = (id) => {
  return api.patch(`investment-plan/${id}/cancel`);
};

export const updateSECDate = (id, params) => {
  return apiForm.put(`loans/${id}/sec`, params);
};

export const issuerPayNow = (id, params) => {
  return api.post(`loans/${id}/payment`, params);
};

export const loanPaymentApprove = (id, params) => {
  return api.post(`loan-payment/${id}/approve`, params);
};

export const loanPaymentCancel = (id, params) => {
  return api.post(`loan-payment/${id}/cancel`, params);
};

export const investPriority = (id, params) => {
  return api.post(`loans/${id}/invest-priority`, params);
};

//roles
export const getRoles = (params) => {
  return api.get('settings/roles', { params });
};

export const createRole = (params) => {
  return api.post('settings/roles', params);
};

export const updateRole = (id, params) => {
  return api.put(`settings/roles/${id}`, params);
};

export const readRole = (id) => {
  return api.get(`settings/roles/${id}`);
};

export const getPermissions = () => {
  return api.get('settings/permission-categories');
};

export const getActivityLogs = (params) => {
  return api.get('activities', { params });
};

export const getSystemLogs = (params) => {
  return api.get('system-logs', { params });
};

export const getNotificationLogs = (params) => {
  return api.get('push-notification', { params });
};

export const getCountries = (params) => {
  return api.get(`settings/countries`, { params });
};

export const patchCountry = (id, params) => {
  return apiForm.patch(`settings/countries/${id}`, params);
};

//industry

export const getIndustyList = (params) => {
  return api.get('settings/industries', { params });
};

export const createIndustry = (params) => {
  return api.post('settings/industries', params);
};

export const updateIndustry = (id, params) => {
  return api.put(`settings/industries/${id}`, params);
};

export const patchIndustry = (id, params) => {
  return apiForm.patch(`settings/industries/${id}`, params);
};

export const deleteIndustry = (id) => {
  return api.delete(`settings/industries/${id}`);
};

//payment-categories
export const getPaymentCategories = () => {
  return api.get('settings/payment-types');
};

export const createPaymentCategory = (params) => {
  return api.post('settings/payment-types', params);
};

export const updatePaymentCategory = (id, params) => {
  return api.put(`settings/payment-types/${id}`, params);
};

export const deletePaymentCategory = (id) => {
  return api.delete(`settings/payment-types/${id}`);
};

export const patchPaymentCategory = (id, params) => {
  return apiForm.patch(`settings/payment-types/${id}`, params);
};

//entity
export const getEntityList = () => {
  return api.get('settings/business-entities');
};

export const createEntity = (params) => {
  return api.post('settings/business-entities', params);
};

export const updateEntity = (id, params) => {
  return api.put(`settings/business-entities/${id}`, params);
};

export const deleteEntity = (id) => {
  return api.delete(`settings/business-entities/${id}`);
};

export const patchEntity = (id, params) => {
  return apiForm.patch(`settings/business-entities/${id}`, params);
};

//request
export const requestTopUps = (params) => {
  return api.get('request', { params });
};

export const getFundRequests = (params) => {
  return api.get('fund_request', { params });
};

export const viewFundRequest = (id) => {
  return api.get(`fund_request/${id}`);
};

export const requestTopUp = (id) => {
  return api.get(`request/${id}`);
};

export const requestTopUpApprove = (id) => {
  return api.put(`request/top-up/${id}/approve`);
};

export const requestTopUpReject = (id, params) => {
  return api.put(`request/top-up/${id}/cancel`, params);
};

export const requestWithdrawApprove = (id) => {
  return api.put(`fund_request/withdrawal/${id}/approve`);
};

export const requestWithdrawReject = (id, params) => {
  return api.put(`fund_request/withdrawal/${id}/cancel`, params);
};

//banks
export const getBanksList = (params) => {
  return api.get('settings/banks', { params });
};

export const patchBank = (id, params) => {
  return apiForm.patch(`settings/banks/${id}`, params);
};

export const createBank = (params) => {
  return api.post('settings/banks', params);
};

export const updateBank = (id, params) => {
  return api.put(`settings/banks/${id}`, params);
};

export const deleteBank = (id) => {
  return api.delete(`settings/banks/${id}`);
};

//payment-type

export const getPaymentOptions = (params) => {
  return api.get('settings/payment-banking', { params });
};
export const createPaymentOption = (params) => {
  return api.post('settings/payment-banking', params);
};

export const updatePaymentOption = (id, params) => {
  return apiForm.put(`settings/payment-banking/${id}`, params);
};

export const patchPaymentOption = (id, params) => {
  return apiForm.patch(`settings/payment-banking/${id}`, params);
};

export const deletePaymentOption = (id) => {
  return api.delete(`settings/payment-banking/${id}`);
};

//global-data
export const getGlobalData = (params) => {
  return api.get('settings/global-data', { params });
};

export const createGlobalData = (params) => {
  return api.post('settings/global-data', params);
};

export const readGlobalData = (id) => {
  return api.get(`settings/global-data/${id}`);
};

export const updateGlobalData = (id, params) => {
  return api.put(`settings/global-data/${id}`, params);
};

//document
export const getDocumentRequirementList = (params) => {
  return api.get('settings/document-requirements', { params });
};

export const createDocumentRequirement = (params) => {
  return api.post('settings/document-requirements', params);
};

export const updateDocumentRequirement = (id, params) => {
  return api.put(`settings/document-requirements/${id}`, params);
};

export const deleteDocumentRequirement = (id) => {
  return api.delete(`settings/document-requirements/${id}`);
};

export const patchDocumentRequirement = (id, params) => {
  return apiForm.patch(`settings/document-requirements/${id}`, params);
};

//transactions

export const getUserTransactions = (params) => {
  return api.get('transactions', { params });
};

export const getPaynamicsTransactions = (params) => {
  return api.get('paynamics', { params });
};

export const getPaynamicsTransaction = (id) => {
  return api.get(`paynamics/${id}`);
};

export const paynamicsForceCheck = (id) => {
  return api.post(`paynamics/${id}/force-check`);
};

export const paynamicsCheckStatus = (id) => {
  return api.post(`paynamics/${id}/check-status`);
};

export const getCoinsPHTransactions = (params) => {
  return api.get('coinsph', { params });
};

export const getCoinsPHTransaction = (id) => {
  return api.get(`coinsph/${id}`);
};

export const getXenditTransactions = (params) => {
  return api.get('xendit', { params });
};

export const getXenditTransaction = (id) => {
  return api.get(`xendit/${id}`);
};

export const checkXenditTransactionStatus = (id) => {
  return api.post(`xendit/${id}/check-status`);
};

export const getDenariiTransactions = (params) => {
  return api.get('denarii', { params });
};

export const getDenariiTransaction = (id) => {
  return api.get(`denarii/${id}`);
};

//app-versions

export const getAppVersions = (params) => {
  return api.get('settings/app-versions', { params });
};

export const createAppVersion = (params) => {
  return api.post('settings/app-versions', params);
};

export const updateAppVersion = (id, params) => {
  return apiForm.put(`settings/app-versions/${id}`, params);
};

export const deleteAppVersion = (id) => {
  return api.delete(`settings/app-versions/${id}`);
};

export const dataSets = (dataset) => {
  return api.get(`dataset?expand=${dataset.toString()}`);
};

//news
export const getNews = (params) => {
  return api.get('settings/articles', { params });
};

export const createNews = (params) => {
  return api.post('settings/articles', params);
};

export const readNews = (id) => {
  return api.get(`settings/articles/${id}`);
};

export const updateNews = (id, params) => {
  return apiForm.put(`settings/articles/${id}`, params);
};

export const deleteNews = (id) => {
  return api.delete(`settings/articles/${id}`);
};

export const changeUserPassword = (id, params) => {
  return api.put(`users/settings/${id}/change-password`, params);
};

export const changeSecurity = (id, params) => {
  return api.put(`users/settings/${id}/security`, params);
};

//drive

export const fileUpload = (params) => {
  return apiFile.post('drive', params);
};

export const base64Upload = (params) => {
  return apiFile.post('drive/base64', params);
};

//dashboard
export const topInvestors = () => {
  return api.get('dashboard/top-investors');
};

export const payouts = () => {
  return api.get('dashboard/payouts');
};

export const newUsers = (params) => {
  return apiForm.get('dashboard/new-users', { params });
};

export const totalFunds = (params) => {
  return api.get('dashboard/total-available-funds', params);
};

//send notification
export const sendMessage = (params) => {
  return api.post('admin/notification', params);
};

//developers

export const getTable = (endpoint, params) => {
  return api.get(endpoint, { params });
};

export const topUp = (type = '', params) => {
  return api.post(`top-up${type}`, params);
};

//merchants
export const getMerchants = (params) => {
  return api.get('merchants', { params });
};

export const readMerchant = (id) => {
  return api.get(`merchants/${id}`);
};

export const createMerchant = (params) => {
  return api.post('merchants', params);
};

export const updateMerchant = (id, params) => {
  return api.put(`merchants/${id}`, params);
};

//perks
export const getPerks = (params) => {
  return api.get('perks', { params });
};

export const getPerkCodes = (id, params) => {
  return api.get(`perks/${id}/codes`, { params });
};

export const readPerk = (id) => {
  return api.get(`perks/${id}`);
};

export const createPerk = (params) => {
  return api.post('perks', params);
};

export const updatePerk = (id, params) => {
  return api.put(`perks/${id}`, params);
};

//reports

export const getOverallReports = ({ params }) => {
  return api.get('overall-report/sales', { params });
};
