import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import PageContext from '../../config/page-settings.js';
import * as API from '../../services/api';
import { Table, RowActionItems, RowItemDelete, RowItemView } from '../../components/table';
import { Notification } from '../../utils/index.js';

const PartnersList = (props) => {
  const history = useHistory();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({
    per_page: 10
  });
  const [skipPageReset, setSkipPageReset] = useState(false);

  useEffect(() => {
    fetchIndustyList();
  }, [props]);

  const fetchIndustyList = () => {
    API.getIndustyList().then((res) => {
      setData([
        {
          id: 1,
          created_date: 'September 2021',
          name: 'Hazel Navarro'
        }
      ]);
    });
  };

  const columns = useMemo(
    () => [
      {
        header: 'Date',
        accessorKey: 'created_date',
        sortable: true
      },
      {
        header: 'Name',
        accessorKey: 'name',
        sortable: true
      },

      {
        header: 'Action Items',
        cell: ({ cell }) => {
          const { original } = cell.row;
          const viewIndusry = () => {
            history.push(`/settings/industry/${original.id}`, original);
          };

          return (
            <RowActionItems>
              <RowItemView onClick={viewIndusry} />
            </RowActionItems>
          );
        }
      }
    ],
    []
  );

  const updateRow = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((prevData) =>
      prevData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...prevData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Panel>
      <PanelHeader>Seedin Partners List</PanelHeader>
      <PanelBody>
        <Table
          columns={columns}
          data={data}
          meta={meta}
          updateRow={updateRow}
          skipPageReset={skipPageReset}
        />
      </PanelBody>
    </Panel>
  );
};

export default memo(PartnersList);
