import React, { memo, useEffect, useState } from 'react';
import { useHistory, useParams, useLocation, withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import * as API from '../../../../services/api';
import { Form, FormGroup, FormFeedback, FormText, Col, Label, Input } from 'reactstrap';
import { isEmpty } from 'lodash';
import ReactSelect from 'react-select';
import { Notification } from '../../../../utils';
import { isUndefined } from 'lodash';

const schemaPaymentOption = yup.object().shape({
  payment_type_id: yup.string(),
  payment_gateway: yup.string().required('Select Payment Gateway'),
  is_active: yup.boolean(),
  name: yup.string().required('Name is required'),
  account_name: yup.string().when('payment_type_id', {
    is: (paymentType) => paymentType == 1,
    then: yup.string().required('Account name is required')
  }),
  account_number: yup.string().when('payment_type_id', {
    is: (paymentType) => paymentType == 1,
    then: yup.string().required('Account number is required')
  }),
  bank_address: yup.string().when('payment_type_id', {
    is: (paymentType) => paymentType == 1,
    then: yup.string().required('Branch is required')
  }),
  swift_code: yup.string().when('payment_type_id', {
    is: (paymentType) => paymentType == 1,
    then: yup.string().required('Swift code is required')
  }),
  key: yup
    .string()
    .required('Key Name is required')
    .matches(/^[a-zA-Z0-9-]+\S$/g, 'This field cannot contain whitespace or other symbol than -')
});

const OPTION_ACCOUNT_TYPE = [
  {
    value: 'Savings',
    label: 'Savings'
  },
  {
    value: 'Checking',
    label: 'Checking'
  }
];

const FormPaymentGateway = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      payment_type_id: 1
    },
    resolver: yupResolver(schemaPaymentOption)
  });

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [optionPaymentType, setOptionPaymentTypes] = useState([]);
  const [optionPaymentGateways, setOptionPaymentGateways] = useState([]);
  const [optionBanks, setOptionBanks] = useState([]);

  useEffect(() => {
    API.dataSets(['payment_type', 'payment_gateway', 'banks']).then((res) => {
      setOptionPaymentTypes(res.data.payment_type);
      setOptionPaymentGateways(res.data.payment_gateway);
      setOptionBanks(res.data.banks);
    });
  }, []);

  const paymentType = watch('payment_type_id', 1);

  useEffect(() => {
    if (paymentType > 1 && props.action === 'create') {
      setValue('name', '');
      setValue('account_name', '');
      setValue('account_number', '');
      setValue('bank_address', '');
      setValue('swift_code', '');
      setValue('account_type', '');
    }
  }, [paymentType]);

  useEffect(() => {
    console.log(optionBanks);
    console.log('location.state', location.state);
    if (props.action === 'update' && optionBanks.length) {
      reset({
        ...location.state,
        name: location.state.name,
        account_number: String(location.state.account_number),
        payment_type_id: Number(location.state.payment_type.id),
        payment_gateway: Number(location.state.payment_gateway.id),
        account_type: 'Checking'
      });
    }
  }, [props.action, optionBanks.length > 0]);

  const onSubmit = (data) => {
    console.log(data);
    setLoadingSubmit(true);

    if (!isUndefined(id)) {
      const params = new URLSearchParams();
      for (let [key, value] of Object.entries({ ...data, is_active: data.is_active ? 1 : 0 })) {
        params.append(key, value);
      }

      API.updatePaymentOption(id, params)
        .then(() => {
          setLoadingSubmit(false);
          Notification.success('Update Payment Option', 'Payment Options successfully updated');
          history.goBack();
        })
        .catch((err) => {
          setLoadingSubmit(false);
          if (err.response.status === 422) {
            for (const [key, value] of Object.entries(err.response.data.errors)) {
              setError(key, {
                type: 'manual',
                message: value[0]
              });
            }
          } else {
            Notification.error(err);
          }
        });
    } else {
      const params = {
        ...data,
        is_active: data.is_active ? 1 : 0
      };

      API.createPaymentOption(params)
        .then(() => {
          setLoadingSubmit(false);
          Notification.success('Create Payment Option', 'Payment Options successfully added');
          history.goBack();
        })
        .catch((err) => {
          setLoadingSubmit(false);
          if (err.response.status === 422) {
            for (const [key, value] of Object.entries(err.response.data.errors)) {
              setError(key, {
                type: 'manual',
                message: value[0]
              });
            }
          } else {
            Notification.error(err);
          }
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label md="3">Payment Type</Label>
        <Col md="9">
          <Controller
            name="payment_type_id"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <ReactSelect
                inputRef={ref}
                style={{ container: { borderColor: '#f44336' } }}
                isSearchable={false}
                value={optionPaymentType.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                options={optionPaymentType}
                isOptionDisabled={(option) => option.disabled}
              />
            )}
          />
        </Col>
      </FormGroup>

      {paymentType == 1 ? (
        <>
          <FormGroup row>
            <Label md="3">Bank</Label>
            <Col md="9">
              <Controller
                name="name"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => {
                  console.log('value', value);
                  return (
                    <ReactSelect
                      inputRef={ref}
                      value={optionBanks.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.value)}
                      options={optionBanks}
                      isOptionDisabled={(option) => option.disabled}
                    />
                  );
                }}
              />
              <FormFeedback style={{ display: errors.name ? 'block' : 'none' }}>
                {errors.name?.message}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="3">Account Type</Label>
            <Col md="9">
              <Controller
                name="account_type"
                isClearable
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <ReactSelect
                    inputRef={ref}
                    value={OPTION_ACCOUNT_TYPE.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                    options={OPTION_ACCOUNT_TYPE}
                    isOptionDisabled={(option) => option.disabled}
                  />
                )}
              />
              <FormFeedback style={{ display: errors.account_type ? 'block' : 'none' }}>
                {errors.account_type?.message}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="3">Account Name</Label>
            <Col md="9">
              <Controller
                name={`account_name`}
                isClearable
                control={control}
                render={({ field }) => (
                  <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                )}
              />
              <FormFeedback style={{ display: errors.account_name ? 'block' : 'none' }}>
                {errors.account_name?.message}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="3">Account Number</Label>
            <Col md="9">
              <Controller
                name="account_number"
                isClearable
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    // type="string"
                    className="number-only-field"
                    invalid={!isEmpty(errors.position)}
                  />
                )}
              />
              <FormFeedback style={{ display: errors.account_number ? 'block' : 'none' }}>
                {errors.account_number?.message}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="3">Branch</Label>
            <Col md="9">
              <Controller
                name={`bank_address`}
                isClearable
                control={control}
                render={({ field }) => (
                  <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                )}
              />
              <FormFeedback style={{ display: errors.bank_address ? 'block' : 'none' }}>
                {errors.bank_address?.message}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="3">Swift Code</Label>
            <Col md="9">
              <Controller
                name={`swift_code`}
                isClearable
                control={control}
                render={({ field }) => (
                  <Input {...field} type="text" invalid={!isEmpty(errors.position)} />
                )}
              />
              <FormFeedback style={{ display: errors.swift_code ? 'block' : 'none' }}>
                {errors.swift_code?.message}
              </FormFeedback>
            </Col>
          </FormGroup>
        </>
      ) : (
        <FormGroup row>
          <Label md="3">Name</Label>
          <Col md="9">
            <Controller
              name="name"
              isClearable
              control={control}
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.name)} />
              )}
            />
            <FormFeedback style={{ display: errors.name ? 'block' : 'none' }}>
              {errors.name?.message}
            </FormFeedback>
          </Col>
        </FormGroup>
      )}

      <FormGroup row>
        <Label md="3">Key Name</Label>
        <Col md="9">
          <Controller
            name={`key`}
            isClearable
            control={control}
            render={({ field }) => <Input {...field} type="text" invalid={!isEmpty(errors.key)} />}
          />
          <FormFeedback style={{ display: errors.key ? 'block' : 'none' }}>
            {errors.key?.message}
          </FormFeedback>
          <FormText color="muted">
            Meta Key is the identifier of the setting. It requires to be only string, no space or
            special characters except _ (underscore). Maximum length 100 characters.
          </FormText>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Payment Gateway</Label>
        <Col md="9">
          <Controller
            name="payment_gateway"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <ReactSelect
                inputRef={ref}
                style={{ container: { borderColor: '#f44336' } }}
                isSearchable={false}
                value={optionPaymentGateways.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                options={optionPaymentGateways}
                isOptionDisabled={(option) => option.disabled}
              />
            )}
          />
          <FormFeedback style={{ display: errors.payment_gateway ? 'block' : 'none' }}>
            {errors.payment_gateway?.message}
          </FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3">Is Active</Label>
        <Col md="9">
          <Controller
            control={control}
            name="is_active"
            render={({ field: { value, onChange } }) => (
              <Switchery checked={value} onChange={onChange} for="is_active" />
            )}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label md="3" />
        <Col md="9">
          <button
            type="submit"
            className={`btn ${loadingSubmit ? 'btn-gray' : 'btn-primary'}`}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? <i className="fas fa-spinner fa-spin"></i> : 'Submit'}
          </button>
        </Col>
      </FormGroup>
    </Form>
  );
};

export default withRouter(FormPaymentGateway);

const Switchery = memo((props) => (
  <div className="switcher">
    <input
      checked={props.checked}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
    />
    <label htmlFor={props.for}></label>
  </div>
));
