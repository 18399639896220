import React, { memo } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel.jsx';
import FormAppVersion from './components/FormAppVersion.js';

const AppVersionView = (props) => {
  return (
    <div>
      <h1 className="page-header">App Version View</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelHeader>Information</PanelHeader>
            <PanelBody>
              <FormAppVersion action="edit" values={props.values} />
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default memo(AppVersionView);
