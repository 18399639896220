import React, { memo, useState, useMemo, useEffect } from 'react';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Form,
  FormGroup,
  FormText,
  FormFeedback,
  Row,
  Col,
  Label,
  Input,
  Tooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';

import { isEmpty, isNull, isUndefined, has } from 'lodash';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Panel, PanelHeader, PanelBody } from '../../../../components/panel/panel.jsx';
import * as API from '../../../../services/api';
import { Notification, PriceFormat } from '../../../../utils/index.js';
import { useAppState } from '../../../../overmind';
import moment from 'moment';
import FileUpload from '../../../../components/forms/FileUpload.js';
const Switchery = memo((props) => (
  <div className="switcher">
    <input
      checked={props.checked}
      onChange={props.onChange}
      type="checkbox"
      name={props.for}
      id={props.for}
    />
    <label htmlFor={props.for}></label>
  </div>
));

const TooltipItem = (props) => {
  const { placement, id, content } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <i className="fa fa-exclamation-circle text-info ml-1" id={'Tooltip-' + id} />
      <Tooltip placement={placement} isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
        {content}
      </Tooltip>
    </span>
  );
};

const CREDIT_RATINGS = [
  {
    value: 'AAA',
    label: 'AAA',
    disabled: false,
    description: 'Highest quality',
    min: 96,
    max: 100
  },
  { value: 'AA+', label: 'AA+', disabled: false, description: 'High quality', min: 91, max: 95 },
  { value: 'AA', label: 'AA', disabled: false, description: 'High quality', min: 86, max: 90 },
  { value: 'AA-', label: 'AA-', disabled: false, description: 'High quality', min: 81, max: 85 },
  {
    value: 'A+',
    label: 'A+',
    disabled: false,
    description: 'Strong payment capacity',
    min: 76,
    max: 80
  },
  {
    value: 'A',
    label: 'A',
    disabled: false,
    description: 'Strong payment capacity',
    min: 71,
    max: 75
  },
  {
    value: 'A-',
    label: 'A-',
    disabled: false,
    description: 'Strong payment capacity',
    min: 66,
    max: 70
  },
  {
    value: 'BBB+',
    label: 'BBB+',
    disabled: false,
    description: 'Adequate payment capacity',
    min: 61,
    max: 65
  },
  {
    value: 'BBB',
    label: 'BBB',
    disabled: false,
    description: 'Adequate payment capacity',
    min: 56,
    max: 60
  },
  {
    value: 'BBB-',
    label: 'BBB-',
    disabled: false,
    description: 'Adequate payment capacity',
    min: 51,
    max: 55
  },
  {
    value: 'BB+',
    label: 'BB+',
    disabled: false,
    description: 'Likely to fulfil obligations ongoing uncertainly',
    min: 46,
    max: 50
  },
  {
    value: 'BB',
    label: 'BB',
    disabled: false,
    description: 'Likely to fulfil obligations ongoing uncertainly',
    min: 41,
    max: 45
  },
  {
    value: 'BB-',
    label: 'BB-',
    disabled: false,
    description: 'Likely to fulfil obligations ongoing uncertainly',
    min: 36,
    max: 40
  },
  { value: 'B+', label: 'B+', disabled: false, description: 'High credit risk', min: 31, max: 35 },
  { value: 'B', label: 'B', disabled: false, description: 'High credit risk', min: 26, max: 30 },
  { value: 'B-', label: 'B-', disabled: false, description: 'High credit risk', min: 21, max: 25 },
  {
    value: 'CCC+',
    label: 'CCC+',
    disabled: false,
    description: 'Very high credit risk',
    min: 16,
    max: 20
  },
  {
    value: 'CCC',
    label: 'CCC',
    disabled: false,
    description: 'Very high credit risk',
    min: 16,
    max: 20
  },
  {
    value: 'CCC-',
    label: 'CCC-',
    disabled: false,
    description: 'Very high credit risk',
    min: 16,
    max: 20
  },
  {
    value: 'CC',
    label: 'CC',
    disabled: false,
    description: 'Near default with possibility of recovery',
    min: 15,
    max: 100
  },
  { value: 'C', label: 'C', disabled: false, description: 'Default', min: 4, max: 10 },
  { value: 'D', label: 'D', disabled: false, description: 'Default', min: 0, max: 5 }
];

const schemaInvestorSelect = yup.object().shape({
  credit_rating: yup.string().nullable().required('Credit Rating is required.'),
  financial_position_rating: yup
    .string()
    .nullable()
    .required('Financial Position Rating is required.'),
  financial_performance_rating: yup
    .string()
    .nullable()
    .required('Financial Performance Rating is required.'),
  credit_score: yup.number().nullable().required('Credit Score Rating is required.'),
  // .when('credit_rating', {
  //   is: (value) => CREDIT_RATINGS.find((cr) => cr.value == value).value,
  //   then: (value) =>
  //     yup
  //       .number()
  //       .min(CREDIT_RATINGS.find((cr) => cr.value == value).min)
  //       .max(CREDIT_RATINGS.find((cr) => cr.value == value).max)
  // }),
  credit_rating_report: yup
    .mixed()
    .required('Credit Rating Report attachment is required')
    .test('type', 'Only the following formats are accepted:.png .jpeg, .jpg,', (value) => {
      return (
        value &&
        (value[0].type === 'image/jpeg' ||
          value[0].type === 'image/jpg' ||
          value[0].type === 'application/pdf' ||
          value[0].type === 'application/vnd.ms-excel' ||
          value[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          value[0].type === 'application/msword' ||
          value[0].type ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          value[0].type === 'application/vnd.ms-powerpoint' ||
          value[0].type ===
            'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
          value[0].type === 'image/png')
      );
    }),

  outlook: yup.string().nullable().required('Outlook is required.')
});

const IssuerInformation = ({ loan }) => {
  // const [loan, setLoan] = useState(loan);

  const [loanDetails, setLoanDetails] = useState(loan);

  const {
    watch,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    control,
    reset
  } = useForm({
    // defaultValues: { entity: { value: 'select', label: 'Select Entity' } },
    defaultValues: useMemo(() => {
      return { ...loan, credit_score: isNull(loan.credit_score) ? 0 : loan.credit_score };
    }, [loan]),
    resolver: yupResolver(schemaInvestorSelect)
  });

  const [optionRating, setOptionRating] = useState([]);

  const watchCreditRating = watch('credit_rating');
  const watchPositionRating = watch('financial_position_rating');
  const watchPerformanceRating = watch('financial_performance_rating');

  useEffect(() => {
    API.dataSets(['credit_rating']).then((res) => {
      setOptionRating(res.data.credit_rating);
    });

    getLoanRequest();
    reset({
      ...loan,
      credit_rating_report: isNull(loan.credit_rating_report && loan.credit_rating_report.view)
        ? []
        : [
            {
              ...loan.credit_rating_report,
              status: 1
            }
          ]
    });
  }, [loan]);

  const parseDocuments = (array = []) => {
    const documentObjects = {};

    var objectName = (doc_type) => {
      switch (doc_type) {
        case 1:
          return 'credit_rating_report';
        case 2:
          return 'bank_account';
        case 3:
          return 'outstanding_loan';
        case 4:
          return 'collateral_statement';
        case 5:
          return 'saln';
        case 6:
          return 'board_resolution';
      }
    };

    var object = array.reduce(
      (obj, item) => (
        (obj[objectName(1)] = isNull(item.document_file.view)
          ? []
          : [
              {
                view: item.document_file.view,
                name: `${objectName(1)}_${moment(item.created_at).format('MMDDYYYYhhss')}`,
                id: item.id,
                status: 2,
                ...item.document_file
              }
            ]),
        obj
      ),
      {}
    );

    return object;
  };

  const getLoanRequest = () => {
    API.getLoanRequest(loan.id).then((res) => {
      setLoanDetails(res.data.data);
      // reset(res.data.loan_request);
    });
  };

  useEffect(() => {
    if (!isNull(watchCreditRating) && !isUndefined(watchCreditRating)) {
      const tempCreditScore = optionRating.find((or) => or.value == watchCreditRating).max;
      setValue('credit_score', tempCreditScore);
    }
  }, [watchCreditRating]);

  // const onLoanRequestApprove = () => {
  //   API.loanRequestStatus(loan.id, 'approve').then(() => {
  //     Notification.success('Loan Request Approved!', 'Loan request successfully approved');
  //     getLoanRequest();
  //   });
  // };

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onStepOneSubmit = async (data) => {
    const params = new URLSearchParams();

    params.append('credit_rating', data.credit_rating);
    params.append('financial_position_rating', data.financial_position_rating);
    params.append('financial_performance_rating', data.financial_performance_rating);
    params.append('credit_score', data.credit_score);
    params.append('outlook', data.outlook);
    params.append('is_draft', 0);

    const isFile = (input) => 'File' in window && input instanceof File;
    if (data.credit_rating_report && isFile(data.credit_rating_report[0])) {
      let res = await uploadFiles([data.credit_rating_report[0]]);
      params.append('credit_rating_report', res[0].id);
    }

    API.putLoanCreditRating(loan.id, params)
      .then(() => {
        if (isNull(loanDetails.credit_score)) {
          Notification.success('Credit Rating!', 'Credit rating succefully applied');
        } else {
          Notification.info('Credit Rating!', 'Credit rating succefully updated');
        }
        getLoanRequest();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const uploadFiles = async (files) => {
    const promises = await files.map(async (file, index) => {
      if (!has(file, 'status')) {
        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('dir', 'Attachments');
        return await API.fileUpload(formData)
          .then((res) => {
            files[index] = { label: file.label, ...res.data.data };
            return { label: file.label, status: 1, ...res.data.data };
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        return file;
      }
    });

    const responses = await Promise.all(promises);
    return responses;
  };

  return (
    <>
      <FormGroup tag="fieldset" disabled>
        {loanDetails && loanDetails.status && loanDetails.status.id === 9 && (
          <>
            <h3>
              Issuer Re-submit Loan Request Terms <i className="fa fa-exclamation text-info"></i>
            </h3>
            <FormGroup row>
              <Label md="3">Loan Amount</Label>
              <Col md="9">
                <Input value={loan.issuer_offer_loan_amount} />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label md="3">Tenure</Label>
              <Col md="9">
                <Input value={loan.issuer_offer_tenor} />
              </Col>
            </FormGroup>
            <hr />
          </>
        )}
        {loanDetails && loanDetails.status && loanDetails.status.id === 12 && (
          <>
            <h4>
              Issuer accepted loan terms offered <i className="fa fa-exclamation text-info"></i>
            </h4>
            <hr />
          </>
        )}

        <h3>Loan Request Detail</h3>
        <FormGroup row>
          <Label md="3">Loan Amount</Label>
          <Col md="9">
            <Controller
              name="amount"
              isClearable
              control={control}
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.amount)} />
              )}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label md="3">Tenure</Label>
          <Col md="9">
            <Controller
              name="loan_tenor"
              isClearable
              control={control}
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.amount)} />
              )}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">Purpose of Loan</Label>
          <Col md="9">
            <Controller
              name="purpose"
              isClearable
              control={control}
              render={({ field }) => (
                <Input {...field} type="text" invalid={!isEmpty(errors.amount)} />
              )}
            />
          </Col>
        </FormGroup>
      </FormGroup>
      <hr />
      <FormGroup tag="fieldset" disabled>
        <h3>SEC Compliance</h3>
        <FormGroup row>
          <Label md="3">
            Use of Proceeds
            <TooltipItem placement="top" id="item_3" content="SEC Form CF : Item 3" />
          </Label>
          <Col md="9">
            <Controller
              name="use_of_proceeds"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.use_of_proceeds)}
                />
              )}
            />
            <FormText>
              Provide above the purpose and intended use of the offering proceeds.
            </FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Exempt Offering in the Past
            <TooltipItem placement="top" id="item_4" content="SEC Form CF : Item 6" />
          </Label>
          <Col md="9">
            <Controller
              name="exempt_offering"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.exempt_offering)}
                />
              )}
            />
            <FormText>Describe exempt offering conducted within the past three years.</FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Indebtedness of the Issuer
            <TooltipItem placement="top" id="item_5" content="SEC Form CF : Item 7.1" />
          </Label>
          <Col md="9">
            <Controller
              name="indebtedness"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.indebtedness)}
                />
              )}
            />
            <FormText>
              Describe the material terms of any indebtedness of the issuer, including amount,
              interest rate, maturity date and any other material terms.
            </FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Ownership and Capital Structure
            <TooltipItem placement="top" id="item_6" content="SEC Form CF : Item 1.2(a)" />
          </Label>
          <Col md="9">
            <Controller
              name="ownership_capital_structure"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  placeholder="Description of ownership and capital structure of issuer"
                  rows="5"
                  invalid={!isEmpty(errors.ownership_capital_structure)}
                />
              )}
            />
            {/* <FormText>
              Describe the material terms of any indebtedness of the issuer, including amount,
              interest rate, maturity date and any other material terms.
            </FormText> */}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Terms of the securities being offered
            <TooltipItem placement="top" id="item_7" content="SEC Form CF : Item 1.2(a-1)" />
          </Label>
          <Col md="9">
            <Controller
              name="terms_of_securities"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.terms_of_securities)}
                />
              )}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            How the exercise of rights held by the principal shareholders could affect the
            purchasers of the securities?
            <TooltipItem placement="top" id="item_8" content="SEC Form CF : Item 1.2(a-2)" />
          </Label>
          <Col md="9">
            <Controller
              name="exercise_rights"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.exercise_rights)}
                />
              )}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            How the securities being offered are being valued
            <TooltipItem placement="top" id="item_9" content="SEC Form CF : Item 1.2(a-3)" />
          </Label>
          <Col md="9">
            <Controller
              name="securities_offered"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.securities_offered)}
                />
              )}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Risk to purchasers of the securities relating to minority ownership in the issuer and
            the risks associated with corporate actions
            <TooltipItem placement="top" id="item_10" content="SEC Form CF : Item 1.2(a-4)" />
          </Label>
          <Col md="9">
            <Controller
              name="risk_purchase"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.risk_purchase)}
                />
              )}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Description of the restrictions on transfer of the securities;
            <TooltipItem placement="top" id="item_11" content="SEC Form CF : Item 1.2(a-5)" />
          </Label>
          <Col md="9">
            <Controller
              name="restriction_description"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  rows="5"
                  invalid={!isEmpty(errors.restriction_description)}
                />
              )}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Direct or indirect material interest
            <TooltipItem placement="top" id="item_12" content="SEC Form CF : Item 1.3" />
          </Label>
          <Col md="9">
            <Controller
              name="material_interest"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  placeholder="Description of any transaction since the beginning of the issuer’s last fiscal year,
                  or any currently proposed transaction, to which the issuer was or is to be a party
                  and the amount involved exceeds five (5) percent of the aggregate amount of capital
                  raised by the issuer during the 12-month period, inclusive of the amount the issuer
                  seeks to raise in the current offering, in which any of the following persons had or
                  is to have a direct or indirect material interest (e.g. director or officer,
                  beneficial owners, promoter, member of the family)."
                  rows="5"
                  invalid={!isEmpty(errors.material_interest)}
                />
              )}
            />
            <FormText>
              (r) A description of any transaction since the beginning of the issuer’s last fiscal
              year, or any currently proposed transaction, to which the issuer was or is to be a
              party and the amount involved exceeds five (5) percent of the aggregate amount of
              capital raised by the issuer during the 12-month period, inclusive of the amount the
              issuer seeks to raise in the current offering, in which any of the following persons
              had or is to have a direct or indirect material interest: <br />
              <table>
                <tr>
                  <td style={{ paddingLeft: 10 }}>(1) Any director or officer of the issuer;</td>
                </tr>
                <tr>
                  <td style={{ paddingLeft: 10 }}>
                    (2) Any person who is, as of the most recent practicable date but no earlier
                    than 120 days prior to the date the offering statement or report is filed, the
                    beneficial owner of 10 percent or more of the issuer’s outstanding voting equity
                    securities, calculated on the basis of voting power;
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingLeft: 10 }}>
                    (3) If the issuer was incorporated or organized within the past three years, any
                    promoter of the issuer; or
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingLeft: 10 }}>
                    (4) Any member of the family of any of the foregoing persons, which includes a
                    child, stepchild, grandchild, parent, stepparent, grandparent, spouse or spousal
                    equivalent, sibling, mother-in-law, father-in-law, son- in-law, daughter-in-law,
                    brother-in-law, or sister-in-law, and shall include adoptive relationships. The
                    term spousal equivalent means a cohabitant occupying a relationship generally
                    equivalent to that of a spouse.
                  </td>
                </tr>
              </table>
            </FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3">
            Material factors making an investment in the issuer risky or speculative
            <TooltipItem placement="top" id="item_13" content="SEC Form CF : Item 1.4" />
          </Label>
          <Col md="9">
            <Controller
              name="material_factor"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="textarea"
                  placeholder="Discuss below the material factors that make an investment in the issuer speculative or risky."
                  rows="5"
                  invalid={!isEmpty(errors.material_factor)}
                />
              )}
            />
          </Col>
        </FormGroup>
      </FormGroup>
      <hr />
      <h3>Document Requirements</h3>
      <Panel>
        <PanelHeader></PanelHeader>
        <PanelBody>
          <div className="table-responsive">
            <table className="table table-striped mb-0 align-middle">
              <thead>
                <tr>
                  <th>Document Name</th>
                  <th>Date Added</th>
                  <th style={{ textAlign: 'center' }}>File</th>
                  {/* <th width="15%" style={{ textAlign: 'center' }}>
                    Action Items
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {loan.documents &&
                  loan.documents.map((doc) => {
                    return (
                      <tr>
                        <td>{doc.document_type.name}</td>
                        <td>
                          {doc.document_file.view != null
                            ? moment(doc.updated_at).format('MMMM DD, YYYY')
                            : 'Not Available'}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {doc.document_file.view != null ? (
                            <>
                              <a
                                className="btn btn-sm btn-primary w-60px me-1 "
                                href={doc.document_file.view}
                                target="_blank"
                              >
                                Preview
                              </a>
                              {/* <a
                                className="btn btn-sm btn-primary w-60px me-1 "
                                href={doc.attachment_download_url}
                                target="_blank"
                              >
                                Download
                              </a> */}
                            </>
                          ) : (
                            'Not Available'
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </PanelBody>
      </Panel>
      <hr />
      {((loanDetails && loanDetails.status && loanDetails.status.id === 4) ||
        (loanDetails && loanDetails.status && loanDetails.status.id == 6) ||
        (loanDetails && loanDetails.status && loanDetails.status.id == 2)) && (
        <>
          <Form>
            <FormGroup tag="fieldset">
              <h3>Credit Rating</h3>
              <FormGroup row>
                <Label md="3">Credit Rating</Label>
                <Col md="9">
                  <Controller
                    name="credit_rating"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <ReactSelect
                        inputRef={ref}
                        value={optionRating.find((c) => c.value === value)}
                        onChange={(val) => onChange(val.value)}
                        options={optionRating}
                        isOptionDisabled={(option) => option.disabled}
                      />
                    )}
                  />
                  <FormText color="black">
                    {(watchCreditRating &&
                      optionRating.find((x) => x.value === watchCreditRating).description) ||
                      ''}
                  </FormText>
                  <FormFeedback style={{ display: errors.credit_rating ? 'block' : 'none' }}>
                    {errors.credit_rating?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label md="3">Financial Position Rating</Label>
                <Col md="9">
                  <Controller
                    name="financial_position_rating"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <ReactSelect
                        inputRef={ref}
                        value={optionRating.find((c) => c.value === value)}
                        onChange={(val) => onChange(val.value)}
                        options={optionRating}
                        isOptionDisabled={(option) => option.disabled}
                      />
                    )}
                  />
                  <FormText color="black">
                    {(watchPositionRating &&
                      optionRating.find((x) => x.value === watchPositionRating).description) ||
                      ''}
                  </FormText>
                  <FormFeedback
                    style={{ display: errors.financial_position_rating ? 'block' : 'none' }}
                  >
                    {errors.financial_performance_rating?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Financial Performance Rating</Label>
                <Col md="9">
                  <Controller
                    name="financial_performance_rating"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <ReactSelect
                        inputRef={ref}
                        value={optionRating.find((c) => c.value === value)}
                        onChange={(val) => onChange(val.value)}
                        options={optionRating}
                        isOptionDisabled={(option) => option.disabled}
                      />
                    )}
                  />
                  <FormText color="black">
                    {(watchPerformanceRating &&
                      optionRating.find((x) => x.value === watchPerformanceRating).description) ||
                      ''}
                  </FormText>
                  <FormFeedback
                    style={{ display: errors.financial_performance_rating ? 'block' : 'none' }}
                  >
                    {errors.financial_performance_rating?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Credit Score</Label>
                <Col md="9">
                  <Controller
                    name="credit_score"
                    control={control}
                    rules={{
                      min:
                        watchCreditRating &&
                        optionRating.find((x) => x.value === watchCreditRating).min,
                      max:
                        watchCreditRating &&
                        optionRating.find((x) => x.value === watchCreditRating).max
                    }}
                    render={({ field }) => (
                      <InputGroup>
                        <Input
                          {...field}
                          type="number"
                          disabled
                          className="number-only-field"
                          invalid={!isEmpty(errors.credit_score)}
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    )}
                  />
                  <FormFeedback style={{ display: errors.credit_score ? 'block' : 'none' }}>
                    {errors.credit_score?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Credit Report</Label>
                <Col md="9">
                  <Controller
                    name="credit_rating_report"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => {
                      return (
                        <FileUpload
                          inputRef={ref}
                          onDrop={onChange}
                          onChange={onChange}
                          imagefile={value}
                          invalid={!isEmpty(errors.credit_rating_report)}
                        />
                      );
                    }}
                  />
                  <FormFeedback style={{ display: errors.credit_rating_report ? 'block' : 'none' }}>
                    {errors.credit_rating_report?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3">Outlook</Label>
                <Col md="9">
                  <Controller
                    name="outlook"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        type="textarea"
                        rows="5"
                        invalid={!isEmpty(errors.outlook)}
                      />
                    )}
                  />
                  <FormFeedback style={{ display: errors.outlook ? 'block' : 'none' }}>
                    {errors.outlook?.message}
                  </FormFeedback>
                </Col>
              </FormGroup>
            </FormGroup>
            <Row>
              <Col className="d-flex justify-content-start" />
              <Col className="d-flex justify-content-end">
                <ButtonApplyCreditRating onSubmit={handleSubmit(onStepOneSubmit)} />
              </Col>
            </Row>
          </Form>
          <hr />
          {/* <h3>Seedin Credit Risk Committee</h3>
          <Panel style={{ opacity: '0.5' }}>
            <PanelHeader></PanelHeader>
            <PanelBody>
              <div className="table-responsive" style={{ opacity: '0.5' }}>
                <table className="table table-striped mb-0 align-middle">
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Position and Function</th>
                      <th>Contact</th>
                      <th>Approve?</th>
                      <th width="10%">Action Items</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sir Anson</td>
                      <td>Big Boss</td>
                      <td>
                        <span>+639164289004</span>
                      </td>
                      <td>
                        <span>No</span>
                      </td>
                      <td nowrap="true">
                        <a className="btn btn-sm btn-primary w-60px me-1 " href="#">
                          Notify
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Sir Edison</td>
                      <td>Boss</td>
                      <td>
                        <span>+639164289004</span>
                      </td>
                      <td>
                        <span>Yes</span>
                      </td>
                      <td nowrap="true">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </PanelBody>
          </Panel>
          <hr /> */}
        </>
      )}

      <Row>
        <Col className="d-flex justify-content-start">
          {loanDetails && loanDetails.status && loanDetails.status.id === 4 && <ButtonReject />}
        </Col>
        <Col className="d-flex justify-content-end">
          {loanDetails && loanDetails.status && loanDetails.status.id === 4 && (
            <ButtonSendOfferAmount
              amount={loan.amount}
              loan_tenor={loan.loan_tenor}
              disabled={isNull(loanDetails.credit_score)}
            />
          )}

          {((loanDetails && loanDetails.status && loanDetails.status.id === 4) ||
            (loanDetails && loanDetails.status && loanDetails.status.id === 12)) && (
            <ButtonApprove
              onSubmit={getLoanRequest}
              amount={loan.amount}
              loan_tenor={loan.loan_tenor}
              disabled={isNull(loanDetails.credit_score)}
            />
          )}
          {loanDetails && loanDetails.status && loanDetails.status.id === 6 && (
            <ButtonStartProject user_id={loan.user_id} loan_request_id={loan.id} />
          )}

          {/* {loanDetails && loanDetails.status && loanDetails.status.id === 2 && (
            <ButtonReview
              onSubmit={() => {
                setLoanDetails({
                  ...loanDetails,
                  status: 4
                });
              }}
              amount={loan.amount}
              loan_tenor={loan.loan_tenor}
            />
          )} */}

          {/* <button className="btn btn-primary" type="button">
            Approve
          </button> */}
        </Col>
      </Row>
    </>
  );
};

const ButtonApplyCreditRating = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onConfirm = () => {
    setModalReject(false);

    props.onSubmit();
    // const params = {
    //   remarks
    // };
    // API.loanRequestStatus(id, 'reject', params).then((res) => {
    //   Notification.success('Loan Request Reject!', 'Loan request successfully rejected');
    //   setModalReject(false);
    //   history.goBack();
    // });
  };

  return (
    <>
      <button className="btn btn-green mr-2 text-white" type="button" onClick={toggleModalReject}>
        Apply Credit Rating
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Apply Credit Rating</span>}
        customIcon={<i className="fa fa-star fa-5x mb-3 text-green"></i>}
        custom
        showCancel
        confirmBtnText="Apply"
        confirmBtnBsStyle="green"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onConfirm}
        onCancel={toggleModalReject}
        show={modalReject}
      >
        <p>Are you sure you want to apply the credit ratings?</p>
      </SweetAlert>
    </>
  );
};

const ButtonReject = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onConfirm = () => {
    setModalReject(false);
    const params = {
      remarks
    };
    API.loanRequestStatus(id, 'reject', params).then((res) => {
      Notification.success('Loan Request Reject!', 'Loan request successfully rejected');
      setModalReject(false);
      history.goBack();
    });
  };

  const [remarks, setRemarks] = useState('');

  const optionReasons = [
    { value: 'Incomplete Details', label: 'Incomplete Details', disabled: true },
    { value: 'Invalid Information', label: 'Invalid Information', disabled: false },
    { value: 'Others', label: 'Others' }
  ];

  const onChangeText = (e) => {
    setRemarks(e.target.value);
  };

  const onChangeSelect = (e) => {
    setReason(e.value);
  };

  return (
    <>
      <button className="btn btn-danger mr-2" type="button" onClick={toggleModalReject}>
        Reject
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Loan Request</span>}
        danger
        showCancel
        confirmBtnText="Reject"
        confirmBtnBsStyle="danger"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onConfirm}
        onCancel={toggleModalReject}
        show={modalReject}
        dependencies={[remarks]}
      >
        <form>
          <textarea
            type="text"
            className="form-control"
            value={remarks}
            onChange={onChangeText}
            placeholder="Please indicate the reason for rejecting loan request"
          />
        </form>
      </SweetAlert>
    </>
  );
};

const ButtonStartProject = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [modal, setModal] = useState(false);

  const { badges } = useAppState();

  const toggleModal = async () => {
    await setModal(!modal);
  };

  const onConfirm = () => {
    // API.loanRequestStatus(id, 'approve').then((res) => {
    // Notification.success('Loan Request Approved!', 'Loan request successfully approved');
    setModal(false);
    // props.onClick();
    if (badges.jobs > 0) {
      Notification.info('Server Background Processing', 'Please try again later.');
    } else {
      history.push(`/projects/${props.user_id}/start/${props.loan_request_id}`);
    }

    // });
  };

  return (
    <>
      <button className="btn btn-green mr-2 text-white" type="button" onClick={toggleModal}>
        Start as Project
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap text-white">Start As Project?</span>}
        custom
        customIcon={<i className="fa fa-leaf fa-5x mb-3 text-green"></i>}
        // customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
        showCancel
        confirmBtnText="Yes, start as project!"
        confirmBtnBsStyle="green"
        confirmBtnCssClass="text-white"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onConfirm}
        onCancel={toggleModal}
        show={modal}
      />
    </>
  );
};

const ButtonApprove = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [modal, setModal] = useState(false);

  const toggleModal = async () => {
    await setModal(!modal);
  };

  const onConfirm = () => {
    API.loanRequestStatus(id, 'approve').then((res) => {
      Notification.success('Loan Request Approved!', 'Loan request successfully approved');
      setModal(false);
      props.onSubmit();
    });
  };

  return (
    <>
      <button
        className={`btn ${props.disabled ? 'btn-gray' : 'btn-info'} mr-2`}
        type="button"
        disabled={props.disabled}
        onClick={toggleModal}
      >
        Approve
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Approve Loan Request?</span>}
        success
        showCancel
        confirmBtnText="Approve"
        confirmBtnBsStyle="success"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onConfirm}
        onCancel={toggleModal}
        show={modal}
      >
        <p>Are you sure you want to approve this loan request?</p>
        <b>
          {PriceFormat(props.amount)} for {props.loan_tenor} months
        </b>
      </SweetAlert>
    </>
  );
};

const schemaSendOffer = yup.object().shape({
  offer_min_loan_amount: yup
    .number()
    .positive()
    .min(1, 'Minimum amount must be greater than ₱0.00')
    .required('Minimum amount is required'),
  offer_min_tenor: yup
    .number()
    .typeError('Tenure must be a number')
    .positive()
    .min(1, 'Minimum tenure must be 1 month')
    .max(12, 'Maximum tenure must be 12 month')
    .required('Tenure is required'),
  offer_recommended_loan_amount: yup
    .number()
    .positive()
    .min(1, 'Recommended amount must be greater than ₱0.00')
    .required('Recommended amount is required'),
  offer_recommended_tenor: yup
    .number()
    .typeError('Tenure must be a number')
    .positive()
    .min(1, 'Minimum tenure must be 1 month')
    .max(12, 'Maximum tenure must be 12 month')
    .required('Tenure is required'),
  offer_max_loan_amount: yup
    .number()
    .positive()
    .min(1, 'Maximum amount must be greater than ₱0.00')
    .required('Maximum amount is required'),
  offer_max_tenor: yup
    .number()
    .typeError('Tenure must be a number')
    .positive()
    .min(1, 'Minimum tenure must be 1 month')
    .max(12, 'Maximum tenure must be 12 month')
    .required('Tenure is required')
});

const ButtonSendOfferAmount = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const [modal, setModal] = useState(false);

  const toggleModal = async () => {
    await setModal(!modal);
  };

  const {
    watch,
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    resolver: yupResolver(schemaSendOffer)
  });

  console.log(errors);
  const onConfirm = (data) => {
    console.log(data);
    API.loanRequestStatus(id, 'offer-to-issuer', data)
      .then((res) => {
        setModal(false);
        Notification.success(
          'Send Offer Amount!',
          'Recommended loan term offers successfully sent'
        );
        setTimeout(() => {
          history.goBack();
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <>
      <button
        className={`btn mr-2 text-white ${props.disabled ? 'btn-gray' : 'btn-info'}`}
        type="button"
        disabled={props.disabled}
        onClick={toggleModal}
      >
        Send Offer Amount
      </button>

      <SweetAlert
        title={<span className="text-black text-wrap">Send Offer Amount</span>}
        info
        showCancel
        style={{ width: '50em' }}
        confirmBtnText="Send Offer Amount"
        confirmBtnCssClass="text-white"
        confirmBtnBsStyle="info"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={handleSubmit(onConfirm)}
        onCancel={toggleModal}
        show={modal}
        dependencies={[]}
      >
        <FormGroup row>
          <Col md="6">
            <CurrencyInput
              prefix="₱"
              defaultValue={props.amount}
              decimalsLimit={2}
              disabled
              className="form-control"
            />
          </Col>
          <Col md="6">
            <Input disabled value={props.loan_tenor} />
          </Col>
        </FormGroup>
        <Form>
          <FormGroup tag="fieldset">
            <FormGroup row>
              <Col md="6">
                <Controller
                  name="offer_min_loan_amount"
                  isClearable
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <CurrencyInput
                      inputRef={ref}
                      value={value}
                      prefix="₱"
                      placeholder="Minimum Loan Amount"
                      decimalsLimit={2}
                      onValueChange={(value, name) => onChange(value)}
                      className={`form-control ${errors.offer_min_loan_amount ? 'is-invalid' : ''}`}
                    />
                  )}
                />
                <FormFeedback style={{ display: errors.offer_min_loan_amount ? 'block' : 'none' }}>
                  {errors.offer_min_loan_amount?.message}
                </FormFeedback>
              </Col>
              <Col md="6">
                <Controller
                  name="offer_min_tenor"
                  isClearable
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="Minimum Tenure"
                      invalid={!isEmpty(errors.offer_min_tenor)}
                    />
                  )}
                />
                <FormFeedback style={{ display: errors.offer_min_tenor ? 'block' : 'none' }}>
                  {errors.offer_min_tenor?.message}
                </FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="6">
                <Controller
                  name="offer_recommended_loan_amount"
                  isClearable
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <CurrencyInput
                      inputRef={ref}
                      value={value}
                      prefix="₱"
                      placeholder="Recommended Loan Amount"
                      decimalsLimit={2}
                      onValueChange={(value, name) => onChange(value)}
                      className="form-control"
                    />
                  )}
                />
              </Col>
              <Col md="6">
                <Controller
                  name="offer_recommended_tenor"
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      placeholder="Recommended Tenure"
                      invalid={!isEmpty(errors.amount)}
                    />
                  )}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="6">
                <Controller
                  name="offer_max_loan_amount"
                  isClearable
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <CurrencyInput
                      inputRef={ref}
                      value={value}
                      prefix="₱"
                      placeholder="Maximum Loan Amount"
                      decimalsLimit={2}
                      onValueChange={(value, name) => onChange(value)}
                      className="form-control"
                    />
                  )}
                />
              </Col>
              <Col md="6">
                <Controller
                  name="offer_max_tenor"
                  isClearable
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      placeholder="Maximum Tenure"
                      invalid={!isEmpty(errors.amount)}
                    />
                  )}
                />
              </Col>
            </FormGroup>
          </FormGroup>
        </Form>
      </SweetAlert>
    </>
  );
};
export default IssuerInformation;
