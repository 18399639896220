import React, { memo, useEffect, useState } from 'react';
import Avatar from 'react-avatar-edit';
import { isBase64 } from '../../utils';

const UploadAvatar = (props) => {
  const [source, setSource] = useState(null);
  useEffect(() => {
    setSource(props.source);
  }, []);

  const onClose = () => {
    props.onChange(null);
  };

  const onCrop = (preview) => {
    props.onChange(preview);
  };

  const onDelete = () => {
    setSource(null);
    props.onChange(null);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 716800) {
      alert('File is too big!');
      elem.target.value = '';
    }
  };

  return (
    <div>
      {source == null || isBase64(source) ? (
        <Avatar
          {...props}
          width={200}
          height={200}
          onCrop={onCrop}
          onClose={onClose}
          imageWidth={200}
          exportAsSquare
          onBeforeFileLoad={onBeforeFileLoad}
          src={source}
        />
      ) : (
        <div className="d-flex">
          <img src={source} alt="Preview" style={{ width: 200, height: 200, borderRadius: 100 }} />
          <button className="btn btn-danger btn-icon btn-circle btn-sm" onClick={onDelete}>
            <i className="fa fa-times"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default memo(UploadAvatar);
