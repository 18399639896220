import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PageContext from '../../config/page-settings.js';

import PartnersList from './PartnersList.js';

const PartnersManagement = (props) => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const createRole = () => {
    props.history.push('/settings/industry/create');
  };

  return (
    <div>
      <h1 className="page-header">
        Seedin Partners
        <button className="btn btn-secondary btn-icon btn-circle btn-md ml-2">
          <i className="fa fa-download" style={{ color: 'white' }}></i>
        </button>
      </h1>
      <PartnersList />
    </div>
  );
};

export default withRouter(PartnersManagement);
