import React, { useState, useContext }from 'react';
import { Route } from 'react-router-dom';
import PageContext, { PageSettings } from '../../config/page-settings.js';
import FloatSubMenuList from './float-sub-menu-list.jsx';


const FloatSubMenu = props => {

	const pageContext = useContext(PageContext);
	const [active, setActive] = useState(-1);
	const [clicked, setClicked] = useState(-1);
  

	const handleExpand = (e, i, match) => {
	  e.preventDefault();
  
	  if (clicked === -1 && match) {
		setActive(-1);
		setClicked(1);
	  } else {
		setActive(active === i ? -1 : i);
		setClicked(1);
	  }
	};

	return (
		<PageSettings value={props.value}>
				<div id="float-sub-menu" 
					onMouseOver={pageContext.handleFloatSubMenuOnMouseOver} 
					onMouseOut={pageContext.handleFloatSubMenuOnMouseOut} 
					className={"float-sub-menu-container " + (pageContext.pageFloatSubMenuActive ? 'd-block' : 'd-none')} 
					style={{ 
						left: pageContext.pageFloatSubMenuLeft, 
						top: pageContext.pageFloatSubMenuTop, 
						bottom: pageContext.pageFloatSubMenuBottom 
					}}>
					<div className="float-sub-menu-arrow" style={{ 
						top: pageContext.pageFloatSubMenuArrowTop, 
						bottom: pageContext.pageFloatSubMenuArrowBottom 
					}}>
					</div>
					<div className="float-sub-menu-line" style={{ 
						top: pageContext.pageFloatSubMenuLineTop, 
						bottom: pageContext.pageFloatSubMenuLineBottom 
					}}>
					</div>
					<ul className="float-sub-menu">
						{pageContext.pageFloatSubMenu.children && pageContext.pageFloatSubMenu.children.map((menu, i) => (
							<Route path={menu.path} exact={menu.exact} key={i} children={({ match }) => (
								<FloatSubMenuList
									data={menu} 
									key={i} 
									expand={(e) => handleExpand(e, i, match)}
									active={i === active} 
									clicked={clicked}
								/>
							)} />
						))}
					</ul>
				</div>
		</PageSettings>
	);
}

export default FloatSubMenu;
