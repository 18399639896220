import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import PageContext from '../../config/page-settings.js';
import SidebarNavList from './sidebar-nav-list.js';
import { useAppState } from '../../overmind';
import Menu from './menu.js';

const SidebarNav = (props) => {
  const pageContext = useContext(PageContext);
  const { badges } = useAppState();

  const [active, setActive] = useState(-1);
  const [clicked, setClicked] = useState(-1);
  const [menus, setMenus] = useState(Menu(badges));

  useEffect(() => {
    setMenus(Menu(badges));
  }, [props]);

  const handleExpand = (e, i, match) => {
    e.preventDefault();

    if (clicked === -1 && match) {
      setActive(-1);
      setClicked(1);
    } else {
      setActive(active === i ? -1 : i);
      setClicked(1);
    }
  };

  const handleSidebarSearch = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();

    setMenus(() => {
      let newMenus = [];
      if (searchValue !== '') {
        newMenus = menus.filter((item) => {
          let title = item.title;
          title = title.toLowerCase();
          if (title.search(searchValue) > -1) {
            item.search = true;
            return true;
          } else {
            if (item.children) {
              for (var i = 0; i < item.children.length; i++) {
                let title2 = item.children[i]['title'];
                title2 = title2.toLowerCase();

                if (title2.search(searchValue) > -1) {
                  item.search = true;
                  return true;
                }
              }
            }
            return false;
          }
        });
      } else {
        newMenus = menus.filter((item) => {
          item.search = false;
          return true;
        });
      }
      return {
        menus: newMenus
      };
    });
  };

  return (
    <ul className="nav">
      {pageContext.pageSidebarSearch && (
        <li className="nav-search">
          <input
            type="text"
            className="form-control"
            placeholder="Sidebar menu filter..."
            onKeyUp={handleSidebarSearch}
          />
        </li>
      )}
      <li className="nav-header">Navigation</li>
      {/* <Switch> */}
      {menus.map((menu, i) => (
        <Route
          path={menu.path}
          exact={menu.exact}
          key={i}
          children={({ match }) => (
            <SidebarNavList
              data={menu}
              key={i}
              expand={(e) => handleExpand(e, i, match)}
              active={i === active}
              clicked={clicked}
            />
          )}
        />
      ))}
      {/* </Switch> */}
    </ul>
  );
};

export default SidebarNav;

// import React from 'react';
// import { Route } from 'react-router-dom';
// // import { PageContext } from './../../config/page-settings.js
// import PageContext from '../../config/page-settings.js';
// import SidebarNavList from './sidebar-nav-list.js';
// import menus from './menu.js';

// class SidebarNav extends React.Component {
//   static contextType = PageContext;

//   constructor(props) {
//     super(props);
//     this.state = {
//       active: -1,
//       clicked: -1,
//       menus: menus
//     };

//     this.handleSidebarSearch = this.handleSidebarSearch.bind(this);
//   }

//   handleExpand(e, i, match) {
//     e.preventDefault();

//     if (this.state.clicked === -1 && match) {
//       this.setState((state) => ({
//         active: -1,
//         clicked: 1
//       }));
//     } else {
//       this.setState((state) => ({
//         active: this.state.active === i ? -1 : i,
//         clicked: 1
//       }));
//     }
//   }

//   handleSidebarSearch(e) {
//     let searchValue = e.target.value;
//     searchValue = searchValue.toLowerCase();

//     this.setState((state) => {
//       let newMenus = [];
//       if (searchValue !== '') {
//         newMenus = menus.filter((item) => {
//           let title = item.title;
//           title = title.toLowerCase();
//           if (title.search(searchValue) > -1) {
//             item.search = true;
//             return true;
//           } else {
//             if (item.children) {
//               for (var i = 0; i < item.children.length; i++) {
//                 let title2 = item.children[i]['title'];
//                 title2 = title2.toLowerCase();

//                 if (title2.search(searchValue) > -1) {
//                   item.search = true;
//                   return true;
//                 }
//               }
//             }
//             return false;
//           }
//         });
//       } else {
//         newMenus = menus.filter((item) => {
//           item.search = false;
//           return true;
//         });
//       }
//       return {
//         menus: newMenus
//       };
//     });
//   }

//   render() {
//     return (
//       <ul className="nav">
//         {this.context.pageSidebarSearch && (
//           <li className="nav-search">
//             <input
//               type="text"
//               className="form-control"
//               placeholder="Sidebar menu filter..."
//               onKeyUp={this.handleSidebarSearch}
//             />
//           </li>
//         )}
//         <li className="nav-header">Navigation</li>
//         {this.state.menus.map((menu, i) => (
//           <Route
//             path={menu.path}
//             exact={menu.exact}
//             key={i}
//             children={({ match }) => (
//               <SidebarNavList
//                 data={menu}
//                 key={i}
//                 expand={(e) => this.handleExpand(e, i, match)}
//                 active={i === this.state.active}
//                 clicked={this.state.clicked}
//               />
//             )}
//           />
//         ))}
//       </ul>
//     );
//   }
// }

// export default SidebarNav;
