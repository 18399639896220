import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Panel, PanelHeader, PanelBody } from './../../../../components/panel/panel.jsx';

const DirectorsAndOfficersList = (props) => {
  const { directors } = props;

  const parseEmployment = (employment) => {
    const employmentList = JSON.parse(employment);
    return (
      <>
        <span>
          1: {employmentList.position_1 || 'N/A'} - {employmentList.former_office_name_1 || 'N/A'}
        </span>
        <br />
        <span>
          2: {employmentList.position_2 || 'N/A'} - {employmentList.former_office_name_2 || 'N/A'}
        </span>
        <br />
        <span>
          3: {employmentList.position_3 || 'N/A'} - {employmentList.former_office_name_3 || 'N/A'}
        </span>
      </>
    );
  };
  return (
    <>
      <h3>Director & Officers</h3>
      <Panel>
        <PanelHeader></PanelHeader>
        <PanelBody>
          <div class="table-responsive">
            <table class="table table-striped mb-0 align-middle">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Position and Function</th>
                  <th>Contact</th>
                  <th>Employment</th>
                  <th>Beneficial Owner</th>
                  {/* <th width="10%">Action Items</th> */}
                </tr>
              </thead>
              <tbody>
                {directors.map((dr) => (
                  <tr key={dr.id}>
                    <td>{dr.full_name}</td>
                    <td>
                      {dr.position}
                      <br />
                      {dr.function}
                    </td>
                    <td>
                      <span>{dr.mobile_number}</span>
                      <br />
                      <span>{dr.address}</span>
                    </td>
                    <td>
                      {parseEmployment(dr.employment_details)}

                      <br />
                    </td>
                    <td>{dr.is_beneficial_owner == 1 ? `Yes (${dr.ownership}%)` : 'No'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </PanelBody>
      </Panel>
      {/* <Row>
        <Col className="d-flex justify-content-end">
          <ButtonReject />
          <button className="btn btn-primary" type="button" onClick={props.prevStep}>
            Approve
          </button>
        </Col>
      </Row> */}
    </>
  );
};

const ButtonReject = (props) => {
  const [modalReject, setModalReject] = useState(false);

  const toggleModalReject = async () => {
    await setModalReject(!modalReject);
  };

  const onConfirm = () => {
    setModalReject(false);
    // toggleModalReject().then(() => {
    //   // setTimeout(() => {
    //   //   props.onConfirm();
    //   // }, 300);
    // });
  };

  const [reason, setReason] = useState('');

  const onChangeText = (e) => {
    setReason(e.target.value);
  };

  return (
    <>
      <button className="btn btn-danger mr-2" type="button" onClick={toggleModalReject}>
        Reject
      </button>
      <SweetAlert
        title={<span className="text-black text-wrap">Directors and Officers</span>}
        danger
        showCancel
        confirmBtnText="Reject"
        confirmBtnBsStyle="danger"
        focusCancelBtn={false}
        focusConfirmBtn={false}
        onConfirm={onConfirm}
        onCancel={toggleModalReject}
        show={modalReject}
        dependencies={[reason]}
      >
        <form>
          <textarea
            type="text"
            className="form-control"
            value={reason}
            onChange={onChangeText}
            placeholder="Please indicate the reason for rejecting directors and officers"
          />
        </form>
      </SweetAlert>
    </>
  );
};

export default DirectorsAndOfficersList;
