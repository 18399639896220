import React, { useContext, useState, useEffect } from 'react';
import PageContext from '../../config/page-settings.js';
import { withRouter } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { PageHeader } from '../../components/common';
import { Row, Col } from 'reactstrap';

import FormInformation from './components/FormInformation.js';
import FormSourceOfIncome from './components/FormSourceOfIncome.js';
import FormWithdrawalMethod from './components/FormWithdrawalMethod.js';
import FormSummary from './components/FormSummary.js';
import * as API from '../../services/api';

const UserCreate = (props) => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.handleSetPageSidebar(true);
    pageContext.handleSetPageHeader(true);
    pageContext.handleSetPageContentFullWidth(false);
  }, []);

  const [currentStep, setCurrentStep] = useState(1);

  const [investor, setInvestor] = useState({});

  const onSubmit = (data) => {
    if (currentStep == 1) {
      setCurrentStep(currentStep + 1);
      setInvestor(data);
      API.getInvestor(data.id).then((res) => {
        setInvestor(res.data.data);
      });
    }
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      <PageHeader title="Investor - Create" />
      <Row>
        <Col>
          <Panel>
            <PanelHeader />
            <PanelBody>
              <div className="row">
                <div className="col-xl-12 nav-wizards-container">
                  <nav className="nav nav-wizards-1 mb-2">
                    <div className="nav-item col">
                      <div
                        className={
                          'nav-link ' +
                          (currentStep == 1
                            ? 'active '
                            : currentStep > 1
                            ? 'completed '
                            : 'disabled ')
                        }
                      >
                        <div className="nav-no">1</div>
                        <div className="nav-text">Information</div>
                      </div>
                    </div>

                    <div className="nav-item col">
                      <div
                        className={
                          'nav-link ' +
                          (currentStep == 2
                            ? 'active '
                            : currentStep > 2
                            ? 'completed '
                            : 'disabled ')
                        }
                      >
                        <div className="nav-no">2</div>
                        <div className="nav-text">Source Of Income</div>
                      </div>
                    </div>

                    <div className="nav-item col">
                      <div
                        className={
                          'nav-link ' +
                          (currentStep == 3
                            ? 'active '
                            : currentStep > 3
                            ? 'completed '
                            : 'disabled ')
                        }
                      >
                        <div className="nav-no">3</div>
                        <div className="nav-text">Payment Method(s)</div>
                      </div>
                    </div>

                    <div className="nav-item col">
                      <div
                        className={
                          'nav-link ' +
                          (currentStep == 4
                            ? 'active '
                            : currentStep > 4
                            ? 'completed '
                            : 'disabled ')
                        }
                      >
                        <div className="nav-no">4</div>
                        <div className="nav-text">Summary</div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <Row>
                <Col sm="12" md="12" lg={{ size: 10, offset: 1 }}>
                  <FormInformation currentStep={currentStep} onSubmit={onSubmit} />
                  <FormSourceOfIncome
                    currentStep={currentStep}
                    prevStep={prevStep}
                    onSubmit={onSubmit}
                    investor={investor}
                  />
                  <FormWithdrawalMethod
                    currentStep={currentStep}
                    prevStep={prevStep}
                    onSubmit={onSubmit}
                    investor={investor}
                  />
                  <FormSummary
                    currentStep={currentStep}
                    prevStep={prevStep}
                    investor={investor}
                    onSubmit={onSubmit}
                  />
                </Col>
              </Row>
            </PanelBody>
          </Panel>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(UserCreate);
