import React, { useState, useEffect, useCallback, useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PageContext from '../../config/page-settings.js';
import * as API from '../../services/api.js';
import { api, setHeaderToken } from '../../services';
import { useLocalStorage, useCountDown } from '../../utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { store } from 'react-notifications-component';
import { useActions } from '../../overmind/index.js';
import { Alert, Spinner } from 'reactstrap';
import { off } from 'codemirror';
import { Notification } from '../../utils';
const initialTime = 5000;
const interval = 1000;

const schemaLogin = yup.object().shape({
  username: yup.string().required('Username is required.'),
  password: yup.string().required('Password is required.').min(8)
});

const LoginForm = (props) => {
  const actions = useActions();
  const {
    register,
    setError,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schemaLogin)
  });

  const [loadingLogin, setLoadingLogin] = useState(false);

  const onLogin = (data) => {
    setLoadingLogin(true);
    actions
      .userLogin(data)
      .then((res) => {
        props.onLogin(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            setError(key, {
              type: 'manual',
              message: value[0]
            });
          }
        } else if (err.response && err.response.status === 401) {
          setError('password', {
            type: 'manual',
            message: err.response.data.password
          });
        } else {
          Notification.error(err);
        }
      })
      .finally(() => {
        setLoadingLogin(false);
      });
  };

  return (
    <form className="margin-bottom-0" onSubmit={handleSubmit(onLogin)}>
      <div className="form-group m-b-20">
        <input
          {...register('username', { required: true })}
          type="text"
          className={`form-control form-control-lg ${errors.username ? 'is-invalid' : ''}`}
          placeholder="Username"
        />
        {errors.username && <div className="invalid-feedback">{errors.username?.message}</div>}
      </div>
      <div className="form-group m-b-20">
        <input
          {...register('password', { required: true })}
          type="password"
          className={`form-control form-control-lg ${errors.password ? 'is-invalid' : ''}`}
          placeholder="Password"
        />
        {errors.password && <div className="invalid-feedback">{errors.password?.message}</div>}
      </div>
      <div className="login-buttons">
        <button type="submit" className="btn btn-success btn-block btn-lg" disabled={loadingLogin}>
          {!loadingLogin ? 'SIGN IN' : <Spinner color="black" size="sm" />}
        </button>
      </div>
    </form>
  );
};

const schemaOtp = yup.object().shape({
  code: yup
    .string()
    .required('OTP code is required')
    .min(6, 'Code must be at least 6 characters')
    .max(6, 'Code must be at most 6 characters')
});

const OTPForm = (props) => {
  const {
    register,
    reset,
    setError,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schemaOtp)
  });

  const [timeLeft, { start }] = useCountDown(initialTime, interval);

  useEffect(() => {
    start();
  }, []);

  const [tokenId, setTokenId] = useState(props.otpData.token_id);

  const restart = useCallback(() => {
    start();
  }, []);

  const onValidate = (data) => {
    const params = {
      code: data.code,
      token_id: tokenId
    };
    API.userValidateOtp(params)
      .then((res) => {
        props.onLogin(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setError('code', {
            type: 'manual',
            message: 'Invalid Code.'
          });
        }
      });
  };

  const resentOtp = (event) => {
    event.preventDefault();
    const params = {
      token_id: props.otpData.token_id
    };

    API.userResendOtp(params).then((res) => {
      reset({ code: '' });
      store.addNotification({
        title: 'Request Resent OTP',
        message: `New OTP Code has been sent ${props.otpData.recipient}`,
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 3000,
          onScreen: true
        }
      });
      setTokenId(res.data.data.token_id);
      restart();
    });
  };

  return (
    <>
      <form className="margin-bottom-0" onSubmit={handleSubmit(onValidate)}>
        <div className="form-group m-b-20 text-center">
          <h3 className="text-white">Please enter the OTP Code to verify your account</h3>
          <p className="text-white">
            OTP (One-time Password) has been sent to {props.otpData.recipient}
          </p>
          <input
            {...register('code', { required: true })}
            type="text"
            className={`form-control form-control-lg ${errors.code ? 'is-invalid' : ''}`}
            placeholder="OTP CODE"
          />
          {errors.code && <div className="invalid-feedback text-left">{errors.code?.message}</div>}
        </div>
        <div className="login-buttons">
          <button type="submit" className="btn btn-success btn-block btn-lg">
            VALIDATE OTP
          </button>
        </div>
      </form>
      <div className="login-buttons mt-3">
        {timeLeft > 0 ? (
          <button className="btn btn-gray btn-block btn-lg" disabled={timeLeft > 0}>
            REQUEST RESEND OTP ({timeLeft})
          </button>
        ) : (
          <button onClick={resentOtp} className="btn btn-primary btn-block btn-lg">
            REQUEST RESEND OTP
          </button>
        )}
      </div>
    </>
  );
};

const Login = (props) => {
  const pageContext = useContext(PageContext);

  const [storedToken, setToken] = useLocalStorage('access_token', '');
  const [storedUser, setUser] = useLocalStorage('user_me', '');

  const [currentStep, setCurrentStep] = useState(1);
  const [otpData, setOtpData] = useState({});

  // useEffect(() => {
  //   pageContext.handleSetPageSidebar(false);
  //   pageContext.handleSetPageHeader(false);
  // }, []);

  const onLogin = (data) => {
    if (data.user.is_required_otp === 0 || currentStep === 2) {
      pageContext.handleSetPageSidebar(true);
      pageContext.handleSetPageHeader(true);
      pageContext.handleSetPageContentFullWidth(false);
      props.history.push('/');
    } else {
      setOtpData(data);
      setCurrentStep(2);
    }
  };

  return (
    <React.Fragment>
      <div className="login-cover">
        <div className="page-container">
          <div
            className="login-cover-image"
            style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-17.jpg)' }}
          ></div>
          <div className="login-cover-bg"></div>
        </div>

        <div className="login login-v2">
          <div className="login-header">
            <div className="brand">
              <img src="/assets/img/logos/logo_seedin_full_white.png" className="img-responsive" />
            </div>
          </div>
          <div className="login-content">
            {currentStep === 1 && <LoginForm onLogin={onLogin} />}
            {currentStep === 2 && <OTPForm otpData={otpData} onLogin={onLogin} />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
